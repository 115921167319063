<template>
  <div class="proportion-compare">
    <dec-block-title>各区域企业占比</dec-block-title>
    <!--    <el-button-->
    <!--      style="float:left;height:90%;margin:0;color:#B2F2F6;width:1vw;"-->
    <!--      type="text"-->
    <!--      icon="el-icon-arrow-left"-->
    <!--      v-show="!showMore"-->
    <!--      @click="handleClick($event)"-->
    <!--    ></el-button>-->
    <!--    <el-button-->
    <!--      style="float:left;height:90%;margin:0;color:#B2F2F6;width:1vw;"-->
    <!--      type="text"-->
    <!--      icon="el-icon-arrow-right"-->
    <!--      v-show="showMore"-->
    <!--      @click="handleClick"-->
    <!--    ></el-button>-->
    <dv-charts :option="option"/>
  </div>
</template>

<script>
import decBlockTitle from '../../decorations/blockTitle2'

function defaultOption() {
  return {
    // color: '#fff',
    legend: {
      data: ['大型', '中型', '小型'],
      top: 20,
      right: 0,
      textStyle: {
        fontSize: 11,
        fill: '#ffffff'
      }
    },
    xAxis: {
      min: 0,
      axisLine: {
        style: {
          stroke: '#324D4D',
          lineDash: [5, 2]
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        style: {
          fontSize: 11,
          fill: '#D0D0D0',
          rotate: 45
        }
      },
      splitLine: {
        stroke: 'red',
        lineWidth: 1
      },
      data: []
    },
    yAxis: {
      min: 0,
      max: 100,
      data: 'value',
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        style: {
          fontSize: 11,
          fill: '#D0D0D0'
        }
      },
      splitLine: {
        style: {
          stroke: '#324D4D',
          lineWidth: 1,
          lineDash: [5, 2]
        }
      }
    },
    series: [
      {
        name: '大型',
        data: [],
        type: 'bar',
        stack: 'a',
        barWidth: 20,
        barStyle: {
          fill: '#165beb'
        },
        tag: 'Big',
        label: {
          show: true,
          position: 'top',
          formatter: function (dataItem) {
            if (dataItem.value === '0') {
              return ''
            } else {
              return dataItem.value
            }
          },
          offset: [23, -5]
        }
      },
      {
        name: '中型',
        data: [],
        type: 'bar',
        stack: 'a',
        barWidth: 20,
        barStyle: {
          fill: '#58a3ff'
        },
        tag: 'Normal',
        label: {
          show: true,
          position: 'top',
          formatter: function (dataItem) {
            if (dataItem.value === '0') {
              return ''
            } else {
              return dataItem.value
            }
          },
          offset: [23, -10]
        }
      },
      {
        name: '小型',
        data: [],
        type: 'bar',
        stack: 'a',
        barWidth: 20,
        barStyle: {
          fill: '#24FFFC'
        },
        tag: 'Small',
        label: {
          show: true,
          position: 'top',
          formatter: function (dataItem) {
            if (dataItem.value === '0') {
              return ''
            } else {
              return dataItem.value
            }
          },
          offset: [23, -15]
        }
      }
    ],
    grid: {
      left: 35,
      top: 17,
      bottom: 32,
      right: 5
    }
  }
}

export default {
  name: 'EnterpriseProportionCompare_chengdu',
  props: ['topList', 'onlineDevice', 'showMore'],
  components: {
    decBlockTitle
  },
  data() {
    return {
      chartsData: null,
      option: defaultOption(),
      chartsLabel: [],
      chartsValue: {
        Big: [],
        Normal: [],
        Small: [],
        maxV: 100
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const t = []
      this.topList.device.forEach((item) => {
        const r = this.onlineDevice.device.find((vo) =>
          item.K.split('/').join('') === vo.K.split('/').join('')
        )
        if (r) {
          let it = Object.assign({}, item)
          it.V = r.V === 0 ? 0 : ((item.V * 100) / r.V).toFixed(2) * 1
          it.N = r.N
          t.push(it)
        }
      })
      console.log(t)
      t.sort((o1, o2) => {
        if (o1.V < o2.V) {
          return 1
        } else if (o1.V > o2.V) {
          return -1
        } else {
          return 0
        }
      })
      t.forEach(e => {
        if (e.V > this.chartsValue.maxV) {
          console.log(e)
          this.chartsValue.maxV = e.V
        }
        this.chartsLabel.push(e.N)
        this.chartsValue.Big.push(e.BigNum)
        this.chartsValue.Normal.push(e.NormalNum)
        this.chartsValue.Small.push(e.SmallNum)
      })
      this.chartsData = {
        chartsLabel: this.chartsLabel,
        chartsValue: this.chartsValue
      }

      if (this.chartsData.chartsLabel.length === 0 || this.chartsData.chartsValue.length === 0) {
        this.option = defaultOption()
      }
      this.option.yAxis.max = Math.floor(this.chartsData.chartsValue.maxV / 100) * 120
      this.handleShowMoreChange(this.showMore)
      this.option = {...this.option}
    },
    handleShowMoreChange(showMore) {
      if (showMore) {
        this.option.xAxis.data = this.chartsData.chartsLabel
        this.option.series.forEach(item => {
          item.data = this.chartsData.chartsValue[item.tag]
          item.label.show = false
        })
      } else {
        this.option.xAxis.data = this.chartsData.chartsLabel.slice(0, 5)
        this.option.series.forEach(item => {
          item.data = this.chartsData.chartsValue[item.tag].slice(0, 5)
        })
      }
    }
  },
  watch: {
    onlineDevice: {
      handler: function () {
        this.initData()
      }
    },
    topList: {
      handler: function () {
        this.initData()
      }
    },
    'contentData': {
      handler: function (val, oldVal) {

      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.proportion-compare {
  height: 100%;
  box-sizing: border-box;
  background-color: #011d24;

  .dv-charts-container {
    height: calc(100% - 45px);
  }
}
</style>
