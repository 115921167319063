export const GKX = [
  {
    coords: [
      [
        104.0989990234375,
        30.5712890625
      ],
      [
        104.09814453125,
        30.5712890625
      ],
      [
        104.0989990234375,
        30.5712890625
      ],
      [
        104.09771728515625,
        30.5712890625
      ],
      [
        104.0968017578125,
        30.5712890625
      ],
      [
        104.095947265625,
        30.5712890625
      ],
      [
        104.0948486328125,
        30.5712890625
      ],
      [
        104.09417724609375,
        30.5712890625
      ],
      [
        104.093505859375,
        30.5712890625
      ],
      [
        104.09197998046875,
        30.5712890625
      ],
      [
        104.09088134765625,
        30.571044921875
      ],
      [
        104.0902099609375,
        30.571044921875
      ],
      [
        104.08978271484375,
        30.57086181640625
      ],
      [
        104.09002685546875,
        30.57196044921875
      ],
      [
        104.09002685546875,
        30.5726318359375
      ],
      [
        104.0902099609375,
        30.57305908203125
      ],
      [
        104.09002685546875,
        30.57373046875
      ],
      [
        104.08953857421875,
        30.57501220703125
      ],
      [
        104.08953857421875,
        30.57611083984375
      ],
      [
        104.08953857421875,
        30.5767822265625
      ],
      [
        104.08953857421875,
        30.577880859375
      ],
      [
        104.08935546875,
        30.57855224609375
      ],
      [
        104.08953857421875,
        30.5792236328125
      ],
      [
        104.08978271484375,
        30.58050537109375
      ],
      [
        104.09002685546875,
        30.5814208984375
      ],
      [
        104.09002685546875,
        30.582275390625
      ],
      [
        104.09002685546875,
        30.58294677734375
      ],
      [
        104.0902099609375,
        30.5836181640625
      ],
      [
        104.09002685546875,
        30.584716796875
      ],
      [
        104.09002685546875,
        30.5858154296875
      ],
      [
        104.09002685546875,
        30.58758544921875
      ],
      [
        104.09002685546875,
        30.5882568359375
      ],
      [
        104.0902099609375,
        30.5888671875
      ],
      [
        104.0906982421875,
        30.5899658203125
      ],
      [
        104.09088134765625,
        30.59088134765625
      ],
      [
        104.09112548828125,
        30.591552734375
      ],
      [
        104.09130859375,
        30.5921630859375
      ],
      [
        104.091796875,
        30.59283447265625
      ],
      [
        104.09222412109375,
        30.59393310546875
      ],
      [
        104.0924072265625,
        30.5946044921875
      ],
      [
        104.09307861328125,
        30.59527587890625
      ],
      [
        104.093505859375,
        30.59613037109375
      ],
      [
        104.093994140625,
        30.59661865234375
      ],
      [
        104.0946044921875,
        30.5970458984375
      ],
      [
        104.09417724609375,
        30.59722900390625
      ],
      [
        104.09332275390625,
        30.59747314453125
      ],
      [
        104.0924072265625,
        30.59747314453125
      ],
      [
        104.091552734375,
        30.59747314453125
      ],
      [
        104.0904541015625,
        30.59771728515625
      ],
      [
        104.09002685546875,
        30.59814453125
      ],
      [
        104.089111328125,
        30.59814453125
      ],
      [
        104.08734130859375,
        30.59832763671875
      ],
      [
        104.0858154296875,
        30.59881591796875
      ],
      [
        104.0849609375,
        30.5989990234375
      ],
      [
        104.0838623046875,
        30.5992431640625
      ],
      [
        104.08294677734375,
        30.59942626953125
      ],
      [
        104.0816650390625,
        30.59967041015625
      ],
      [
        104.08099365234375,
        30.59991455078125
      ],
      [
        104.080078125,
        30.60009765625
      ],
      [
        104.0792236328125,
        30.600341796875
      ],
      [
        104.07855224609375,
        30.60052490234375
      ],
      [
        104.07745361328125,
        30.60052490234375
      ],
      [
        104.0767822265625,
        30.60052490234375
      ],
      [
        104.076171875,
        30.60052490234375
      ],
      [
        104.075927734375,
        30.60052490234375
      ],
      [
        104.07550048828125,
        30.60052490234375
      ],
      [
        104.0745849609375,
        30.600341796875
      ],
      [
        104.073974609375,
        30.60052490234375
      ],
      [
        104.07330322265625,
        30.60052490234375
      ],
      [
        104.0723876953125,
        30.60052490234375
      ],
      [
        104.071533203125,
        30.60052490234375
      ],
      [
        104.0706787109375,
        30.60052490234375
      ],
      [
        104.0693359375,
        30.60052490234375
      ],
      [
        104.06842041015625,
        30.60052490234375
      ],
      [
        104.06756591796875,
        30.60052490234375
      ],
      [
        104.06671142578125,
        30.60052490234375
      ],
      [
        104.0657958984375,
        30.60076904296875
      ],
      [
        104.06512451171875,
        30.60076904296875
      ],
      [
        104.06451416015625,
        30.60076904296875
      ],
      [
        104.0638427734375,
        30.60052490234375
      ],
      [
        104.06317138671875,
        30.60052490234375
      ],
      [
        104.0625,
        30.60076904296875
      ],
      [
        104.06182861328125,
        30.60076904296875
      ],
      [
        104.06121826171875,
        30.60076904296875
      ],
      [
        104.060546875,
        30.60052490234375
      ],
      [
        104.05963134765625,
        30.60052490234375
      ],
      [
        104.05853271484375,
        30.60052490234375
      ],
      [
        104.057861328125,
        30.60052490234375
      ],
      [
        104.0570068359375,
        30.60052490234375
      ],
      [
        104.055908203125,
        30.60076904296875
      ],
      [
        104.0567626953125,
        30.60052490234375
      ],
      [
        104.05523681640625,
        30.60076904296875
      ],
      [
        104.05438232421875,
        30.60076904296875
      ],
      [
        104.05194091796875,
        30.60076904296875
      ],
      [
        104.05084228515625,
        30.60076904296875
      ],
      [
        104.04998779296875,
        30.60076904296875
      ],
      [
        104.049072265625,
        30.60076904296875
      ],
      [
        104.0482177734375,
        30.60076904296875
      ],
      [
        104.046875,
        30.60101318359375
      ],
      [
        104.04620361328125,
        30.6011962890625
      ],
      [
        104.044921875,
        30.60162353515625
      ],
      [
        104.04449462890625,
        30.60101318359375
      ],
      [
        104.04400634765625,
        30.59991455078125
      ],
      [
        104.0435791015625,
        30.5989990234375
      ],
      [
        104.04339599609375,
        30.59814453125
      ],
      [
        104.04290771484375,
        30.59747314453125
      ],
      [
        104.042724609375,
        30.5968017578125
      ],
      [
        104.042724609375,
        30.59613037109375
      ],
      [
        104.04229736328125,
        30.595458984375
      ],
      [
        104.04205322265625,
        30.5948486328125
      ],
      [
        104.04180908203125,
        30.59417724609375
      ],
      [
        104.0413818359375,
        30.59375
      ],
      [
        104.04119873046875,
        30.5926513671875
      ],
      [
        104.04071044921875,
        30.59173583984375
      ],
      [
        104.040283203125,
        30.59063720703125
      ],
      [
        104.03985595703125,
        30.58978271484375
      ],
      [
        104.03961181640625,
        30.589111328125
      ],
      [
        104.0394287109375,
        30.58843994140625
      ],
      [
        104.0391845703125,
        30.58758544921875
      ],
      [
        104.03851318359375,
        30.586669921875
      ],
      [
        104.038330078125,
        30.5858154296875
      ],
      [
        104.037841796875,
        30.584716796875
      ],
      [
        104.03741455078125,
        30.58380126953125
      ],
      [
        104.0372314453125,
        30.58270263671875
      ],
      [
        104.0367431640625,
        30.58209228515625
      ],
      [
        104.03656005859375,
        30.58099365234375
      ],
      [
        104.03631591796875,
        30.58074951171875
      ],
      [
        104.0367431640625,
        30.5811767578125
      ],
      [
        104.03656005859375,
        30.58099365234375
      ],
      [
        104.0361328125,
        30.580078125
      ],
      [
        104.03564453125,
        30.57940673828125
      ],
      [
        104.03564453125,
        30.57879638671875
      ],
      [
        104.03521728515625,
        30.577880859375
      ],
      [
        104.0345458984375,
        30.5765380859375
      ],
      [
        104.03436279296875,
        30.575927734375
      ],
      [
        104.03436279296875,
        30.57525634765625
      ],
      [
        104.033935546875,
        30.5748291015625
      ],
      [
        104.031494140625,
        30.575927734375
      ],
      [
        104.03082275390625,
        30.57635498046875
      ],
      [
        104.02996826171875,
        30.575927734375
      ],
      [
        104.029296875,
        30.57611083984375
      ],
      [
        104.02862548828125,
        30.5765380859375
      ],
      [
        104.0279541015625,
        30.5770263671875
      ],
      [
        104.027099609375,
        30.57720947265625
      ],
      [
        104.02642822265625,
        30.57611083984375
      ],
      [
        104.0260009765625,
        30.57568359375
      ],
      [
        104.02557373046875,
        30.57373046875
      ],
      [
        104.02490234375,
        30.57281494140625
      ],
      [
        104.024658203125,
        30.5721435546875
      ],
      [
        104.02447509765625,
        30.5712890625
      ],
      [
        104.02398681640625,
        30.5701904296875
      ],
      [
        104.0235595703125,
        30.5693359375
      ],
      [
        104.02288818359375,
        30.5682373046875
      ],
      [
        104.022705078125,
        30.56732177734375
      ],
      [
        104.02203369140625,
        30.566650390625
      ],
      [
        104.0213623046875,
        30.5655517578125
      ],
      [
        104.0211181640625,
        30.56488037109375
      ],
      [
        104.0205078125,
        30.5635986328125
      ],
      [
        104.01959228515625,
        30.5625
      ],
      [
        104.0191650390625,
        30.56158447265625
      ],
      [
        104.017822265625,
        30.55963134765625
      ],
      [
        104.0167236328125,
        30.55767822265625
      ],
      [
        104.01629638671875,
        30.5570068359375
      ],
      [
        104.015869140625,
        30.55609130859375
      ],
      [
        104.01519775390625,
        30.55499267578125
      ],
      [
        104.0150146484375,
        30.5548095703125
      ],
      [
        104.01409912109375,
        30.55322265625
      ],
      [
        104.01324462890625,
        30.55194091796875
      ],
      [
        104.0125732421875,
        30.551025390625
      ],
      [
        104.01190185546875,
        30.5504150390625
      ],
      [
        104.01171875,
        30.54974365234375
      ],
      [
        104.011474609375,
        30.54949951171875
      ],
      [
        104.01104736328125,
        30.548828125
      ],
      [
        104.01055908203125,
        30.54840087890625
      ],
      [
        104.009033203125,
        30.546875
      ],
      [
        104.0081787109375,
        30.54620361328125
      ],
      [
        104.00775146484375,
        30.5457763671875
      ],
      [
        104.00726318359375,
        30.54510498046875
      ],
      [
        104.00640869140625,
        30.544677734375
      ],
      [
        104.00555419921875,
        30.54400634765625
      ],
      [
        104.004638671875,
        30.5433349609375
      ],
      [
        104.00335693359375,
        30.54266357421875
      ],
      [
        104.00244140625,
        30.54180908203125
      ],
      [
        104.0010986328125,
        30.54095458984375
      ],
      [
        104.00048828125,
        30.54071044921875
      ],
      [
        104,
        30.540283203125
      ],
      [
        103.9991455078125,
        30.53961181640625
      ],
      [
        103.99847412109375,
        30.53936767578125
      ],
      [
        103.997802734375,
        30.5389404296875
      ],
      [
        103.9971923828125,
        30.53826904296875
      ],
      [
        103.99627685546875,
        30.53741455078125
      ],
      [
        103.99542236328125,
        30.5367431640625
      ],
      [
        103.9945068359375,
        30.53564453125
      ],
      [
        103.9927978515625,
        30.53387451171875
      ],
      [
        103.98858642578125,
        30.52972412109375
      ],
      [
        103.9892578125,
        30.52880859375
      ],
      [
        103.9903564453125,
        30.5281982421875
      ],
      [
        103.9912109375,
        30.5277099609375
      ],
      [
        103.99212646484375,
        30.52703857421875
      ],
      [
        103.99322509765625,
        30.526611328125
      ],
      [
        103.99407958984375,
        30.52593994140625
      ],
      [
        103.9947509765625,
        30.5255126953125
      ],
      [
        103.99542236328125,
        30.52508544921875
      ],
      [
        103.99609375,
        30.52484130859375
      ],
      [
        103.9971923828125,
        30.5244140625
      ],
      [
        103.998291015625,
        30.52374267578125
      ],
      [
        103.9991455078125,
        30.5233154296875
      ],
      [
        103.99981689453125,
        30.52313232421875
      ],
      [
        104.00048828125,
        30.52264404296875
      ],
      [
        104.0010986328125,
        30.52264404296875
      ],
      [
        104.00177001953125,
        30.5224609375
      ],
      [
        104.002685546875,
        30.522216796875
      ],
      [
        104.00335693359375,
        30.522216796875
      ],
      [
        104.00445556640625,
        30.52203369140625
      ],
      [
        104.00506591796875,
        30.52154541015625
      ],
      [
        104.004638671875,
        30.52001953125
      ],
      [
        104.00421142578125,
        30.51873779296875
      ],
      [
        104.00396728515625,
        30.517578125
      ],
      [
        104.0035400390625,
        30.5167236328125
      ],
      [
        104.00335693359375,
        30.51605224609375
      ],
      [
        104.00335693359375,
        30.515380859375
      ],
      [
        104.00286865234375,
        30.5142822265625
      ],
      [
        104.002685546875,
        30.513671875
      ],
      [
        104.0015869140625,
        30.5101318359375
      ],
      [
        104.0013427734375,
        30.509033203125
      ],
      [
        104.0010986328125,
        30.5079345703125
      ],
      [
        104.00067138671875,
        30.50726318359375
      ],
      [
        104,
        30.50616455078125
      ],
      [
        103.99957275390625,
        30.50531005859375
      ],
      [
        103.99871826171875,
        30.50372314453125
      ],
      [
        103.997802734375,
        30.50286865234375
      ],
      [
        103.997802734375,
        30.50262451171875
      ],
      [
        103.99847412109375,
        30.5035400390625
      ],
      [
        103.997802734375,
        30.50286865234375
      ],
      [
        103.9971923828125,
        30.50152587890625
      ],
      [
        103.9967041015625,
        30.50091552734375
      ],
      [
        103.99627685546875,
        30.5
      ],
      [
        103.995849609375,
        30.4991455078125
      ],
      [
        103.99542236328125,
        30.49847412109375
      ],
      [
        103.9945068359375,
        30.49713134765625
      ],
      [
        103.993896484375,
        30.495849609375
      ],
      [
        103.99322509765625,
        30.49493408203125
      ],
      [
        103.9927978515625,
        30.4945068359375
      ],
      [
        103.9923095703125,
        30.49365234375
      ],
      [
        103.99212646484375,
        30.4925537109375
      ],
      [
        103.9912109375,
        30.49163818359375
      ],
      [
        103.9923095703125,
        30.490966796875
      ],
      [
        103.9927978515625,
        30.49053955078125
      ],
      [
        103.99365234375,
        30.4903564453125
      ],
      [
        103.9945068359375,
        30.4901123046875
      ],
      [
        103.99542236328125,
        30.48968505859375
      ],
      [
        103.99609375,
        30.48944091796875
      ],
      [
        103.9969482421875,
        30.4892578125
      ],
      [
        103.998046875,
        30.4892578125
      ],
      [
        104.000244140625,
        30.48876953125
      ],
      [
        104.0010986328125,
        30.48834228515625
      ],
      [
        104.00286865234375,
        30.48809814453125
      ],
      [
        104.0037841796875,
        30.4879150390625
      ],
      [
        104.0048828125,
        30.4879150390625
      ],
      [
        104.00555419921875,
        30.4876708984375
      ],
      [
        104.00616455078125,
        30.4876708984375
      ],
      [
        104.007080078125,
        30.4879150390625
      ],
      [
        104.0079345703125,
        30.4879150390625
      ],
      [
        104.00860595703125,
        30.4879150390625
      ],
      [
        104.00946044921875,
        30.4879150390625
      ],
      [
        104.01104736328125,
        30.4879150390625
      ],
      [
        104.01190185546875,
        30.4879150390625
      ],
      [
        104.0125732421875,
        30.4879150390625
      ],
      [
        104.013427734375,
        30.4879150390625
      ],
      [
        104.0150146484375,
        30.48809814453125
      ],
      [
        104.015625,
        30.48809814453125
      ],
      [
        104.01629638671875,
        30.48809814453125
      ],
      [
        104.0172119140625,
        30.48809814453125
      ],
      [
        104.0191650390625,
        30.48809814453125
      ],
      [
        104.020263671875,
        30.48809814453125
      ],
      [
        104.0213623046875,
        30.48809814453125
      ],
      [
        104.022705078125,
        30.48834228515625
      ],
      [
        104.0235595703125,
        30.48858642578125
      ],
      [
        104.02423095703125,
        30.48858642578125
      ],
      [
        104.02490234375,
        30.48834228515625
      ],
      [
        104.0257568359375,
        30.48834228515625
      ],
      [
        104.02642822265625,
        30.48834228515625
      ],
      [
        104.027099609375,
        30.48809814453125
      ],
      [
        104.02777099609375,
        30.48809814453125
      ],
      [
        104.02838134765625,
        30.48809814453125
      ],
      [
        104.029052734375,
        30.48809814453125
      ],
      [
        104.0301513671875,
        30.48809814453125
      ],
      [
        104.03106689453125,
        30.48809814453125
      ],
      [
        104.03173828125,
        30.48809814453125
      ],
      [
        104.0323486328125,
        30.48809814453125
      ],
      [
        104.033447265625,
        30.48809814453125
      ],
      [
        104.0350341796875,
        30.48809814453125
      ],
      [
        104.035888671875,
        30.48809814453125
      ],
      [
        104.038330078125,
        30.48809814453125
      ],
      [
        104.04931640625,
        30.4879150390625
      ],
      [
        104.04998779296875,
        30.4879150390625
      ],
      [
        104.0548095703125,
        30.4879150390625
      ],
      [
        104.05615234375,
        30.48809814453125
      ],
      [
        104.05810546875,
        30.48834228515625
      ],
      [
        104.060302734375,
        30.48876953125
      ],
      [
        104.06182861328125,
        30.489013671875
      ],
      [
        104.06317138671875,
        30.48944091796875
      ],
      [
        104.06512451171875,
        30.4901123046875
      ],
      [
        104.06622314453125,
        30.4901123046875
      ],
      [
        104.06756591796875,
        30.4901123046875
      ],
      [
        104.06842041015625,
        30.4903564453125
      ],
      [
        104.06890869140625,
        30.4901123046875
      ],
      [
        104.06976318359375,
        30.4901123046875
      ],
      [
        104.07086181640625,
        30.4898681640625
      ],
      [
        104.071533203125,
        30.4898681640625
      ],
      [
        104.0726318359375,
        30.48968505859375
      ],
      [
        104.07330322265625,
        30.48944091796875
      ],
      [
        104.073974609375,
        30.48944091796875
      ],
      [
        104.0748291015625,
        30.48944091796875
      ],
      [
        104.07568359375,
        30.4892578125
      ],
      [
        104.07659912109375,
        30.4892578125
      ],
      [
        104.077880859375,
        30.489013671875
      ],
      [
        104.0789794921875,
        30.48876953125
      ],
      [
        104.07965087890625,
        30.48876953125
      ],
      [
        104.080322265625,
        30.48858642578125
      ],
      [
        104.08099365234375,
        30.48858642578125
      ],
      [
        104.0816650390625,
        30.48834228515625
      ],
      [
        104.08294677734375,
        30.48834228515625
      ],
      [
        104.08404541015625,
        30.48809814453125
      ],
      [
        104.08868408203125,
        30.48724365234375
      ],
      [
        104.08935546875,
        30.48724365234375
      ],
      [
        104.0902099609375,
        30.48748779296875
      ],
      [
        104.09197998046875,
        30.486572265625
      ],
      [
        104.09307861328125,
        30.48638916015625
      ],
      [
        104.0946044921875,
        30.48590087890625
      ],
      [
        104.09552001953125,
        30.4857177734375
      ],
      [
        104.09619140625,
        30.4854736328125
      ],
      [
        104.098388671875,
        30.48504638671875
      ],
      [
        104.1005859375,
        30.484375
      ],
      [
        104.1014404296875,
        30.48419189453125
      ],
      [
        104.1025390625,
        30.48394775390625
      ],
      [
        104.10406494140625,
        30.4835205078125
      ],
      [
        104.104736328125,
        30.4832763671875
      ],
      [
        104.10540771484375,
        30.48309326171875
      ],
      [
        104.10675048828125,
        30.48284912109375
      ],
      [
        104.10736083984375,
        30.48260498046875
      ],
      [
        104.1080322265625,
        30.48260498046875
      ],
      [
        104.109130859375,
        30.482177734375
      ],
      [
        104.11004638671875,
        30.48199462890625
      ],
      [
        104.1129150390625,
        30.48175048828125
      ],
      [
        104.113525390625,
        30.48260498046875
      ],
      [
        104.11334228515625,
        30.48419189453125
      ],
      [
        104.113525390625,
        30.48504638671875
      ],
      [
        104.11334228515625,
        30.48614501953125
      ],
      [
        104.11334228515625,
        30.48699951171875
      ],
      [
        104.11334228515625,
        30.4876708984375
      ],
      [
        104.11334228515625,
        30.4892578125
      ],
      [
        104.11334228515625,
        30.49078369140625
      ],
      [
        104.11334228515625,
        30.49163818359375
      ],
      [
        104.11334228515625,
        30.4925537109375
      ],
      [
        104.11334228515625,
        30.4931640625
      ],
      [
        104.11309814453125,
        30.49407958984375
      ],
      [
        104.11309814453125,
        30.495361328125
      ],
      [
        104.1129150390625,
        30.4967041015625
      ],
      [
        104.1124267578125,
        30.498046875
      ],
      [
        104.1124267578125,
        30.4989013671875
      ],
      [
        104.11199951171875,
        30.49981689453125
      ],
      [
        104.111572265625,
        30.50067138671875
      ],
      [
        104.111328125,
        30.50152587890625
      ],
      [
        104.11114501953125,
        30.502197265625
      ],
      [
        104.11090087890625,
        30.50311279296875
      ],
      [
        104.1107177734375,
        30.50421142578125
      ],
      [
        104.1102294921875,
        30.50531005859375
      ],
      [
        104.11004638671875,
        30.50616455078125
      ],
      [
        104.11004638671875,
        30.5068359375
      ],
      [
        104.10980224609375,
        30.5076904296875
      ],
      [
        104.10980224609375,
        30.50836181640625
      ],
      [
        104.10980224609375,
        30.50927734375
      ],
      [
        104.10980224609375,
        30.5098876953125
      ],
      [
        104.10980224609375,
        30.51080322265625
      ],
      [
        104.10955810546875,
        30.51190185546875
      ],
      [
        104.10955810546875,
        30.513427734375
      ],
      [
        104.10955810546875,
        30.5145263671875
      ],
      [
        104.10955810546875,
        30.515625
      ],
      [
        104.10955810546875,
        30.51629638671875
      ],
      [
        104.10955810546875,
        30.5169677734375
      ],
      [
        104.10955810546875,
        30.51806640625
      ],
      [
        104.109375,
        30.5189208984375
      ],
      [
        104.109130859375,
        30.52001953125
      ],
      [
        104.109375,
        30.52093505859375
      ],
      [
        104.109375,
        30.52178955078125
      ],
      [
        104.109375,
        30.52288818359375
      ],
      [
        104.109375,
        30.5235595703125
      ],
      [
        104.109375,
        30.52532958984375
      ],
      [
        104.109375,
        30.52642822265625
      ],
      [
        104.10955810546875,
        30.52728271484375
      ],
      [
        104.109375,
        30.5281982421875
      ],
      [
        104.109375,
        30.529052734375
      ],
      [
        104.109375,
        30.5299072265625
      ],
      [
        104.109375,
        30.53057861328125
      ],
      [
        104.109375,
        30.53167724609375
      ],
      [
        104.109130859375,
        30.5323486328125
      ],
      [
        104.109375,
        30.53387451171875
      ],
      [
        104.109130859375,
        30.5347900390625
      ],
      [
        104.109375,
        30.535888671875
      ],
      [
        104.109130859375,
        30.5367431640625
      ],
      [
        104.109130859375,
        30.53741455078125
      ],
      [
        104.10894775390625,
        30.53826904296875
      ],
      [
        104.1082763671875,
        30.5389404296875
      ],
      [
        104.10845947265625,
        30.53961181640625
      ],
      [
        104.1080322265625,
        30.54071044921875
      ],
      [
        104.1080322265625,
        30.5413818359375
      ],
      [
        104.10760498046875,
        30.542236328125
      ],
      [
        104.107177734375,
        30.54315185546875
      ],
      [
        104.10650634765625,
        30.544677734375
      ],
      [
        104.10650634765625,
        30.54534912109375
      ],
      [
        104.10626220703125,
        30.54595947265625
      ],
      [
        104.1060791015625,
        30.546875
      ],
      [
        104.1058349609375,
        30.5477294921875
      ],
      [
        104.1058349609375,
        30.54840087890625
      ],
      [
        104.1058349609375,
        30.54949951171875
      ],
      [
        104.10565185546875,
        30.55059814453125
      ],
      [
        104.10565185546875,
        30.55194091796875
      ],
      [
        104.1058349609375,
        30.5526123046875
      ],
      [
        104.1058349609375,
        30.55322265625
      ],
      [
        104.10565185546875,
        30.55413818359375
      ],
      [
        104.1060791015625,
        30.5548095703125
      ],
      [
        104.1058349609375,
        30.5556640625
      ],
      [
        104.1058349609375,
        30.5567626953125
      ],
      [
        104.1058349609375,
        30.55767822265625
      ],
      [
        104.1058349609375,
        30.55853271484375
      ],
      [
        104.1060791015625,
        30.55938720703125
      ],
      [
        104.10650634765625,
        30.560302734375
      ],
      [
        104.10626220703125,
        30.5611572265625
      ],
      [
        104.10693359375,
        30.56268310546875
      ],
      [
        104.10693359375,
        30.5635986328125
      ],
      [
        104.10693359375,
        30.56427001953125
      ],
      [
        104.10760498046875,
        30.5655517578125
      ],
      [
        104.10784912109375,
        30.5677490234375
      ],
      [
        104.10845947265625,
        30.5677490234375
      ],
      [
        104.1080322265625,
        30.56884765625
      ],
      [
        104.1082763671875,
        30.5699462890625
      ],
      [
        104.10894775390625,
        30.5704345703125
      ],
      [
        104.1082763671875,
        30.57061767578125
      ],
      [
        104.10736083984375,
        30.57061767578125
      ],
      [
        104.1058349609375,
        30.57061767578125
      ],
      [
        104.10430908203125,
        30.57086181640625
      ],
      [
        104.1036376953125,
        30.57086181640625
      ],
      [
        104.102783203125,
        30.57086181640625
      ],
      [
        104.1016845703125,
        30.571044921875
      ],
      [
        104.10076904296875,
        30.571044921875
      ],
      [
        104.10015869140625,
        30.571044921875
      ],
      [
        104.0989990234375,
        30.5712890625
      ]
    ]
  },
  {
    coords: [
      [
        104.41888427734375,
        30.70086669921875
      ],
      [
        104.4217529296875,
        30.70196533203125
      ],
      [
        104.42327880859375,
        30.702392578125
      ],
      [
        104.42486572265625,
        30.70263671875
      ],
      [
        104.42572021484375,
        30.70220947265625
      ],
      [
        104.4263916015625,
        30.70196533203125
      ],
      [
        104.42681884765625,
        30.7015380859375
      ],
      [
        104.427734375,
        30.7015380859375
      ],
      [
        104.4288330078125,
        30.70111083984375
      ],
      [
        104.429443359375,
        30.70062255859375
      ],
      [
        104.4296875,
        30.69952392578125
      ],
      [
        104.4307861328125,
        30.6990966796875
      ],
      [
        104.43231201171875,
        30.69952392578125
      ],
      [
        104.4332275390625,
        30.6993408203125
      ],
      [
        104.43389892578125,
        30.7001953125
      ],
      [
        104.43365478515625,
        30.70086669921875
      ],
      [
        104.43365478515625,
        30.70172119140625
      ],
      [
        104.43450927734375,
        30.70220947265625
      ],
      [
        104.4354248046875,
        30.70220947265625
      ],
      [
        104.4365234375,
        30.70220947265625
      ],
      [
        104.43719482421875,
        30.70220947265625
      ],
      [
        104.43780517578125,
        30.70306396484375
      ],
      [
        104.4384765625,
        30.703399658203125
      ],
      [
        104.43939208984375,
        30.7034912109375
      ],
      [
        104.44024658203125,
        30.70330810546875
      ],
      [
        104.44110107421875,
        30.70281982421875
      ],
      [
        104.4420166015625,
        30.702392578125
      ],
      [
        104.44329833984375,
        30.70172119140625
      ],
      [
        104.4439697265625,
        30.7012939453125
      ],
      [
        104.445068359375,
        30.70111083984375
      ],
      [
        104.44573974609375,
        30.70062255859375
      ],
      [
        104.4464111328125,
        30.700439453125
      ],
      [
        104.44708251953125,
        30.70001220703125
      ],
      [
        104.44793701171875,
        30.70001220703125
      ],
      [
        104.4488525390625,
        30.6990966796875
      ],
      [
        104.44927978515625,
        30.69842529296875
      ],
      [
        104.45013427734375,
        30.6982421875
      ],
      [
        104.4505615234375,
        30.69781494140625
      ],
      [
        104.45123291015625,
        30.69732666015625
      ],
      [
        104.451904296875,
        30.69671630859375
      ],
      [
        104.45257568359375,
        30.696044921875
      ],
      [
        104.4530029296875,
        30.69537353515625
      ],
      [
        104.45367431640625,
        30.6944580078125
      ],
      [
        104.4541015625,
        30.69403076171875
      ],
      [
        104.45501708984375,
        30.693603515625
      ],
      [
        104.4554443359375,
        30.69317626953125
      ],
      [
        104.456298828125,
        30.6925048828125
      ],
      [
        104.45672607421875,
        30.69207763671875
      ],
      [
        104.45721435546875,
        30.69140625
      ],
      [
        104.4576416015625,
        30.6905517578125
      ],
      [
        104.45831298828125,
        30.68963623046875
      ],
      [
        104.45916748046875,
        30.689208984375
      ],
      [
        104.4598388671875,
        30.68896484375
      ],
      [
        104.46051025390625,
        30.689208984375
      ],
      [
        104.46112060546875,
        30.68878173828125
      ],
      [
        104.4620361328125,
        30.68853759765625
      ],
      [
        104.46246337890625,
        30.68768310546875
      ],
      [
        104.463134765625,
        30.68768310546875
      ],
      [
        104.46380615234375,
        30.6881103515625
      ],
      [
        104.4644775390625,
        30.68853759765625
      ],
      [
        104.46466064453125,
        30.689208984375
      ],
      [
        104.46533203125,
        30.689453125
      ],
      [
        104.46600341796875,
        30.68988037109375
      ],
      [
        104.46710205078125,
        30.6900634765625
      ],
      [
        104.46795654296875,
        30.6903076171875
      ],
      [
        104.4688720703125,
        30.6903076171875
      ],
      [
        104.4697265625,
        30.6903076171875
      ],
      [
        104.47039794921875,
        30.6900634765625
      ],
      [
        104.4710693359375,
        30.68963623046875
      ],
      [
        104.47259521484375,
        30.6900634765625
      ],
      [
        104.4727783203125,
        30.69073486328125
      ],
      [
        104.4730224609375,
        30.69183349609375
      ],
      [
        104.47259521484375,
        30.6927490234375
      ],
      [
        104.47259521484375,
        30.693603515625
      ],
      [
        104.471923828125,
        30.69384765625
      ],
      [
        104.4716796875,
        30.6947021484375
      ],
      [
        104.47149658203125,
        30.69580078125
      ],
      [
        104.47149658203125,
        30.6968994140625
      ],
      [
        104.47125244140625,
        30.69757080078125
      ],
      [
        104.47125244140625,
        30.6982421875
      ],
      [
        104.4710693359375,
        30.6993408203125
      ],
      [
        104.4705810546875,
        30.7001953125
      ],
      [
        104.47039794921875,
        30.70086669921875
      ],
      [
        104.4697265625,
        30.7012939453125
      ],
      [
        104.469482421875,
        30.70196533203125
      ],
      [
        104.46905517578125,
        30.702392578125
      ],
      [
        104.4686279296875,
        30.70281982421875
      ],
      [
        104.46820068359375,
        30.70330810546875
      ],
      [
        104.467529296875,
        30.7034912109375
      ],
      [
        104.46710205078125,
        30.70391845703125
      ],
      [
        104.4661865234375,
        30.70458984375
      ],
      [
        104.465087890625,
        30.704833984375
      ],
      [
        104.4642333984375,
        30.705078125
      ],
      [
        104.46337890625,
        30.70526123046875
      ],
      [
        104.4617919921875,
        30.70635986328125
      ],
      [
        104.46051025390625,
        30.70660400390625
      ],
      [
        104.46002197265625,
        30.70745849609375
      ],
      [
        104.45941162109375,
        30.706787109375
      ],
      [
        104.458740234375,
        30.707275390625
      ],
      [
        104.45782470703125,
        30.70770263671875
      ],
      [
        104.45697021484375,
        30.7081298828125
      ],
      [
        104.45611572265625,
        30.7083740234375
      ],
      [
        104.4552001953125,
        30.708984375
      ],
      [
        104.454345703125,
        30.70947265625
      ],
      [
        104.45343017578125,
        30.70965576171875
      ],
      [
        104.451904296875,
        30.7103271484375
      ],
      [
        104.4508056640625,
        30.7105712890625
      ],
      [
        104.45013427734375,
        30.71099853515625
      ],
      [
        104.449462890625,
        30.71099853515625
      ],
      [
        104.44903564453125,
        30.71142578125
      ],
      [
        104.4483642578125,
        30.711669921875
      ],
      [
        104.44775390625,
        30.711669921875
      ],
      [
        104.44708251953125,
        30.711669921875
      ],
      [
        104.44598388671875,
        30.7122802734375
      ],
      [
        104.4453125,
        30.7125244140625
      ],
      [
        104.44329833984375,
        30.71319580078125
      ],
      [
        104.44268798828125,
        30.71337890625
      ],
      [
        104.4417724609375,
        30.7138671875
      ],
      [
        104.44158935546875,
        30.71453857421875
      ],
      [
        104.44000244140625,
        30.7147216796875
      ],
      [
        104.43939208984375,
        30.71514892578125
      ],
      [
        104.44000244140625,
        30.71563720703125
      ],
      [
        104.44000244140625,
        30.71624755859375
      ],
      [
        104.44049072265625,
        30.71673583984375
      ],
      [
        104.44110107421875,
        30.71734619140625
      ],
      [
        104.44158935546875,
        30.71673583984375
      ],
      [
        104.44158935546875,
        30.716064453125
      ],
      [
        104.44244384765625,
        30.71563720703125
      ],
      [
        104.44329833984375,
        30.71514892578125
      ],
      [
        104.44354248046875,
        30.71429443359375
      ],
      [
        104.4444580078125,
        30.713623046875
      ],
      [
        104.4453125,
        30.71337890625
      ],
      [
        104.4461669921875,
        30.71337890625
      ],
      [
        104.44683837890625,
        30.71405029296875
      ],
      [
        104.447265625,
        30.7149658203125
      ],
      [
        104.44818115234375,
        30.71673583984375
      ],
      [
        104.4486083984375,
        30.71759033203125
      ],
      [
        104.44903564453125,
        30.718017578125
      ],
      [
        104.449462890625,
        30.71844482421875
      ],
      [
        104.45013427734375,
        30.71868896484375
      ],
      [
        104.4508056640625,
        30.7191162109375
      ],
      [
        104.4521484375,
        30.71978759765625
      ],
      [
        104.4530029296875,
        30.72021484375
      ],
      [
        104.45367431640625,
        30.72021484375
      ],
      [
        104.454345703125,
        30.72003173828125
      ],
      [
        104.4552001953125,
        30.71954345703125
      ],
      [
        104.45611572265625,
        30.71893310546875
      ],
      [
        104.45672607421875,
        30.71868896484375
      ],
      [
        104.45721435546875,
        30.71783447265625
      ],
      [
        104.4573974609375,
        30.71673583984375
      ],
      [
        104.45782470703125,
        30.71563720703125
      ],
      [
        104.45849609375,
        30.71563720703125
      ],
      [
        104.45916748046875,
        30.7171630859375
      ],
      [
        104.45941162109375,
        30.71783447265625
      ],
      [
        104.45916748046875,
        30.71893310546875
      ],
      [
        104.45892333984375,
        30.71978759765625
      ],
      [
        104.45849609375,
        30.720458984375
      ],
      [
        104.45831298828125,
        30.72113037109375
      ],
      [
        104.45831298828125,
        30.72174072265625
      ],
      [
        104.45831298828125,
        30.72283935546875
      ],
      [
        104.458740234375,
        30.72393798828125
      ],
      [
        104.45941162109375,
        30.724853515625
      ],
      [
        104.4598388671875,
        30.7257080078125
      ],
      [
        104.45916748046875,
        30.72637939453125
      ],
      [
        104.45849609375,
        30.726806640625
      ],
      [
        104.45782470703125,
        30.727294921875
      ],
      [
        104.45697021484375,
        30.727294921875
      ],
      [
        104.456298828125,
        30.727294921875
      ],
      [
        104.45587158203125,
        30.7279052734375
      ],
      [
        104.4552001953125,
        30.7281494140625
      ],
      [
        104.454345703125,
        30.72857666015625
      ],
      [
        104.45343017578125,
        30.729248046875
      ],
      [
        104.4527587890625,
        30.7294921875
      ],
      [
        104.451904296875,
        30.7294921875
      ],
      [
        104.4508056640625,
        30.72967529296875
      ],
      [
        104.449951171875,
        30.72991943359375
      ],
      [
        104.4488525390625,
        30.7301025390625
      ],
      [
        104.44793701171875,
        30.7305908203125
      ],
      [
        104.44683837890625,
        30.7314453125
      ],
      [
        104.4461669921875,
        30.731689453125
      ],
      [
        104.44573974609375,
        30.7327880859375
      ],
      [
        104.445068359375,
        30.73388671875
      ],
      [
        104.44464111328125,
        30.7349853515625
      ],
      [
        104.4444580078125,
        30.73583984375
      ],
      [
        104.4442138671875,
        30.73651123046875
      ],
      [
        104.4439697265625,
        30.7371826171875
      ],
      [
        104.4439697265625,
        30.738037109375
      ],
      [
        104.44354248046875,
        30.73870849609375
      ],
      [
        104.44329833984375,
        30.73956298828125
      ],
      [
        104.443115234375,
        30.740234375
      ],
      [
        104.44244384765625,
        30.74090576171875
      ],
      [
        104.4417724609375,
        30.7413330078125
      ],
      [
        104.44110107421875,
        30.74176025390625
      ],
      [
        104.44024658203125,
        30.742431640625
      ],
      [
        104.43939208984375,
        30.74285888671875
      ],
      [
        104.438720703125,
        30.74310302734375
      ],
      [
        104.43804931640625,
        30.74334716796875
      ],
      [
        104.4376220703125,
        30.7437744140625
      ],
      [
        104.4373779296875,
        30.74444580078125
      ],
      [
        104.43695068359375,
        30.744873046875
      ],
      [
        104.4365234375,
        30.74554443359375
      ],
      [
        104.43609619140625,
        30.7459716796875
      ],
      [
        104.43585205078125,
        30.74664306640625
      ],
      [
        104.4351806640625,
        30.746826171875
      ],
      [
        104.43408203125,
        30.74639892578125
      ],
      [
        104.43341064453125,
        30.74639892578125
      ],
      [
        104.4327392578125,
        30.74664306640625
      ],
      [
        104.431884765625,
        30.746826171875
      ],
      [
        104.43145751953125,
        30.747314453125
      ],
      [
        104.4307861328125,
        30.74774169921875
      ],
      [
        104.429931640625,
        30.7479248046875
      ],
      [
        104.4288330078125,
        30.74884033203125
      ],
      [
        104.42791748046875,
        30.74969482421875
      ],
      [
        104.427490234375,
        30.7503662109375
      ],
      [
        104.4266357421875,
        30.7503662109375
      ],
      [
        104.42572021484375,
        30.74993896484375
      ],
      [
        104.425048828125,
        30.74969482421875
      ],
      [
        104.4244384765625,
        30.74969482421875
      ],
      [
        104.42376708984375,
        30.74993896484375
      ],
      [
        104.42376708984375,
        30.75103759765625
      ],
      [
        104.4244384765625,
        30.75146484375
      ],
      [
        104.42486572265625,
        30.7523193359375
      ],
      [
        104.425048828125,
        30.75299072265625
      ],
      [
        104.42572021484375,
        30.75323486328125
      ],
      [
        104.4263916015625,
        30.75323486328125
      ],
      [
        104.42724609375,
        30.75323486328125
      ],
      [
        104.42791748046875,
        30.753662109375
      ],
      [
        104.4288330078125,
        30.75390625
      ],
      [
        104.429443359375,
        30.753662109375
      ],
      [
        104.43011474609375,
        30.75390625
      ],
      [
        104.4305419921875,
        30.75433349609375
      ],
      [
        104.43121337890625,
        30.75457763671875
      ],
      [
        104.43212890625,
        30.75457763671875
      ],
      [
        104.4332275390625,
        30.7550048828125
      ],
      [
        104.43365478515625,
        30.75543212890625
      ],
      [
        104.43408203125,
        30.755859375
      ],
      [
        104.43450927734375,
        30.75628662109375
      ],
      [
        104.4351806640625,
        30.75677490234375
      ],
      [
        104.436279296875,
        30.7569580078125
      ],
      [
        104.43695068359375,
        30.75677490234375
      ],
      [
        104.43780517578125,
        30.75653076171875
      ],
      [
        104.4384765625,
        30.756103515625
      ],
      [
        104.43914794921875,
        30.756103515625
      ],
      [
        104.4398193359375,
        30.75653076171875
      ],
      [
        104.44024658203125,
        30.7569580078125
      ],
      [
        104.44091796875,
        30.75762939453125
      ],
      [
        104.44134521484375,
        30.75848388671875
      ],
      [
        104.4417724609375,
        30.75897216796875
      ],
      [
        104.4417724609375,
        30.75982666015625
      ],
      [
        104.4417724609375,
        30.76068115234375
      ],
      [
        104.44219970703125,
        30.7615966796875
      ],
      [
        104.44287109375,
        30.76202392578125
      ],
      [
        104.44329833984375,
        30.7626953125
      ],
      [
        104.44378662109375,
        30.7635498046875
      ],
      [
        104.4444580078125,
        30.7640380859375
      ],
      [
        104.44329833984375,
        30.76422119140625
      ],
      [
        104.44244384765625,
        30.76422119140625
      ],
      [
        104.44158935546875,
        30.7640380859375
      ],
      [
        104.440673828125,
        30.7635498046875
      ],
      [
        104.44024658203125,
        30.76312255859375
      ],
      [
        104.4398193359375,
        30.762451171875
      ],
      [
        104.43914794921875,
        30.7626953125
      ],
      [
        104.4384765625,
        30.76336669921875
      ],
      [
        104.43780517578125,
        30.7635498046875
      ],
      [
        104.43719482421875,
        30.7640380859375
      ],
      [
        104.4365234375,
        30.7646484375
      ],
      [
        104.43585205078125,
        30.76531982421875
      ],
      [
        104.4351806640625,
        30.7657470703125
      ],
      [
        104.43450927734375,
        30.7659912109375
      ],
      [
        104.43408203125,
        30.76641845703125
      ],
      [
        104.43341064453125,
        30.76776123046875
      ],
      [
        104.4329833984375,
        30.76861572265625
      ],
      [
        104.43231201171875,
        30.769287109375
      ],
      [
        104.43145751953125,
        30.76904296875
      ],
      [
        104.43145751953125,
        30.7684326171875
      ],
      [
        104.43121337890625,
        30.76751708984375
      ],
      [
        104.43121337890625,
        30.766845703125
      ],
      [
        104.431640625,
        30.7659912109375
      ],
      [
        104.43212890625,
        30.76531982421875
      ],
      [
        104.43255615234375,
        30.764892578125
      ],
      [
        104.43255615234375,
        30.76422119140625
      ],
      [
        104.43255615234375,
        30.76336669921875
      ],
      [
        104.43121337890625,
        30.7626953125
      ],
      [
        104.43035888671875,
        30.76226806640625
      ],
      [
        104.429443359375,
        30.762451171875
      ],
      [
        104.4285888671875,
        30.76287841796875
      ],
      [
        104.42791748046875,
        30.76336669921875
      ],
      [
        104.4288330078125,
        30.7640380859375
      ],
      [
        104.4285888671875,
        30.764892578125
      ],
      [
        104.42791748046875,
        30.76556396484375
      ],
      [
        104.42706298828125,
        30.7659912109375
      ],
      [
        104.4263916015625,
        30.76666259765625
      ],
      [
        104.42572021484375,
        30.76708984375
      ],
      [
        104.425537109375,
        30.76776123046875
      ],
      [
        104.425048828125,
        30.7681884765625
      ],
      [
        104.4244384765625,
        30.76885986328125
      ],
      [
        104.42352294921875,
        30.76953125
      ],
      [
        104.42266845703125,
        30.76953125
      ],
      [
        104.42218017578125,
        30.7701416015625
      ],
      [
        104.4219970703125,
        30.77081298828125
      ],
      [
        104.42132568359375,
        30.771484375
      ],
      [
        104.42022705078125,
        30.7723388671875
      ],
      [
        104.4197998046875,
        30.7728271484375
      ],
      [
        104.41888427734375,
        30.77301025390625
      ],
      [
        104.41802978515625,
        30.77349853515625
      ],
      [
        104.41693115234375,
        30.77435302734375
      ],
      [
        104.41668701171875,
        30.77520751953125
      ],
      [
        104.41607666015625,
        30.77587890625
      ],
      [
        104.41558837890625,
        30.77655029296875
      ],
      [
        104.41497802734375,
        30.77740478515625
      ],
      [
        104.41448974609375,
        30.7783203125
      ],
      [
        104.413818359375,
        30.77899169921875
      ],
      [
        104.41339111328125,
        30.7794189453125
      ],
      [
        104.4129638671875,
        30.77984619140625
      ],
      [
        104.41229248046875,
        30.780517578125
      ],
      [
        104.41162109375,
        30.78070068359375
      ],
      [
        104.4107666015625,
        30.78070068359375
      ],
      [
        104.409423828125,
        30.780517578125
      ],
      [
        104.4085693359375,
        30.78094482421875
      ],
      [
        104.40789794921875,
        30.7813720703125
      ],
      [
        104.4072265625,
        30.7816162109375
      ],
      [
        104.40679931640625,
        30.78204345703125
      ],
      [
        104.4066162109375,
        30.78271484375
      ],
      [
        104.4061279296875,
        30.78314208984375
      ],
      [
        104.4066162109375,
        30.7835693359375
      ],
      [
        104.4066162109375,
        30.78448486328125
      ],
      [
        104.40679931640625,
        30.7857666015625
      ],
      [
        104.407470703125,
        30.78643798828125
      ],
      [
        104.405517578125,
        30.786865234375
      ],
      [
        104.40374755859375,
        30.78753662109375
      ],
      [
        104.4017333984375,
        30.7882080078125
      ],
      [
        104.3997802734375,
        30.78863525390625
      ],
      [
        104.39776611328125,
        30.7890625
      ],
      [
        104.39556884765625,
        30.78955078125
      ],
      [
        104.39361572265625,
        30.78997802734375
      ],
      [
        104.39227294921875,
        30.7906494140625
      ],
      [
        104.3916015625,
        30.7901611328125
      ],
      [
        104.3909912109375,
        30.78997802734375
      ],
      [
        104.39007568359375,
        30.789306640625
      ],
      [
        104.38922119140625,
        30.78887939453125
      ],
      [
        104.3885498046875,
        30.7884521484375
      ],
      [
        104.38787841796875,
        30.7879638671875
      ],
      [
        104.38720703125,
        30.78753662109375
      ],
      [
        104.38677978515625,
        30.786865234375
      ],
      [
        104.3861083984375,
        30.78643798828125
      ],
      [
        104.38525390625,
        30.78558349609375
      ],
      [
        104.38482666015625,
        30.78515625
      ],
      [
        104.38433837890625,
        30.78448486328125
      ],
      [
        104.3839111328125,
        30.7840576171875
      ],
      [
        104.3828125,
        30.78314208984375
      ],
      [
        104.3819580078125,
        30.78228759765625
      ],
      [
        104.380859375,
        30.78118896484375
      ],
      [
        104.37994384765625,
        30.7802734375
      ],
      [
        104.3795166015625,
        30.77984619140625
      ],
      [
        104.37908935546875,
        30.7794189453125
      ],
      [
        104.378662109375,
        30.77899169921875
      ],
      [
        104.37823486328125,
        30.7791748046875
      ],
      [
        104.37774658203125,
        30.77984619140625
      ],
      [
        104.3773193359375,
        30.78070068359375
      ],
      [
        104.37664794921875,
        30.7813720703125
      ],
      [
        104.37579345703125,
        30.782470703125
      ],
      [
        104.3753662109375,
        30.78289794921875
      ],
      [
        104.37445068359375,
        30.78424072265625
      ],
      [
        104.373779296875,
        30.784912109375
      ],
      [
        104.3731689453125,
        30.78533935546875
      ],
      [
        104.3729248046875,
        30.7860107421875
      ],
      [
        104.37225341796875,
        30.78668212890625
      ],
      [
        104.371826171875,
        30.787353515625
      ],
      [
        104.37115478515625,
        30.78778076171875
      ],
      [
        104.3709716796875,
        30.7884521484375
      ],
      [
        104.37005615234375,
        30.78887939453125
      ],
      [
        104.36920166015625,
        30.78955078125
      ],
      [
        104.3685302734375,
        30.7904052734375
      ],
      [
        104.36785888671875,
        30.79107666015625
      ],
      [
        104.367431640625,
        30.791748046875
      ],
      [
        104.36676025390625,
        30.7926025390625
      ],
      [
        104.3660888671875,
        30.7923583984375
      ],
      [
        104.365234375,
        30.79217529296875
      ],
      [
        104.36431884765625,
        30.791748046875
      ],
      [
        104.36346435546875,
        30.79150390625
      ],
      [
        104.36236572265625,
        30.791748046875
      ],
      [
        104.36151123046875,
        30.79150390625
      ],
      [
        104.36041259765625,
        30.791259765625
      ],
      [
        104.3597412109375,
        30.79083251953125
      ],
      [
        104.35882568359375,
        30.7906494140625
      ],
      [
        104.35797119140625,
        30.79083251953125
      ],
      [
        104.35711669921875,
        30.791259765625
      ],
      [
        104.356201171875,
        30.79193115234375
      ],
      [
        104.35552978515625,
        30.7923583984375
      ],
      [
        104.3548583984375,
        30.7923583984375
      ],
      [
        104.35443115234375,
        30.7926025390625
      ],
      [
        104.35400390625,
        30.79327392578125
      ],
      [
        104.35333251953125,
        30.79327392578125
      ],
      [
        104.35247802734375,
        30.79327392578125
      ],
      [
        104.3515625,
        30.7928466796875
      ],
      [
        104.3507080078125,
        30.7926025390625
      ],
      [
        104.35003662109375,
        30.79302978515625
      ],
      [
        104.34893798828125,
        30.79302978515625
      ],
      [
        104.34765625,
        30.79302978515625
      ],
      [
        104.34716796875,
        30.7923583984375
      ],
      [
        104.34716796875,
        30.79150390625
      ],
      [
        104.34698486328125,
        30.7906494140625
      ],
      [
        104.3465576171875,
        30.78973388671875
      ],
      [
        104.3460693359375,
        30.7890625
      ],
      [
        104.34564208984375,
        30.7884521484375
      ],
      [
        104.344970703125,
        30.7879638671875
      ],
      [
        104.34429931640625,
        30.7879638671875
      ],
      [
        104.34368896484375,
        30.78753662109375
      ],
      [
        104.343017578125,
        30.78753662109375
      ],
      [
        104.34210205078125,
        30.78753662109375
      ],
      [
        104.34149169921875,
        30.7879638671875
      ],
      [
        104.3416748046875,
        30.78863525390625
      ],
      [
        104.34124755859375,
        30.7890625
      ],
      [
        104.34039306640625,
        30.7890625
      ],
      [
        104.33990478515625,
        30.78863525390625
      ],
      [
        104.34048461914062,
        30.78668212890625
      ],
      [
        104.340576171875,
        30.7860107421875
      ],
      [
        104.3408203125,
        30.78533935546875
      ],
      [
        104.3408203125,
        30.78466796875
      ],
      [
        104.3408203125,
        30.7840576171875
      ],
      [
        104.3408203125,
        30.78338623046875
      ],
      [
        104.340576171875,
        30.78271484375
      ],
      [
        104.340576171875,
        30.78204345703125
      ],
      [
        104.34039306640625,
        30.7813720703125
      ],
      [
        104.34014892578125,
        30.780517578125
      ],
      [
        104.33929443359375,
        30.77960205078125
      ],
      [
        104.338623046875,
        30.7791748046875
      ],
      [
        104.33819580078125,
        30.77874755859375
      ],
      [
        104.3375244140625,
        30.77850341796875
      ],
      [
        104.33685302734375,
        30.7783203125
      ],
      [
        104.336181640625,
        30.7783203125
      ],
      [
        104.33551025390625,
        30.778076171875
      ],
      [
        104.33465576171875,
        30.778076171875
      ],
      [
        104.333984375,
        30.77764892578125
      ],
      [
        104.33355712890625,
        30.77679443359375
      ],
      [
        104.3328857421875,
        30.77569580078125
      ],
      [
        104.33245849609375,
        30.7747802734375
      ],
      [
        104.3326416015625,
        30.77325439453125
      ],
      [
        104.3331298828125,
        30.7723388671875
      ],
      [
        104.33221435546875,
        30.76904296875
      ],
      [
        104.33203125,
        30.76751708984375
      ],
      [
        104.3326416015625,
        30.76531982421875
      ],
      [
        104.33135986328125,
        30.7640380859375
      ],
      [
        104.33135986328125,
        30.7637939453125
      ],
      [
        104.33026123046875,
        30.76312255859375
      ],
      [
        104.33111572265625,
        30.7613525390625
      ],
      [
        104.3309326171875,
        30.76025390625
      ],
      [
        104.3309326171875,
        30.75897216796875
      ],
      [
        104.33111572265625,
        30.75787353515625
      ],
      [
        104.3304443359375,
        30.75677490234375
      ],
      [
        104.33001708984375,
        30.75567626953125
      ],
      [
        104.329833984375,
        30.7547607421875
      ],
      [
        104.32891845703125,
        30.75408935546875
      ],
      [
        104.32958984375,
        30.75341796875
      ],
      [
        104.329833984375,
        30.7525634765625
      ],
      [
        104.3304443359375,
        30.751708984375
      ],
      [
        104.33026123046875,
        30.7506103515625
      ],
      [
        104.33026123046875,
        30.74993896484375
      ],
      [
        104.3309326171875,
        30.74859619140625
      ],
      [
        104.3309326171875,
        30.74749755859375
      ],
      [
        104.33135986328125,
        30.7457275390625
      ],
      [
        104.33111572265625,
        30.74420166015625
      ],
      [
        104.3309326171875,
        30.74267578125
      ],
      [
        104.3304443359375,
        30.73956298828125
      ],
      [
        104.3306884765625,
        30.7393798828125
      ],
      [
        104.33111572265625,
        30.73870849609375
      ],
      [
        104.3309326171875,
        30.738037109375
      ],
      [
        104.3309326171875,
        30.7369384765625
      ],
      [
        104.3309326171875,
        30.73583984375
      ],
      [
        104.3309326171875,
        30.7349853515625
      ],
      [
        104.3309326171875,
        30.73388671875
      ],
      [
        104.33111572265625,
        30.73297119140625
      ],
      [
        104.3306884765625,
        30.73187255859375
      ],
      [
        104.3306884765625,
        30.731201171875
      ],
      [
        104.3306884765625,
        30.7303466796875
      ],
      [
        104.33026123046875,
        30.7294921875
      ],
      [
        104.32958984375,
        30.72882080078125
      ],
      [
        104.32916259765625,
        30.72772216796875
      ],
      [
        104.32891845703125,
        30.72705078125
      ],
      [
        104.32891845703125,
        30.72637939453125
      ],
      [
        104.32891845703125,
        30.7257080078125
      ],
      [
        104.32891845703125,
        30.72509765625
      ],
      [
        104.3287353515625,
        30.72393798828125
      ],
      [
        104.3284912109375,
        30.72283935546875
      ],
      [
        104.32891845703125,
        30.72198486328125
      ],
      [
        104.329345703125,
        30.7213134765625
      ],
      [
        104.329833984375,
        30.72088623046875
      ],
      [
        104.3304443359375,
        30.72021484375
      ],
      [
        104.3306884765625,
        30.7191162109375
      ],
      [
        104.33135986328125,
        30.71868896484375
      ],
      [
        104.33245849609375,
        30.71844482421875
      ],
      [
        104.3331298828125,
        30.71783447265625
      ],
      [
        104.33355712890625,
        30.7169189453125
      ],
      [
        104.333740234375,
        30.7158203125
      ],
      [
        104.3353271484375,
        30.7158203125
      ],
      [
        104.336181640625,
        30.716064453125
      ],
      [
        104.33709716796875,
        30.71649169921875
      ],
      [
        104.33770751953125,
        30.71673583984375
      ],
      [
        104.33880615234375,
        30.71649169921875
      ],
      [
        104.3408203125,
        30.71649169921875
      ],
      [
        104.34149169921875,
        30.71624755859375
      ],
      [
        104.34210205078125,
        30.716064453125
      ],
      [
        104.343017578125,
        30.716064453125
      ],
      [
        104.3441162109375,
        30.7158203125
      ],
      [
        104.34478759765625,
        30.71563720703125
      ],
      [
        104.34539794921875,
        30.71539306640625
      ],
      [
        104.3465576171875,
        30.7158203125
      ],
      [
        104.34716796875,
        30.716064453125
      ],
      [
        104.3482666015625,
        30.71624755859375
      ],
      [
        104.34918212890625,
        30.71624755859375
      ],
      [
        104.35003662109375,
        30.716064453125
      ],
      [
        104.3509521484375,
        30.7158203125
      ],
      [
        104.3515625,
        30.71514892578125
      ],
      [
        104.35205078125,
        30.71429443359375
      ],
      [
        104.3526611328125,
        30.7138671875
      ],
      [
        104.35333251953125,
        30.71337890625
      ],
      [
        104.354248046875,
        30.71319580078125
      ],
      [
        104.3548583984375,
        30.71295166015625
      ],
      [
        104.35552978515625,
        30.71295166015625
      ],
      [
        104.356201171875,
        30.71295166015625
      ],
      [
        104.35687255859375,
        30.7127685546875
      ],
      [
        104.35711669921875,
        30.71209716796875
      ],
      [
        104.3572998046875,
        30.71142578125
      ],
      [
        104.35772705078125,
        30.71099853515625
      ],
      [
        104.3572998046875,
        30.7105712890625
      ],
      [
        104.35662841796875,
        30.7100830078125
      ],
      [
        104.356201171875,
        30.709228515625
      ],
      [
        104.35662841796875,
        30.70880126953125
      ],
      [
        104.3575439453125,
        30.70855712890625
      ],
      [
        104.35821533203125,
        30.7081298828125
      ],
      [
        104.35772705078125,
        30.707275390625
      ],
      [
        104.3572998046875,
        30.70703125
      ],
      [
        104.3575439453125,
        30.70635986328125
      ],
      [
        104.358642578125,
        30.7061767578125
      ],
      [
        104.3597412109375,
        30.70635986328125
      ],
      [
        104.360595703125,
        30.7061767578125
      ],
      [
        104.36102294921875,
        30.70635986328125
      ],
      [
        104.36151123046875,
        30.70703125
      ],
      [
        104.3619384765625,
        30.7078857421875
      ],
      [
        104.36236572265625,
        30.70880126953125
      ],
      [
        104.36279296875,
        30.70947265625
      ],
      [
        104.36370849609375,
        30.70965576171875
      ],
      [
        104.36431884765625,
        30.7100830078125
      ],
      [
        104.364990234375,
        30.7100830078125
      ],
      [
        104.36566162109375,
        30.7100830078125
      ],
      [
        104.3663330078125,
        30.7103271484375
      ],
      [
        104.36700439453125,
        30.7100830078125
      ],
      [
        104.3671875,
        30.709228515625
      ],
      [
        104.367431640625,
        30.70855712890625
      ],
      [
        104.36810302734375,
        30.7083740234375
      ],
      [
        104.36895751953125,
        30.70855712890625
      ],
      [
        104.36920166015625,
        30.70947265625
      ],
      [
        104.36962890625,
        30.70989990234375
      ],
      [
        104.37005615234375,
        30.7103271484375
      ],
      [
        104.3709716796875,
        30.71075439453125
      ],
      [
        104.37158203125,
        30.711181640625
      ],
      [
        104.3726806640625,
        30.711181640625
      ],
      [
        104.3731689453125,
        30.71075439453125
      ],
      [
        104.373779296875,
        30.711181640625
      ],
      [
        104.37445068359375,
        30.71099853515625
      ],
      [
        104.37469482421875,
        30.7100830078125
      ],
      [
        104.3753662109375,
        30.7100830078125
      ],
      [
        104.37603759765625,
        30.70965576171875
      ],
      [
        104.37689208984375,
        30.70989990234375
      ],
      [
        104.37774658203125,
        30.70965576171875
      ],
      [
        104.37841796875,
        30.7103271484375
      ],
      [
        104.37908935546875,
        30.7100830078125
      ],
      [
        104.37994384765625,
        30.7100830078125
      ],
      [
        104.380615234375,
        30.70947265625
      ],
      [
        104.38104248046875,
        30.70989990234375
      ],
      [
        104.38153076171875,
        30.7103271484375
      ],
      [
        104.38214111328125,
        30.70965576171875
      ],
      [
        104.3828125,
        30.70965576171875
      ],
      [
        104.38262939453125,
        30.70880126953125
      ],
      [
        104.38238525390625,
        30.7078857421875
      ],
      [
        104.38238525390625,
        30.707275390625
      ],
      [
        104.383056640625,
        30.70660400390625
      ],
      [
        104.38372802734375,
        30.7059326171875
      ],
      [
        104.38433837890625,
        30.70526123046875
      ],
      [
        104.38482666015625,
        30.70440673828125
      ],
      [
        104.385009765625,
        30.7037353515625
      ],
      [
        104.38568115234375,
        30.70330810546875
      ],
      [
        104.3865966796875,
        30.70306396484375
      ],
      [
        104.38720703125,
        30.70281982421875
      ],
      [
        104.38787841796875,
        30.70306396484375
      ],
      [
        104.38922119140625,
        30.70220947265625
      ],
      [
        104.39007568359375,
        30.70196533203125
      ],
      [
        104.3909912109375,
        30.70172119140625
      ],
      [
        104.39141845703125,
        30.70062255859375
      ],
      [
        104.3916015625,
        30.69976806640625
      ],
      [
        104.391845703125,
        30.69891357421875
      ],
      [
        104.39208984375,
        30.69781494140625
      ],
      [
        104.39208984375,
        30.6968994140625
      ],
      [
        104.39251708984375,
        30.69647216796875
      ],
      [
        104.3931884765625,
        30.69622802734375
      ],
      [
        104.393798828125,
        30.69580078125
      ],
      [
        104.394287109375,
        30.69512939453125
      ],
      [
        104.39495849609375,
        30.6947021484375
      ],
      [
        104.3953857421875,
        30.69537353515625
      ],
      [
        104.39605712890625,
        30.696044921875
      ],
      [
        104.39666748046875,
        30.69622802734375
      ],
      [
        104.3973388671875,
        30.69671630859375
      ],
      [
        104.39801025390625,
        30.69732666015625
      ],
      [
        104.3984375,
        30.69781494140625
      ],
      [
        104.39910888671875,
        30.697998046875
      ],
      [
        104.3997802734375,
        30.6982421875
      ],
      [
        104.40045166015625,
        30.69866943359375
      ],
      [
        104.40106201171875,
        30.69891357421875
      ],
      [
        104.4017333984375,
        30.69891357421875
      ],
      [
        104.40240478515625,
        30.69891357421875
      ],
      [
        104.40283203125,
        30.69952392578125
      ],
      [
        104.40325927734375,
        30.700439453125
      ],
      [
        104.4041748046875,
        30.7015380859375
      ],
      [
        104.40484619140625,
        30.70196533203125
      ],
      [
        104.40594482421875,
        30.70220947265625
      ],
      [
        104.4066162109375,
        30.70263671875
      ],
      [
        104.40679931640625,
        30.70196533203125
      ],
      [
        104.40771484375,
        30.70111083984375
      ],
      [
        104.40814208984375,
        30.7001953125
      ],
      [
        104.4085693359375,
        30.69952392578125
      ],
      [
        104.40924072265625,
        30.69952392578125
      ],
      [
        104.40966796875,
        30.70001220703125
      ],
      [
        104.41009521484375,
        30.700439453125
      ],
      [
        104.4110107421875,
        30.70086669921875
      ],
      [
        104.41162109375,
        30.70111083984375
      ],
      [
        104.41229248046875,
        30.70086669921875
      ],
      [
        104.41363525390625,
        30.700439453125
      ],
      [
        104.41448974609375,
        30.7001953125
      ],
      [
        104.4151611328125,
        30.70001220703125
      ],
      [
        104.41583251953125,
        30.70001220703125
      ],
      [
        104.41668701171875,
        30.70001220703125
      ],
      [
        104.4173583984375,
        30.7001953125
      ],
      [
        104.41827392578125,
        30.70062255859375
      ],
      [
        104.41888427734375,
        30.70086669921875
      ],
      [
        104.41937255859375,
        30.70111083984375
      ]
    ]
  },
  {
    coords: [
      [
        104.189208984375,
        30.56488037109375
      ],
      [
        104.1885986328125,
        30.56402587890625
      ],
      [
        104.18792724609375,
        30.56317138671875
      ],
      [
        104.18743896484375,
        30.562255859375
      ],
      [
        104.18701171875,
        30.56182861328125
      ],
      [
        104.18658447265625,
        30.5611572265625
      ],
      [
        104.1859130859375,
        30.56072998046875
      ],
      [
        104.18524169921875,
        30.56005859375
      ],
      [
        104.184814453125,
        30.55938720703125
      ],
      [
        104.18353271484375,
        30.55828857421875
      ],
      [
        104.182861328125,
        30.55767822265625
      ],
      [
        104.18194580078125,
        30.5570068359375
      ],
      [
        104.1815185546875,
        30.5565185546875
      ],
      [
        104.18084716796875,
        30.55609130859375
      ],
      [
        104.1795654296875,
        30.5545654296875
      ],
      [
        104.17864990234375,
        30.55413818359375
      ],
      [
        104.17822265625,
        30.553466796875
      ],
      [
        104.17779541015625,
        30.55303955078125
      ],
      [
        104.1773681640625,
        30.5526123046875
      ],
      [
        104.1768798828125,
        30.5521240234375
      ],
      [
        104.17645263671875,
        30.55169677734375
      ],
      [
        104.176025390625,
        30.551025390625
      ],
      [
        104.17559814453125,
        30.5504150390625
      ],
      [
        104.1749267578125,
        30.54931640625
      ],
      [
        104.17449951171875,
        30.548828125
      ],
      [
        104.174072265625,
        30.5482177734375
      ],
      [
        104.173583984375,
        30.54754638671875
      ],
      [
        104.1729736328125,
        30.54730224609375
      ],
      [
        104.1729736328125,
        30.546630859375
      ],
      [
        104.1727294921875,
        30.54595947265625
      ],
      [
        104.17230224609375,
        30.54510498046875
      ],
      [
        104.17205810546875,
        30.54443359375
      ],
      [
        104.17205810546875,
        30.54376220703125
      ],
      [
        104.171875,
        30.54315185546875
      ],
      [
        104.171875,
        30.54248046875
      ],
      [
        104.1724853515625,
        30.54180908203125
      ],
      [
        104.17315673828125,
        30.5413818359375
      ],
      [
        104.173828125,
        30.54046630859375
      ],
      [
        104.17889404296875,
        30.53631591796875
      ],
      [
        104.1793212890625,
        30.535888671875
      ],
      [
        104.18133544921875,
        30.53411865234375
      ],
      [
        104.17755126953125,
        30.53057861328125
      ],
      [
        104.1768798828125,
        30.5301513671875
      ],
      [
        104.174072265625,
        30.52728271484375
      ],
      [
        104.173828125,
        30.526611328125
      ],
      [
        104.1729736328125,
        30.526611328125
      ],
      [
        104.17230224609375,
        30.5255126953125
      ],
      [
        104.171630859375,
        30.52508544921875
      ],
      [
        104.17205810546875,
        30.5244140625
      ],
      [
        104.17205810546875,
        30.5235595703125
      ],
      [
        104.17205810546875,
        30.5224609375
      ],
      [
        104.171630859375,
        30.52154541015625
      ],
      [
        104.171630859375,
        30.52093505859375
      ],
      [
        104.17138671875,
        30.520263671875
      ],
      [
        104.17138671875,
        30.51934814453125
      ],
      [
        104.17138671875,
        30.51873779296875
      ],
      [
        104.17120361328125,
        30.51806640625
      ],
      [
        104.17120361328125,
        30.51739501953125
      ],
      [
        104.17230224609375,
        30.5167236328125
      ],
      [
        104.1727294921875,
        30.51629638671875
      ],
      [
        104.173583984375,
        30.51605224609375
      ],
      [
        104.17449951171875,
        30.515869140625
      ],
      [
        104.17535400390625,
        30.515625
      ],
      [
        104.17669677734375,
        30.51519775390625
      ],
      [
        104.17755126953125,
        30.51495361328125
      ],
      [
        104.178466796875,
        30.5147705078125
      ],
      [
        104.17864990234375,
        30.51409912109375
      ],
      [
        104.17889404296875,
        30.51318359375
      ],
      [
        104.17889404296875,
        30.5123291015625
      ],
      [
        104.17913818359375,
        30.51165771484375
      ],
      [
        104.1793212890625,
        30.51055908203125
      ],
      [
        104.17999267578125,
        30.5098876953125
      ],
      [
        104.1806640625,
        30.50927734375
      ],
      [
        104.18109130859375,
        30.50836181640625
      ],
      [
        104.18109130859375,
        30.50726318359375
      ],
      [
        104.1815185546875,
        30.506591796875
      ],
      [
        104.18218994140625,
        30.5057373046875
      ],
      [
        104.18243408203125,
        30.50506591796875
      ],
      [
        104.1826171875,
        30.50439453125
      ],
      [
        104.18243408203125,
        30.5035400390625
      ],
      [
        104.18243408203125,
        30.502197265625
      ],
      [
        104.18243408203125,
        30.5013427734375
      ],
      [
        104.1826171875,
        30.50067138671875
      ],
      [
        104.182861328125,
        30.5
      ],
      [
        104.18328857421875,
        30.4991455078125
      ],
      [
        104.18353271484375,
        30.49822998046875
      ],
      [
        104.1839599609375,
        30.49713134765625
      ],
      [
        104.18414306640625,
        30.495849609375
      ],
      [
        104.18463134765625,
        30.4947509765625
      ],
      [
        104.184814453125,
        30.49407958984375
      ],
      [
        104.18524169921875,
        30.49365234375
      ],
      [
        104.1861572265625,
        30.4942626953125
      ],
      [
        104.18682861328125,
        30.49493408203125
      ],
      [
        104.18768310546875,
        30.49407958984375
      ],
      [
        104.18792724609375,
        30.493408203125
      ],
      [
        104.1881103515625,
        30.49273681640625
      ],
      [
        104.1883544921875,
        30.4920654296875
      ],
      [
        104.18902587890625,
        30.491455078125
      ],
      [
        104.189453125,
        30.490966796875
      ],
      [
        104.19012451171875,
        30.49078369140625
      ],
      [
        104.19097900390625,
        30.4901123046875
      ],
      [
        104.191650390625,
        30.48944091796875
      ],
      [
        104.19207763671875,
        30.48876953125
      ],
      [
        104.1927490234375,
        30.48809814453125
      ],
      [
        104.1929931640625,
        30.48748779296875
      ],
      [
        104.1929931640625,
        30.48681640625
      ],
      [
        104.1929931640625,
        30.48614501953125
      ],
      [
        104.1927490234375,
        30.4854736328125
      ],
      [
        104.19207763671875,
        30.48480224609375
      ],
      [
        104.191650390625,
        30.484375
      ],
      [
        104.191650390625,
        30.4835205078125
      ],
      [
        104.191650390625,
        30.48260498046875
      ],
      [
        104.191650390625,
        30.48199462890625
      ],
      [
        104.19207763671875,
        30.4813232421875
      ],
      [
        104.1927490234375,
        30.48089599609375
      ],
      [
        104.193603515625,
        30.48065185546875
      ],
      [
        104.19427490234375,
        30.48089599609375
      ],
      [
        104.1949462890625,
        30.4813232421875
      ],
      [
        104.19580078125,
        30.48089599609375
      ],
      [
        104.19647216796875,
        30.48040771484375
      ],
      [
        104.1971435546875,
        30.48040771484375
      ],
      [
        104.19781494140625,
        30.48040771484375
      ],
      [
        104.19866943359375,
        30.48040771484375
      ],
      [
        104.19866943359375,
        30.4813232421875
      ],
      [
        104.19915771484375,
        30.482177734375
      ],
      [
        104.1993408203125,
        30.48309326171875
      ],
      [
        104.1993408203125,
        30.48419189453125
      ],
      [
        104.19915771484375,
        30.48504638671875
      ],
      [
        104.1995849609375,
        30.48590087890625
      ],
      [
        104.20703125,
        30.4854736328125
      ],
      [
        104.20745849609375,
        30.48590087890625
      ],
      [
        104.2081298828125,
        30.48638916015625
      ],
      [
        104.20904541015625,
        30.48681640625
      ],
      [
        104.20989990234375,
        30.48724365234375
      ],
      [
        104.2105712890625,
        30.4876708984375
      ],
      [
        104.21124267578125,
        30.4876708984375
      ],
      [
        104.2119140625,
        30.48699951171875
      ],
      [
        104.2127685546875,
        30.48614501953125
      ],
      [
        104.21343994140625,
        30.4854736328125
      ],
      [
        104.21429443359375,
        30.48480224609375
      ],
      [
        104.2152099609375,
        30.484619140625
      ],
      [
        104.216064453125,
        30.48419189453125
      ],
      [
        104.2169189453125,
        30.48419189453125
      ],
      [
        104.21759033203125,
        30.48394775390625
      ],
      [
        104.21826171875,
        30.48370361328125
      ],
      [
        104.21893310546875,
        30.48370361328125
      ],
      [
        104.21978759765625,
        30.4835205078125
      ],
      [
        104.220703125,
        30.4832763671875
      ],
      [
        104.2215576171875,
        30.4832763671875
      ],
      [
        104.22247314453125,
        30.4832763671875
      ],
      [
        104.22308349609375,
        30.48284912109375
      ],
      [
        104.2239990234375,
        30.48199462890625
      ],
      [
        104.22442626953125,
        30.48150634765625
      ],
      [
        104.22509765625,
        30.4810791015625
      ],
      [
        104.22637939453125,
        30.48065185546875
      ],
      [
        104.227294921875,
        30.480224609375
      ],
      [
        104.2281494140625,
        30.47998046875
      ],
      [
        104.229248046875,
        30.47979736328125
      ],
      [
        104.23101806640625,
        30.47930908203125
      ],
      [
        104.23211669921875,
        30.47955322265625
      ],
      [
        104.2330322265625,
        30.47998046875
      ],
      [
        104.23345947265625,
        30.48040771484375
      ],
      [
        104.23388671875,
        30.4810791015625
      ],
      [
        104.23431396484375,
        30.48150634765625
      ],
      [
        104.2347412109375,
        30.482177734375
      ],
      [
        104.23541259765625,
        30.48284912109375
      ],
      [
        104.236083984375,
        30.4835205078125
      ],
      [
        104.23675537109375,
        30.48419189453125
      ],
      [
        104.2371826171875,
        30.48480224609375
      ],
      [
        104.23760986328125,
        30.4854736328125
      ],
      [
        104.23809814453125,
        30.48614501953125
      ],
      [
        104.2415771484375,
        30.48699951171875
      ],
      [
        104.26336669921875,
        30.49188232421875
      ],
      [
        104.26513671875,
        30.49273681640625
      ],
      [
        104.26690673828125,
        30.49365234375
      ],
      [
        104.2686767578125,
        30.4947509765625
      ],
      [
        104.269775390625,
        30.49517822265625
      ],
      [
        104.2703857421875,
        30.495849609375
      ],
      [
        104.27105712890625,
        30.49560546875
      ],
      [
        104.271484375,
        30.49493408203125
      ],
      [
        104.27215576171875,
        30.4942626953125
      ],
      [
        104.2728271484375,
        30.49407958984375
      ],
      [
        104.2730712890625,
        30.4947509765625
      ],
      [
        104.2728271484375,
        30.495361328125
      ],
      [
        104.2730712890625,
        30.49603271484375
      ],
      [
        104.27325439453125,
        30.4967041015625
      ],
      [
        104.2730712890625,
        30.497802734375
      ],
      [
        104.273681640625,
        30.498046875
      ],
      [
        104.2752685546875,
        30.4991455078125
      ],
      [
        104.27587890625,
        30.49981689453125
      ],
      [
        104.27545166015625,
        30.50042724609375
      ],
      [
        104.27545166015625,
        30.5010986328125
      ],
      [
        104.27545166015625,
        30.50177001953125
      ],
      [
        104.27459716796875,
        30.50201416015625
      ],
      [
        104.273681640625,
        30.50262451171875
      ],
      [
        104.27325439453125,
        30.5032958984375
      ],
      [
        104.2728271484375,
        30.50372314453125
      ],
      [
        104.2725830078125,
        30.50439453125
      ],
      [
        104.2730712890625,
        30.50531005859375
      ],
      [
        104.2728271484375,
        30.50616455078125
      ],
      [
        104.2725830078125,
        30.5068359375
      ],
      [
        104.2725830078125,
        30.50750732421875
      ],
      [
        104.2730712890625,
        30.5079345703125
      ],
      [
        104.27349853515625,
        30.50836181640625
      ],
      [
        104.27392578125,
        30.509033203125
      ],
      [
        104.27435302734375,
        30.5098876953125
      ],
      [
        104.2747802734375,
        30.5103759765625
      ],
      [
        104.2750244140625,
        30.51123046875
      ],
      [
        104.27569580078125,
        30.51165771484375
      ],
      [
        104.2763671875,
        30.5125732421875
      ],
      [
        104.27679443359375,
        30.51300048828125
      ],
      [
        104.2772216796875,
        30.513427734375
      ],
      [
        104.27764892578125,
        30.51385498046875
      ],
      [
        104.2783203125,
        30.5142822265625
      ],
      [
        104.27923583984375,
        30.5145263671875
      ],
      [
        104.28009033203125,
        30.51519775390625
      ],
      [
        104.28033447265625,
        30.51605224609375
      ],
      [
        104.28076171875,
        30.5167236328125
      ],
      [
        104.28118896484375,
        30.51715087890625
      ],
      [
        104.28118896484375,
        30.517822265625
      ],
      [
        104.28118896484375,
        30.51849365234375
      ],
      [
        104.2816162109375,
        30.51934814453125
      ],
      [
        104.28204345703125,
        30.52001953125
      ],
      [
        104.28253173828125,
        30.52093505859375
      ],
      [
        104.28271484375,
        30.52178955078125
      ],
      [
        104.28271484375,
        30.5224609375
      ],
      [
        104.28271484375,
        30.52313232421875
      ],
      [
        104.28204345703125,
        30.5233154296875
      ],
      [
        104.2816162109375,
        30.52398681640625
      ],
      [
        104.28143310546875,
        30.52484130859375
      ],
      [
        104.2816162109375,
        30.5255126953125
      ],
      [
        104.28204345703125,
        30.52642822265625
      ],
      [
        104.28204345703125,
        30.52728271484375
      ],
      [
        104.28228759765625,
        30.5281982421875
      ],
      [
        104.28204345703125,
        30.529052734375
      ],
      [
        104.28253173828125,
        30.5301513671875
      ],
      [
        104.28253173828125,
        30.531005859375
      ],
      [
        104.2818603515625,
        30.53192138671875
      ],
      [
        104.28118896484375,
        30.53277587890625
      ],
      [
        104.28033447265625,
        30.53277587890625
      ],
      [
        104.2796630859375,
        30.533447265625
      ],
      [
        104.2794189453125,
        30.53411865234375
      ],
      [
        104.2794189453125,
        30.5347900390625
      ],
      [
        104.278564453125,
        30.53497314453125
      ],
      [
        104.27813720703125,
        30.535400390625
      ],
      [
        104.27764892578125,
        30.53607177734375
      ],
      [
        104.27813720703125,
        30.5369873046875
      ],
      [
        104.27764892578125,
        30.537841796875
      ],
      [
        104.27764892578125,
        30.5389404296875
      ],
      [
        104.2772216796875,
        30.53985595703125
      ],
      [
        104.27789306640625,
        30.53985595703125
      ],
      [
        104.27984619140625,
        30.54071044921875
      ],
      [
        104.2794189453125,
        30.5413818359375
      ],
      [
        104.27899169921875,
        30.54205322265625
      ],
      [
        104.278564453125,
        30.54266357421875
      ],
      [
        104.27789306640625,
        30.54290771484375
      ],
      [
        104.2772216796875,
        30.54376220703125
      ],
      [
        104.27655029296875,
        30.54443359375
      ],
      [
        104.2763671875,
        30.54510498046875
      ],
      [
        104.27569580078125,
        30.5457763671875
      ],
      [
        104.27569580078125,
        30.54644775390625
      ],
      [
        104.2747802734375,
        30.546630859375
      ],
      [
        104.27392578125,
        30.546875
      ],
      [
        104.27349853515625,
        30.54730224609375
      ],
      [
        104.27325439453125,
        30.5479736328125
      ],
      [
        104.27325439453125,
        30.548828125
      ],
      [
        104.2730712890625,
        30.54949951171875
      ],
      [
        104.2725830078125,
        30.5501708984375
      ],
      [
        104.27215576171875,
        30.54949951171875
      ],
      [
        104.27130126953125,
        30.54949951171875
      ],
      [
        104.2703857421875,
        30.5499267578125
      ],
      [
        104.26953125,
        30.5499267578125
      ],
      [
        104.2708740234375,
        30.55169677734375
      ],
      [
        104.27197265625,
        30.55322265625
      ],
      [
        104.27239990234375,
        30.5537109375
      ],
      [
        104.27325439453125,
        30.5548095703125
      ],
      [
        104.27392578125,
        30.555419921875
      ],
      [
        104.27459716796875,
        30.55633544921875
      ],
      [
        104.27545166015625,
        30.5570068359375
      ],
      [
        104.27679443359375,
        30.557861328125
      ],
      [
        104.2774658203125,
        30.55828857421875
      ],
      [
        104.2783203125,
        30.55853271484375
      ],
      [
        104.27923583984375,
        30.5592041015625
      ],
      [
        104.2796630859375,
        30.56005859375
      ],
      [
        104.2794189453125,
        30.5614013671875
      ],
      [
        104.27923583984375,
        30.56207275390625
      ],
      [
        104.27874755859375,
        30.5633544921875
      ],
      [
        104.278564453125,
        30.56402587890625
      ],
      [
        104.27899169921875,
        30.56488037109375
      ],
      [
        104.27923583984375,
        30.56597900390625
      ],
      [
        104.2796630859375,
        30.56732177734375
      ],
      [
        104.28076171875,
        30.5679931640625
      ],
      [
        104.2816162109375,
        30.56842041015625
      ],
      [
        104.28228759765625,
        30.569091796875
      ],
      [
        104.282958984375,
        30.56951904296875
      ],
      [
        104.2838134765625,
        30.5701904296875
      ],
      [
        104.28448486328125,
        30.571044921875
      ],
      [
        104.28515625,
        30.571533203125
      ],
      [
        104.28558349609375,
        30.57196044921875
      ],
      [
        104.2860107421875,
        30.5723876953125
      ],
      [
        104.28668212890625,
        30.57281494140625
      ],
      [
        104.28759765625,
        30.57305908203125
      ],
      [
        104.2882080078125,
        30.5732421875
      ],
      [
        104.28912353515625,
        30.573486328125
      ],
      [
        104.289794921875,
        30.57373046875
      ],
      [
        104.2908935546875,
        30.5743408203125
      ],
      [
        104.29132080078125,
        30.57501220703125
      ],
      [
        104.291748046875,
        30.57568359375
      ],
      [
        104.2919921875,
        30.57635498046875
      ],
      [
        104.29217529296875,
        30.5770263671875
      ],
      [
        104.2926025390625,
        30.57769775390625
      ],
      [
        104.2930908203125,
        30.57830810546875
      ],
      [
        104.29351806640625,
        30.57879638671875
      ],
      [
        104.2939453125,
        30.57940673828125
      ],
      [
        104.29461669921875,
        30.57989501953125
      ],
      [
        104.2952880859375,
        30.58074951171875
      ],
      [
        104.29571533203125,
        30.5814208984375
      ],
      [
        104.296142578125,
        30.582275390625
      ],
      [
        104.29638671875,
        30.58294677734375
      ],
      [
        104.29638671875,
        30.5836181640625
      ],
      [
        104.29656982421875,
        30.584716796875
      ],
      [
        104.2972412109375,
        30.58514404296875
      ],
      [
        104.29766845703125,
        30.5855712890625
      ],
      [
        104.29833984375,
        30.58599853515625
      ],
      [
        104.29901123046875,
        30.58648681640625
      ],
      [
        104.2994384765625,
        30.5869140625
      ],
      [
        104.30010986328125,
        30.58734130859375
      ],
      [
        104.300537109375,
        30.5880126953125
      ],
      [
        104.30120849609375,
        30.58843994140625
      ],
      [
        104.3016357421875,
        30.5888671875
      ],
      [
        104.30206298828125,
        30.58935546875
      ],
      [
        104.302734375,
        30.58978271484375
      ],
      [
        104.30340576171875,
        30.5902099609375
      ],
      [
        104.3040771484375,
        30.59063720703125
      ],
      [
        104.30474853515625,
        30.591064453125
      ],
      [
        104.30560302734375,
        30.59197998046875
      ],
      [
        104.3060302734375,
        30.5926513671875
      ],
      [
        104.3065185546875,
        30.59326171875
      ],
      [
        104.30517578125,
        30.59417724609375
      ],
      [
        104.3038330078125,
        30.59503173828125
      ],
      [
        104.3018798828125,
        30.59613037109375
      ],
      [
        104.30096435546875,
        30.59637451171875
      ],
      [
        104.30010986328125,
        30.5970458984375
      ],
      [
        104.29925537109375,
        30.59747314453125
      ],
      [
        104.29815673828125,
        30.59771728515625
      ],
      [
        104.2974853515625,
        30.597900390625
      ],
      [
        104.29681396484375,
        30.597900390625
      ],
      [
        104.2958984375,
        30.59814453125
      ],
      [
        104.2950439453125,
        30.59814453125
      ],
      [
        104.29437255859375,
        30.59814453125
      ],
      [
        104.293701171875,
        30.59814453125
      ],
      [
        104.2930908203125,
        30.59814453125
      ],
      [
        104.29241943359375,
        30.59814453125
      ],
      [
        104.29150390625,
        30.59814453125
      ],
      [
        104.2904052734375,
        30.59814453125
      ],
      [
        104.28887939453125,
        30.597900390625
      ],
      [
        104.287109375,
        30.597900390625
      ],
      [
        104.28533935546875,
        30.597900390625
      ],
      [
        104.28363037109375,
        30.597900390625
      ],
      [
        104.28253173828125,
        30.597900390625
      ],
      [
        104.28143310546875,
        30.597900390625
      ],
      [
        104.280517578125,
        30.597900390625
      ],
      [
        104.27984619140625,
        30.59814453125
      ],
      [
        104.27923583984375,
        30.59814453125
      ],
      [
        104.27813720703125,
        30.59832763671875
      ],
      [
        104.2774658203125,
        30.59857177734375
      ],
      [
        104.27569580078125,
        30.59881591796875
      ],
      [
        104.274169921875,
        30.5989990234375
      ],
      [
        104.27325439453125,
        30.59942626953125
      ],
      [
        104.27215576171875,
        30.59942626953125
      ],
      [
        104.271484375,
        30.59967041015625
      ],
      [
        104.2706298828125,
        30.59967041015625
      ],
      [
        104.26995849609375,
        30.59991455078125
      ],
      [
        104.269287109375,
        30.59991455078125
      ],
      [
        104.2686767578125,
        30.59991455078125
      ],
      [
        104.267578125,
        30.59991455078125
      ],
      [
        104.26513671875,
        30.60009765625
      ],
      [
        104.2618408203125,
        30.600341796875
      ],
      [
        104.26007080078125,
        30.600341796875
      ],
      [
        104.25921630859375,
        30.600341796875
      ],
      [
        104.258544921875,
        30.60052490234375
      ],
      [
        104.25787353515625,
        30.60052490234375
      ],
      [
        104.2572021484375,
        30.60076904296875
      ],
      [
        104.25653076171875,
        30.60076904296875
      ],
      [
        104.255859375,
        30.60101318359375
      ],
      [
        104.2552490234375,
        30.6014404296875
      ],
      [
        104.25433349609375,
        30.60162353515625
      ],
      [
        104.25323486328125,
        30.60211181640625
      ],
      [
        104.25238037109375,
        30.602294921875
      ],
      [
        104.251708984375,
        30.60272216796875
      ],
      [
        104.25128173828125,
        30.60321044921875
      ],
      [
        104.2503662109375,
        30.6036376953125
      ],
      [
        104.24951171875,
        30.60430908203125
      ],
      [
        104.2486572265625,
        30.604736328125
      ],
      [
        104.24798583984375,
        30.60491943359375
      ],
      [
        104.247314453125,
        30.60540771484375
      ],
      [
        104.2462158203125,
        30.60601806640625
      ],
      [
        104.24554443359375,
        30.606689453125
      ],
      [
        104.244873046875,
        30.60650634765625
      ],
      [
        104.2437744140625,
        30.6058349609375
      ],
      [
        104.24334716796875,
        30.60540771484375
      ],
      [
        104.24224853515625,
        30.60430908203125
      ],
      [
        104.240478515625,
        30.60272216796875
      ],
      [
        104.23828125,
        30.6011962890625
      ],
      [
        104.236328125,
        30.60009765625
      ],
      [
        104.23541259765625,
        30.59967041015625
      ],
      [
        104.234130859375,
        30.59942626953125
      ],
      [
        104.233642578125,
        30.5989990234375
      ],
      [
        104.2305908203125,
        30.59814453125
      ],
      [
        104.22882080078125,
        30.59771728515625
      ],
      [
        104.22705078125,
        30.5970458984375
      ],
      [
        104.2259521484375,
        30.59661865234375
      ],
      [
        104.224853515625,
        30.59613037109375
      ],
      [
        104.2239990234375,
        30.595458984375
      ],
      [
        104.22332763671875,
        30.59527587890625
      ],
      [
        104.22222900390625,
        30.5946044921875
      ],
      [
        104.22113037109375,
        30.59393310546875
      ],
      [
        104.22027587890625,
        30.59307861328125
      ],
      [
        104.218505859375,
        30.591552734375
      ],
      [
        104.2174072265625,
        30.5902099609375
      ],
      [
        104.21673583984375,
        30.58935546875
      ],
      [
        104.21630859375,
        30.58843994140625
      ],
      [
        104.21539306640625,
        30.58758544921875
      ],
      [
        104.2149658203125,
        30.58648681640625
      ],
      [
        104.2147216796875,
        30.5858154296875
      ],
      [
        104.21453857421875,
        30.58514404296875
      ],
      [
        104.214111328125,
        30.58447265625
      ],
      [
        104.2138671875,
        30.58380126953125
      ],
      [
        104.21343994140625,
        30.58294677734375
      ],
      [
        104.2127685546875,
        30.5811767578125
      ],
      [
        104.211669921875,
        30.57879638671875
      ],
      [
        104.2105712890625,
        30.5789794921875
      ],
      [
        104.20947265625,
        30.5789794921875
      ],
      [
        104.20880126953125,
        30.5792236328125
      ],
      [
        104.20745849609375,
        30.57989501953125
      ],
      [
        104.20660400390625,
        30.57989501953125
      ],
      [
        104.20526123046875,
        30.580322265625
      ],
      [
        104.20440673828125,
        30.58050537109375
      ],
      [
        104.2037353515625,
        30.58099365234375
      ],
      [
        104.20263671875,
        30.5811767578125
      ],
      [
        104.20111083984375,
        30.58160400390625
      ],
      [
        104.20001220703125,
        30.58209228515625
      ],
      [
        104.19866943359375,
        30.582275390625
      ],
      [
        104.19781494140625,
        30.58251953125
      ],
      [
        104.1962890625,
        30.58294677734375
      ],
      [
        104.1951904296875,
        30.5833740234375
      ],
      [
        104.1949462890625,
        30.58270263671875
      ],
      [
        104.1949462890625,
        30.58209228515625
      ],
      [
        104.1947021484375,
        30.58074951171875
      ],
      [
        104.1947021484375,
        30.57830810546875
      ],
      [
        104.19451904296875,
        30.57720947265625
      ],
      [
        104.19451904296875,
        30.57635498046875
      ],
      [
        104.19427490234375,
        30.57501220703125
      ],
      [
        104.194091796875,
        30.57281494140625
      ],
      [
        104.194091796875,
        30.5721435546875
      ],
      [
        104.194091796875,
        30.571044921875
      ],
      [
        104.19342041015625,
        30.5701904296875
      ],
      [
        104.1929931640625,
        30.56976318359375
      ],
      [
        104.19207763671875,
        30.56866455078125
      ],
      [
        104.191650390625,
        30.5679931640625
      ],
      [
        104.19122314453125,
        30.56732177734375
      ],
      [
        104.1905517578125,
        30.56646728515625
      ],
      [
        104.18988037109375,
        30.5657958984375
      ],
      [
        104.189208984375,
        30.56488037109375
      ],
      [
        104.1885986328125,
        30.56402587890625
      ]
    ]
  },
  {
    coords: [
      [
        104.09682079100014,
        30.657664060000116
      ],
      [
        104.09666804000005,
        30.657411548000027
      ],
      [
        104.09646566700007,
        30.657077011000126
      ],
      [
        104.0963147290002,
        30.656827498000155
      ],
      [
        104.09610554600005,
        30.656494647000102
      ],
      [
        104.09606529200005,
        30.656440074000102
      ],
      [
        104.09595574700018,
        30.656277898000155
      ],
      [
        104.09568970800007,
        30.655884043000185
      ],
      [
        104.0954375010001,
        30.655479148000154
      ],
      [
        104.09541293400008,
        30.65543971000011
      ],
      [
        104.09539292500011,
        30.655407584000102
      ],
      [
        104.09526541200017,
        30.65520159200014
      ],
      [
        104.09514265600012,
        30.65500328400003
      ],
      [
        104.09501389600013,
        30.654795276000073
      ],
      [
        104.0948914920001,
        30.654591573000175
      ],
      [
        104.09489099900014,
        30.65459075500013
      ],
      [
        104.09486964299998,
        30.654555213000037
      ],
      [
        104.09480831600018,
        30.65448238200014
      ],
      [
        104.09478412300001,
        30.654449994999993
      ],
      [
        104.0947517310002,
        30.65440663000004
      ],
      [
        104.09463215900007,
        30.654192842000157
      ],
      [
        104.09462868300011,
        30.65418663000014
      ],
      [
        104.09454715800007,
        30.654040875000078
      ],
      [
        104.09451951200009,
        30.65399144600019
      ],
      [
        104.09449136500012,
        30.653941122000106
      ],
      [
        104.09420270500004,
        30.65347452800006
      ],
      [
        104.09380261600006,
        30.652827818000162
      ],
      [
        104.0935121550001,
        30.65235830000006
      ],
      [
        104.093264051,
        30.65195724900019
      ],
      [
        104.09321488200015,
        30.65187653800018
      ],
      [
        104.09267336099998,
        30.650987643000065
      ],
      [
        104.09247811000012,
        30.650667139000177
      ],
      [
        104.09229284600002,
        30.65036302400017
      ],
      [
        104.0921045120001,
        30.650042507000023
      ],
      [
        104.09184050200008,
        30.64961428400005
      ],
      [
        104.09176071200005,
        30.649484864000158
      ],
      [
        104.09168092000004,
        30.649355441000182
      ],
      [
        104.09159943400005,
        30.64922326599998
      ],
      [
        104.09153978400008,
        30.64912651200018
      ],
      [
        104.09151794600007,
        30.649091093000152
      ],
      [
        104.0912896760002,
        30.64872083199998
      ],
      [
        104.09122758900008,
        30.64862012200018
      ],
      [
        104.09121521700013,
        30.648600053000052
      ],
      [
        104.09091249000005,
        30.648109013000067
      ],
      [
        104.09091028700016,
        30.648105407000173
      ],
      [
        104.09062871900011,
        30.64764472000013
      ],
      [
        104.09061221900015,
        30.647617711000123
      ],
      [
        104.09048786099999,
        30.64741416600009
      ],
      [
        104.09030849599998,
        30.64712798700009
      ],
      [
        104.09029957800016,
        30.647113758000103
      ],
      [
        104.09019466300009,
        30.646946363000154
      ],
      [
        104.09008082800017,
        30.646764741000084
      ],
      [
        104.0897728610002,
        30.646273371000063
      ],
      [
        104.08973742200016,
        30.64621682500001
      ],
      [
        104.0897226040002,
        30.64619318400014
      ],
      [
        104.08963377000015,
        30.646051445000182
      ],
      [
        104.08946642000012,
        30.645781288000137
      ],
      [
        104.08946305100011,
        30.645775852000043
      ],
      [
        104.0894188800001,
        30.64570454300008
      ],
      [
        104.08937134000007,
        30.645627797000145
      ],
      [
        104.08901269500018,
        30.645048820000113
      ],
      [
        104.08893460100012,
        30.644922749000045
      ],
      [
        104.08865290400013,
        30.644470374000036
      ],
      [
        104.088231619,
        30.643793826000035
      ],
      [
        104.08821673700004,
        30.643771001000175
      ],
      [
        104.08815912800014,
        30.643682650000073
      ],
      [
        104.08804749500007,
        30.64352473600013
      ],
      [
        104.0879791590001,
        30.64345491200004
      ],
      [
        104.08796265300015,
        30.643438046000085
      ],
      [
        104.08760324000013,
        30.643158061000065
      ],
      [
        104.08750594200012,
        30.64310832200016
      ],
      [
        104.08750299200005,
        30.643106811999985
      ],
      [
        104.08742444800009,
        30.643066660000045
      ],
      [
        104.08733367300016,
        30.64301282400004
      ],
      [
        104.08723862700009,
        30.642956452000192
      ],
      [
        104.08707770100011,
        30.64285045000014
      ],
      [
        104.08692113500013,
        30.642747317000044
      ],
      [
        104.08660518900007,
        30.642539199000055
      ],
      [
        104.08658969200013,
        30.642528992000166
      ],
      [
        104.08626480200007,
        30.642314977000126
      ],
      [
        104.08615208300006,
        30.642240727
      ],
      [
        104.08613212900019,
        30.64222856599997
      ],
      [
        104.08559701700017,
        30.641902404000177
      ],
      [
        104.08539409000014,
        30.64177871300018
      ],
      [
        104.08532200000008,
        30.641728861000104
      ],
      [
        104.08520598100012,
        30.641653755000107
      ],
      [
        104.08506955000013,
        30.64156686700005
      ],
      [
        104.0850679040002,
        30.64156606300014
      ],
      [
        104.08457898400002,
        30.641327356000033
      ],
      [
        104.08437530400005,
        30.64122791000017
      ],
      [
        104.08430489800008,
        30.641194634000044
      ],
      [
        104.08353497000019,
        30.640830747000052
      ],
      [
        104.08338460100009,
        30.640759678000165
      ],
      [
        104.0825732740002,
        30.640364390000116
      ],
      [
        104.08247835000014,
        30.640318140000034
      ],
      [
        104.08167113100006,
        30.63994865300009
      ],
      [
        104.08160043900006,
        30.63991513300016
      ],
      [
        104.08100991900005,
        30.639635116000193
      ],
      [
        104.08067053400015,
        30.639474185000097
      ],
      [
        104.0805696320001,
        30.639426339000067
      ],
      [
        104.08041938800011,
        30.639355124000076
      ],
      [
        104.07997476300005,
        30.639144376000047
      ],
      [
        104.07993354500019,
        30.639124838000043
      ],
      [
        104.0795657180002,
        30.638950487000102
      ],
      [
        104.07871205700019,
        30.638545844000134
      ],
      [
        104.07811309900018,
        30.638261927000144
      ],
      [
        104.07761055300017,
        30.638023707000173
      ],
      [
        104.07751414400008,
        30.637978008000005
      ],
      [
        104.07731230000007,
        30.637882329000035
      ],
      [
        104.0765297060002,
        30.63751134900002
      ],
      [
        104.0755999590001,
        30.637071632000186
      ],
      [
        104.07488474000013,
        30.63673337000006
      ],
      [
        104.07413191300009,
        30.63637730800008
      ],
      [
        104.07387918100017,
        30.636273803000165
      ],
      [
        104.07373400600017,
        30.636236118000113
      ],
      [
        104.07365408600009,
        30.636215370000002
      ],
      [
        104.07365440100006,
        30.636210260000155
      ],
      [
        104.07366047200009,
        30.636111702000164
      ],
      [
        104.07366404100014,
        30.636053782000147
      ],
      [
        104.07366776900005,
        30.635904776000075
      ],
      [
        104.0736844290002,
        30.63561575000017
      ],
      [
        104.07370198800015,
        30.635311128000183
      ],
      [
        104.07370912100015,
        30.635015886000076
      ],
      [
        104.07371347800006,
        30.634835545000158
      ],
      [
        104.073748647,
        30.634273050000047
      ],
      [
        104.07375381999998,
        30.634190314000136
      ],
      [
        104.07377093600019,
        30.633916544000044
      ],
      [
        104.07379221500008,
        30.633576206000157
      ],
      [
        104.0738364400001,
        30.632868799000164
      ],
      [
        104.07388178600013,
        30.632136830000093
      ],
      [
        104.07389577300012,
        30.631911098000046
      ],
      [
        104.07389642100014,
        30.63190061000006
      ],
      [
        104.07389708200014,
        30.631889942000043
      ],
      [
        104.07390018100011,
        30.63183994500008
      ],
      [
        104.07391857200014,
        30.63154306100006
      ],
      [
        104.07393341400012,
        30.631303483000124
      ],
      [
        104.07395080300006,
        30.63104849000007
      ],
      [
        104.07398452100017,
        30.630553992000046
      ],
      [
        104.07401640100016,
        30.63008646900016
      ],
      [
        104.07403126900016,
        30.629913135000038
      ],
      [
        104.07406950199999,
        30.629467375000104
      ],
      [
        104.07407117100007,
        30.629447897999967
      ],
      [
        104.07407284200013,
        30.629428420000124
      ],
      [
        104.07407616300014,
        30.629389722000155
      ],
      [
        104.07408618900007,
        30.62927280000008
      ],
      [
        104.07410816999999,
        30.62901651400017
      ],
      [
        104.07413801000018,
        30.62847835500014
      ],
      [
        104.07414955100018,
        30.62827020300017
      ],
      [
        104.07415538900011,
        30.62814570200004
      ],
      [
        104.07417706500013,
        30.627683380000178
      ],
      [
        104.07418776500009,
        30.627455162000103
      ],
      [
        104.07418853400009,
        30.627438742000038
      ],
      [
        104.07418939600018,
        30.627420369000163
      ],
      [
        104.07421065700004,
        30.62696686000004
      ],
      [
        104.07421447500019,
        30.62688542400008
      ],
      [
        104.07424207600008,
        30.626490466000064
      ],
      [
        104.07425374100006,
        30.626250671000037
      ],
      [
        104.07426182000012,
        30.626084578000075
      ],
      [
        104.0742844610001,
        30.625772128000165
      ],
      [
        104.07431663600005,
        30.625328075000084
      ],
      [
        104.074333728,
        30.625092169000084
      ],
      [
        104.07434202000019,
        30.624977741000066
      ],
      [
        104.0743440407976,
        30.624949852207237
      ],
      [
        104.07436214400008,
        30.624700012000062
      ],
      [
        104.07438226700003,
        30.624422282000182
      ],
      [
        104.07444079800018,
        30.623614451000037
      ],
      [
        104.07445158000019,
        30.623465636000105
      ],
      [
        104.07446667300007,
        30.62298998000017
      ],
      [
        104.07446824400012,
        30.62294044200013
      ],
      [
        104.07447248500006,
        30.622806723000167
      ],
      [
        104.07447359700012,
        30.62280522400016
      ],
      [
        104.07447373200006,
        30.622803055000077
      ],
      [
        104.07448198300011,
        30.622670071000073
      ],
      [
        104.07448439300015,
        30.62263124600014
      ],
      [
        104.07450627300017,
        30.622278664000078
      ],
      [
        104.07452886400017,
        30.621914597000114
      ],
      [
        104.07453014700019,
        30.621893940000064
      ],
      [
        104.074539808,
        30.621738230000176
      ],
      [
        104.07454796000019,
        30.621606846000134
      ],
      [
        104.07456571200004,
        30.621320793000166
      ],
      [
        104.07459395900014,
        30.620865571000138
      ],
      [
        104.07464591000007,
        30.620028370000057
      ],
      [
        104.07464609300018,
        30.620025419000115
      ],
      [
        104.07467347600004,
        30.619541073000164
      ],
      [
        104.07469560800013,
        30.619007472000135
      ],
      [
        104.07469902100013,
        30.618925206000085
      ],
      [
        104.07470251000018,
        30.61884107300017
      ],
      [
        104.07472627400017,
        30.61826815800009
      ],
      [
        104.07474295200012,
        30.61786605800006
      ],
      [
        104.07474717200012,
        30.617610911000156
      ],
      [
        104.07477264400012,
        30.61607088400018
      ],
      [
        104.07478269900014,
        30.61546291700006
      ],
      [
        104.07481162900007,
        30.61453630400007
      ],
      [
        104.07481179300004,
        30.614531026000122
      ],
      [
        104.07483455000005,
        30.61380213800004
      ],
      [
        104.07485730500008,
        30.613073250000127
      ],
      [
        104.07487165200013,
        30.612613694000117
      ],
      [
        104.07487596300001,
        30.61247557500019
      ],
      [
        104.07487713400002,
        30.612438103000102
      ],
      [
        104.07487839800012,
        30.612397636000026
      ],
      [
        104.07488238300004,
        30.61226996400012
      ],
      [
        104.07488389600007,
        30.612221522000027
      ],
      [
        104.07488517700011,
        30.612154116000113
      ],
      [
        104.07489031000006,
        30.611883789000046
      ],
      [
        104.07489668800008,
        30.611547806000033
      ],
      [
        104.07490493600005,
        30.61111341200018
      ],
      [
        104.07490644800015,
        30.611033748000125
      ],
      [
        104.07490701200004,
        30.611003981000067
      ],
      [
        104.07490820000015,
        30.610941495000077
      ],
      [
        104.07490990300016,
        30.61085179600019
      ],
      [
        104.07491111000019,
        30.610788168000056
      ],
      [
        104.0749119140001,
        30.61074587000013
      ],
      [
        104.07491198700018,
        30.61074196700008
      ],
      [
        104.074937939,
        30.609576995000168
      ],
      [
        104.074945528,
        30.60923621699999
      ],
      [
        104.07496700500013,
        30.608621236000033
      ],
      [
        104.07498007900011,
        30.608246949000034
      ],
      [
        104.07498127400015,
        30.608212721000086
      ],
      [
        104.07498252100015,
        30.60817700500013
      ],
      [
        104.0749855050002,
        30.608091546000082
      ],
      [
        104.07498848500012,
        30.608006253000042
      ],
      [
        104.07500924200014,
        30.60741184800014
      ],
      [
        104.07501284800009,
        30.607308613000043
      ],
      [
        104.07502050700009,
        30.60708924800008
      ],
      [
        104.07503046300019,
        30.60661079500005
      ],
      [
        104.07503046300019,
        30.606610748000094
      ],
      [
        104.07503470129967,
        30.606407042502326
      ],
      [
        104.07504472700015,
        30.60592517700013
      ],
      [
        104.0750611410001,
        30.60522898200003
      ],
      [
        104.07508007800016,
        30.604661070000077
      ],
      [
        104.07514257600013,
        30.603848108000136
      ],
      [
        104.07514481200019,
        30.603819019000127
      ],
      [
        104.07515605000003,
        30.60368114700009
      ],
      [
        104.07515909400013,
        30.603643786000134
      ],
      [
        104.07516151600015,
        30.60361407700009
      ],
      [
        104.07523162900014,
        30.602753756000027
      ],
      [
        104.07523448000012,
        30.60271741600002
      ],
      [
        104.07527043900018,
        30.60225928000017
      ],
      [
        104.07532627500018,
        30.60154784000008
      ],
      [
        104.07533277400006,
        30.60146503400017
      ],
      [
        104.07535069900007,
        30.601236647000178
      ],
      [
        104.07535200700016,
        30.601219995000065
      ],
      [
        104.07535221100017,
        30.601217400999985
      ],
      [
        104.075352431,
        30.60121457200006
      ],
      [
        104.07535411200007,
        30.601193169000112
      ],
      [
        104.07535414600005,
        30.601192753000078
      ],
      [
        104.07535531200017,
        30.601177865000125
      ],
      [
        104.07535750300008,
        30.601149952000185
      ],
      [
        104.07535886700003,
        30.601132571000107
      ],
      [
        104.07536090299999,
        30.601106632000153
      ],
      [
        104.0753611310002,
        30.60110372200012
      ],
      [
        104.07536231800003,
        30.601088598000104
      ],
      [
        104.07536233800005,
        30.601088353000137
      ],
      [
        104.07536432200004,
        30.601063059000182
      ],
      [
        104.07536594900006,
        30.601042313000107
      ],
      [
        104.07536732200009,
        30.60102484000015
      ],
      [
        104.07536942100018,
        30.600998062000087
      ],
      [
        104.07537553000009,
        30.600920239999994
      ],
      [
        104.07538010200011,
        30.600862015000075
      ],
      [
        104.07538314900006,
        30.60082317200016
      ],
      [
        104.07538726200016,
        30.600770769000178
      ],
      [
        104.0753932950002,
        30.600707975000148
      ],
      [
        104.07539690700014,
        30.600670366000145
      ],
      [
        104.07540531100011,
        30.60058288199997
      ],
      [
        104.07541371400004,
        30.600495397000145
      ],
      [
        104.0755446010001,
        30.60049453400012
      ],
      [
        104.07554475900014,
        30.600494533000074
      ],
      [
        104.07607931300015,
        30.600491005000094
      ],
      [
        104.07608585700012,
        30.60049096300014
      ],
      [
        104.0760866830002,
        30.600490958000023
      ],
      [
        104.0761465860001,
        30.600490561000072
      ],
      [
        104.07618275700014,
        30.600490324000134
      ],
      [
        104.07619344500017,
        30.600490253000032
      ],
      [
        104.0762269270001,
        30.60049003100005
      ],
      [
        104.07650228400001,
        30.600488211000084
      ],
      [
        104.07658343200018,
        30.600484968000046
      ],
      [
        104.07674483900013,
        30.600478516000067
      ],
      [
        104.07692350900015,
        30.60047137300012
      ],
      [
        104.0770021130001,
        30.600468232000082
      ],
      [
        104.077506782,
        30.600448054000026
      ],
      [
        104.0776777100001,
        30.600431616000094
      ],
      [
        104.0782022030001,
        30.600381173000073
      ],
      [
        104.07849366400012,
        30.60032512800018
      ],
      [
        104.0786002860001,
        30.60030462500015
      ],
      [
        104.0787585330001,
        30.600274196000044
      ],
      [
        104.0788548820002,
        30.60025261000004
      ],
      [
        104.07934063500016,
        30.600143778000074
      ],
      [
        104.0793628630002,
        30.600138797000113
      ],
      [
        104.07982009200009,
        30.600036353000178
      ],
      [
        104.07991239800009,
        30.600015672000154
      ],
      [
        104.08012429300015,
        30.599969149000117
      ],
      [
        104.08094778200018,
        30.599788340000146
      ],
      [
        104.08147024100015,
        30.59967107600005
      ],
      [
        104.08273522200005,
        30.599387147000073
      ],
      [
        104.08376196000012,
        30.59916109300002
      ],
      [
        104.08485898700019,
        30.598919553000144
      ],
      [
        104.0856198260002,
        30.59876300900015
      ],
      [
        104.0870569600001,
        30.598442005000038
      ],
      [
        104.08740286700004,
        30.598364160000074
      ],
      [
        104.08885980400004,
        30.59803627100007
      ],
      [
        104.08975025000018,
        30.597844184000053
      ],
      [
        104.089843965,
        30.597823966000135
      ],
      [
        104.08994192000006,
        30.597802835000095
      ],
      [
        104.09047205100012,
        30.59768847000015
      ],
      [
        104.09092307200007,
        30.59758435400005
      ],
      [
        104.09124468700008,
        30.597510110000144
      ],
      [
        104.0920936060001,
        30.597332827000116
      ],
      [
        104.09235651600017,
        30.597292238000023
      ],
      [
        104.09283544599998,
        30.597231665000095
      ],
      [
        104.0930135910001,
        30.59720683500018
      ],
      [
        104.09366703500012,
        30.59715764499998
      ],
      [
        104.09412115200013,
        30.59712065500014
      ],
      [
        104.09420964399999,
        30.597124028000167
      ],
      [
        104.09428113700017,
        30.59712675200001
      ],
      [
        104.09434635400015,
        30.59712923700016
      ],
      [
        104.0943515890001,
        30.597129507000147
      ],
      [
        104.09454075700012,
        30.597140768000145
      ],
      [
        104.09459740800014,
        30.597144142000047
      ],
      [
        104.09465849700013,
        30.59714894100017
      ],
      [
        104.09476970000014,
        30.597157676999984
      ],
      [
        104.09477491500013,
        30.597158136000132
      ],
      [
        104.09504072700014,
        30.597183161000146
      ],
      [
        104.09504593800011,
        30.59718366100003
      ],
      [
        104.0953893590002,
        30.597220322000112
      ],
      [
        104.09539455900011,
        30.597220893999975
      ],
      [
        104.09567531300007,
        30.59725243200012
      ],
      [
        104.09580528800007,
        30.597267068000065
      ],
      [
        104.09614871700012,
        30.597303675000035
      ],
      [
        104.0961903480001,
        30.597308100000134
      ],
      [
        104.09675210500012,
        30.59736953400005
      ],
      [
        104.09678330900005,
        30.597372971000084
      ],
      [
        104.09712655000016,
        30.597410880000098
      ],
      [
        104.09715775600006,
        30.597414307999998
      ],
      [
        104.09722017400009,
        30.59742113500016
      ],
      [
        104.09725138700009,
        30.597424540000134
      ],
      [
        104.09751132100007,
        30.597452746000158
      ],
      [
        104.09765715000015,
        30.59746856900017
      ],
      [
        104.09772477100017,
        30.59747593900005
      ],
      [
        104.09813067900018,
        30.597518939000054
      ],
      [
        104.09823474800015,
        30.597530036000023
      ],
      [
        104.0987860530002,
        30.597590683000135
      ],
      [
        104.09885368100015,
        30.59759800100005
      ],
      [
        104.09920765999999,
        30.597634733000064
      ],
      [
        104.09964478000012,
        30.59768115700018
      ],
      [
        104.09976964200013,
        30.59769460400014
      ],
      [
        104.1003262910001,
        30.597754866000116
      ],
      [
        104.10041987300002,
        30.597765396000113
      ],
      [
        104.10048083000004,
        30.59777204300002
      ],
      [
        104.10057276200018,
        30.597782069000175
      ],
      [
        104.10094522900016,
        30.597822685000153
      ],
      [
        104.10128350500008,
        30.597858387000088
      ],
      [
        104.10157457800011,
        30.597891603000164
      ],
      [
        104.1019854330001,
        30.597936830000094
      ],
      [
        104.10199063600004,
        30.59793739000014
      ],
      [
        104.102006249,
        30.597939028999974
      ],
      [
        104.10224532600017,
        30.597964763000164
      ],
      [
        104.10252053500011,
        30.597995522000133
      ],
      [
        104.10358977700014,
        30.598115021000183
      ],
      [
        104.10436096700016,
        30.598201207000045
      ],
      [
        104.10663202700016,
        30.598447940000085
      ],
      [
        104.10966023200012,
        30.598776867000026
      ],
      [
        104.11020123100002,
        30.598835624000003
      ],
      [
        104.11190299700019,
        30.599016712000093
      ],
      [
        104.11246762000007,
        30.59907678900015
      ],
      [
        104.11267258900006,
        30.59909859600009
      ],
      [
        104.11271677700017,
        30.59910218900012
      ],
      [
        104.11293717300009,
        30.599122234000106
      ],
      [
        104.11295787500006,
        30.599124413000027
      ],
      [
        104.11297857400007,
        30.599126589000036
      ],
      [
        104.11299927800019,
        30.599128770000107
      ],
      [
        104.11301998100004,
        30.599130946000116
      ],
      [
        104.1131642530001,
        30.599177642000086
      ],
      [
        104.11316532500018,
        30.599177780000105
      ],
      [
        104.11320450300019,
        30.599182787000075
      ],
      [
        104.11324729600011,
        30.599187826000048
      ],
      [
        104.11326799000005,
        30.599190074000035
      ],
      [
        104.11328868100009,
        30.59919231200007
      ],
      [
        104.11330937000008,
        30.599194560000058
      ],
      [
        104.1133300640002,
        30.599196808000045
      ],
      [
        104.11335075500006,
        30.599199050000095
      ],
      [
        104.11337145000005,
        30.59920129700015
      ],
      [
        104.11339213800017,
        30.599203545000023
      ],
      [
        104.113412831,
        30.59920579300018
      ],
      [
        104.11342925100013,
        30.599207574000047
      ],
      [
        104.11343352500012,
        30.599208036000107
      ],
      [
        104.11345186800014,
        30.59921002500016
      ],
      [
        104.11345421600015,
        30.599210279000033
      ],
      [
        104.11347490500003,
        30.599212526000144
      ],
      [
        104.11347506300001,
        30.599212544000125
      ],
      [
        104.11349559900015,
        30.59921477400013
      ],
      [
        104.11351629000018,
        30.599217017000058
      ],
      [
        104.11353697900017,
        30.599219262000133
      ],
      [
        104.11355767300012,
        30.599221510000177
      ],
      [
        104.11357836600013,
        30.599223751000068
      ],
      [
        104.11359905300014,
        30.599225999000055
      ],
      [
        104.11361974700009,
        30.599228245000063
      ],
      [
        104.1136404400001,
        30.599230493000107
      ],
      [
        104.11366112800005,
        30.5992327350001
      ],
      [
        104.11368182100006,
        30.599234977000094
      ],
      [
        104.11370251400012,
        30.599237225000138
      ],
      [
        104.11372320200002,
        30.599239473000125
      ],
      [
        104.11374389400015,
        30.59924171600005
      ],
      [
        104.1137645880001,
        30.59924396200006
      ],
      [
        104.11378527600004,
        30.599246210000047
      ],
      [
        104.11380597100003,
        30.599248452000097
      ],
      [
        104.11382666300017,
        30.599250700000084
      ],
      [
        104.11384735600018,
        30.59925294300018
      ],
      [
        104.11386804500006,
        30.599255191000168
      ],
      [
        104.11388873700014,
        30.599257435000027
      ],
      [
        104.11390942999998,
        30.59925967700019
      ],
      [
        104.11393012400009,
        30.599261925000008
      ],
      [
        104.11395081100011,
        30.59926417300005
      ],
      [
        104.1139643840001,
        30.599265647000152
      ],
      [
        104.11396689100002,
        30.599265863000028
      ],
      [
        104.11396896200011,
        30.5992660820001
      ],
      [
        104.11398966400009,
        30.599268265000035
      ],
      [
        104.11401036200016,
        30.59927044200009
      ],
      [
        104.11403106100005,
        30.59927262900004
      ],
      [
        104.11405176500011,
        30.599274808000132
      ],
      [
        104.11407246800019,
        30.599276989000032
      ],
      [
        104.11409316600009,
        30.599279172000138
      ],
      [
        104.11411386400005,
        30.59928135400014
      ],
      [
        104.11413456400015,
        30.59928353300006
      ],
      [
        104.1141552680001,
        30.599285719000022
      ],
      [
        104.11417597000019,
        30.599287899000046
      ],
      [
        104.11419666900002,
        30.59929007900007
      ],
      [
        104.11421736700015,
        30.59929226200012
      ],
      [
        104.1142380710001,
        30.599294447000034
      ],
      [
        104.1142587700001,
        30.59929662800016
      ],
      [
        104.11427947300012,
        30.59929880900006
      ],
      [
        104.11430017100008,
        30.599300992000167
      ],
      [
        104.11432086900004,
        30.59930317400017
      ],
      [
        104.11434157400004,
        30.599305352000158
      ],
      [
        104.11436227700005,
        30.599307538000176
      ],
      [
        104.11438297500018,
        30.599309717999972
      ],
      [
        104.11440367400002,
        30.599311898000167
      ],
      [
        104.11442437900007,
        30.599314081000102
      ],
      [
        104.1144450760001,
        30.59931626399998
      ],
      [
        104.11446578000005,
        30.599318441000094
      ],
      [
        104.11448647800012,
        30.59932062700011
      ],
      [
        104.11450717600007,
        30.59932280800018
      ],
      [
        104.11452788100007,
        30.599324988000035
      ],
      [
        104.11454858300004,
        30.599327171000084
      ],
      [
        104.11456928200005,
        30.59932935200004
      ],
      [
        104.11458998100011,
        30.599331537000126
      ],
      [
        104.114610685,
        30.599333717000093
      ],
      [
        104.11463138400006,
        30.59933590000003
      ],
      [
        104.11465208600015,
        30.599338083000134
      ],
      [
        104.1146727840001,
        30.599340261000123
      ],
      [
        104.11469348300011,
        30.599342445000104
      ],
      [
        104.11471418700006,
        30.59934462600006
      ],
      [
        104.11473489000008,
        30.599346806000028
      ],
      [
        104.114755586,
        30.599348989000134
      ],
      [
        104.11477628700004,
        30.599351170000034
      ],
      [
        104.1147969920001,
        30.599353350000058
      ],
      [
        104.11481769000017,
        30.599355536000076
      ],
      [
        104.11483839200014,
        30.599357717000146
      ],
      [
        104.11485909100014,
        30.599359895000134
      ],
      [
        104.1148797890001,
        30.59936208000005
      ],
      [
        104.1149004940001,
        30.599364266000066
      ],
      [
        104.11492119700011,
        30.599366445000157
      ],
      [
        104.11494189500007,
        30.59936862500018
      ],
      [
        104.11496259400008,
        30.59937080800006
      ],
      [
        104.11498329200003,
        30.599372991000166
      ],
      [
        104.11500399600015,
        30.59937516800005
      ],
      [
        104.1150247000001,
        30.59937735400007
      ],
      [
        104.11504539800018,
        30.599379534000093
      ],
      [
        104.11506609700007,
        30.59938171200008
      ],
      [
        104.11507617600006,
        30.59938316800003
      ],
      [
        104.11508653000004,
        30.599384260000136
      ],
      [
        104.11509685100009,
        30.59938550800007
      ],
      [
        104.11510718000017,
        30.59938675600017
      ],
      [
        104.11511751000012,
        30.599388004000048
      ],
      [
        104.11512783400008,
        30.599389256000165
      ],
      [
        104.11513816100012,
        30.599390499000037
      ],
      [
        104.1151484840002,
        30.5993917510001
      ],
      [
        104.11515881300005,
        30.599393001000067
      ],
      [
        104.11516914100002,
        30.59939424900017
      ],
      [
        104.11517946400011,
        30.59939549600017
      ],
      [
        104.11529656700009,
        30.59940890900009
      ],
      [
        104.11537644500015,
        30.599418059000186
      ],
      [
        104.11538677200019,
        30.599419309000098
      ],
      [
        104.11539709500005,
        30.599420552000083
      ],
      [
        104.11540742500006,
        30.59942180400003
      ],
      [
        104.1154177530002,
        30.599423052000134
      ],
      [
        104.11542807600006,
        30.59942429800003
      ],
      [
        104.11543840500013,
        30.599425549000046
      ],
      [
        104.11544872800016,
        30.59942680000006
      ],
      [
        104.11545905600013,
        30.599428043000103
      ],
      [
        104.11546158000016,
        30.599428347000128
      ],
      [
        104.11548239300004,
        30.59943062500014
      ],
      [
        104.11550317400008,
        30.599432907000164
      ],
      [
        104.1155239220002,
        30.599435187000154
      ],
      [
        104.11554463400012,
        30.599437466999973
      ],
      [
        104.11556531100013,
        30.59943974700019
      ],
      [
        104.1155859650001,
        30.59944201400009
      ],
      [
        104.1156065790002,
        30.599444282000093
      ],
      [
        104.11562716399999,
        30.599446542000123
      ],
      [
        104.11564771000013,
        30.59944879000011
      ],
      [
        104.11566822700013,
        30.599451029000022
      ],
      [
        104.11568870900004,
        30.599453251000057
      ],
      [
        104.11570915500016,
        30.599455461000048
      ],
      [
        104.11572956000015,
        30.59945765600014
      ],
      [
        104.11573626000018,
        30.59945837400005
      ],
      [
        104.1157566660001,
        30.599460561000114
      ],
      [
        104.11577710000017,
        30.599462742000014
      ],
      [
        104.11579755900016,
        30.599464911000155
      ],
      [
        104.11581803200016,
        30.599467068000024
      ],
      [
        104.11583852700016,
        30.599469218000024
      ],
      [
        104.11585904300011,
        30.599471363000134
      ],
      [
        104.11587957400013,
        30.599473502000023
      ],
      [
        104.11590012300013,
        30.599475629000153
      ],
      [
        104.11592068600015,
        30.599477759000138
      ],
      [
        104.1162302250001,
        30.59950917900005
      ],
      [
        104.11625091200011,
        30.599511262000135
      ],
      [
        104.11627159400007,
        30.599513338000122
      ],
      [
        104.11629228800018,
        30.599515418000124
      ],
      [
        104.11631298000009,
        30.59951749400011
      ],
      [
        104.11633367800005,
        30.59951956800012
      ],
      [
        104.11635438800016,
        30.599521642000127
      ],
      [
        104.11637508600006,
        30.599523718000114
      ],
      [
        104.11639579500013,
        30.599525795000034
      ],
      [
        104.11641651000014,
        30.599527870000088
      ],
      [
        104.11643722300016,
        30.599529938000103
      ],
      [
        104.11643896000004,
        30.599530095000034
      ],
      [
        104.11645963200016,
        30.599532438000097
      ],
      [
        104.1164803160001,
        30.59953477700003
      ],
      [
        104.11650098700011,
        30.599537129000055
      ],
      [
        104.11652166500005,
        30.59953946800016
      ],
      [
        104.11654233800004,
        30.599541808000026
      ],
      [
        104.11656301600016,
        30.599544152000192
      ],
      [
        104.1165836940001,
        30.59954649200006
      ],
      [
        104.11660437100011,
        30.599548829000128
      ],
      [
        104.11662504400016,
        30.599551174000055
      ],
      [
        104.11664572700016,
        30.599553506000063
      ],
      [
        104.116666398,
        30.599555842000086
      ],
      [
        104.11668708300004,
        30.599558171000012
      ],
      [
        104.11670775800008,
        30.599560505000056
      ],
      [
        104.11672844200018,
        30.599562836000132
      ],
      [
        104.11674912000012,
        30.599565157000086
      ],
      [
        104.11676980300012,
        30.599567482000168
      ],
      [
        104.11679048200017,
        30.599569798000175
      ],
      [
        104.11681116400013,
        30.599572112000033
      ],
      [
        104.11683185100014,
        30.599574417000156
      ],
      [
        104.11685253600012,
        30.599576729000148
      ],
      [
        104.1168722330001,
        30.599578919000123
      ],
      [
        104.11689292200009,
        30.599581219000186
      ],
      [
        104.11691361000004,
        30.599583511000162
      ],
      [
        104.11691475000015,
        30.599583637000023
      ],
      [
        104.1169354380001,
        30.599585930000103
      ],
      [
        104.1169561270001,
        30.59958821700019
      ],
      [
        104.11696647100007,
        30.59958935800006
      ],
      [
        104.11697681500004,
        30.599590500000147
      ],
      [
        104.11698715900019,
        30.59959164300011
      ],
      [
        104.11699750300016,
        30.599592785000027
      ],
      [
        104.11700784700014,
        30.599593925000136
      ],
      [
        104.1170181870001,
        30.599595067000052
      ],
      [
        104.11702853100007,
        30.599596214000087
      ],
      [
        104.11703887500005,
        30.599597359000086
      ],
      [
        104.1170492190002,
        30.5995985080001
      ],
      [
        104.11705955700012,
        30.59959965900009
      ],
      [
        104.1170699010001,
        30.599600811000187
      ],
      [
        104.11708024100005,
        30.599601968000172
      ],
      [
        104.1170905780001,
        30.599603129000002
      ],
      [
        104.11710092200008,
        30.599604290000002
      ],
      [
        104.11711126300014,
        30.59960546700006
      ],
      [
        104.11712160100012,
        30.59960663800007
      ],
      [
        104.11713193400016,
        30.59960781700005
      ],
      [
        104.11714227400012,
        30.59960900200008
      ],
      [
        104.11715260700004,
        30.599610196000185
      ],
      [
        104.11716294100017,
        30.59961139400008
      ],
      [
        104.11717327500014,
        30.59961260000017
      ],
      [
        104.11718360900011,
        30.599613820000116
      ],
      [
        104.11719393700008,
        30.59961504500012
      ],
      [
        104.1172042660001,
        30.599616283000046
      ],
      [
        104.11721458900018,
        30.599617527000134
      ],
      [
        104.11722491700016,
        30.59961879200017
      ],
      [
        104.117235236,
        30.599620068000092
      ],
      [
        104.11724257100019,
        30.59962096800018
      ],
      [
        104.11725288900016,
        30.59962225400011
      ],
      [
        104.11726320600019,
        30.59962354800018
      ],
      [
        104.11727352500009,
        30.59962485500006
      ],
      [
        104.11728383900004,
        30.599626174000036
      ],
      [
        104.11729415200011,
        30.599627499000064
      ],
      [
        104.11730446200005,
        30.599628838000115
      ],
      [
        104.1173147770001,
        30.599630182000055
      ],
      [
        104.11732508300008,
        30.59963153400014
      ],
      [
        104.11733539200014,
        30.599632892000102
      ],
      [
        104.11734569900017,
        30.599634256
      ],
      [
        104.11735600800006,
        30.59963562300004
      ],
      [
        104.11736631100013,
        30.59963699800005
      ],
      [
        104.1173766170001,
        30.599638374000165
      ],
      [
        104.11738691900007,
        30.599639750000108
      ],
      [
        104.11739722900018,
        30.599641135000127
      ],
      [
        104.11740753100014,
        30.599642519999975
      ],
      [
        104.1174178330001,
        30.59964390700003
      ],
      [
        104.11742813600017,
        30.599645292000048
      ],
      [
        104.11743843800014,
        30.59964668100008
      ],
      [
        104.11806175999999,
        30.59972567500006
      ],
      [
        104.11818842100013,
        30.599748037000097
      ],
      [
        104.1189804710001,
        30.599913217999983
      ],
      [
        104.1190292030002,
        30.599935000000016
      ],
      [
        104.11939483300011,
        30.600052909000112
      ],
      [
        104.11975048800014,
        30.600171547000173
      ],
      [
        104.11996438400007,
        30.60025711800006
      ],
      [
        104.12052889600005,
        30.600501225000073
      ],
      [
        104.12058799100004,
        30.600526779000063
      ],
      [
        104.12137921700014,
        30.600913479999974
      ],
      [
        104.12224282000011,
        30.601336177000064
      ],
      [
        104.12242430300012,
        30.60142888600018
      ],
      [
        104.12310963700014,
        30.60177899500013
      ],
      [
        104.12322556800007,
        30.601838217000193
      ],
      [
        104.12334200600009,
        30.601889068000048
      ],
      [
        104.12354517600005,
        30.601990745000023
      ],
      [
        104.12364823100012,
        30.602042318000144
      ],
      [
        104.1236771660001,
        30.60205687000007
      ],
      [
        104.12424089800004,
        30.602340392000087
      ],
      [
        104.12425351500013,
        30.602346738999984
      ],
      [
        104.12432084699998,
        30.602380604000075
      ],
      [
        104.12440079600015,
        30.602420813000094
      ],
      [
        104.12444046900015,
        30.60239660400015
      ],
      [
        104.12444094400007,
        30.602396317
      ],
      [
        104.12447754800007,
        30.602373988000068
      ],
      [
        104.12455430300014,
        30.602327163000155
      ],
      [
        104.12461371100005,
        30.602290918000108
      ],
      [
        104.12466702500006,
        30.602258390000088
      ],
      [
        104.12467312000012,
        30.602254673000004
      ],
      [
        104.12467366300007,
        30.602254945000027
      ],
      [
        104.12474344400005,
        30.602290043000096
      ],
      [
        104.12476984900019,
        30.602303321000136
      ],
      [
        104.1248137680002,
        30.602325410000105
      ],
      [
        104.12497265400003,
        30.602405317000034
      ],
      [
        104.12513153900011,
        30.602485223000087
      ],
      [
        104.12550874500005,
        30.60267492900016
      ],
      [
        104.12556712300011,
        30.60270428800004
      ],
      [
        104.12558856900006,
        30.60271416100005
      ],
      [
        104.1256046260001,
        30.602721540000005
      ],
      [
        104.12574526700007,
        30.60279176200015
      ],
      [
        104.12579846700004,
        30.602820466000026
      ],
      [
        104.12584872000019,
        30.602847578000137
      ],
      [
        104.12632999000004,
        30.603085803000056
      ],
      [
        104.12696079900013,
        30.603400823000186
      ],
      [
        104.1271225700001,
        30.603481742000042
      ],
      [
        104.1274199070001,
        30.603630474000113
      ],
      [
        104.127611306,
        30.60372597800017
      ],
      [
        104.12794890500015,
        30.603894466999975
      ],
      [
        104.12799387000007,
        30.603916993999974
      ],
      [
        104.12852372100014,
        30.604182441000034
      ],
      [
        104.12852505600011,
        30.604182696000123
      ],
      [
        104.12900077100016,
        30.604421580000178
      ],
      [
        104.12934579900008,
        30.604594769000187
      ],
      [
        104.12939609500017,
        30.604618945000084
      ],
      [
        104.1296075250001,
        30.60472816200013
      ],
      [
        104.1299816290001,
        30.60491259000014
      ],
      [
        104.13039292100007,
        30.60512067100018
      ],
      [
        104.13089469200008,
        30.605373297000142
      ],
      [
        104.13134579000013,
        30.605615805000127
      ],
      [
        104.13186878400006,
        30.60593284000015
      ],
      [
        104.13204464800003,
        30.60604818300004
      ],
      [
        104.13223550800018,
        30.60618359500006
      ],
      [
        104.13246102,
        30.606343593000133
      ],
      [
        104.13259800700007,
        30.606449056000088
      ],
      [
        104.1329161590001,
        30.60670265400006
      ],
      [
        104.13291530800012,
        30.60670297000013
      ],
      [
        104.13331819900014,
        30.607064252000157
      ],
      [
        104.13349524500006,
        30.607238739000138
      ],
      [
        104.13357203700019,
        30.60731442000008
      ],
      [
        104.13366189100014,
        30.607409396000094
      ],
      [
        104.13366937400014,
        30.60741914100015
      ],
      [
        104.13385389400014,
        30.60761687700017
      ],
      [
        104.13387448800006,
        30.607638189000113
      ],
      [
        104.13408384000019,
        30.607888199000058
      ],
      [
        104.13422020500019,
        30.608051047000174
      ],
      [
        104.13422725600014,
        30.608054426000024
      ],
      [
        104.13423145500002,
        30.608058374000166
      ],
      [
        104.1346539990002,
        30.60861869100006
      ],
      [
        104.13473965200006,
        30.608732273000044
      ],
      [
        104.13482693700013,
        30.608853824000107
      ],
      [
        104.13487364700006,
        30.608917640000016
      ],
      [
        104.13502072400007,
        30.609118571000067
      ],
      [
        104.13511391200012,
        30.609241906000022
      ],
      [
        104.13513898900015,
        30.609275097000193
      ],
      [
        104.13536402500011,
        30.60958367700016
      ],
      [
        104.1355915470001,
        30.60989276400005
      ],
      [
        104.13584598200015,
        30.610237160000167
      ],
      [
        104.13608039800016,
        30.610558316000095
      ],
      [
        104.13630820399999,
        30.610868267000058
      ],
      [
        104.13640550000008,
        30.611006177000093
      ],
      [
        104.13653085300007,
        30.61117275700019
      ],
      [
        104.13659451100017,
        30.61125878600012
      ],
      [
        104.13682893300017,
        30.611579938000034
      ],
      [
        104.1369039700001,
        30.611682031000043
      ],
      [
        104.13705674300002,
        30.61188988800012
      ],
      [
        104.13715404100009,
        30.612027798000156
      ],
      [
        104.13719139700015,
        30.61208010400003
      ],
      [
        104.13721878900014,
        30.612118457000122
      ],
      [
        104.1372682970001,
        30.612187778000077
      ],
      [
        104.1372860210002,
        30.612210646000165
      ],
      [
        104.137356162,
        30.61230114600005
      ],
      [
        104.13753203700008,
        30.61254043300005
      ],
      [
        104.13762242100012,
        30.612658873000044
      ],
      [
        104.13770711500018,
        30.612778909000042
      ],
      [
        104.13771972500018,
        30.61279678300008
      ],
      [
        104.13805619800002,
        30.613257658000123
      ],
      [
        104.13809115600009,
        30.61330553800019
      ],
      [
        104.13812611000014,
        30.613353416000052
      ],
      [
        104.13830209400015,
        30.613594459000183
      ],
      [
        104.13831514800017,
        30.613612341000078
      ],
      [
        104.13832034400002,
        30.61361945900012
      ],
      [
        104.13851457900006,
        30.613885500000038
      ],
      [
        104.13854661400012,
        30.61392937800008
      ],
      [
        104.13857864900018,
        30.61397325600018
      ],
      [
        104.13858676700016,
        30.613983828000187
      ],
      [
        104.13859488400004,
        30.61399439900009
      ],
      [
        104.13861735000012,
        30.614023656000086
      ],
      [
        104.13863837500014,
        30.614051034000056
      ],
      [
        104.13868186400009,
        30.61410766900019
      ],
      [
        104.13887469600007,
        30.614358784000103
      ],
      [
        104.13906752500014,
        30.614609896000104
      ],
      [
        104.13910460099999,
        30.614658176000034
      ],
      [
        104.13914167400014,
        30.614706455000032
      ],
      [
        104.13931364900009,
        30.614930406000155
      ],
      [
        104.13932984500019,
        30.614952442000003
      ],
      [
        104.13951275200014,
        30.615201319000107
      ],
      [
        104.1396305180001,
        30.61536155700003
      ],
      [
        104.13974828000005,
        30.61552179300014
      ],
      [
        104.13990206199998,
        30.615731037000046
      ],
      [
        104.14023213500002,
        30.616177333000053
      ],
      [
        104.14029494700009,
        30.616261872000166
      ],
      [
        104.14060867900014,
        30.616689785000176
      ],
      [
        104.14071417200006,
        30.616833332000056
      ],
      [
        104.14081227200018,
        30.61696681900014
      ],
      [
        104.14082390700014,
        30.61698265200016
      ],
      [
        104.1408416860001,
        30.61700684300007
      ],
      [
        104.14086014700013,
        30.617031962000112
      ],
      [
        104.14137644900006,
        30.61773450400017
      ],
      [
        104.14138653500015,
        30.617750940999997
      ],
      [
        104.14145370900002,
        30.61784642600003
      ],
      [
        104.14146185499999,
        30.617858004000084
      ],
      [
        104.14149111500012,
        30.61789870300015
      ],
      [
        104.14156033800009,
        30.61799360000009
      ],
      [
        104.14173079200015,
        30.618182361000038
      ],
      [
        104.14177597700007,
        30.61823666000015
      ],
      [
        104.14190303400017,
        30.618397137000045
      ],
      [
        104.14194751900004,
        30.61846579300004
      ],
      [
        104.14208000800011,
        30.618670260000158
      ],
      [
        104.14212112000013,
        30.618743026000118
      ],
      [
        104.14226597300012,
        30.61899698200017
      ],
      [
        104.14230355500018,
        30.619070288000046
      ],
      [
        104.14245823200014,
        30.61938296600016
      ],
      [
        104.14248248700005,
        30.61943935500011
      ],
      [
        104.14258727500004,
        30.619682964000106
      ],
      [
        104.14263493600015,
        30.6198053440001
      ],
      [
        104.14276117500003,
        30.620169059999967
      ],
      [
        104.14276268000009,
        30.620173576000184
      ],
      [
        104.14285895700016,
        30.62051942400001
      ],
      [
        104.14293216000004,
        30.62084085200013
      ],
      [
        104.14296238000009,
        30.62099250900019
      ],
      [
        104.14296710400009,
        30.621019445000115
      ],
      [
        104.14297435100008,
        30.621060787000147
      ],
      [
        104.14300119400008,
        30.621204804000058
      ],
      [
        104.14302464600007,
        30.621354095000072
      ],
      [
        104.1430271160001,
        30.62137294500002
      ],
      [
        104.14303782500019,
        30.621442912000077
      ],
      [
        104.14307170100017,
        30.621650401000068
      ],
      [
        104.14308071700003,
        30.62169416500012
      ],
      [
        104.14310139400004,
        30.621830784000167
      ],
      [
        104.14314121000012,
        30.62207784000003
      ],
      [
        104.14321849000015,
        30.622547732000157
      ],
      [
        104.14324292700013,
        30.62270879500005
      ],
      [
        104.14331046600012,
        30.623121346000175
      ],
      [
        104.14337663400005,
        30.62350087600015
      ],
      [
        104.1434152760001,
        30.623722524000186
      ],
      [
        104.14341598500005,
        30.623726591000093
      ],
      [
        104.14348246000014,
        30.624115751000147
      ],
      [
        104.14349009100016,
        30.624160437000114
      ],
      [
        104.14350411600014,
        30.624257362000037
      ],
      [
        104.14351798400003,
        30.62435699600013
      ],
      [
        104.1435323930001,
        30.624465324000028
      ],
      [
        104.14354919800013,
        30.624525831000142
      ],
      [
        104.14355563100003,
        30.62457097600003
      ],
      [
        104.14359110900017,
        30.624689448000083
      ],
      [
        104.14360170300012,
        30.624728268000126
      ],
      [
        104.1436989950002,
        30.625015622999967
      ],
      [
        104.14372085300005,
        30.62507417500001
      ],
      [
        104.14383423600009,
        30.625351961000092
      ],
      [
        104.14386700300008,
        30.625424953000106
      ],
      [
        104.14391251000018,
        30.625526328000035
      ],
      [
        104.14394372499999,
        30.62559400000015
      ],
      [
        104.14394528400004,
        30.625597675000165
      ],
      [
        104.14401797000005,
        30.62574524000007
      ],
      [
        104.14402223700012,
        30.62575054900003
      ],
      [
        104.14402953600012,
        30.62576529300003
      ],
      [
        104.14404502200011,
        30.625797268999975
      ],
      [
        104.14405275100017,
        30.62581311300005
      ],
      [
        104.14406267300006,
        30.625831381000182
      ],
      [
        104.14407780800019,
        30.625862151000035
      ],
      [
        104.14413676400011,
        30.625977376000094
      ],
      [
        104.1441959170001,
        30.62609223900006
      ],
      [
        104.14433293500008,
        30.626382096000157
      ],
      [
        104.14452511700017,
        30.626752077000106
      ],
      [
        104.14458582500004,
        30.626864006000062
      ],
      [
        104.14466552500005,
        30.62701095700004
      ],
      [
        104.14477977000018,
        30.62722159599997
      ],
      [
        104.14488660900003,
        30.627424145000134
      ],
      [
        104.14514803900016,
        30.627919770000005
      ],
      [
        104.1451576290001,
        30.627933961000053
      ],
      [
        104.14525697100015,
        30.628136645000097
      ],
      [
        104.14543098100012,
        30.628475747000095
      ],
      [
        104.14555734700014,
        30.628719589000013
      ],
      [
        104.14559335100006,
        30.628789066000024
      ],
      [
        104.14574979900004,
        30.629092030000038
      ],
      [
        104.14583799000013,
        30.62926255100018
      ],
      [
        104.14642082200004,
        30.630366140000092
      ],
      [
        104.14645223600019,
        30.630427036000185
      ],
      [
        104.14693146500014,
        30.631356045000018
      ],
      [
        104.14712991300019,
        30.63174074000017
      ],
      [
        104.14715019500011,
        30.631782535000127
      ],
      [
        104.14749568399998,
        30.632494483000073
      ],
      [
        104.14781400000004,
        30.63315042800008
      ],
      [
        104.1479403420002,
        30.633410775000073
      ],
      [
        104.14797174300003,
        30.633475481000062
      ],
      [
        104.14798177000006,
        30.633496141000023
      ],
      [
        104.14818525700014,
        30.63391779799997
      ],
      [
        104.14818869200013,
        30.63392480400006
      ],
      [
        104.14834021700017,
        30.634234585000172
      ],
      [
        104.14839509300009,
        30.634346774000107
      ],
      [
        104.14841904100012,
        30.6343957360001
      ],
      [
        104.14847531600003,
        30.63450932700016
      ],
      [
        104.14849144200014,
        30.63454287000019
      ],
      [
        104.14850626200013,
        30.63456711000009
      ],
      [
        104.14874305400019,
        30.635034471000097
      ],
      [
        104.1487622890001,
        30.635075055000073
      ],
      [
        104.14880091700007,
        30.63515656600015
      ],
      [
        104.14887609800019,
        30.635315206000143
      ],
      [
        104.1489341410001,
        30.635437682000145
      ],
      [
        104.14913570500005,
        30.635852424000177
      ],
      [
        104.14915436600012,
        30.63589052600014
      ],
      [
        104.14943559800008,
        30.636464759000148
      ],
      [
        104.14946491900014,
        30.63652462900012
      ],
      [
        104.1494814580002,
        30.636558400000183
      ],
      [
        104.14950632800009,
        30.636609180999983
      ],
      [
        104.14952736800007,
        30.63665202300018
      ],
      [
        104.149536571,
        30.63667076100012
      ],
      [
        104.14954099300002,
        30.636679762000085
      ],
      [
        104.14955461400018,
        30.636707500000057
      ],
      [
        104.14956564300013,
        30.63672995800016
      ],
      [
        104.14957667000004,
        30.63675241500016
      ],
      [
        104.14959229200008,
        30.63678422600003
      ],
      [
        104.1497748610002,
        30.637155983000127
      ],
      [
        104.14997305300005,
        30.637559548000013
      ],
      [
        104.14999089900016,
        30.637595884000007
      ],
      [
        104.15013244500011,
        30.637881655000115
      ],
      [
        104.15029196600011,
        30.638203712000063
      ],
      [
        104.15088647200008,
        30.639403939000147
      ],
      [
        104.15097402400005,
        30.63957164500016
      ],
      [
        104.15097754700008,
        30.63957799300016
      ],
      [
        104.15098186400013,
        30.639586189000113
      ],
      [
        104.15098602400013,
        30.639594456000168
      ],
      [
        104.15099006300005,
        30.63960277000018
      ],
      [
        104.15099401700019,
        30.63961111900005
      ],
      [
        104.15099790300013,
        30.639619492000122
      ],
      [
        104.15100175800018,
        30.639627881000024
      ],
      [
        104.15100559400014,
        30.63963627600009
      ],
      [
        104.15100942900006,
        30.63964467500017
      ],
      [
        104.15101328000009,
        30.639653065000175
      ],
      [
        104.15101717400006,
        30.639661441000158
      ],
      [
        104.15102016100019,
        30.639667780000025
      ],
      [
        104.15102415000007,
        30.639676121000093
      ],
      [
        104.15102817100018,
        30.63968444300008
      ],
      [
        104.15103222100015,
        30.63969275600016
      ],
      [
        104.15103629300012,
        30.639701060000107
      ],
      [
        104.151040384,
        30.639709356000083
      ],
      [
        104.15104448500011,
        30.639717651000183
      ],
      [
        104.15104859700006,
        30.63972593700015
      ],
      [
        104.15106676900018,
        30.639773142000024
      ],
      [
        104.15107087800004,
        30.63978142800005
      ],
      [
        104.15107497800005,
        30.639789719000078
      ],
      [
        104.15107907000004,
        30.639798018000135
      ],
      [
        104.15107972600009,
        30.63979935800006
      ],
      [
        104.15108379200007,
        30.63980766600008
      ],
      [
        104.15108785100011,
        30.639815976000023
      ],
      [
        104.1510918960002,
        30.639824289000103
      ],
      [
        104.15109593400018,
        30.63983260300006
      ],
      [
        104.15109996700005,
        30.63984091900005
      ],
      [
        104.15110399600013,
        30.639849241000036
      ],
      [
        104.15110801800012,
        30.63985756000011
      ],
      [
        104.1511120400001,
        30.63986588299997
      ],
      [
        104.15111605600003,
        30.63987420700016
      ],
      [
        104.15111973400019,
        30.639881813000045
      ],
      [
        104.15112007900012,
        30.63988252700011
      ],
      [
        104.1511240980002,
        30.63989085300011
      ],
      [
        104.15112812100006,
        30.639899175000096
      ],
      [
        104.15113213700005,
        30.639907498000127
      ],
      [
        104.15113615900003,
        30.639915820000112
      ],
      [
        104.1511401910002,
        30.639924139000186
      ],
      [
        104.15114421600009,
        30.63993246100017
      ],
      [
        104.15114824900013,
        30.63994077800004
      ],
      [
        104.15115229100013,
        30.63994909100012
      ],
      [
        104.1511563320002,
        30.639957405000132
      ],
      [
        104.15116039200018,
        30.639965718000155
      ],
      [
        104.15116446300004,
        30.639974023000036
      ],
      [
        104.15116491100008,
        30.639974939000126
      ],
      [
        104.15116899999998,
        30.639983237000138
      ],
      [
        104.15117310300008,
        30.6399915290001
      ],
      [
        104.15117720900008,
        30.63999981800015
      ],
      [
        104.15117853200007,
        30.64000555900003
      ],
      [
        104.15118265400008,
        30.640013846000045
      ],
      [
        104.15118677500016,
        30.640022127000123
      ],
      [
        104.15119090400003,
        30.640030412000044
      ],
      [
        104.15119503600005,
        30.6400386950001
      ],
      [
        104.15119916900011,
        30.64004697600012
      ],
      [
        104.15120330700006,
        30.640055253000014
      ],
      [
        104.15120743800014,
        30.64006353600007
      ],
      [
        104.15121156900005,
        30.640071816000045
      ],
      [
        104.15121570200012,
        30.64008009900016
      ],
      [
        104.1512198320001,
        30.640088379000133
      ],
      [
        104.15122395700018,
        30.640096665999977
      ],
      [
        104.15122807800009,
        30.640104952000172
      ],
      [
        104.15122863800013,
        30.640106079000134
      ],
      [
        104.15123275700006,
        30.640114366000034
      ],
      [
        104.15123686200013,
        30.64012265700012
      ],
      [
        104.15124096900018,
        30.64013094600017
      ],
      [
        104.15124507400009,
        30.64013923800013
      ],
      [
        104.15124917100013,
        30.640147533000004
      ],
      [
        104.15125326900011,
        30.64015582899998
      ],
      [
        104.15134998700012,
        30.640350869000144
      ],
      [
        104.15135408500015,
        30.640359166000053
      ],
      [
        104.15135817900011,
        30.640367458000185
      ],
      [
        104.15136227700009,
        30.64037575400016
      ],
      [
        104.15136636500006,
        30.640384049000033
      ],
      [
        104.15137046100006,
        30.640392350000127
      ],
      [
        104.15137455300004,
        30.64040064500017
      ],
      [
        104.15137864300004,
        30.640408941000146
      ],
      [
        104.15138273700018,
        30.640417235000143
      ],
      [
        104.15138682900016,
        30.64042553400003
      ],
      [
        104.15139091400005,
        30.640433829000074
      ],
      [
        104.15139500400005,
        30.64044212500005
      ],
      [
        104.15139908900011,
        30.64045042500004
      ],
      [
        104.151403174,
        30.640458724000098
      ],
      [
        104.15140726000016,
        30.64046702400009
      ],
      [
        104.15141134100008,
        30.640475323000146
      ],
      [
        104.15141541900005,
        30.640483628000027
      ],
      [
        104.1514194940001,
        30.64049193200003
      ],
      [
        104.15142355699999,
        30.64050023600015
      ],
      [
        104.15142724800006,
        30.640507807000176
      ],
      [
        104.15143129900014,
        30.640516114000036
      ],
      [
        104.15143534900011,
        30.64052443300011
      ],
      [
        104.15152578300012,
        30.64071245600013
      ],
      [
        104.15152982300009,
        30.640720773000055
      ],
      [
        104.1515338690001,
        30.64072908600008
      ],
      [
        104.15153790700003,
        30.640737405000152
      ],
      [
        104.1515419480001,
        30.640745718000062
      ],
      [
        104.15154598800012,
        30.6407540350001
      ],
      [
        104.15155003600006,
        30.64076234800018
      ],
      [
        104.15155408700014,
        30.64077065800018
      ],
      [
        104.15155814200006,
        30.64077896600014
      ],
      [
        104.15156220400019,
        30.64078727000009
      ],
      [
        104.15156628200003,
        30.640795571000183
      ],
      [
        104.15156783600014,
        30.64079872700006
      ],
      [
        104.15157192800012,
        30.640807016000167
      ],
      [
        104.15157602800008,
        30.640815302000135
      ],
      [
        104.15158014000008,
        30.64082358500019
      ],
      [
        104.15158425500016,
        30.6408318660001
      ],
      [
        104.15158837800004,
        30.640840143000162
      ],
      [
        104.15159250100015,
        30.6408484210001
      ],
      [
        104.15159663300011,
        30.640856693000103
      ],
      [
        104.1516007670001,
        30.640864970000166
      ],
      [
        104.15160490300008,
        30.640873248000105
      ],
      [
        104.15160904700008,
        30.640881525000168
      ],
      [
        104.15161319300006,
        30.640889803000164
      ],
      [
        104.15161734600014,
        30.640898080000056
      ],
      [
        104.15162151300007,
        30.6409063590001
      ],
      [
        104.1516256860001,
        30.640914642000155
      ],
      [
        104.15162987000014,
        30.640922929000055
      ],
      [
        104.15175351500011,
        30.641174806000038
      ],
      [
        104.1517576940002,
        30.641183104000163
      ],
      [
        104.15183956200008,
        30.64136657000006
      ],
      [
        104.15187911200013,
        30.641460864000067
      ],
      [
        104.1519659880002,
        30.64165480000014
      ],
      [
        104.15208469400011,
        30.64194165700013
      ],
      [
        104.15215292600004,
        30.642123611000102
      ],
      [
        104.1521532060001,
        30.642124415000183
      ],
      [
        104.15271191300019,
        30.64346115300009
      ],
      [
        104.1527119230002,
        30.643461189000107
      ],
      [
        104.15271498800018,
        30.643469814000184
      ],
      [
        104.15271804800011,
        30.64347843400003
      ],
      [
        104.15272109300008,
        30.64348706000004
      ],
      [
        104.15272414400005,
        30.643495684000015
      ],
      [
        104.15272718500006,
        30.64350431400004
      ],
      [
        104.15273022800005,
        30.643512943000133
      ],
      [
        104.1527332600001,
        30.64352156800004
      ],
      [
        104.15273629400019,
        30.643530202000022
      ],
      [
        104.1527393240001,
        30.643538832000047
      ],
      [
        104.15274235300006,
        30.64354746100014
      ],
      [
        104.15274537500011,
        30.643556097000157
      ],
      [
        104.15274839000011,
        30.643564730000037
      ],
      [
        104.15275140500012,
        30.643573364000076
      ],
      [
        104.15275441900019,
        30.643581997000183
      ],
      [
        104.15275742700004,
        30.643590636000056
      ],
      [
        104.15276042600004,
        30.643599273000177
      ],
      [
        104.15276342200013,
        30.643607914000086
      ],
      [
        104.15276641100007,
        30.64361655099998
      ],
      [
        104.15276939300014,
        30.643625197000176
      ],
      [
        104.15277237000015,
        30.643633838000085
      ],
      [
        104.15277411100004,
        30.64363993900014
      ],
      [
        104.15277707500007,
        30.643648587000143
      ],
      [
        104.15278002600007,
        30.643657239000163
      ],
      [
        104.15278105400017,
        30.643660250000153
      ],
      [
        104.15278399200002,
        30.643668903000048
      ],
      [
        104.152786919,
        30.643677560000185
      ],
      [
        104.15278984300011,
        30.643686217000095
      ],
      [
        104.15279275600005,
        30.643694877000087
      ],
      [
        104.1527956610002,
        30.643703539000114
      ],
      [
        104.15279856600017,
        30.643712204000167
      ],
      [
        104.15280146500015,
        30.64372086500009
      ],
      [
        104.1528247810001,
        30.64379046099998
      ],
      [
        104.15292372000005,
        30.64409078200009
      ],
      [
        104.15292663300005,
        30.644099439000172
      ],
      [
        104.15292954400019,
        30.644108100000096
      ],
      [
        104.15293246200008,
        30.64411676300017
      ],
      [
        104.15293539000015,
        30.644125418999977
      ],
      [
        104.15293832100014,
        30.64413407400008
      ],
      [
        104.1529412550002,
        30.644142730999988
      ],
      [
        104.15294419300005,
        30.644151383000178
      ],
      [
        104.15294714000004,
        30.64416003600013
      ],
      [
        104.15295010100004,
        30.644168684000135
      ],
      [
        104.15295306500008,
        30.644177330000105
      ],
      [
        104.15295397900019,
        30.64417998500005
      ],
      [
        104.15295696400017,
        30.64418862400015
      ],
      [
        104.15295995400004,
        30.644197269000074
      ],
      [
        104.15296295500019,
        30.64420590700007
      ],
      [
        104.15296596900009,
        30.644214540000178
      ],
      [
        104.15296897900015,
        30.644223175000093
      ],
      [
        104.15297199800017,
        30.64423180800003
      ],
      [
        104.15297502100015,
        30.644240443000115
      ],
      [
        104.15297804200009,
        30.644249073000083
      ],
      [
        104.15298106600017,
        30.64425770600019
      ],
      [
        104.1529840880001,
        30.644266341000105
      ],
      [
        104.15298711000014,
        30.644274970000026
      ],
      [
        104.15299013200007,
        30.644283603000133
      ],
      [
        104.15299315200014,
        30.64429223900015
      ],
      [
        104.15299616700008,
        30.64430087200003
      ],
      [
        104.15299917500016,
        30.644309509000152
      ],
      [
        104.15300217400016,
        30.644318145000113
      ],
      [
        104.15300517000003,
        30.644326784000043
      ],
      [
        104.15300815700004,
        30.644335426000055
      ],
      [
        104.15300904399999,
        30.64433800500018
      ],
      [
        104.15301201400007,
        30.644346653000184
      ],
      [
        104.15301498000014,
        30.64435530100019
      ],
      [
        104.15301793500015,
        30.644363947000045
      ],
      [
        104.15302088200008,
        30.644372601000043
      ],
      [
        104.15302382400017,
        30.64438125200013
      ],
      [
        104.15302676900012,
        30.64438990900004
      ],
      [
        104.15302971400013,
        30.644398562000106
      ],
      [
        104.15303265300008,
        30.644407213000022
      ],
      [
        104.15303559600005,
        30.644415866000145
      ],
      [
        104.15303854100006,
        30.64442451700006
      ],
      [
        104.15304148700011,
        30.644433170000127
      ],
      [
        104.15304444200012,
        30.644441822000147
      ],
      [
        104.15304740600016,
        30.64445046900005
      ],
      [
        104.15305037600007,
        30.644459113000096
      ],
      [
        104.15305335800014,
        30.644467755000107
      ],
      [
        104.15305586500006,
        30.64447501900014
      ],
      [
        104.15305885800007,
        30.64448365800007
      ],
      [
        104.15306186600014,
        30.64449229700017
      ],
      [
        104.15306487800018,
        30.64450093000005
      ],
      [
        104.15306789400006,
        30.64450956500019
      ],
      [
        104.15308641100012,
        30.644562935000124
      ],
      [
        104.153199287,
        30.644888283000057
      ],
      [
        104.153242191,
        30.64500757400009
      ],
      [
        104.15325135400019,
        30.645033051000155
      ],
      [
        104.15326093300018,
        30.645059679000042
      ],
      [
        104.1535019640001,
        30.645729848000144
      ],
      [
        104.15356483600016,
        30.645929046000106
      ],
      [
        104.15362556900016,
        30.646107796000138
      ],
      [
        104.15370042300009,
        30.646318284000188
      ],
      [
        104.15396663600018,
        30.647078506000184
      ],
      [
        104.154110522,
        30.647516214000177
      ],
      [
        104.15413260600008,
        30.64758198300018
      ],
      [
        104.15444711400016,
        30.648490143000174
      ],
      [
        104.1548443980002,
        30.64964784700004
      ],
      [
        104.15503038500003,
        30.650182329000017
      ],
      [
        104.15533693200007,
        30.651063261000104
      ],
      [
        104.15520912700003,
        30.651074011000105
      ],
      [
        104.15519643600015,
        30.651075078000076
      ],
      [
        104.15519185900007,
        30.65107512600008
      ],
      [
        104.1551503880001,
        30.651075555000034
      ],
      [
        104.15508036500006,
        30.65107627700013
      ],
      [
        104.15502586200006,
        30.651076839000154
      ],
      [
        104.15496085900014,
        30.65107822900012
      ],
      [
        104.15487310900016,
        30.651080105000062
      ],
      [
        104.15484134899998,
        30.651079344000095
      ],
      [
        104.1547412650001,
        30.651076956000054
      ],
      [
        104.15473192900004,
        30.651076732000035
      ],
      [
        104.15470818800009,
        30.65107616500012
      ],
      [
        104.15469961700018,
        30.651075960000185
      ],
      [
        104.1546818590001,
        30.65107580200015
      ],
      [
        104.15465852900013,
        30.65107559300003
      ],
      [
        104.15418655600007,
        30.65107138700006
      ],
      [
        104.15409039700006,
        30.651070530000084
      ],
      [
        104.15382804400008,
        30.651068190000046
      ],
      [
        104.15340177100006,
        30.65106799600005
      ],
      [
        104.15315855000011,
        30.651074298000026
      ],
      [
        104.15304913800014,
        30.651082936000023
      ],
      [
        104.15293228500013,
        30.651092160000076
      ],
      [
        104.15292041700008,
        30.65109348100009
      ],
      [
        104.15262206100005,
        30.651126695000187
      ],
      [
        104.15236535700012,
        30.651172521999968
      ],
      [
        104.15213073600017,
        30.651216782999995
      ],
      [
        104.15212216400016,
        30.65121839900013
      ],
      [
        104.1517842400001,
        30.65128947000005
      ],
      [
        104.15162415700007,
        30.651332929000034
      ],
      [
        104.1512355120002,
        30.65144248100006
      ],
      [
        104.15114737000016,
        30.651467325000112
      ],
      [
        104.15105486900006,
        30.65149178500019
      ],
      [
        104.14793706600011,
        30.65237062800003
      ],
      [
        104.1478470180001,
        30.652396009000086
      ],
      [
        104.14756452400019,
        30.65247563300005
      ],
      [
        104.1463162020001,
        30.652600227000107
      ],
      [
        104.14437530600009,
        30.652187108000078
      ],
      [
        104.14437474000005,
        30.652187040000115
      ],
      [
        104.14426795600008,
        30.652173962000063
      ],
      [
        104.14355155500004,
        30.652086248000103
      ],
      [
        104.14336577700016,
        30.652071528000135
      ],
      [
        104.14235250800004,
        30.651991229000146
      ],
      [
        104.14227924100015,
        30.651985424000088
      ],
      [
        104.14154834200014,
        30.65195909100015
      ],
      [
        104.14074402300014,
        30.651930109000034
      ],
      [
        104.14043547400007,
        30.651918991000002
      ],
      [
        104.14043125000006,
        30.651918789000035
      ],
      [
        104.14011870700006,
        30.65190385200009
      ],
      [
        104.13974713700009,
        30.65188609200004
      ],
      [
        104.13937556600013,
        30.651868333000095
      ],
      [
        104.13904980900008,
        30.651852760000168
      ],
      [
        104.1387848190002,
        30.65182402000005
      ],
      [
        104.138597,
        30.651786354000137
      ],
      [
        104.13827238100009,
        30.651702610000143
      ],
      [
        104.13776336100005,
        30.65157129400012
      ],
      [
        104.13770367900008,
        30.651553900000124
      ],
      [
        104.13721271600008,
        30.651410812000165
      ],
      [
        104.13707582699999,
        30.651370916000076
      ],
      [
        104.136994287,
        30.651347151000152
      ],
      [
        104.13693885600014,
        30.65133122400016
      ],
      [
        104.13693044800016,
        30.65132880800013
      ],
      [
        104.13654496000004,
        30.651218037000092
      ],
      [
        104.13650026800008,
        30.651205194000056
      ],
      [
        104.13646381299998,
        30.651194720000035
      ],
      [
        104.1338342520001,
        30.650439066000047
      ],
      [
        104.13373819800017,
        30.65041443200016
      ],
      [
        104.133610681,
        30.65039188800006
      ],
      [
        104.13321876100008,
        30.650325416000044
      ],
      [
        104.13254410000013,
        30.650211366000065
      ],
      [
        104.13221269300016,
        30.65015033400016
      ],
      [
        104.13202930600016,
        30.650117350000073
      ],
      [
        104.13177817800016,
        30.650072181000155
      ],
      [
        104.131767712,
        30.65007262100005
      ],
      [
        104.1316710270001,
        30.650058546000025
      ],
      [
        104.131666838,
        30.65005772800015
      ],
      [
        104.13119390000014,
        30.64996522900003
      ],
      [
        104.1310494220001,
        30.649937234000106
      ],
      [
        104.13103917800015,
        30.649935493000044
      ],
      [
        104.1310289430001,
        30.6499337570001
      ],
      [
        104.13099825600011,
        30.649928548000105
      ],
      [
        104.13098802399998,
        30.64992681700005
      ],
      [
        104.13097779800006,
        30.64992508100005
      ],
      [
        104.13096756900018,
        30.649923340000043
      ],
      [
        104.13095734600012,
        30.64992160100013
      ],
      [
        104.13070547400008,
        30.64987738900004
      ],
      [
        104.13069525100019,
        30.64987564600017
      ],
      [
        104.13068502700008,
        30.649873892000187
      ],
      [
        104.13067480000018,
        30.649872133000144
      ],
      [
        104.1306705520002,
        30.649871399000062
      ],
      [
        104.13066032400008,
        30.649869630000182
      ],
      [
        104.13065010200012,
        30.64986785500014
      ],
      [
        104.13063987100003,
        30.6498660740001
      ],
      [
        104.13062964500011,
        30.649864287000185
      ],
      [
        104.13060919700018,
        30.649860707000073
      ],
      [
        104.13059897500005,
        30.649858913999992
      ],
      [
        104.13057852800006,
        30.649855317000174
      ],
      [
        104.13056830100015,
        30.649853511000174
      ],
      [
        104.13055808300004,
        30.64985171000012
      ],
      [
        104.13054785600019,
        30.64984989600015
      ],
      [
        104.13053763900012,
        30.64984808300011
      ],
      [
        104.13052741900015,
        30.64984626200004
      ],
      [
        104.13039323800018,
        30.649822663000123
      ],
      [
        104.1303830240002,
        30.649820826000052
      ],
      [
        104.13037303500016,
        30.649819017000027
      ],
      [
        104.1303526150001,
        30.64981530400013
      ],
      [
        104.13034240600007,
        30.649813451000057
      ],
      [
        104.13033219300019,
        30.64981159900009
      ],
      [
        104.13032197800015,
        30.649809766000033
      ],
      [
        104.13031176599998,
        30.64980795400004
      ],
      [
        104.13030153900013,
        30.649806164000097
      ],
      [
        104.13029130500018,
        30.649804410000115
      ],
      [
        104.13028474700013,
        30.649803306000024
      ],
      [
        104.13014802700002,
        30.649778775000073
      ],
      [
        104.1301377690001,
        30.649777119000078
      ],
      [
        104.13012750900009,
        30.649775487000113
      ],
      [
        104.13011724900014,
        30.649773878000076
      ],
      [
        104.13010697600009,
        30.64977228700002
      ],
      [
        104.13009670500003,
        30.649770708000176
      ],
      [
        104.1300864270001,
        30.649769145000164
      ],
      [
        104.13007615000004,
        30.64976759400008
      ],
      [
        104.13006587500018,
        30.649766055000043
      ],
      [
        104.13005558600014,
        30.649764532000006
      ],
      [
        104.13004530700005,
        30.64976302000008
      ],
      [
        104.13003501100007,
        30.649761528000113
      ],
      [
        104.13002472200003,
        30.649760058000027
      ],
      [
        104.12989215200008,
        30.649742276000154
      ],
      [
        104.12988184500006,
        30.649740869000084
      ],
      [
        104.12987153200015,
        30.649739508000096
      ],
      [
        104.12986564500011,
        30.649738751000086
      ],
      [
        104.12985531900017,
        30.64973746600009
      ],
      [
        104.1298449840001,
        30.649736226000186
      ],
      [
        104.12983464100006,
        30.64973501900016
      ],
      [
        104.12982429400017,
        30.64973384200016
      ],
      [
        104.12979929400007,
        30.64973165300006
      ],
      [
        104.12978893400009,
        30.649730540000064
      ],
      [
        104.12977857200013,
        30.64972946300003
      ],
      [
        104.12976820800014,
        30.64972842500015
      ],
      [
        104.12975784100007,
        30.64972743400017
      ],
      [
        104.12974411400012,
        30.649726872000087
      ],
      [
        104.12973377300005,
        30.64972593900012
      ],
      [
        104.12972640800007,
        30.64972545900008
      ],
      [
        104.12972334100004,
        30.649725230000172
      ],
      [
        104.12971982700014,
        30.649724980000144
      ],
      [
        104.12970942500016,
        30.64972429800008
      ],
      [
        104.1296990150002,
        30.64972369500009
      ],
      [
        104.12968860400014,
        30.64972313900006
      ],
      [
        104.12949137200013,
        30.649715305000143
      ],
      [
        104.12932181900004,
        30.649719627000138
      ],
      [
        104.12911787700006,
        30.649732458000017
      ],
      [
        104.1289721550001,
        30.64974586500017
      ],
      [
        104.12885412999998,
        30.649763523000047
      ],
      [
        104.12872762300009,
        30.649783748000175
      ],
      [
        104.12872645499999,
        30.649783925000122
      ],
      [
        104.12871649500005,
        30.64978546600014
      ],
      [
        104.12870623000003,
        30.64978710200012
      ],
      [
        104.12869598000009,
        30.64978878500017
      ],
      [
        104.12868573700018,
        30.649790503000133
      ],
      [
        104.12867550500005,
        30.649792256000183
      ],
      [
        104.12866527400018,
        30.64979404500008
      ],
      [
        104.12865506100013,
        30.649795852000125
      ],
      [
        104.12864484200009,
        30.649797673000023
      ],
      [
        104.12863462700005,
        30.649799518000066
      ],
      [
        104.12862442400018,
        30.649801371000137
      ],
      [
        104.12861166300013,
        30.649803697000152
      ],
      [
        104.12860563300012,
        30.64980479700006
      ],
      [
        104.1285994220001,
        30.64980599500018
      ],
      [
        104.12859380000015,
        30.649806956000134
      ],
      [
        104.12858359600017,
        30.649808812000117
      ],
      [
        104.12857338200007,
        30.649810662000107
      ],
      [
        104.12856836000014,
        30.649809926000046
      ],
      [
        104.12856815100008,
        30.64980996100013
      ],
      [
        104.1285579380002,
        30.649811802000045
      ],
      [
        104.12854772000003,
        30.649813625000093
      ],
      [
        104.12853750400012,
        30.649815441000044
      ],
      [
        104.12853738500019,
        30.649815441000044
      ],
      [
        104.12852716200013,
        30.649817244000133
      ],
      [
        104.12851693700009,
        30.649819045000186
      ],
      [
        104.12850671600006,
        30.64982083600006
      ],
      [
        104.12849636000004,
        30.649821899000074
      ],
      [
        104.1284861330002,
        30.649823685000058
      ],
      [
        104.1284849490001,
        30.64982387600014
      ],
      [
        104.12846457400008,
        30.649827334000065
      ],
      [
        104.12844416799999,
        30.649830817000066
      ],
      [
        104.12842374600012,
        30.649834317
      ],
      [
        104.12840330400019,
        30.64983783500014
      ],
      [
        104.12838284700018,
        30.649841355999968
      ],
      [
        104.1283623880002,
        30.649844886000096
      ],
      [
        104.12834191600007,
        30.649848433000102
      ],
      [
        104.12832144600014,
        30.649851980000165
      ],
      [
        104.12830096100015,
        30.649855541000193
      ],
      [
        104.12828048400013,
        30.64985910200005
      ],
      [
        104.12826001000008,
        30.649862677000044
      ],
      [
        104.12823953900005,
        30.64986626299998
      ],
      [
        104.12821906300013,
        30.649869857000112
      ],
      [
        104.12819859400014,
        30.649873476000153
      ],
      [
        104.12817813000004,
        30.64987710700018
      ],
      [
        104.12815767500018,
        30.64988076000003
      ],
      [
        104.12813722700008,
        30.64988444700009
      ],
      [
        104.128116799,
        30.649888174000125
      ],
      [
        104.12809686400004,
        30.64989185400009
      ],
      [
        104.12807646600015,
        30.649895669000102
      ],
      [
        104.12805607400003,
        30.649899516000175
      ],
      [
        104.12803569600015,
        30.64990339200017
      ],
      [
        104.12801532300017,
        30.649907302000088
      ],
      [
        104.12799496100007,
        30.6499112460001
      ],
      [
        104.12799220900018,
        30.64991177000013
      ],
      [
        104.12797185700009,
        30.649915734000103
      ],
      [
        104.12795151400013,
        30.6499197280001
      ],
      [
        104.12793117300009,
        30.64992374800005
      ],
      [
        104.1279108380001,
        30.649927772000012
      ],
      [
        104.12789051100015,
        30.649931821999985
      ],
      [
        104.12787017400012,
        30.64993587800018
      ],
      [
        104.12784985000002,
        30.649939942000174
      ],
      [
        104.12782953000016,
        30.649944015000017
      ],
      [
        104.12780920200004,
        30.649948091
      ],
      [
        104.12778888000014,
        30.649952160999987
      ],
      [
        104.12776856000005,
        30.649956239000176
      ],
      [
        104.1277482270001,
        30.649960304000047
      ],
      [
        104.12772790100018,
        30.649964366000063
      ],
      [
        104.12770756100008,
        30.649968410000042
      ],
      [
        104.12768722800007,
        30.649972437000088
      ],
      [
        104.1276668810001,
        30.64997644300007
      ],
      [
        104.12765195100008,
        30.649979380000048
      ],
      [
        104.12764175800004,
        30.64998131100009
      ],
      [
        104.12763159200011,
        30.649983329000122
      ],
      [
        104.12762138800014,
        30.649985242000184
      ],
      [
        104.12761122000018,
        30.64998726000016
      ],
      [
        104.12760101600009,
        30.64998915700005
      ],
      [
        104.12759084800012,
        30.64999115500001
      ],
      [
        104.12758064200017,
        30.64999304500003
      ],
      [
        104.12757046500013,
        30.64999502800009
      ],
      [
        104.12756025300013,
        30.649996910000084
      ],
      [
        104.12755007300018,
        30.64999888100016
      ],
      [
        104.12753986000013,
        30.650000751000107
      ],
      [
        104.12751946300017,
        30.650004571000068
      ],
      [
        104.12749905900006,
        30.6500083680001
      ],
      [
        104.12747865000006,
        30.650012139000182
      ],
      [
        104.12745823100005,
        30.650015882000048
      ],
      [
        104.12743782200005,
        30.650019622000173
      ],
      [
        104.12741739700004,
        30.65002332600011
      ],
      [
        104.12739696000006,
        30.650027006000073
      ],
      [
        104.12737652500005,
        30.650030659000095
      ],
      [
        104.12735608600002,
        30.650034296000115
      ],
      [
        104.12733564300004,
        30.650037899000154
      ],
      [
        104.12731519500011,
        30.650041480000027
      ],
      [
        104.12729473900004,
        30.65004503200015
      ],
      [
        104.12727428300013,
        30.65004855899997
      ],
      [
        104.12725382100018,
        30.650052055000117
      ],
      [
        104.12723336500011,
        30.65005551300004
      ],
      [
        104.12721290100018,
        30.650058921000152
      ],
      [
        104.12719243800018,
        30.650062280000157
      ],
      [
        104.1271895540001,
        30.650062744000024
      ],
      [
        104.12716908900012,
        30.650066036000112
      ],
      [
        104.12714861000012,
        30.650069332000044
      ],
      [
        104.12712813100012,
        30.650072691000048
      ],
      [
        104.12711787100017,
        30.650074344000075
      ],
      [
        104.12709738000012,
        30.650077825000096
      ],
      [
        104.1270957210001,
        30.650078119000113
      ],
      [
        104.12692436300011,
        30.650107660000174
      ],
      [
        104.12669502900013,
        30.65014719300018
      ],
      [
        104.12646569499998,
        30.650186725000026
      ],
      [
        104.12639976900005,
        30.650198100000182
      ],
      [
        104.12633851900011,
        30.650208669999984
      ],
      [
        104.1263361610001,
        30.65020883900013
      ],
      [
        104.12632776500016,
        30.650209997000047
      ],
      [
        104.12630722400013,
        30.650212991000103
      ],
      [
        104.12630660800005,
        30.650213097999995
      ],
      [
        104.12628619200018,
        30.650216363000027
      ],
      [
        104.1262657480001,
        30.650219614000036
      ],
      [
        104.12624527500009,
        30.65022286100003
      ],
      [
        104.12622477700012,
        30.650226107000094
      ],
      [
        104.12620426700005,
        30.65022935000013
      ],
      [
        104.12618374400006,
        30.650232597000127
      ],
      [
        104.126163211,
        30.650235868000095
      ],
      [
        104.12615990400019,
        30.65023637900009
      ],
      [
        104.1261393650002,
        30.65023966600006
      ],
      [
        104.12613082500019,
        30.650241039000093
      ],
      [
        104.12605545300016,
        30.650252663000174
      ],
      [
        104.12587392700004,
        30.6502806580001
      ],
      [
        104.1256938890001,
        30.650308423000183
      ],
      [
        104.12543292200007,
        30.650348666000014
      ],
      [
        104.12534298100019,
        30.650372295000125
      ],
      [
        104.12513748000015,
        30.650461153000037
      ],
      [
        104.12506301000019,
        30.65051527800017
      ],
      [
        104.12501311600016,
        30.65055153900005
      ],
      [
        104.12490685500018,
        30.65061031600004
      ],
      [
        104.12471744900012,
        30.650722210000026
      ],
      [
        104.12443523300004,
        30.65092196200004
      ],
      [
        104.1243455450001,
        30.65098774100005
      ],
      [
        104.12375059800013,
        30.651424079000094
      ],
      [
        104.12307838400011,
        30.651917077000064
      ],
      [
        104.12295322200009,
        30.652008867000177
      ],
      [
        104.12268164500006,
        30.65220803700015
      ],
      [
        104.12236093400008,
        30.65242520600009
      ],
      [
        104.12217492200017,
        30.652532028000053
      ],
      [
        104.12202159700018,
        30.652620076000062
      ],
      [
        104.12195470400007,
        30.65265229000005
      ],
      [
        104.12166570400012,
        30.652791458000024
      ],
      [
        104.12129542700012,
        30.652938306000067
      ],
      [
        104.1209130220002,
        30.653059724000173
      ],
      [
        104.12054682200011,
        30.65314865900018
      ],
      [
        104.12054533100007,
        30.653149020000114
      ],
      [
        104.12052082200006,
        30.65315497200004
      ],
      [
        104.12012122400006,
        30.653223470000057
      ],
      [
        104.11971665900006,
        30.65326479900017
      ],
      [
        104.11933514700013,
        30.65328160200005
      ],
      [
        104.11931401900017,
        30.653282530000126
      ],
      [
        104.11927420400013,
        30.653282441000044
      ],
      [
        104.11885771500005,
        30.653281473000163
      ],
      [
        104.11871672900014,
        30.65328114600004
      ],
      [
        104.11871379700005,
        30.653280070000108
      ],
      [
        104.11870702100003,
        30.653278660000183
      ],
      [
        104.11864614900014,
        30.653278569000065
      ],
      [
        104.11840141600004,
        30.653279059000113
      ],
      [
        104.11822272199998,
        30.653279417000135
      ],
      [
        104.11804402600012,
        30.65327977300018
      ],
      [
        104.11777606400017,
        30.653280309000024
      ],
      [
        104.11775133700019,
        30.65328036000011
      ],
      [
        104.11736365800016,
        30.65328113000004
      ],
      [
        104.11707438000019,
        30.653283602000045
      ],
      [
        104.1165870440002,
        30.653282657000034
      ],
      [
        104.1162154430001,
        30.653281933000187
      ],
      [
        104.11601826700013,
        30.653281367000147
      ],
      [
        104.11593836999998,
        30.653280889000087
      ],
      [
        104.11564909300012,
        30.653283361000092
      ],
      [
        104.11526405800015,
        30.65328138800004
      ],
      [
        104.1151300620001,
        30.653281082000035
      ],
      [
        104.11485044200003,
        30.653280438000138
      ],
      [
        104.11469648200011,
        30.65328015700004
      ],
      [
        104.11443187800006,
        30.653278867000097
      ],
      [
        104.11425186300016,
        30.65327798600009
      ],
      [
        104.11409461100016,
        30.653276164000147
      ],
      [
        104.11361556500009,
        30.653276268000127
      ],
      [
        104.11297062100004,
        30.6532764050001
      ],
      [
        104.11277762600014,
        30.65328137500012
      ],
      [
        104.11245276100016,
        30.653284846000133
      ],
      [
        104.11232308199999,
        30.65330880800019
      ],
      [
        104.11230547900004,
        30.65331206100018
      ],
      [
        104.11229773400004,
        30.6533134930001
      ],
      [
        104.11216974300004,
        30.653337142000055
      ],
      [
        104.11202062000001,
        30.65337910600016
      ],
      [
        104.11199466600004,
        30.653389314000094
      ],
      [
        104.11189742300007,
        30.65342756100017
      ],
      [
        104.11180986700009,
        30.653461995000157
      ],
      [
        104.11156108,
        30.65355984399997
      ],
      [
        104.11152112000008,
        30.653578860000152
      ],
      [
        104.1111651450002,
        30.6537482760001
      ],
      [
        104.1109070670002,
        30.65387499700006
      ],
      [
        104.11078077100001,
        30.653937009000003
      ],
      [
        104.11028173800003,
        30.654153973000007
      ],
      [
        104.11019360000006,
        30.65419228899998
      ],
      [
        104.10978417500019,
        30.65437029100019
      ],
      [
        104.10971964800007,
        30.654398342000093
      ],
      [
        104.10961381300012,
        30.654447820000087
      ],
      [
        104.10957268100009,
        30.654467046000093
      ],
      [
        104.10929308800013,
        30.654597749000175
      ],
      [
        104.10924729600009,
        30.654619155000148
      ],
      [
        104.10918737000009,
        30.654647167000178
      ],
      [
        104.10879615700014,
        30.654827196000042
      ],
      [
        104.10865784600014,
        30.65489084600017
      ],
      [
        104.10851512000005,
        30.65495652300018
      ],
      [
        104.10829208600006,
        30.655045053000038
      ],
      [
        104.10828938600002,
        30.655046125000126
      ],
      [
        104.10828668500011,
        30.655047196000112
      ],
      [
        104.10767102500012,
        30.655349735000186
      ],
      [
        104.10737935800006,
        30.655493060000083
      ],
      [
        104.10723807700003,
        30.655562484000143
      ],
      [
        104.1070522550001,
        30.65564754900015
      ],
      [
        104.10662377400007,
        30.655843701000038
      ],
      [
        104.10619528900008,
        30.656039851000116
      ],
      [
        104.10615931600017,
        30.656056317000036
      ],
      [
        104.10554539700013,
        30.65634305300017
      ],
      [
        104.10503945699998,
        30.656579353000154
      ],
      [
        104.10499274000011,
        30.656605404000118
      ],
      [
        104.10495130600009,
        30.65662629000002
      ],
      [
        104.10489840800011,
        30.656650903000013
      ],
      [
        104.10430963400012,
        30.656924843000127
      ],
      [
        104.10372085600005,
        30.65719878100009
      ],
      [
        104.10334454100007,
        30.65737386700016
      ],
      [
        104.10316267000007,
        30.65745848100005
      ],
      [
        104.10260448200006,
        30.657718178000152
      ],
      [
        104.10253930900006,
        30.657748505000086
      ],
      [
        104.10249440799998,
        30.657766568000056
      ],
      [
        104.10246841400016,
        30.657776679000108
      ],
      [
        104.10244356500016,
        30.657789221000087
      ],
      [
        104.10239958700004,
        30.657813133000104
      ],
      [
        104.10230407400007,
        30.657857980000188
      ],
      [
        104.10183116400003,
        30.65808002300014
      ],
      [
        104.10169572600017,
        30.65814291400011
      ],
      [
        104.10103741900014,
        30.658448597000074
      ],
      [
        104.10078563600018,
        30.658565509000027
      ],
      [
        104.10036768600008,
        30.658759578000172
      ],
      [
        104.10021288200005,
        30.658831460000044
      ],
      [
        104.10004820000012,
        30.658907925999983
      ],
      [
        104.09994973200014,
        30.658953646000043
      ],
      [
        104.09985522400012,
        30.658997528000157
      ],
      [
        104.09955481800012,
        30.65913859200009
      ],
      [
        104.09950743100006,
        30.659160843000052
      ],
      [
        104.09915853200005,
        30.659324677000086
      ],
      [
        104.09906508700004,
        30.65936798000007
      ],
      [
        104.09895849399999,
        30.659417375000146
      ],
      [
        104.09866785300005,
        30.65955205800003
      ],
      [
        104.09831988600013,
        30.65971330500014
      ],
      [
        104.09827061300018,
        30.659736136000163
      ],
      [
        104.0982028630001,
        30.65976753100017
      ],
      [
        104.09820197200014,
        30.659767944000123
      ],
      [
        104.09813511200008,
        30.659798925000075
      ],
      [
        104.09798718300004,
        30.659570307000024
      ],
      [
        104.09786032900018,
        30.659365826000055
      ],
      [
        104.09753738400019,
        30.658848526000043
      ],
      [
        104.09749878500008,
        30.658786696999982
      ],
      [
        104.0973449830002,
        30.65853057300012
      ],
      [
        104.09729590600011,
        30.658449442000062
      ],
      [
        104.09712833500004,
        30.65817244400006
      ],
      [
        104.09705639200018,
        30.65805351800003
      ],
      [
        104.09695477200012,
        30.65788553300007
      ],
      [
        104.09682079100014,
        30.657664060000116
      ],
      [
        104.09666804000005,
        30.657411548000027
      ]
    ]
  },
  {
    coords: [
      [
        104.11202062000001,
        30.65337910600016
      ],
      [
        104.11216974300004,
        30.653337142000055
      ],
      [
        104.11229773400004,
        30.6533134930001
      ],
      [
        104.11230547900004,
        30.65331206100018
      ],
      [
        104.11232308199999,
        30.65330880800019
      ],
      [
        104.11245276100016,
        30.653284846000133
      ],
      [
        104.11277762600014,
        30.65328137500012
      ],
      [
        104.11297062100004,
        30.6532764050001
      ],
      [
        104.11361556500009,
        30.653276268000127
      ],
      [
        104.11409461100016,
        30.653276164000147
      ],
      [
        104.11425186300016,
        30.65327798600009
      ],
      [
        104.11443187800006,
        30.653278867000097
      ],
      [
        104.11469648200011,
        30.65328015700004
      ],
      [
        104.11485044200003,
        30.653280438000138
      ],
      [
        104.1151300620001,
        30.653281082000035
      ],
      [
        104.11526405800015,
        30.65328138800004
      ],
      [
        104.11564909300012,
        30.653283361000092
      ],
      [
        104.11593836999998,
        30.653280889000087
      ],
      [
        104.11601826700013,
        30.653281367000147
      ],
      [
        104.1162154430001,
        30.653281933000187
      ],
      [
        104.1165870440002,
        30.653282657000034
      ],
      [
        104.11707438000019,
        30.653283602000045
      ],
      [
        104.11736365800016,
        30.65328113000004
      ],
      [
        104.11775133700019,
        30.65328036000011
      ],
      [
        104.11777606400017,
        30.653280309000024
      ],
      [
        104.11804402600012,
        30.65327977300018
      ],
      [
        104.11822272199998,
        30.653279417000135
      ],
      [
        104.11840141600004,
        30.653279059000113
      ],
      [
        104.11864614900014,
        30.653278569000065
      ],
      [
        104.11870702100003,
        30.653278660000183
      ],
      [
        104.11871379700005,
        30.653280070000108
      ],
      [
        104.11871672900014,
        30.65328114600004
      ],
      [
        104.11885771500005,
        30.653281473000163
      ],
      [
        104.11927420400013,
        30.653282441000044
      ],
      [
        104.11931401900017,
        30.653282530000126
      ],
      [
        104.11933514700013,
        30.65328160200005
      ],
      [
        104.11971665900006,
        30.65326479900017
      ],
      [
        104.12012122400006,
        30.653223470000057
      ],
      [
        104.12052082200006,
        30.65315497200004
      ],
      [
        104.12054533100007,
        30.653149020000114
      ],
      [
        104.12054682200011,
        30.65314865900018
      ],
      [
        104.1209130220002,
        30.653059724000173
      ],
      [
        104.12129542700012,
        30.652938306000067
      ],
      [
        104.12166570400012,
        30.652791458000024
      ],
      [
        104.12195470400007,
        30.65265229000005
      ],
      [
        104.12202159700018,
        30.652620076000062
      ],
      [
        104.12217492200017,
        30.652532028000053
      ],
      [
        104.12236093400008,
        30.65242520600009
      ],
      [
        104.12268164500006,
        30.65220803700015
      ],
      [
        104.12295322200009,
        30.652008867000177
      ],
      [
        104.12307838400011,
        30.651917077000064
      ],
      [
        104.12375059800013,
        30.651424079000094
      ],
      [
        104.1243455450001,
        30.65098774100005
      ],
      [
        104.12443523300004,
        30.65092196200004
      ],
      [
        104.12471744900012,
        30.650722210000026
      ],
      [
        104.12490685500018,
        30.65061031600004
      ],
      [
        104.12501311600016,
        30.65055153900005
      ],
      [
        104.12506301000019,
        30.65051527800017
      ],
      [
        104.12513748000015,
        30.650461153000037
      ],
      [
        104.12534298100019,
        30.650372295000125
      ],
      [
        104.12543292200007,
        30.650348666000014
      ],
      [
        104.1256938890001,
        30.650308423000183
      ],
      [
        104.12587392700004,
        30.6502806580001
      ],
      [
        104.12605545300016,
        30.650252663000174
      ],
      [
        104.12613082500019,
        30.650241039000093
      ],
      [
        104.1261393650002,
        30.65023966600006
      ],
      [
        104.12615990400019,
        30.65023637900009
      ],
      [
        104.126163211,
        30.650235868000095
      ],
      [
        104.12618374400006,
        30.650232597000127
      ],
      [
        104.12620426700005,
        30.65022935000013
      ],
      [
        104.12622477700012,
        30.650226107000094
      ],
      [
        104.12624527500009,
        30.65022286100003
      ],
      [
        104.1262657480001,
        30.650219614000036
      ],
      [
        104.12628619200018,
        30.650216363000027
      ],
      [
        104.12630660800005,
        30.650213097999995
      ],
      [
        104.12630722400013,
        30.650212991000103
      ],
      [
        104.12632776500016,
        30.650209997000047
      ],
      [
        104.1263361610001,
        30.65020883900013
      ],
      [
        104.12633851900011,
        30.650208669999984
      ],
      [
        104.12639976900005,
        30.650198100000182
      ],
      [
        104.12646569499998,
        30.650186725000026
      ],
      [
        104.12669502900013,
        30.65014719300018
      ],
      [
        104.12692436300011,
        30.650107660000174
      ],
      [
        104.1270957210001,
        30.650078119000113
      ],
      [
        104.12709738000012,
        30.650077825000096
      ],
      [
        104.12711787100017,
        30.650074344000075
      ],
      [
        104.12712813100012,
        30.650072691000048
      ],
      [
        104.12714861000012,
        30.650069332000044
      ],
      [
        104.12716908900012,
        30.650066036000112
      ],
      [
        104.1271895540001,
        30.650062744000024
      ],
      [
        104.12719243800018,
        30.650062280000157
      ],
      [
        104.12721290100018,
        30.650058921000152
      ],
      [
        104.12723336500011,
        30.65005551300004
      ],
      [
        104.12725382100018,
        30.650052055000117
      ],
      [
        104.12727428300013,
        30.65004855899997
      ],
      [
        104.12729473900004,
        30.65004503200015
      ],
      [
        104.12731519500011,
        30.650041480000027
      ],
      [
        104.12733564300004,
        30.650037899000154
      ],
      [
        104.12735608600002,
        30.650034296000115
      ],
      [
        104.12737652500005,
        30.650030659000095
      ],
      [
        104.12739696000006,
        30.650027006000073
      ],
      [
        104.12741739700004,
        30.65002332600011
      ],
      [
        104.12743782200005,
        30.650019622000173
      ],
      [
        104.12745823100005,
        30.650015882000048
      ],
      [
        104.12747865000006,
        30.650012139000182
      ],
      [
        104.12749905900006,
        30.6500083680001
      ],
      [
        104.12751946300017,
        30.650004571000068
      ],
      [
        104.12753986000013,
        30.650000751000107
      ],
      [
        104.12755007300018,
        30.64999888100016
      ],
      [
        104.12756025300013,
        30.649996910000084
      ],
      [
        104.12757046500013,
        30.64999502800009
      ],
      [
        104.12758064200017,
        30.64999304500003
      ],
      [
        104.12759084800012,
        30.64999115500001
      ],
      [
        104.12760101600009,
        30.64998915700005
      ],
      [
        104.12761122000018,
        30.64998726000016
      ],
      [
        104.12762138800014,
        30.649985242000184
      ],
      [
        104.12763159200011,
        30.649983329000122
      ],
      [
        104.12764175800004,
        30.64998131100009
      ],
      [
        104.12765195100008,
        30.649979380000048
      ],
      [
        104.1276668810001,
        30.64997644300007
      ],
      [
        104.12768722800007,
        30.649972437000088
      ],
      [
        104.12770756100008,
        30.649968410000042
      ],
      [
        104.12772790100018,
        30.649964366000063
      ],
      [
        104.1277482270001,
        30.649960304000047
      ],
      [
        104.12776856000005,
        30.649956239000176
      ],
      [
        104.12778888000014,
        30.649952160999987
      ],
      [
        104.12780920200004,
        30.649948091
      ],
      [
        104.12782953000016,
        30.649944015000017
      ],
      [
        104.12784985000002,
        30.649939942000174
      ],
      [
        104.12787017400012,
        30.64993587800018
      ],
      [
        104.12789051100015,
        30.649931821999985
      ],
      [
        104.1279108380001,
        30.649927772000012
      ],
      [
        104.12793117300009,
        30.64992374800005
      ],
      [
        104.12795151400013,
        30.6499197280001
      ],
      [
        104.12797185700009,
        30.649915734000103
      ],
      [
        104.12799220900018,
        30.64991177000013
      ],
      [
        104.12799496100007,
        30.6499112460001
      ],
      [
        104.12801532300017,
        30.649907302000088
      ],
      [
        104.12803569600015,
        30.64990339200017
      ],
      [
        104.12805607400003,
        30.649899516000175
      ],
      [
        104.12807646600015,
        30.649895669000102
      ],
      [
        104.12809686400004,
        30.64989185400009
      ],
      [
        104.128116799,
        30.649888174000125
      ],
      [
        104.12813722700008,
        30.64988444700009
      ],
      [
        104.12815767500018,
        30.64988076000003
      ],
      [
        104.12817813000004,
        30.64987710700018
      ],
      [
        104.12819859400014,
        30.649873476000153
      ],
      [
        104.12821906300013,
        30.649869857000112
      ],
      [
        104.12823953900005,
        30.64986626299998
      ],
      [
        104.12826001000008,
        30.649862677000044
      ],
      [
        104.12828048400013,
        30.64985910200005
      ],
      [
        104.12830096100015,
        30.649855541000193
      ],
      [
        104.12832144600014,
        30.649851980000165
      ],
      [
        104.12834191600007,
        30.649848433000102
      ],
      [
        104.1283623880002,
        30.649844886000096
      ],
      [
        104.12838284700018,
        30.649841355999968
      ],
      [
        104.12840330400019,
        30.64983783500014
      ],
      [
        104.12842374600012,
        30.649834317
      ],
      [
        104.12844416799999,
        30.649830817000066
      ],
      [
        104.12846457400008,
        30.649827334000065
      ],
      [
        104.1284849490001,
        30.64982387600014
      ],
      [
        104.1284861330002,
        30.649823685000058
      ],
      [
        104.12849636000004,
        30.649821899000074
      ],
      [
        104.12850671600006,
        30.64982083600006
      ],
      [
        104.12851693700009,
        30.649819045000186
      ],
      [
        104.12852716200013,
        30.649817244000133
      ],
      [
        104.12853738500019,
        30.649815441000044
      ],
      [
        104.12853750400012,
        30.649815441000044
      ],
      [
        104.12854772000003,
        30.649813625000093
      ],
      [
        104.1285579380002,
        30.649811802000045
      ],
      [
        104.12856815100008,
        30.64980996100013
      ],
      [
        104.12856836000014,
        30.649809926000046
      ],
      [
        104.12857338200007,
        30.649810662000107
      ],
      [
        104.12858359600017,
        30.649808812000117
      ],
      [
        104.12859380000015,
        30.649806956000134
      ],
      [
        104.1285994220001,
        30.64980599500018
      ],
      [
        104.12860563300012,
        30.64980479700006
      ],
      [
        104.12861166300013,
        30.649803697000152
      ],
      [
        104.12862442400018,
        30.649801371000137
      ],
      [
        104.12863462700005,
        30.649799518000066
      ],
      [
        104.12864484200009,
        30.649797673000023
      ],
      [
        104.12865506100013,
        30.649795852000125
      ],
      [
        104.12866527400018,
        30.64979404500008
      ],
      [
        104.12867550500005,
        30.649792256000183
      ],
      [
        104.12868573700018,
        30.649790503000133
      ],
      [
        104.12869598000009,
        30.64978878500017
      ],
      [
        104.12870623000003,
        30.64978710200012
      ],
      [
        104.12871649500005,
        30.64978546600014
      ],
      [
        104.12872645499999,
        30.649783925000122
      ],
      [
        104.12872762300009,
        30.649783748000175
      ],
      [
        104.12885412999998,
        30.649763523000047
      ],
      [
        104.1289721550001,
        30.64974586500017
      ],
      [
        104.12911787700006,
        30.649732458000017
      ],
      [
        104.12932181900004,
        30.649719627000138
      ],
      [
        104.12949137200013,
        30.649715305000143
      ],
      [
        104.12968860400014,
        30.64972313900006
      ],
      [
        104.1296990150002,
        30.64972369500009
      ],
      [
        104.12970942500016,
        30.64972429800008
      ],
      [
        104.12971982700014,
        30.649724980000144
      ],
      [
        104.12972334100004,
        30.649725230000172
      ],
      [
        104.12972640800007,
        30.64972545900008
      ],
      [
        104.12973377300005,
        30.64972593900012
      ],
      [
        104.12974411400012,
        30.649726872000087
      ],
      [
        104.12975784100007,
        30.64972743400017
      ],
      [
        104.12976820800014,
        30.64972842500015
      ],
      [
        104.12977857200013,
        30.64972946300003
      ],
      [
        104.12978893400009,
        30.649730540000064
      ],
      [
        104.12979929400007,
        30.64973165300006
      ],
      [
        104.12982429400017,
        30.64973384200016
      ],
      [
        104.12983464100006,
        30.64973501900016
      ],
      [
        104.1298449840001,
        30.649736226000186
      ],
      [
        104.12985531900017,
        30.64973746600009
      ],
      [
        104.12986564500011,
        30.649738751000086
      ],
      [
        104.12987153200015,
        30.649739508000096
      ],
      [
        104.12988184500006,
        30.649740869000084
      ],
      [
        104.12989215200008,
        30.649742276000154
      ],
      [
        104.13002472200003,
        30.649760058000027
      ],
      [
        104.13003501100007,
        30.649761528000113
      ],
      [
        104.13004530700005,
        30.64976302000008
      ],
      [
        104.13005558600014,
        30.649764532000006
      ],
      [
        104.13006587500018,
        30.649766055000043
      ],
      [
        104.13007615000004,
        30.64976759400008
      ],
      [
        104.1300864270001,
        30.649769145000164
      ],
      [
        104.13009670500003,
        30.649770708000176
      ],
      [
        104.13010697600009,
        30.64977228700002
      ],
      [
        104.13011724900014,
        30.649773878000076
      ],
      [
        104.13012750900009,
        30.649775487000113
      ],
      [
        104.1301377690001,
        30.649777119000078
      ],
      [
        104.13014802700002,
        30.649778775000073
      ],
      [
        104.13028474700013,
        30.649803306000024
      ],
      [
        104.13029130500018,
        30.649804410000115
      ],
      [
        104.13030153900013,
        30.649806164000097
      ],
      [
        104.13031176599998,
        30.64980795400004
      ],
      [
        104.13032197800015,
        30.649809766000033
      ],
      [
        104.13033219300019,
        30.64981159900009
      ],
      [
        104.13034240600007,
        30.649813451000057
      ],
      [
        104.1303526150001,
        30.64981530400013
      ],
      [
        104.13037303500016,
        30.649819017000027
      ],
      [
        104.1303830240002,
        30.649820826000052
      ],
      [
        104.13039323800018,
        30.649822663000123
      ],
      [
        104.13052741900015,
        30.64984626200004
      ],
      [
        104.13053763900012,
        30.64984808300011
      ],
      [
        104.13054785600019,
        30.64984989600015
      ],
      [
        104.13055808300004,
        30.64985171000012
      ],
      [
        104.13056830100015,
        30.649853511000174
      ],
      [
        104.13057852800006,
        30.649855317000174
      ],
      [
        104.13059897500005,
        30.649858913999992
      ],
      [
        104.13060919700018,
        30.649860707000073
      ],
      [
        104.13062964500011,
        30.649864287000185
      ],
      [
        104.13063987100003,
        30.6498660740001
      ],
      [
        104.13065010200012,
        30.64986785500014
      ],
      [
        104.13066032400008,
        30.649869630000182
      ],
      [
        104.1306705520002,
        30.649871399000062
      ],
      [
        104.13067480000018,
        30.649872133000144
      ],
      [
        104.13068502700008,
        30.649873892000187
      ],
      [
        104.13069525100019,
        30.64987564600017
      ],
      [
        104.13070547400008,
        30.64987738900004
      ],
      [
        104.13095734600012,
        30.64992160100013
      ],
      [
        104.13096756900018,
        30.649923340000043
      ],
      [
        104.13097779800006,
        30.64992508100005
      ],
      [
        104.13098802399998,
        30.64992681700005
      ],
      [
        104.13099825600011,
        30.649928548000105
      ],
      [
        104.1310289430001,
        30.6499337570001
      ],
      [
        104.13103917800015,
        30.649935493000044
      ],
      [
        104.1310494220001,
        30.649937234000106
      ],
      [
        104.13119390000014,
        30.64996522900003
      ],
      [
        104.131666838,
        30.65005772800015
      ],
      [
        104.1316710270001,
        30.650058546000025
      ],
      [
        104.131767712,
        30.65007262100005
      ],
      [
        104.13177817800016,
        30.650072181000155
      ],
      [
        104.13202930600016,
        30.650117350000073
      ],
      [
        104.13221269300016,
        30.65015033400016
      ],
      [
        104.13254410000013,
        30.650211366000065
      ],
      [
        104.13321876100008,
        30.650325416000044
      ],
      [
        104.133610681,
        30.65039188800006
      ],
      [
        104.13373819800017,
        30.65041443200016
      ],
      [
        104.1338342520001,
        30.650439066000047
      ],
      [
        104.13646381299998,
        30.651194720000035
      ],
      [
        104.13650026800008,
        30.651205194000056
      ],
      [
        104.13654496000004,
        30.651218037000092
      ],
      [
        104.13693044800016,
        30.65132880800013
      ],
      [
        104.13693885600014,
        30.65133122400016
      ],
      [
        104.136994287,
        30.651347151000152
      ],
      [
        104.13707582699999,
        30.651370916000076
      ],
      [
        104.13721271600008,
        30.651410812000165
      ],
      [
        104.13770367900008,
        30.651553900000124
      ],
      [
        104.13776336100005,
        30.65157129400012
      ],
      [
        104.13827238100009,
        30.651702610000143
      ],
      [
        104.138597,
        30.651786354000137
      ],
      [
        104.1387848190002,
        30.65182402000005
      ],
      [
        104.13904980900008,
        30.651852760000168
      ],
      [
        104.13937556600013,
        30.651868333000095
      ],
      [
        104.13974713700009,
        30.65188609200004
      ],
      [
        104.14011870700006,
        30.65190385200009
      ],
      [
        104.14043125000006,
        30.651918789000035
      ],
      [
        104.14043547400007,
        30.651918991000002
      ],
      [
        104.14074402300014,
        30.651930109000034
      ],
      [
        104.14043547400007,
        30.651918991000002
      ],
      [
        104.14074402300014,
        30.651930109000034
      ],
      [
        104.14154834200014,
        30.65195909100015
      ],
      [
        104.14227924100015,
        30.651985424000088
      ],
      [
        104.14235250800004,
        30.651991229000146
      ],
      [
        104.14336577700016,
        30.652071528000135
      ],
      [
        104.14355155500004,
        30.652086248000103
      ],
      [
        104.14426795600008,
        30.652173962000063
      ],
      [
        104.14437474000005,
        30.652187040000115
      ],
      [
        104.14437530600009,
        30.652187108000078
      ],
      [
        104.1463162020001,
        30.652600227000107
      ],
      [
        104.14756452400019,
        30.65247563300005
      ],
      [
        104.14778194708578,
        30.65241434994357
      ],
      [
        104.1478470180001,
        30.652396009000086
      ],
      [
        104.14793706600011,
        30.65237062800003
      ],
      [
        104.148432287589,
        30.65223103546424
      ],
      [
        104.15105486900006,
        30.65149178500019
      ],
      [
        104.15114737000016,
        30.651467325000112
      ],
      [
        104.1512355120002,
        30.65144248100006
      ],
      [
        104.15162415700007,
        30.651332929000034
      ],
      [
        104.1517842400001,
        30.65128947000005
      ],
      [
        104.15212216400016,
        30.65121839900013
      ],
      [
        104.15213073600017,
        30.651216782999995
      ],
      [
        104.15236535700012,
        30.651172521999968
      ],
      [
        104.15262206100005,
        30.651126695000187
      ],
      [
        104.15292041700008,
        30.65109348100009
      ],
      [
        104.15293228500013,
        30.651092160000076
      ],
      [
        104.15304913800014,
        30.651082936000023
      ],
      [
        104.15315855000011,
        30.651074298000026
      ],
      [
        104.15340177100006,
        30.65106799600005
      ],
      [
        104.15382804400008,
        30.651068190000046
      ],
      [
        104.15409039700006,
        30.651070530000084
      ],
      [
        104.15418655600007,
        30.65107138700006
      ],
      [
        104.15465852900013,
        30.65107559300003
      ],
      [
        104.1546818590001,
        30.65107580200015
      ],
      [
        104.15469961700018,
        30.651075960000185
      ],
      [
        104.15470818800009,
        30.65107616500012
      ],
      [
        104.15473192900004,
        30.651076732000035
      ],
      [
        104.1547412650001,
        30.651076956000054
      ],
      [
        104.15484134899998,
        30.651079344000095
      ],
      [
        104.15487310900016,
        30.651080105000062
      ],
      [
        104.15496085900014,
        30.65107822900012
      ],
      [
        104.15502586200006,
        30.651076839000154
      ],
      [
        104.15508036500006,
        30.65107627700013
      ],
      [
        104.1551503880001,
        30.651075555000034
      ],
      [
        104.15519185900007,
        30.65107512600008
      ],
      [
        104.15519643600015,
        30.651075078000076
      ],
      [
        104.15520912700003,
        30.651074011000105
      ],
      [
        104.15533693200007,
        30.651063261000104
      ],
      [
        104.15543700300009,
        30.651054844000043
      ],
      [
        104.1555370700001,
        30.65104642600005
      ],
      [
        104.15558402500017,
        30.651042475000168
      ],
      [
        104.15587848600012,
        30.65099475200003
      ],
      [
        104.1560854390001,
        30.650953085000026
      ],
      [
        104.15618006200003,
        30.65093403500009
      ],
      [
        104.15639090200006,
        30.65089158300009
      ],
      [
        104.15650618500007,
        30.650862962000133
      ],
      [
        104.1568597100001,
        30.650775184000167
      ],
      [
        104.15695802700014,
        30.65074609800007
      ],
      [
        104.1571200790001,
        30.650698158000182
      ],
      [
        104.1572460320001,
        30.650662163000106
      ],
      [
        104.15761361100004,
        30.650557114000037
      ],
      [
        104.1579296170001,
        30.650467052000067
      ],
      [
        104.15833243200012,
        30.650356002000137
      ],
      [
        104.15834236800004,
        30.65035326500015
      ],
      [
        104.15858860500009,
        30.650289504000114
      ],
      [
        104.1588348430002,
        30.65022574400018
      ],
      [
        104.15962306400013,
        30.650013939000132
      ],
      [
        104.15969737300014,
        30.649993970000025
      ],
      [
        104.16040993300015,
        30.64980429100001
      ],
      [
        104.1604118730001,
        30.64980377700016
      ],
      [
        104.1604919880001,
        30.649782683000183
      ],
      [
        104.16057210200017,
        30.64976158800016
      ],
      [
        104.16094420000019,
        30.649663606000047
      ],
      [
        104.16140166000002,
        30.64954552500012
      ],
      [
        104.16186128500016,
        30.649426286000107
      ],
      [
        104.16240347500008,
        30.64928960900005
      ],
      [
        104.16255785100003,
        30.649248501000045
      ],
      [
        104.16292956900008,
        30.649149517000012
      ],
      [
        104.16362064900005,
        30.64897101500003
      ],
      [
        104.16413612200017,
        30.648836149000033
      ],
      [
        104.16423882200013,
        30.648805121000123
      ],
      [
        104.16435754200018,
        30.648769253000182
      ],
      [
        104.16443257800017,
        30.648746585000083
      ],
      [
        104.16453639500014,
        30.648715222000078
      ],
      [
        104.16455995900014,
        30.64870810200017
      ],
      [
        104.16485371700014,
        30.648639890000027
      ],
      [
        104.16512969700005,
        30.64857421400012
      ],
      [
        104.16513580800012,
        30.6485726250001
      ],
      [
        104.16514292500005,
        30.648570601000074
      ],
      [
        104.16515283299998,
        30.648567771000046
      ],
      [
        104.16516274100013,
        30.64856494100013
      ],
      [
        104.16517264500004,
        30.648562109000125
      ],
      [
        104.16518255200003,
        30.648559278000107
      ],
      [
        104.16519245900008,
        30.64855645299997
      ],
      [
        104.16521235800002,
        30.64855103700006
      ],
      [
        104.16522235100007,
        30.648548450000135
      ],
      [
        104.16523234300018,
        30.64854586299998
      ],
      [
        104.16524233700011,
        30.648543281000116
      ],
      [
        104.16526224700016,
        30.64853789500006
      ],
      [
        104.16546506200018,
        30.648493015000042
      ],
      [
        104.1654805070001,
        30.64848959700015
      ],
      [
        104.16548405100002,
        30.648488649000058
      ],
      [
        104.16549402300018,
        30.64848597600013
      ],
      [
        104.1655063450001,
        30.648481980000156
      ],
      [
        104.16551630800012,
        30.648479279000185
      ],
      [
        104.1655262700001,
        30.648476573000153
      ],
      [
        104.16553622999999,
        30.648473862000174
      ],
      [
        104.16555587500011,
        30.648467838000045
      ],
      [
        104.16556583900007,
        30.648465137000073
      ],
      [
        104.1655758030002,
        30.648462449000192
      ],
      [
        104.16558578000019,
        30.648459775000163
      ],
      [
        104.1655961350001,
        30.648457780000058
      ],
      [
        104.16560611200009,
        30.648455128000023
      ],
      [
        104.16560868200014,
        30.64845466800017
      ],
      [
        104.16561893200009,
        30.64845271900009
      ],
      [
        104.16562891600006,
        30.648450098000183
      ],
      [
        104.16563904400016,
        30.648448045000066
      ],
      [
        104.16564904500018,
        30.648445456000104
      ],
      [
        104.16565912200008,
        30.64844335700002
      ],
      [
        104.16566312300012,
        30.64844251700015
      ],
      [
        104.16567313100006,
        30.64843997100013
      ],
      [
        104.16568323500019,
        30.648437859000126
      ],
      [
        104.16569325900014,
        30.648435361999987
      ],
      [
        104.16571342500004,
        30.64843081000015
      ],
      [
        104.16572065999998,
        30.64842920300009
      ],
      [
        104.16573362200018,
        30.64842632000017
      ],
      [
        104.1657538560001,
        30.64842192800012
      ],
      [
        104.16577412800018,
        30.648417637000023
      ],
      [
        104.16579296800012,
        30.6484137650001
      ],
      [
        104.1658133000002,
        30.64840972100012
      ],
      [
        104.16583022200018,
        30.648406494000085
      ],
      [
        104.16585061500018,
        30.64840269400014
      ],
      [
        104.16587103799998,
        30.64839899700013
      ],
      [
        104.1658914750002,
        30.64839538100017
      ],
      [
        104.16591193599999,
        30.648391839000055
      ],
      [
        104.16593240800012,
        30.64838835200004
      ],
      [
        104.1659528900002,
        30.648384919000193
      ],
      [
        104.16597338200012,
        30.648381525000104
      ],
      [
        104.1659938890001,
        30.648378170000058
      ],
      [
        104.16600419399998,
        30.648376703000054
      ],
      [
        104.1660247010002,
        30.64837338900014
      ],
      [
        104.1660452210001,
        30.648370103000047
      ],
      [
        104.1660657450002,
        30.648366832000193
      ],
      [
        104.16608627200003,
        30.648363590000088
      ],
      [
        104.16610679800016,
        30.648360380000042
      ],
      [
        104.16612733100015,
        30.64835719900003
      ],
      [
        104.1661478760002,
        30.648354074000054
      ],
      [
        104.16615507300014,
        30.648353031000056
      ],
      [
        104.16628735300009,
        30.64833860000016
      ],
      [
        104.1662976020001,
        30.64833694100014
      ],
      [
        104.16630786500019,
        30.648335370000098
      ],
      [
        104.16631506100003,
        30.6483343270001
      ],
      [
        104.16632535600013,
        30.64833291300016
      ],
      [
        104.16633566600007,
        30.648331562000124
      ],
      [
        104.16634599100013,
        30.648330264000037
      ],
      [
        104.16635631500009,
        30.648329006000097
      ],
      [
        104.16636665400017,
        30.648327776000144
      ],
      [
        104.16637698900001,
        30.648326569000176
      ],
      [
        104.16638733700017,
        30.648325386000124
      ],
      [
        104.16639767800007,
        30.648324210000055
      ],
      [
        104.166408026,
        30.648323044000108
      ],
      [
        104.16641838100008,
        30.6483218890001
      ],
      [
        104.16642873000012,
        30.64832074100019
      ],
      [
        104.16643907900004,
        30.64831959500009
      ],
      [
        104.16644943500017,
        30.648318458999995
      ],
      [
        104.16645978700012,
        30.64831732300007
      ],
      [
        104.16648051800018,
        30.64831522300011
      ],
      [
        104.16650124400007,
        30.648313127000108
      ],
      [
        104.16656343900019,
        30.64830690200006
      ],
      [
        104.16658417800005,
        30.648304860000053
      ],
      [
        104.16660491400017,
        30.648302841000145
      ],
      [
        104.16662565299998,
        30.648300850000055
      ],
      [
        104.16664639800001,
        30.648298904000058
      ],
      [
        104.16666714600012,
        30.648297005000188
      ],
      [
        104.1666879030002,
        30.648295177000023
      ],
      [
        104.16669828300013,
        30.64829429000008
      ],
      [
        104.1667190550001,
        30.648292575000028
      ],
      [
        104.16672633600007,
        30.648292018000063
      ],
      [
        104.16673672100012,
        30.64829118700004
      ],
      [
        104.16674711500008,
        30.648290460999988
      ],
      [
        104.1667575030001,
        30.648289667000142
      ],
      [
        104.1667782950002,
        30.64828824900019
      ],
      [
        104.16679910000005,
        30.648286930000154
      ],
      [
        104.16681990400019,
        30.648285714000053
      ],
      [
        104.16682063200005,
        30.64828567400008
      ],
      [
        104.16684145800019,
        30.648284553000053
      ],
      [
        104.16686227700006,
        30.64828352700016
      ],
      [
        104.16688311600007,
        30.64828258000017
      ],
      [
        104.16690395,
        30.648281691000193
      ],
      [
        104.16692479200015,
        30.648280853000074
      ],
      [
        104.16694563300018,
        30.648280061000037
      ],
      [
        104.16696648200002,
        30.648279310000078
      ],
      [
        104.16698733599998,
        30.64827859800016
      ],
      [
        104.16700818600015,
        30.648277916000097
      ],
      [
        104.16702904200008,
        30.64827727500017
      ],
      [
        104.167049894,
        30.648276674000044
      ],
      [
        104.16707075800014,
        30.64827612200014
      ],
      [
        104.16709161900019,
        30.648275632000093
      ],
      [
        104.16711248600006,
        30.648275207000154
      ],
      [
        104.16713335500009,
        30.648274865000133
      ],
      [
        104.16714489500015,
        30.648274746000027
      ],
      [
        104.16716576800019,
        30.648274543000127
      ],
      [
        104.16718664600012,
        30.648274442000172
      ],
      [
        104.16720321200012,
        30.648274430000185
      ],
      [
        104.16722408800007,
        30.64827447800019
      ],
      [
        104.1672449730001,
        30.648274590000028
      ],
      [
        104.16725540600015,
        30.648274655000137
      ],
      [
        104.16726585500004,
        30.648274753000123
      ],
      [
        104.16727629299999,
        30.64827483500011
      ],
      [
        104.16729717900012,
        30.64827506000006
      ],
      [
        104.16731805100011,
        30.648275327000192
      ],
      [
        104.16733893200012,
        30.64827562300019
      ],
      [
        104.16735980400006,
        30.64827595000014
      ],
      [
        104.16738066800019,
        30.648276313000054
      ],
      [
        104.16740152300008,
        30.648276715999998
      ],
      [
        104.1674223600001,
        30.648277165000138
      ],
      [
        104.16744318800005,
        30.6482776740001
      ],
      [
        104.16746399800019,
        30.648278249000043
      ],
      [
        104.16748377800002,
        30.648278860000005
      ],
      [
        104.16750454700008,
        30.648279572000092
      ],
      [
        104.1675253200001,
        30.648280312000168
      ],
      [
        104.16754610400005,
        30.648281065000106
      ],
      [
        104.1675669230001,
        30.648281788000077
      ],
      [
        104.1675877940001,
        30.648282460000132
      ],
      [
        104.16760873700014,
        30.648283058000175
      ],
      [
        104.16761936900014,
        30.64828312000003
      ],
      [
        104.16763752100007,
        30.648283460000073
      ],
      [
        104.16765860800012,
        30.648284024000077
      ],
      [
        104.1676690220001,
        30.64828456500004
      ],
      [
        104.16767433000018,
        30.64828487799997
      ],
      [
        104.16769513600013,
        30.648286374000122
      ],
      [
        104.1677055240001,
        30.648287142000186
      ],
      [
        104.16771591100013,
        30.648287971000173
      ],
      [
        104.16793332200012,
        30.648308250000127
      ],
      [
        104.16838594000006,
        30.648350468000103
      ],
      [
        104.16839629800006,
        30.64835134600014
      ],
      [
        104.1684066630001,
        30.648352211000145
      ],
      [
        104.16841528100008,
        30.648352915000032
      ],
      [
        104.16842566300005,
        30.648353747999977
      ],
      [
        104.16843604400015,
        30.648354570000095
      ],
      [
        104.16844643000013,
        30.64835538800014
      ],
      [
        104.16845681400008,
        30.648356195000133
      ],
      [
        104.16846720700016,
        30.648357001000193
      ],
      [
        104.16847759800004,
        30.64835780100009
      ],
      [
        104.1684879930001,
        30.648358594000058
      ],
      [
        104.16869223700013,
        30.64837771700013
      ],
      [
        104.16870263100009,
        30.64837851200008
      ],
      [
        104.16871302900006,
        30.648379309000063
      ],
      [
        104.16872342400012,
        30.648380103000136
      ],
      [
        104.16873381300019,
        30.648380893000024
      ],
      [
        104.16874421300014,
        30.64838169000018
      ],
      [
        104.1687546080002,
        30.648382479000134
      ],
      [
        104.1687649980002,
        30.64838327200016
      ],
      [
        104.16877539500007,
        30.648384066000006
      ],
      [
        104.168785791,
        30.648384862000114
      ],
      [
        104.16879618299998,
        30.64838566200018
      ],
      [
        104.1688035630001,
        30.64838622800005
      ],
      [
        104.1688416400001,
        30.648389339000175
      ],
      [
        104.1688520300001,
        30.648390146000168
      ],
      [
        104.16886242200007,
        30.648390959000153
      ],
      [
        104.16887281200002,
        30.648391792000155
      ],
      [
        104.1688831990001,
        30.648392632000025
      ],
      [
        104.16889358600014,
        30.648393490000103
      ],
      [
        104.16890396700006,
        30.648394377000102
      ],
      [
        104.16891434400009,
        30.64839530000006
      ],
      [
        104.16892471300014,
        30.64839626500003
      ],
      [
        104.16893507900011,
        30.64839729600004
      ],
      [
        104.16894542900008,
        30.648398404000147
      ],
      [
        104.16894931600012,
        30.648398853000117
      ],
      [
        104.1689596450002,
        30.648400100000117
      ],
      [
        104.16934233500007,
        30.648445290000097
      ],
      [
        104.16977024000005,
        30.648495818000015
      ],
      [
        104.1708658570002,
        30.648595542000123
      ],
      [
        104.17178094300004,
        30.64867882699997
      ],
      [
        104.17196145800017,
        30.648695407000105
      ],
      [
        104.17302073700012,
        30.648793060000116
      ],
      [
        104.17311099200003,
        30.648801380000066
      ],
      [
        104.1736698430002,
        30.648852895000175
      ],
      [
        104.17383606400017,
        30.648868216000096
      ],
      [
        104.17399880600016,
        30.648883216000115
      ],
      [
        104.17426052400015,
        30.64890733900006
      ],
      [
        104.17441576900012,
        30.648922234000167
      ],
      [
        104.17457101100013,
        30.648937126000135
      ],
      [
        104.17458398300016,
        30.648938371000156
      ],
      [
        104.17519828600007,
        30.64899730700006
      ],
      [
        104.17517564200017,
        30.649200086000064
      ],
      [
        104.17510354300015,
        30.649925597000106
      ],
      [
        104.17507970500014,
        30.650351344000057
      ],
      [
        104.17506446000004,
        30.650551810000138
      ],
      [
        104.17492999000012,
        30.65076764700018
      ],
      [
        104.17482929400012,
        30.650915377000103
      ],
      [
        104.17492028700008,
        30.65097824900016
      ],
      [
        104.17492056700013,
        30.65097844200011
      ],
      [
        104.17492053200004,
        30.65105136699998
      ],
      [
        104.1749201040002,
        30.651913576000027
      ],
      [
        104.1749128690002,
        30.652135638999994
      ],
      [
        104.17491272000007,
        30.65243971800004
      ],
      [
        104.17491258600006,
        30.652709674000164
      ],
      [
        104.17491088100019,
        30.65289025400017
      ],
      [
        104.17490865000013,
        30.65312613400016
      ],
      [
        104.1749001190002,
        30.65390098900008
      ],
      [
        104.17489966300019,
        30.654058677000137
      ],
      [
        104.17489751200009,
        30.65480203800007
      ],
      [
        104.17489098300018,
        30.65651763200009
      ],
      [
        104.17488976400017,
        30.656684557000176
      ],
      [
        104.17488665000008,
        30.65711075600018
      ],
      [
        104.17488415878927,
        30.65745166428843
      ],
      [
        104.17488665000008,
        30.65711075600018
      ],
      [
        104.17488037700019,
        30.657969181000055
      ],
      [
        104.17487442400011,
        30.658409889000097
      ],
      [
        104.1748610840001,
        30.659397099000046
      ],
      [
        104.17486102600009,
        30.659419391000142
      ],
      [
        104.17486096400006,
        30.659443707000037
      ],
      [
        104.17486090000006,
        30.65946802200017
      ],
      [
        104.1748592690002,
        30.66008719300015
      ],
      [
        104.17485763700006,
        30.66070636300003
      ],
      [
        104.17485722600014,
        30.660862896000083
      ],
      [
        104.17485435200007,
        30.661955365000154
      ],
      [
        104.17485272300019,
        30.662574536000136
      ],
      [
        104.17485181200004,
        30.662920318000147
      ],
      [
        104.1748257050001,
        30.663730853000175
      ],
      [
        104.1748386980002,
        30.66440873500011
      ],
      [
        104.17482790500009,
        30.664442663000102
      ],
      [
        104.17478935500009,
        30.66447772900017
      ],
      [
        104.17477214100012,
        30.664507015000083
      ],
      [
        104.17477046800002,
        30.664510926000162
      ],
      [
        104.17476297200011,
        30.66452842399997
      ],
      [
        104.17475677800013,
        30.664546393000137
      ],
      [
        104.17473113800014,
        30.66462077000017
      ],
      [
        104.17470553100009,
        30.664655246000166
      ],
      [
        104.17469342800007,
        30.664659454000173
      ],
      [
        104.17472658000014,
        30.66477529100007
      ],
      [
        104.17474477000007,
        30.66483885000008
      ],
      [
        104.17474907100012,
        30.66488222400011
      ],
      [
        104.17474918700015,
        30.664992506000033
      ],
      [
        104.17474669500012,
        30.66532101900009
      ],
      [
        104.1747432620001,
        30.665773287000036
      ],
      [
        104.17474051400006,
        30.66598231200004
      ],
      [
        104.17473725800016,
        30.66614603800008
      ],
      [
        104.17473344200016,
        30.666383236000172
      ],
      [
        104.1747330840476,
        30.666620033366257
      ],
      [
        104.17473218300017,
        30.667216106000126
      ],
      [
        104.17472849600011,
        30.667520558000092
      ],
      [
        104.17472557700017,
        30.667761581000093
      ],
      [
        104.17472401200013,
        30.667980253000053
      ],
      [
        104.17472279100008,
        30.668150813000125
      ],
      [
        104.17472247800015,
        30.668173179000007
      ],
      [
        104.17471595500007,
        30.668643451000037
      ],
      [
        104.1747130120001,
        30.668855738000104
      ],
      [
        104.1747192420001,
        30.668897401000095
      ],
      [
        104.17474410900007,
        30.668958705000193
      ],
      [
        104.17480012800019,
        30.66908801000011
      ],
      [
        104.17508868099998,
        30.669684530000097
      ],
      [
        104.17523389500013,
        30.669980809000037
      ],
      [
        104.17531386600007,
        30.670135129000187
      ],
      [
        104.17531770000005,
        30.67014252900009
      ],
      [
        104.17539207300007,
        30.670275326000137
      ],
      [
        104.17539598000013,
        30.670281584000122
      ],
      [
        104.17540044500004,
        30.670284468000034
      ],
      [
        104.17540545999998,
        30.670284946000038
      ],
      [
        104.17539061700012,
        30.670343049000167
      ],
      [
        104.17538504200007,
        30.670424065000077
      ],
      [
        104.17537944700007,
        30.670486925000148
      ],
      [
        104.17535426200004,
        30.67065432800007
      ],
      [
        104.17530462000019,
        30.670984321000105
      ],
      [
        104.17530144400013,
        30.671009408000145
      ],
      [
        104.1753044030001,
        30.67102352700016
      ],
      [
        104.17530811000012,
        30.671030409000025
      ],
      [
        104.17532612500008,
        30.671063839000112
      ],
      [
        104.1753411570001,
        30.67108970000004
      ],
      [
        104.17535695500015,
        30.671116881000046
      ],
      [
        104.17537498800016,
        30.671141688000034
      ],
      [
        104.17538477000005,
        30.671155148000082
      ],
      [
        104.17526113300005,
        30.671212688000082
      ],
      [
        104.17480471900012,
        30.671430335000025
      ],
      [
        104.17461220100017,
        30.671520175000182
      ],
      [
        104.1745615750001,
        30.671546680000176
      ],
      [
        104.17453065000012,
        30.671564033000095
      ],
      [
        104.1744992730001,
        30.671587473999978
      ],
      [
        104.17448386600012,
        30.671606731000054
      ],
      [
        104.1744370900002,
        30.671676433000187
      ],
      [
        104.17428252800016,
        30.671940053000128
      ],
      [
        104.17401201100006,
        30.67240958200017
      ],
      [
        104.17386775100005,
        30.672652706000008
      ],
      [
        104.17360283000005,
        30.67310451600008
      ],
      [
        104.17328616800017,
        30.673642909000137
      ],
      [
        104.17314233400015,
        30.673909744000127
      ],
      [
        104.17310716600008,
        30.673954949000063
      ],
      [
        104.17308508200006,
        30.673978906000173
      ],
      [
        104.1730690220001,
        30.673993145000168
      ],
      [
        104.17306743500006,
        30.67399397800017
      ],
      [
        104.1730557620001,
        30.674000095000054
      ],
      [
        104.17303104700011,
        30.674002533000078
      ],
      [
        104.17299524200007,
        30.67400299799999
      ],
      [
        104.17296091600008,
        30.67400344200007
      ],
      [
        104.17287180800014,
        30.67400786900015
      ],
      [
        104.17284567500002,
        30.67401022000007
      ],
      [
        104.17282040600014,
        30.674012492000088
      ],
      [
        104.1727961580001,
        30.674093354000036
      ],
      [
        104.17276575800008,
        30.674194730000067
      ],
      [
        104.17276238500006,
        30.674205977000042
      ],
      [
        104.17275107500001,
        30.67422638500011
      ],
      [
        104.1726961170001,
        30.67432556100016
      ],
      [
        104.17267623900005,
        30.6743614290001
      ],
      [
        104.17267533800009,
        30.674363037000035
      ],
      [
        104.17262913000013,
        30.674445514000013
      ],
      [
        104.17261802400014,
        30.674462485000106
      ],
      [
        104.17259913400017,
        30.674480741000025
      ],
      [
        104.1725843110001,
        30.674489075000167
      ],
      [
        104.17256800100017,
        30.674493246000054
      ],
      [
        104.17253797100017,
        30.674494232000143
      ],
      [
        104.17246321800008,
        30.67448661600008
      ],
      [
        104.17227080800018,
        30.674458732000062
      ],
      [
        104.17223499300013,
        30.67445660300018
      ],
      [
        104.1721914210001,
        30.674459056000103
      ],
      [
        104.172145527,
        30.674464645000114
      ],
      [
        104.17211396000016,
        30.674469464000083
      ],
      [
        104.17209350400009,
        30.674475789000155
      ],
      [
        104.17207655400006,
        30.674482111000145
      ],
      [
        104.17206457600014,
        30.674489438000137
      ],
      [
        104.17205318500004,
        30.674499792000063
      ],
      [
        104.17204238099998,
        30.674513679000086
      ],
      [
        104.17202632900012,
        30.674545840000178
      ],
      [
        104.1720145480001,
        30.67458268900009
      ],
      [
        104.17199126200012,
        30.674688748999984
      ],
      [
        104.17197840600011,
        30.674734272000137
      ],
      [
        104.17197141600008,
        30.674752418000026
      ],
      [
        104.17193710200007,
        30.67482002700001
      ],
      [
        104.1719147780002,
        30.674865271000044
      ],
      [
        104.17185071900013,
        30.674952074000032
      ],
      [
        104.17168701300011,
        30.675183633000074
      ],
      [
        104.17154729600014,
        30.675384362000102
      ],
      [
        104.17148593600007,
        30.675481676000175
      ],
      [
        104.17132645999999,
        30.675736076000078
      ],
      [
        104.17124840100001,
        30.67584202300003
      ],
      [
        104.1711919500001,
        30.675913180000123
      ],
      [
        104.17115966800009,
        30.675945902000024
      ],
      [
        104.17110438200012,
        30.675993892000065
      ],
      [
        104.171034636,
        30.67603547300007
      ],
      [
        104.17099264000012,
        30.676050711000073
      ],
      [
        104.17094251200007,
        30.676061279000066
      ],
      [
        104.17089508900011,
        30.676066581000157
      ],
      [
        104.17084833800004,
        30.676069540000128
      ],
      [
        104.170571881,
        30.676082688000122
      ],
      [
        104.17031600900015,
        30.676084275000164
      ],
      [
        104.1701974610001,
        30.676084828000114
      ],
      [
        104.17016259200017,
        30.676082541000028
      ],
      [
        104.16998393200015,
        30.676065902000175
      ],
      [
        104.16994282600012,
        30.676064368000027
      ],
      [
        104.16983705000007,
        30.676064970000084
      ],
      [
        104.16979473800012,
        30.67606761200011
      ],
      [
        104.16964902000012,
        30.676079580000078
      ],
      [
        104.16961087100009,
        30.67608453500003
      ],
      [
        104.16938290500008,
        30.676112117000173
      ],
      [
        104.16899615700004,
        30.676160441000093
      ],
      [
        104.16878275600004,
        30.676189740000098
      ],
      [
        104.16859254000013,
        30.67621170200016
      ],
      [
        104.16851003700015,
        30.676226010999983
      ],
      [
        104.16849275200019,
        30.676230773000043
      ],
      [
        104.16846800600013,
        30.676241306000122
      ],
      [
        104.16844876400012,
        30.676252853999983
      ],
      [
        104.16843698100013,
        30.67626236100017
      ],
      [
        104.16838780200004,
        30.676319561000128
      ],
      [
        104.16829239700007,
        30.676430858000174
      ],
      [
        104.16827524100006,
        30.676450876000047
      ],
      [
        104.16803680300006,
        30.676759321000077
      ],
      [
        104.16782848500003,
        30.677019799000163
      ],
      [
        104.16768391800008,
        30.67718386600012
      ],
      [
        104.16742653099999,
        30.67748033200013
      ],
      [
        104.16737235300013,
        30.677547618000006
      ],
      [
        104.16729422900005,
        30.677657212000042
      ],
      [
        104.1670979230002,
        30.67794008900006
      ],
      [
        104.1670501120002,
        30.678012899000066
      ],
      [
        104.16692601900019,
        30.678201865000062
      ],
      [
        104.1668886010001,
        30.678258733000177
      ],
      [
        104.16687758200015,
        30.678278998000167
      ],
      [
        104.16686127200006,
        30.678308997000045
      ],
      [
        104.16622095500009,
        30.678418005000026
      ],
      [
        104.16543469800013,
        30.678544816000112
      ],
      [
        104.16542036200019,
        30.67857312400014
      ],
      [
        104.16541164400019,
        30.678590336000127
      ],
      [
        104.16539503200005,
        30.67861966600009
      ],
      [
        104.16537850500004,
        30.67864884200003
      ],
      [
        104.16515889900012,
        30.67901205000004
      ],
      [
        104.1649792280001,
        30.679316692000043
      ],
      [
        104.1647266400002,
        30.679733438000028
      ],
      [
        104.16454700900016,
        30.680022410000163
      ],
      [
        104.1643264990002,
        30.680389185000024
      ],
      [
        104.16414725300012,
        30.680725373000143
      ],
      [
        104.16402807500003,
        30.680967158000158
      ],
      [
        104.16399436000017,
        30.681033709000076
      ],
      [
        104.16396111100016,
        30.68109971400014
      ],
      [
        104.16388578000004,
        30.681249253000146
      ],
      [
        104.16374540400017,
        30.681549635000124
      ],
      [
        104.16360789200013,
        30.68184018300019
      ],
      [
        104.16351159800018,
        30.682090917000153
      ],
      [
        104.1634354900001,
        30.682279394000034
      ],
      [
        104.16341868500007,
        30.682321006999985
      ],
      [
        104.16341836500004,
        30.68232220100009
      ],
      [
        104.16340947015357,
        30.6823551947964
      ],
      [
        104.16338847000014,
        30.682433091000064
      ],
      [
        104.16327523400014,
        30.682769003000146
      ],
      [
        104.16324869200008,
        30.682895446000032
      ],
      [
        104.16322778900008,
        30.683126569000024
      ],
      [
        104.16318887700004,
        30.683667416000105
      ],
      [
        104.16318269900006,
        30.68375328200011
      ],
      [
        104.16318159700012,
        30.683773409000082
      ],
      [
        104.1631745000002,
        30.68390282400003
      ],
      [
        104.1631562020001,
        30.68423658600011
      ],
      [
        104.16312774700015,
        30.68467907900009
      ],
      [
        104.16312096700011,
        30.684784536000166
      ],
      [
        104.16311937700016,
        30.684809240999982
      ],
      [
        104.16310898100016,
        30.68490817200012
      ],
      [
        104.16395409400019,
        30.68494770500007
      ],
      [
        104.16433387900008,
        30.684967455000105
      ],
      [
        104.16469367000013,
        30.68498616400018
      ],
      [
        104.16483698700017,
        30.68500219700013
      ],
      [
        104.164872914,
        30.685006215000158
      ],
      [
        104.1648966090001,
        30.685008790000097
      ],
      [
        104.16506031400007,
        30.68502657200014
      ],
      [
        104.16516467800017,
        30.685029706000023
      ],
      [
        104.16625795100015,
        30.685062542000082
      ],
      [
        104.16639921100011,
        30.68506678400007
      ],
      [
        104.16642230900004,
        30.685067881000066
      ],
      [
        104.16661824800019,
        30.685077188000037
      ],
      [
        104.1668663870002,
        30.685088975000042
      ],
      [
        104.16705112200015,
        30.685097750000182
      ],
      [
        104.1681746970001,
        30.685151116000156
      ],
      [
        104.16834165300003,
        30.685158424000008
      ],
      [
        104.16903679500018,
        30.68518885600008
      ],
      [
        104.1692330090001,
        30.685197446000075
      ],
      [
        104.1692944560001,
        30.685200135000173
      ],
      [
        104.16932077500007,
        30.68520128800003
      ],
      [
        104.1693877040002,
        30.685206200000096
      ],
      [
        104.16971604500009,
        30.685230303000026
      ],
      [
        104.17041232500014,
        30.685281408000094
      ],
      [
        104.17041335600015,
        30.685281471000167
      ],
      [
        104.1704904730002,
        30.685286227000006
      ],
      [
        104.17084094200015,
        30.685306856000068
      ],
      [
        104.17114320500002,
        30.685321304000183
      ],
      [
        104.17156372100004,
        30.685335722000104
      ],
      [
        104.17206475900008,
        30.6853514230001
      ],
      [
        104.17236542400019,
        30.68536084500016
      ],
      [
        104.17343449300006,
        30.685408141000153
      ],
      [
        104.17371715300004,
        30.685422351000113
      ],
      [
        104.17443087200019,
        30.685458227000026
      ],
      [
        104.1751842660002,
        30.685496093000154
      ],
      [
        104.17560132100016,
        30.685514366000177
      ],
      [
        104.17576114700006,
        30.685522018000086
      ],
      [
        104.1762170680002,
        30.685543846000144
      ],
      [
        104.17750709900008,
        30.685610968000162
      ],
      [
        104.17769013800006,
        30.68561973200019
      ],
      [
        104.1777475290001,
        30.685622480000063
      ],
      [
        104.17793499500016,
        30.68563145500019
      ],
      [
        104.17799958200004,
        30.685634548000166
      ],
      [
        104.1780881200001,
        30.685639224
      ],
      [
        104.17821456500019,
        30.685645904000012
      ],
      [
        104.17823974000004,
        30.68564723200012
      ],
      [
        104.17852965200007,
        30.685659000000044
      ],
      [
        104.17868662800004,
        30.685671068000147
      ],
      [
        104.1787771860001,
        30.68567629800009
      ],
      [
        104.17887020200016,
        30.685687824000127
      ],
      [
        104.17899505600019,
        30.68570589100011
      ],
      [
        104.17911960200018,
        30.68573318300008
      ],
      [
        104.17913977600006,
        30.685737604000167
      ],
      [
        104.17928702300009,
        30.685781865000024
      ],
      [
        104.17943247200014,
        30.685834389000092
      ],
      [
        104.1795536420002,
        30.685890223000115
      ],
      [
        104.17964234200008,
        30.685935567000115
      ],
      [
        104.1797314530001,
        30.68598294800006
      ],
      [
        104.17991150200015,
        30.68610138400004
      ],
      [
        104.18005969300009,
        30.68621846400015
      ],
      [
        104.18014667200009,
        30.68628718200017
      ],
      [
        104.18040437100018,
        30.686487852000084
      ],
      [
        104.18063455800012,
        30.68666414500018
      ],
      [
        104.18090719600013,
        30.686875513000075
      ],
      [
        104.18115912600018,
        30.687072830000147
      ],
      [
        104.18127981300012,
        30.687167354000167
      ],
      [
        104.18138317500018,
        30.687248731000068
      ],
      [
        104.18143177000019,
        30.687286990000132
      ],
      [
        104.18149668400002,
        30.687336553000193
      ],
      [
        104.18183832500017,
        30.68760387800006
      ],
      [
        104.18187400600016,
        30.687631549000116
      ],
      [
        104.18211087600008,
        30.687815263000005
      ],
      [
        104.18225683300017,
        30.687928853000187
      ],
      [
        104.1823552090001,
        30.68800541400003
      ],
      [
        104.18242537000015,
        30.688059553000187
      ],
      [
        104.18265572199999,
        30.688235977000033
      ],
      [
        104.18289409700009,
        30.688420096000073
      ],
      [
        104.18294612700015,
        30.68846054200003
      ],
      [
        104.1832253500001,
        30.68867759900013
      ],
      [
        104.18355133700015,
        30.688929860000144
      ],
      [
        104.18358474700005,
        30.688956043000076
      ],
      [
        104.18363213000015,
        30.688995396000053
      ],
      [
        104.18365530200015,
        30.689014644000054
      ],
      [
        104.18371361500004,
        30.68906146200004
      ],
      [
        104.18382550800015,
        30.689166042000124
      ],
      [
        104.18388588600004,
        30.689227639000023
      ],
      [
        104.18394613800001,
        30.6892918100001
      ],
      [
        104.18400692000006,
        30.68937032100007
      ],
      [
        104.18406036000005,
        30.689446838000094
      ],
      [
        104.18410596300004,
        30.689520596000193
      ],
      [
        104.18413668000017,
        30.689574028000152
      ],
      [
        104.18417771300011,
        30.689655621000043
      ],
      [
        104.18423967700005,
        30.689806696000062
      ],
      [
        104.18425014900004,
        30.689830812000082
      ],
      [
        104.1842680090001,
        30.689885446000176
      ],
      [
        104.18429308200012,
        30.689970133000145
      ],
      [
        104.18430818600012,
        30.690045006000048
      ],
      [
        104.18432263000011,
        30.690126056000167
      ],
      [
        104.18432983900004,
        30.690210185000126
      ],
      [
        104.18434170500018,
        30.69039760400011
      ],
      [
        104.18435244600005,
        30.69054478800018
      ],
      [
        104.18436664799998,
        30.69073939800012
      ],
      [
        104.1844244830001,
        30.69168708100011
      ],
      [
        104.18443478800015,
        30.69185595500005
      ],
      [
        104.18443839100019,
        30.69191498900011
      ],
      [
        104.18444150300007,
        30.691965973000094
      ],
      [
        104.18444539100005,
        30.69202969200012
      ],
      [
        104.18445139400006,
        30.692128052999976
      ],
      [
        104.18445843600011,
        30.69224539200019
      ],
      [
        104.1844807010001,
        30.692616341000075
      ],
      [
        104.18451550500015,
        30.693196189000105
      ],
      [
        104.1845675630002,
        30.694074489000116
      ],
      [
        104.18458299300005,
        30.694360304000043
      ],
      [
        104.18458642300016,
        30.694408428000145
      ],
      [
        104.18461082200014,
        30.69475082400004
      ],
      [
        104.18465166100003,
        30.695385606000173
      ],
      [
        104.18465212600017,
        30.695392178000134
      ],
      [
        104.18465993700005,
        30.695502178000027
      ],
      [
        104.18468106800009,
        30.69579976200015
      ],
      [
        104.1847059600002,
        30.696279430000004
      ],
      [
        104.18470902299998,
        30.696338441000023
      ],
      [
        104.1847399030001,
        30.696606280000083
      ],
      [
        104.18474924700013,
        30.696687322000002
      ],
      [
        104.18475403100018,
        30.69672883300018
      ],
      [
        104.18476070700018,
        30.696779771000138
      ],
      [
        104.18479978200014,
        30.6970780690001
      ],
      [
        104.18480141900017,
        30.697086444000035
      ],
      [
        104.18487623300018,
        30.697469560000116
      ],
      [
        104.18494996800013,
        30.697849999000027
      ],
      [
        104.18498416400013,
        30.698026436000134
      ],
      [
        104.18511193600006,
        30.698684593000166
      ],
      [
        104.18511306800013,
        30.69869042800019
      ],
      [
        104.18514092800012,
        30.698988620000193
      ],
      [
        104.18514327400015,
        30.69901375000012
      ],
      [
        104.18532228300012,
        30.69976409800006
      ],
      [
        104.18533690400017,
        30.699840451000057
      ],
      [
        104.1855120240001,
        30.700755056000162
      ],
      [
        104.18556028300009,
        30.701007093000158
      ],
      [
        104.18556670900011,
        30.701040656000146
      ],
      [
        104.18557324500011,
        30.701074789000074
      ],
      [
        104.1857429760002,
        30.70196123100004
      ],
      [
        104.18574727200019,
        30.701983680000183
      ],
      [
        104.18582533500017,
        30.702395550000176
      ],
      [
        104.18586487800013,
        30.702569457000095
      ],
      [
        104.18574228100005,
        30.70256331100012
      ],
      [
        104.1838532970001,
        30.702468601000078
      ],
      [
        104.18377808800017,
        30.702464827000142
      ],
      [
        104.18353867300016,
        30.70245282100001
      ],
      [
        104.18174690600011,
        30.702362954000137
      ],
      [
        104.18118509700008,
        30.702334770000107
      ],
      [
        104.18079138400014,
        30.70231541100003
      ],
      [
        104.18077319600002,
        30.702314515000126
      ],
      [
        104.1807468610001,
        30.702313220999997
      ],
      [
        104.18068509200003,
        30.702310183000122
      ],
      [
        104.18008673900016,
        30.702280756000107
      ],
      [
        104.17901029800004,
        30.702227809000078
      ],
      [
        104.17876983400004,
        30.702215981000165
      ],
      [
        104.17870724400018,
        30.702212902000156
      ],
      [
        104.17707689000008,
        30.70213269100003
      ],
      [
        104.17664693200004,
        30.70211153300005
      ],
      [
        104.17610328600017,
        30.702072879000013
      ],
      [
        104.17561897700017,
        30.702038440000138
      ],
      [
        104.17447408400005,
        30.70198691200011
      ],
      [
        104.17439565100005,
        30.70198314800018
      ],
      [
        104.17410870200018,
        30.701969370000086
      ],
      [
        104.17404685400004,
        30.701966399000128
      ],
      [
        104.1739855300001,
        30.7019634560001
      ],
      [
        104.17398296200008,
        30.701963332000105
      ],
      [
        104.17266151800015,
        30.701896495000028
      ],
      [
        104.17214226300013,
        30.701870227000143
      ],
      [
        104.17173426900007,
        30.701852073000055
      ],
      [
        104.17073281000012,
        30.701807506000193
      ],
      [
        104.16988856400008,
        30.70176992800009
      ],
      [
        104.1693606709515,
        30.70119392744192
      ],
      [
        104.16250940500015,
        30.699002468000117
      ],
      [
        104.16203575700018,
        30.699002468000117
      ],
      [
        104.16131574614303,
        30.69854865862959
      ],
      [
        104.15940798900004,
        30.69734623500011
      ],
      [
        104.15886673632798,
        30.69725602622151
      ],
      [
        104.15861812348123,
        30.69721459074708
      ],
      [
        104.15860322200012,
        30.697451361000105
      ],
      [
        104.15860293700018,
        30.697455895000132
      ],
      [
        104.15860070299999,
        30.697491390000096
      ],
      [
        104.15859795600005,
        30.697535041000094
      ],
      [
        104.15859152400003,
        30.697637233000023
      ],
      [
        104.15856913400017,
        30.69800797300013
      ],
      [
        104.15854357100011,
        30.69843123800007
      ],
      [
        104.15853793500014,
        30.698524574000146
      ],
      [
        104.15852282200007,
        30.69877479200011
      ],
      [
        104.15852112500016,
        30.69880289500003
      ],
      [
        104.15851339900007,
        30.698903194000025
      ],
      [
        104.15851091700006,
        30.698921322000103
      ],
      [
        104.15849678200004,
        30.699024518000158
      ],
      [
        104.15849256500013,
        30.6990553010001
      ],
      [
        104.15848262500003,
        30.699127902000157
      ],
      [
        104.15848180200004,
        30.699133910000114
      ],
      [
        104.15845249699998,
        30.699356095000155
      ],
      [
        104.15843941300017,
        30.69945528300019
      ],
      [
        104.15843786200014,
        30.699467043000084
      ],
      [
        104.15843612100008,
        30.69948024900009
      ],
      [
        104.15843468300011,
        30.699491145000138
      ],
      [
        104.15841553200005,
        30.699598243000082
      ],
      [
        104.15839969100006,
        30.699686834000033
      ],
      [
        104.15834555500004,
        30.699989591000133
      ],
      [
        104.15830164300013,
        30.700235169000052
      ],
      [
        104.1582980570002,
        30.700255223000113
      ],
      [
        104.1582914190002,
        30.70029234700013
      ],
      [
        104.15828697800009,
        30.700317186000177
      ],
      [
        104.1582656700001,
        30.70043634400008
      ],
      [
        104.15825667100017,
        30.700486667000064
      ],
      [
        104.15824632099998,
        30.700526670999977
      ],
      [
        104.158232682,
        30.700579373000096
      ],
      [
        104.15822844500013,
        30.700595750000105
      ],
      [
        104.15822310800013,
        30.70061637900011
      ],
      [
        104.15810754200015,
        30.701062982000053
      ],
      [
        104.15779327500019,
        30.702019411000038
      ],
      [
        104.1576797140001,
        30.702302291000024
      ],
      [
        104.15760813300011,
        30.702480597000033
      ],
      [
        104.15734895100019,
        30.703055615000153
      ],
      [
        104.15723134300003,
        30.703295902000036
      ],
      [
        104.15716721500007,
        30.703409590000035
      ],
      [
        104.15713488600016,
        30.703466900000024
      ],
      [
        104.15708901200009,
        30.70354822900009
      ],
      [
        104.15686431800015,
        30.703946556000062
      ],
      [
        104.15660525900012,
        30.704374174000122
      ],
      [
        104.15639852100009,
        30.70469998200008
      ],
      [
        104.1559492080001,
        30.705308073000026
      ],
      [
        104.15579976100008,
        30.705497319000017
      ],
      [
        104.155737768,
        30.705575817000124
      ],
      [
        104.15552206500018,
        30.70583902700008
      ],
      [
        104.15534203000016,
        30.70604648699998
      ],
      [
        104.15504037400007,
        30.706439728000134
      ],
      [
        104.1547267667836,
        30.706776319971823
      ],
      [
        104.15468823033524,
        30.706817680815846
      ],
      [
        104.15054184800016,
        30.705628034000085
      ],
      [
        104.147709577,
        30.704832468000177
      ],
      [
        104.14644097100006,
        30.704552416000126
      ],
      [
        104.14642398300003,
        30.70454866500006
      ],
      [
        104.1432919230557,
        30.70396685799392
      ],
      [
        104.14321327800008,
        30.70395224900011
      ],
      [
        104.14251555600003,
        30.703837962000136
      ],
      [
        104.14205753000016,
        30.703762935000043
      ],
      [
        104.1406363050001,
        30.70348465900014
      ],
      [
        104.14032105700011,
        30.70342293200008
      ],
      [
        104.1368707150001,
        30.702767195000092
      ],
      [
        104.13251415400015,
        30.70195588700011
      ],
      [
        104.13091359200007,
        30.701675849000026
      ],
      [
        104.12950844400012,
        30.701381856000125
      ],
      [
        104.12515772900014,
        30.700591067000175
      ],
      [
        104.12260212500007,
        30.70021978200009
      ],
      [
        104.12008661400006,
        30.699831113000187
      ],
      [
        104.1185532900002,
        30.699641193000105
      ],
      [
        104.1158764500002,
        30.699661130000038
      ],
      [
        104.11497494173324,
        30.6996678227884
      ],
      [
        104.1158764500002,
        30.699661130000038
      ],
      [
        104.11244042700002,
        30.699686639000106
      ],
      [
        104.10759021400008,
        30.69972277700009
      ],
      [
        104.10479432400007,
        30.6997322200001
      ],
      [
        104.10400337135036,
        30.699711988258287
      ],
      [
        104.10433544000011,
        30.698596957000007
      ],
      [
        104.10438494800013,
        30.69851836300012
      ],
      [
        104.10443445400011,
        30.698439769000174
      ],
      [
        104.10489134400007,
        30.69771441800009
      ],
      [
        104.10490936600013,
        30.697685806000038
      ],
      [
        104.10522051100003,
        30.69718396700017
      ],
      [
        104.1052543790002,
        30.697129341999982
      ],
      [
        104.10533389900019,
        30.696982325000192
      ],
      [
        104.1053571700001,
        30.696934052000188
      ],
      [
        104.10538044200007,
        30.696885774000123
      ],
      [
        104.10538203300007,
        30.696882469000116
      ],
      [
        104.1053865670001,
        30.69687153200016
      ],
      [
        104.10541018000004,
        30.69681455600005
      ],
      [
        104.10542081300008,
        30.69677436300003
      ],
      [
        104.10544376000013,
        30.696687620000034
      ],
      [
        104.10544766400011,
        30.6966728540001
      ],
      [
        104.10544936600007,
        30.696666648000075
      ],
      [
        104.10545510500009,
        30.69664570300006
      ],
      [
        104.1054549110001,
        30.696626755000068
      ],
      [
        104.10545510300011,
        30.696625528000084
      ],
      [
        104.10547371300004,
        30.696505760000093
      ],
      [
        104.10547744600007,
        30.696481718000086
      ],
      [
        104.10548923800019,
        30.69640582900007
      ],
      [
        104.10549233700016,
        30.696385888000123
      ],
      [
        104.10549371799999,
        30.696377002000133
      ],
      [
        104.10551468600016,
        30.69624205100007
      ],
      [
        104.10551153100016,
        30.696196525000175
      ],
      [
        104.10550392800013,
        30.69608685900016
      ],
      [
        104.10549619200003,
        30.69601819700017
      ],
      [
        104.10549201200007,
        30.695988220000118
      ],
      [
        104.10548232900004,
        30.69591881700012
      ],
      [
        104.1054637380002,
        30.695792789000166
      ],
      [
        104.1054271980002,
        30.695703265000077
      ],
      [
        104.10540895700018,
        30.695658577000074
      ],
      [
        104.1053941720001,
        30.695622358000037
      ],
      [
        104.10533968200008,
        30.695500121000123
      ],
      [
        104.10526876700015,
        30.695346349000033
      ],
      [
        104.10517908000008,
        30.695231237000087
      ],
      [
        104.10506495900017,
        30.695084765000047
      ],
      [
        104.10501076700012,
        30.69501823300004
      ],
      [
        104.10265958000019,
        30.69208694400004
      ],
      [
        104.1025500830001,
        30.69195042600012
      ],
      [
        104.10134747900003,
        30.69045757700019
      ],
      [
        104.10100245000018,
        30.690016831000094
      ],
      [
        104.10096482500018,
        30.68996816200007
      ],
      [
        104.10088184600016,
        30.689860818000056
      ],
      [
        104.1008337180001,
        30.6898009680001
      ],
      [
        104.1002776810002,
        30.689070676000085
      ],
      [
        104.10007797300017,
        30.688811066000085
      ],
      [
        104.10007182500004,
        30.68880294500002
      ],
      [
        104.09998747500015,
        30.68869150600017
      ],
      [
        104.09997335100002,
        30.688672847000134
      ],
      [
        104.09986872000019,
        30.68853462900006
      ],
      [
        104.09982865400008,
        30.688481701000114
      ],
      [
        104.09978858800014,
        30.68842877300017
      ],
      [
        104.09978840000008,
        30.688428526000052
      ],
      [
        104.09966531612224,
        30.68826591906594
      ],
      [
        104.0996404990002,
        30.688233133000153
      ],
      [
        104.09955595000008,
        30.68812143200006
      ],
      [
        104.09953267000009,
        30.688090677000105
      ],
      [
        104.09949241000015,
        30.688037492000035
      ],
      [
        104.09949222800009,
        30.688037252000186
      ],
      [
        104.09944632800006,
        30.687976611000067
      ],
      [
        104.09942973200009,
        30.687954688000104
      ],
      [
        104.0994225300002,
        30.687945175000095
      ],
      [
        104.09942236100011,
        30.68794496300012
      ],
      [
        104.09939950800009,
        30.687916146000134
      ],
      [
        104.09944846500014,
        30.68788854000013
      ],
      [
        104.09947386600004,
        30.687874215000136
      ],
      [
        104.09949777300011,
        30.68786297300005
      ],
      [
        104.09949795000006,
        30.687862890000133
      ],
      [
        104.09952718700009,
        30.687849144000097
      ],
      [
        104.09953543900002,
        30.68784526200011
      ],
      [
        104.09955714600017,
        30.687836181000137
      ],
      [
        104.09961671400009,
        30.687795186000073
      ],
      [
        104.09962074600008,
        30.68779288900015
      ],
      [
        104.09968808500008,
        30.68775449800006
      ],
      [
        104.09974638500012,
        30.687721261000092
      ],
      [
        104.09980468300012,
        30.68768802500017
      ],
      [
        104.09983273600005,
        30.68767203300007
      ],
      [
        104.09986078700013,
        30.68765603900016
      ],
      [
        104.09986104100017,
        30.687655892000066
      ],
      [
        104.1001210820001,
        30.687507635000145
      ],
      [
        104.10020529200011,
        30.687454398000114
      ],
      [
        104.10028138300015,
        30.68739278200013
      ],
      [
        104.10033893800016,
        30.687340528999982
      ],
      [
        104.10041501100017,
        30.687254634999988
      ],
      [
        104.10046746300009,
        30.68717680800006
      ],
      [
        104.10053338099999,
        30.687064129000078
      ],
      [
        104.10079804300005,
        30.686594344000184
      ],
      [
        104.100917799,
        30.68638177100007
      ],
      [
        104.10126044100014,
        30.68579077400011
      ],
      [
        104.10126617600014,
        30.685780882000188
      ],
      [
        104.10131653300016,
        30.685695546000034
      ],
      [
        104.10142111500011,
        30.685356605000152
      ],
      [
        104.10150861300008,
        30.68521533300003
      ],
      [
        104.10152141400016,
        30.68519011600017
      ],
      [
        104.10161791600012,
        30.685000001000162
      ],
      [
        104.10163515100004,
        30.684969261000106
      ],
      [
        104.10178894300014,
        30.68469494200002
      ],
      [
        104.10185306900013,
        30.684580559999972
      ],
      [
        104.101984781,
        30.68434811100002
      ],
      [
        104.10212821400006,
        30.68408968300014
      ],
      [
        104.10217931800003,
        30.68399856400015
      ],
      [
        104.10234658700011,
        30.68370032100006
      ],
      [
        104.10240814600013,
        30.683592492000116
      ],
      [
        104.10246334300018,
        30.683502831000112
      ],
      [
        104.10254737700001,
        30.68336692700001
      ],
      [
        104.10260988500005,
        30.683265828999993
      ],
      [
        104.10262015300003,
        30.683249222000143
      ],
      [
        104.10286395500009,
        30.68282666900012
      ],
      [
        104.10300261200013,
        30.68257622400006
      ],
      [
        104.10302343600006,
        30.68253861100004
      ],
      [
        104.1030236590002,
        30.682538206000117
      ],
      [
        104.10308112100012,
        30.68243441800007
      ],
      [
        104.10335520900009,
        30.68194047300011
      ],
      [
        104.10341996900013,
        30.681823770000165
      ],
      [
        104.10368089000013,
        30.681369065000126
      ],
      [
        104.10377840000018,
        30.68119692800019
      ],
      [
        104.10379514700008,
        30.681166590000146
      ],
      [
        104.10384363300017,
        30.68107874200018
      ],
      [
        104.10385997700013,
        30.681049124000026
      ],
      [
        104.10392225700008,
        30.680937783000104
      ],
      [
        104.10457453300006,
        30.679795436000006
      ],
      [
        104.1046477110001,
        30.679669967000052
      ],
      [
        104.10471918300016,
        30.679547419000187
      ],
      [
        104.10472058400018,
        30.679545014000098
      ],
      [
        104.10526607399999,
        30.678609717000086
      ],
      [
        104.10564818500006,
        30.67789598700017
      ],
      [
        104.10565346800007,
        30.67788612100003
      ],
      [
        104.10576141000013,
        30.677684496000154
      ],
      [
        104.10584842095784,
        30.67748696385661
      ],
      [
        104.10596079600009,
        30.677227711000114
      ],
      [
        104.1060629980002,
        30.676991930000042
      ],
      [
        104.10609083800006,
        30.676927704000093
      ],
      [
        104.106238926,
        30.676586061000137
      ],
      [
        104.10667080200005,
        30.675488339000083
      ],
      [
        104.10680731200017,
        30.67514135600004
      ],
      [
        104.10687460200006,
        30.67497415200006
      ],
      [
        104.1069278560002,
        30.674841826000147
      ],
      [
        104.1070869040002,
        30.674446614000146
      ],
      [
        104.10718734000017,
        30.674197041000184
      ],
      [
        104.10733993500008,
        30.673817863000068
      ],
      [
        104.10735336200003,
        30.673784496000053
      ],
      [
        104.10749033000019,
        30.673444142000164
      ],
      [
        104.10749252599999,
        30.67343868400002
      ],
      [
        104.1077749260001,
        30.672736939000174
      ],
      [
        104.10805732200004,
        30.67203519100019
      ],
      [
        104.10809878900017,
        30.671932138000102
      ],
      [
        104.10835913400012,
        30.67127794200013
      ],
      [
        104.10854667900009,
        30.670798548000107
      ],
      [
        104.10861135100015,
        30.670643523000138
      ],
      [
        104.10867786300014,
        30.670484088000023
      ],
      [
        104.10887621000012,
        30.669991021000158
      ],
      [
        104.10895869500007,
        30.669785968000156
      ],
      [
        104.10899161600008,
        30.669705332000035
      ],
      [
        104.10907175400013,
        30.669509031000075
      ],
      [
        104.10916990200008,
        30.66926051400003
      ],
      [
        104.10917101100006,
        30.669257707000043
      ],
      [
        104.10917921700002,
        30.669236929000135
      ],
      [
        104.10918742500019,
        30.66921614800009
      ],
      [
        104.10933426400015,
        30.668844339000145
      ],
      [
        104.10937593000006,
        30.668742332000136
      ],
      [
        104.10963464200017,
        30.668086224000035
      ],
      [
        104.1097663020002,
        30.667752324000105
      ],
      [
        104.11008666900017,
        30.666956723000112
      ],
      [
        104.1101292030001,
        30.666851089000033
      ],
      [
        104.11014513200013,
        30.66680957000011
      ],
      [
        104.11020236300016,
        30.666660383000078
      ],
      [
        104.11020250700017,
        30.666660006000143
      ],
      [
        104.1102118280001,
        30.666635714999984
      ],
      [
        104.1102392680001,
        30.6665641890001
      ],
      [
        104.11024555900013,
        30.666547787000184
      ],
      [
        104.11024875100009,
        30.666539467000007
      ],
      [
        104.11027275300012,
        30.666476900000077
      ],
      [
        104.11028084000014,
        30.666455821000056
      ],
      [
        104.1102822960001,
        30.666452024000193
      ],
      [
        104.11028321400016,
        30.666449634000173
      ],
      [
        104.1103176310001,
        30.666359921000094
      ],
      [
        104.11031884300019,
        30.6663567600001
      ],
      [
        104.11035917300012,
        30.666251632000126
      ],
      [
        104.11038796600013,
        30.666176582000105
      ],
      [
        104.11041727100013,
        30.66610019000018
      ],
      [
        104.1104210540002,
        30.666090979000046
      ],
      [
        104.11059406600015,
        30.665669790000095
      ],
      [
        104.11068065100011,
        30.66545900100016
      ],
      [
        104.11085293500014,
        30.665039575000094
      ],
      [
        104.11085710400005,
        30.665029423000135
      ],
      [
        104.11094840700002,
        30.664820369000097
      ],
      [
        104.11094938900015,
        30.664818117000095
      ],
      [
        104.11107541500007,
        30.664529548000075
      ],
      [
        104.11109444300007,
        30.66448910700001
      ],
      [
        104.11121508700006,
        30.664195753000115
      ],
      [
        104.11131913100019,
        30.66392643600011
      ],
      [
        104.11142985999999,
        30.66363981100011
      ],
      [
        104.11168156700012,
        30.663024610000093
      ],
      [
        104.11172703700015,
        30.66291347500004
      ],
      [
        104.11193724700013,
        30.66232341000017
      ],
      [
        104.1121397600001,
        30.661785430000066
      ],
      [
        104.1121706200002,
        30.661669336000045
      ],
      [
        104.11221090800012,
        30.661494985000104
      ],
      [
        104.11222540700004,
        30.661410507000028
      ],
      [
        104.11224607300011,
        30.661212712000122
      ],
      [
        104.11226882700004,
        30.66076392300016
      ],
      [
        104.11226732200004,
        30.660066847000053
      ],
      [
        104.11225943500017,
        30.65964062000006
      ],
      [
        104.11225917300015,
        30.659617822000143
      ],
      [
        104.1122567750001,
        30.659410497000124
      ],
      [
        104.11225232700014,
        30.659288478000065
      ],
      [
        104.11223741200001,
        30.658879297000055
      ],
      [
        104.11223802500018,
        30.658445424000092
      ],
      [
        104.11223823800009,
        30.658294372000057
      ],
      [
        104.11223700500011,
        30.65815142600013
      ],
      [
        104.11223688400014,
        30.658137406000037
      ],
      [
        104.11223576300017,
        30.65800693100016
      ],
      [
        104.11223463800013,
        30.65787645800009
      ],
      [
        104.1122284240002,
        30.657154643000126
      ],
      [
        104.11222220700012,
        30.656432827000117
      ],
      [
        104.112222202,
        30.656432230000178
      ],
      [
        104.11221615000005,
        30.65572948400012
      ],
      [
        104.11221094900009,
        30.65512559500013
      ],
      [
        104.11220772500013,
        30.65502653900012
      ],
      [
        104.1122082510002,
        30.65496664900013
      ],
      [
        104.11221195700017,
        30.654543937000142
      ],
      [
        104.11220563900014,
        30.65418569100018
      ],
      [
        104.11217726100011,
        30.653903550000166
      ],
      [
        104.11211912100009,
        30.653681990000166
      ],
      [
        104.11202130499998,
        30.653380578000053
      ],
      [
        104.11202062000001,
        30.65337910600016
      ]
    ]
  },
  {
    coords: [
      [
        103.79852294921875,
        30.67669677734375
      ],
      [
        103.79852294921875,
        30.67645263671875
      ],
      [
        103.7989501953125,
        30.67559814453125
      ],
      [
        103.7991943359375,
        30.6749267578125
      ],
      [
        103.7998046875,
        30.67425537109375
      ],
      [
        103.80047607421875,
        30.67315673828125
      ],
      [
        103.8009033203125,
        30.6722412109375
      ],
      [
        103.8011474609375,
        30.671630859375
      ],
      [
        103.8013916015625,
        30.671142578125
      ],
      [
        103.802001953125,
        30.6702880859375
      ],
      [
        103.802490234375,
        30.66961669921875
      ],
      [
        103.80291748046875,
        30.66876220703125
      ],
      [
        103.8031005859375,
        30.6678466796875
      ],
      [
        103.80377197265625,
        30.667236328125
      ],
      [
        103.80401611328125,
        30.66656494140625
      ],
      [
        103.804443359375,
        30.6658935546875
      ],
      [
        103.80487060546875,
        30.66522216796875
      ],
      [
        103.8055419921875,
        30.66412353515625
      ],
      [
        103.80596923828125,
        30.6636962890625
      ],
      [
        103.80596923828125,
        30.66302490234375
      ],
      [
        103.806640625,
        30.66217041015625
      ],
      [
        103.80706787109375,
        30.6612548828125
      ],
      [
        103.80755615234375,
        30.660400390625
      ],
      [
        103.8079833984375,
        30.65948486328125
      ],
      [
        103.80841064453125,
        30.65863037109375
      ],
      [
        103.808837890625,
        30.65777587890625
      ],
      [
        103.80926513671875,
        30.6568603515625
      ],
      [
        103.80975341796875,
        30.65576171875
      ],
      [
        103.8099365234375,
        30.65509033203125
      ],
      [
        103.81085205078125,
        30.65533447265625
      ],
      [
        103.81195068359375,
        30.656005859375
      ],
      [
        103.81280517578125,
        30.65618896484375
      ],
      [
        103.81365966796875,
        30.65667724609375
      ],
      [
        103.8145751953125,
        30.6571044921875
      ],
      [
        103.81524658203125,
        30.65728759765625
      ],
      [
        103.81591796875,
        30.65753173828125
      ],
      [
        103.8165283203125,
        30.657958984375
      ],
      [
        103.81719970703125,
        30.65863037109375
      ],
      [
        103.81787109375,
        30.6571044921875
      ],
      [
        103.81829833984375,
        30.656005859375
      ],
      [
        103.8187255859375,
        30.65509033203125
      ],
      [
        103.8189697265625,
        30.6544189453125
      ],
      [
        103.8192138671875,
        30.653564453125
      ],
      [
        103.81964111328125,
        30.6527099609375
      ],
      [
        103.8203125,
        30.651123046875
      ],
      [
        103.82049560546875,
        30.6505126953125
      ],
      [
        103.82073974609375,
        30.6500244140625
      ],
      [
        103.8211669921875,
        30.6502685546875
      ],
      [
        103.82183837890625,
        30.6502685546875
      ],
      [
        103.82293701171875,
        30.6505126953125
      ],
      [
        103.82403564453125,
        30.6502685546875
      ],
      [
        103.82489013671875,
        30.6500244140625
      ],
      [
        103.8255615234375,
        30.6500244140625
      ],
      [
        103.826904296875,
        30.64984130859375
      ],
      [
        103.82757568359375,
        30.64984130859375
      ],
      [
        103.82818603515625,
        30.64959716796875
      ],
      [
        103.8291015625,
        30.64959716796875
      ],
      [
        103.8299560546875,
        30.64959716796875
      ],
      [
        103.831298828125,
        30.6494140625
      ],
      [
        103.8321533203125,
        30.6494140625
      ],
      [
        103.83306884765625,
        30.649169921875
      ],
      [
        103.83358764648438,
        30.649169921875
      ],
      [
        103.8343505859375,
        30.649169921875
      ],
      [
        103.83502197265625,
        30.64959716796875
      ],
      [
        103.83612060546875,
        30.64984130859375
      ],
      [
        103.8367919921875,
        30.6500244140625
      ],
      [
        103.83746337890625,
        30.6505126953125
      ],
      [
        103.83831787109375,
        30.651123046875
      ],
      [
        103.8389892578125,
        30.65093994140625
      ],
      [
        103.84033203125,
        30.6513671875
      ],
      [
        103.8411865234375,
        30.651611328125
      ],
      [
        103.84185791015625,
        30.651611328125
      ],
      [
        103.84271240234375,
        30.65179443359375
      ],
      [
        103.84423828125,
        30.6522216796875
      ],
      [
        103.84490966796875,
        30.6522216796875
      ],
      [
        103.8455810546875,
        30.6524658203125
      ],
      [
        103.84625244140625,
        30.6527099609375
      ],
      [
        103.846923828125,
        30.65289306640625
      ],
      [
        103.84759521484375,
        30.65289306640625
      ],
      [
        103.84820556640625,
        30.65313720703125
      ],
      [
        103.84912109375,
        30.6533203125
      ],
      [
        103.851318359375,
        30.65399169921875
      ],
      [
        103.85198974609375,
        30.6533203125
      ],
      [
        103.8524169921875,
        30.6527099609375
      ],
      [
        103.85308837890625,
        30.65203857421875
      ],
      [
        103.853515625,
        30.651123046875
      ],
      [
        103.85394287109375,
        30.6502685546875
      ],
      [
        103.8543701171875,
        30.64984130859375
      ],
      [
        103.8548583984375,
        30.649169921875
      ],
      [
        103.85504150390625,
        30.64849853515625
      ],
      [
        103.85546875,
        30.64739990234375
      ],
      [
        103.85595703125,
        30.6483154296875
      ],
      [
        103.8565673828125,
        30.64849853515625
      ],
      [
        103.85723876953125,
        30.64892578125
      ],
      [
        103.85833740234375,
        30.64959716796875
      ],
      [
        103.8590087890625,
        30.64984130859375
      ],
      [
        103.85986328125,
        30.6505126953125
      ],
      [
        103.86053466796875,
        30.65093994140625
      ],
      [
        103.86163330078125,
        30.6513671875
      ],
      [
        103.862548828125,
        30.65179443359375
      ],
      [
        103.86297607421875,
        30.6522216796875
      ],
      [
        103.86383056640625,
        30.65313720703125
      ],
      [
        103.864501953125,
        30.65289306640625
      ],
      [
        103.86517333984375,
        30.6533203125
      ],
      [
        103.86627197265625,
        30.65399169921875
      ],
      [
        103.86712646484375,
        30.6544189453125
      ],
      [
        103.86871337890625,
        30.65509033203125
      ],
      [
        103.86932373046875,
        30.65576171875
      ],
      [
        103.87091064453125,
        30.65643310546875
      ],
      [
        103.87176513671875,
        30.6568603515625
      ],
      [
        103.8724365234375,
        30.65728759765625
      ],
      [
        103.87310791015625,
        30.65753173828125
      ],
      [
        103.87420654296875,
        30.657958984375
      ],
      [
        103.8748779296875,
        30.65863037109375
      ],
      [
        103.8759765625,
        30.6590576171875
      ],
      [
        103.8765869140625,
        30.65948486328125
      ],
      [
        103.87750244140625,
        30.65997314453125
      ],
      [
        103.878173828125,
        30.660400390625
      ],
      [
        103.8787841796875,
        30.66082763671875
      ],
      [
        103.87969970703125,
        30.6612548828125
      ],
      [
        103.88037109375,
        30.6614990234375
      ],
      [
        103.8809814453125,
        30.66192626953125
      ],
      [
        103.88165283203125,
        30.662353515625
      ],
      [
        103.882568359375,
        30.66278076171875
      ],
      [
        103.8834228515625,
        30.66326904296875
      ],
      [
        103.88433837890625,
        30.6636962890625
      ],
      [
        103.88494873046875,
        30.66412353515625
      ],
      [
        103.8858642578125,
        30.66455078125
      ],
      [
        103.88671875,
        30.66497802734375
      ],
      [
        103.88739013671875,
        30.66546630859375
      ],
      [
        103.88824462890625,
        30.6658935546875
      ],
      [
        103.888916015625,
        30.66632080078125
      ],
      [
        103.88934326171875,
        30.6669921875
      ],
      [
        103.88929748535156,
        30.667236328125
      ],
      [
        103.8900146484375,
        30.66766357421875
      ],
      [
        103.89044189453125,
        30.6683349609375
      ],
      [
        103.89111328125,
        30.66876220703125
      ],
      [
        103.891357421875,
        30.66943359375
      ],
      [
        103.89202880859375,
        30.6700439453125
      ],
      [
        103.8924560546875,
        30.67071533203125
      ],
      [
        103.89288330078125,
        30.67138671875
      ],
      [
        103.8935546875,
        30.67205810546875
      ],
      [
        103.89398193359375,
        30.6727294921875
      ],
      [
        103.8944091796875,
        30.6727294921875
      ],
      [
        103.8944091796875,
        30.67333984375
      ],
      [
        103.89422607421875,
        30.673828125
      ],
      [
        103.89398193359375,
        30.6744384765625
      ],
      [
        103.89398193359375,
        30.6746826171875
      ],
      [
        103.89398193359375,
        30.6749267578125
      ],
      [
        103.893798828125,
        30.67510986328125
      ],
      [
        103.893310546875,
        30.676025390625
      ],
      [
        103.89312744140625,
        30.67669677734375
      ],
      [
        103.89288330078125,
        30.67755126953125
      ],
      [
        103.8924560546875,
        30.67864990234375
      ],
      [
        103.89288330078125,
        30.67999267578125
      ],
      [
        103.8944091796875,
        30.67999267578125
      ],
      [
        103.89532470703125,
        30.67999267578125
      ],
      [
        103.89599609375,
        30.67999267578125
      ],
      [
        103.8966064453125,
        30.67999267578125
      ],
      [
        103.89727783203125,
        30.67999267578125
      ],
      [
        103.89794921875,
        30.67999267578125
      ],
      [
        103.89862060546875,
        30.67999267578125
      ],
      [
        103.8992919921875,
        30.67999267578125
      ],
      [
        103.900390625,
        30.67999267578125
      ],
      [
        103.9012451171875,
        30.67999267578125
      ],
      [
        103.90191650390625,
        30.67999267578125
      ],
      [
        103.902587890625,
        30.68017578125
      ],
      [
        103.9031982421875,
        30.68017578125
      ],
      [
        103.9036865234375,
        30.680419921875
      ],
      [
        103.9031982421875,
        30.68017578125
      ],
      [
        103.90386962890625,
        30.680419921875
      ],
      [
        103.904541015625,
        30.68060302734375
      ],
      [
        103.90545654296875,
        30.68060302734375
      ],
      [
        103.90631103515625,
        30.68084716796875
      ],
      [
        103.90716552734375,
        30.68084716796875
      ],
      [
        103.9078369140625,
        30.68084716796875
      ],
      [
        103.90850830078125,
        30.68084716796875
      ],
      [
        103.9091796875,
        30.68084716796875
      ],
      [
        103.90985107421875,
        30.68084716796875
      ],
      [
        103.91070556640625,
        30.68060302734375
      ],
      [
        103.91180419921875,
        30.680419921875
      ],
      [
        103.91265869140625,
        30.67999267578125
      ],
      [
        103.91314697265625,
        30.68060302734375
      ],
      [
        103.913330078125,
        30.6812744140625
      ],
      [
        103.913818359375,
        30.68170166015625
      ],
      [
        103.91400146484375,
        30.682373046875
      ],
      [
        103.9146728515625,
        30.68304443359375
      ],
      [
        103.91510009765625,
        30.6837158203125
      ],
      [
        103.91552734375,
        30.6845703125
      ],
      [
        103.91619873046875,
        30.68524169921875
      ],
      [
        103.9168701171875,
        30.68634033203125
      ],
      [
        103.91729736328125,
        30.68701171875
      ],
      [
        103.917724609375,
        30.68768310546875
      ],
      [
        103.91796875,
        30.6883544921875
      ],
      [
        103.9188232421875,
        30.689208984375
      ],
      [
        103.9193115234375,
        30.6900634765625
      ],
      [
        103.91973876953125,
        30.69097900390625
      ],
      [
        103.91973876953125,
        30.69207763671875
      ],
      [
        103.91973876953125,
        30.6927490234375
      ],
      [
        103.91949462890625,
        30.693359375
      ],
      [
        103.9188232421875,
        30.69384765625
      ],
      [
        103.918212890625,
        30.6944580078125
      ],
      [
        103.91754150390625,
        30.69512939453125
      ],
      [
        103.9171142578125,
        30.69580078125
      ],
      [
        103.9168701171875,
        30.69647216796875
      ],
      [
        103.9166259765625,
        30.69757080078125
      ],
      [
        103.91552734375,
        30.69757080078125
      ],
      [
        103.9149169921875,
        30.69757080078125
      ],
      [
        103.91424560546875,
        30.69732666015625
      ],
      [
        103.91357421875,
        30.69732666015625
      ],
      [
        103.91290283203125,
        30.69732666015625
      ],
      [
        103.9122314453125,
        30.69732666015625
      ],
      [
        103.91156005859375,
        30.69732666015625
      ],
      [
        103.91070556640625,
        30.69732666015625
      ],
      [
        103.90985107421875,
        30.69732666015625
      ],
      [
        103.908935546875,
        30.6971435546875
      ],
      [
        103.90826416015625,
        30.6971435546875
      ],
      [
        103.90765380859375,
        30.69732666015625
      ],
      [
        103.90673828125,
        30.69732666015625
      ],
      [
        103.9056396484375,
        30.69781494140625
      ],
      [
        103.904541015625,
        30.697998046875
      ],
      [
        103.90478515625,
        30.69866943359375
      ],
      [
        103.90496826171875,
        30.70001220703125
      ],
      [
        103.90521240234375,
        30.70086669921875
      ],
      [
        103.9056396484375,
        30.702392578125
      ],
      [
        103.90606689453125,
        30.70330810546875
      ],
      [
        103.9056396484375,
        30.70391845703125
      ],
      [
        103.90545654296875,
        30.70458984375
      ],
      [
        103.90496826171875,
        30.70550537109375
      ],
      [
        103.90435791015625,
        30.70703125
      ],
      [
        103.90386962890625,
        30.7078857421875
      ],
      [
        103.9034423828125,
        30.70855712890625
      ],
      [
        103.90301513671875,
        30.70965576171875
      ],
      [
        103.902587890625,
        30.7105712890625
      ],
      [
        103.902099609375,
        30.71142578125
      ],
      [
        103.9014892578125,
        30.7125244140625
      ],
      [
        103.89971923828125,
        30.7158203125
      ],
      [
        103.89938354492188,
        30.71673583984375
      ],
      [
        103.8990478515625,
        30.71783447265625
      ],
      [
        103.8988037109375,
        30.71893310546875
      ],
      [
        103.8988037109375,
        30.71954345703125
      ],
      [
        103.89862060546875,
        30.720458984375
      ],
      [
        103.89837646484375,
        30.7213134765625
      ],
      [
        103.898193359375,
        30.72222900390625
      ],
      [
        103.89752197265625,
        30.7235107421875
      ],
      [
        103.89727783203125,
        30.7257080078125
      ],
      [
        103.8970947265625,
        30.7279052734375
      ],
      [
        103.8970947265625,
        30.729248046875
      ],
      [
        103.89642333984375,
        30.7294921875
      ],
      [
        103.895751953125,
        30.729248046875
      ],
      [
        103.89508056640625,
        30.72900390625
      ],
      [
        103.89398193359375,
        30.72882080078125
      ],
      [
        103.88958740234375,
        30.7322998046875
      ],
      [
        103.888916015625,
        30.7325439453125
      ],
      [
        103.8880615234375,
        30.73321533203125
      ],
      [
        103.88763427734375,
        30.733642578125
      ],
      [
        103.886962890625,
        30.73406982421875
      ],
      [
        103.88629150390625,
        30.73455810546875
      ],
      [
        103.8856201171875,
        30.7349853515625
      ],
      [
        103.884521484375,
        30.73583984375
      ],
      [
        103.8834228515625,
        30.7371826171875
      ],
      [
        103.87860107421875,
        30.74090576171875
      ],
      [
        103.8779296875,
        30.74114990234375
      ],
      [
        103.87750244140625,
        30.7415771484375
      ],
      [
        103.87640380859375,
        30.74224853515625
      ],
      [
        103.8759765625,
        30.74267578125
      ],
      [
        103.87530517578125,
        30.74334716796875
      ],
      [
        103.8746337890625,
        30.7435302734375
      ],
      [
        103.87420654296875,
        30.74420166015625
      ],
      [
        103.87353515625,
        30.74444580078125
      ],
      [
        103.87310791015625,
        30.744873046875
      ],
      [
        103.8721923828125,
        30.7457275390625
      ],
      [
        103.87152099609375,
        30.7459716796875
      ],
      [
        103.87042236328125,
        30.7470703125
      ],
      [
        103.8699951171875,
        30.74749755859375
      ],
      [
        103.86932373046875,
        30.7462158203125
      ],
      [
        103.86822509765625,
        30.74462890625
      ],
      [
        103.8677978515625,
        30.7437744140625
      ],
      [
        103.86712646484375,
        30.74285888671875
      ],
      [
        103.86669921875,
        30.74200439453125
      ],
      [
        103.86602783203125,
        30.74090576171875
      ],
      [
        103.86541748046875,
        30.73980712890625
      ],
      [
        103.86474609375,
        30.7391357421875
      ],
      [
        103.864501953125,
        30.73846435546875
      ],
      [
        103.86407470703125,
        30.73785400390625
      ],
      [
        103.8634033203125,
        30.7371826171875
      ],
      [
        103.86297607421875,
        30.73651123046875
      ],
      [
        103.862548828125,
        30.736083984375
      ],
      [
        103.862060546875,
        30.73651123046875
      ],
      [
        103.86163330078125,
        30.7369384765625
      ],
      [
        103.8612060546875,
        30.73736572265625
      ],
      [
        103.86077880859375,
        30.73785400390625
      ],
      [
        103.8603515625,
        30.73828125
      ],
      [
        103.85986328125,
        30.73870849609375
      ],
      [
        103.85943603515625,
        30.7391357421875
      ],
      [
        103.8590087890625,
        30.73980712890625
      ],
      [
        103.85833740234375,
        30.740478515625
      ],
      [
        103.857666015625,
        30.74090576171875
      ],
      [
        103.85723876953125,
        30.7415771484375
      ],
      [
        103.8568115234375,
        30.74200439453125
      ],
      [
        103.855712890625,
        30.74267578125
      ],
      [
        103.8543701171875,
        30.742431640625
      ],
      [
        103.853515625,
        30.74200439453125
      ],
      [
        103.8524169921875,
        30.74200439453125
      ],
      [
        103.85150146484375,
        30.7415771484375
      ],
      [
        103.85040283203125,
        30.7413330078125
      ],
      [
        103.84979248046875,
        30.74114990234375
      ],
      [
        103.84912109375,
        30.74066162109375
      ],
      [
        103.8480224609375,
        30.740478515625
      ],
      [
        103.846923828125,
        30.740234375
      ],
      [
        103.8458251953125,
        30.73980712890625
      ],
      [
        103.84490966796875,
        30.73956298828125
      ],
      [
        103.84405517578125,
        30.7391357421875
      ],
      [
        103.8431396484375,
        30.73895263671875
      ],
      [
        103.84228515625,
        30.73870849609375
      ],
      [
        103.8411865234375,
        30.73828125
      ],
      [
        103.840087890625,
        30.738037109375
      ],
      [
        103.83941650390625,
        30.73785400390625
      ],
      [
        103.8387451171875,
        30.73760986328125
      ],
      [
        103.838134765625,
        30.73736572265625
      ],
      [
        103.837646484375,
        30.7369384765625
      ],
      [
        103.83721923828125,
        30.73785400390625
      ],
      [
        103.8365478515625,
        30.73870849609375
      ],
      [
        103.83636474609375,
        30.7393798828125
      ],
      [
        103.8359375,
        30.740478515625
      ],
      [
        103.8359375,
        30.7413330078125
      ],
      [
        103.8359375,
        30.74200439453125
      ],
      [
        103.83612060546875,
        30.74267578125
      ],
      [
        103.83612060546875,
        30.7435302734375
      ],
      [
        103.83544921875,
        30.7437744140625
      ],
      [
        103.8345947265625,
        30.7440185546875
      ],
      [
        103.83349609375,
        30.7440185546875
      ],
      [
        103.83282470703125,
        30.7440185546875
      ],
      [
        103.8321533203125,
        30.74420166015625
      ],
      [
        103.83148193359375,
        30.74420166015625
      ],
      [
        103.83087158203125,
        30.74420166015625
      ],
      [
        103.8299560546875,
        30.74444580078125
      ],
      [
        103.82928466796875,
        30.74462890625
      ],
      [
        103.82818603515625,
        30.74444580078125
      ],
      [
        103.82757568359375,
        30.744873046875
      ],
      [
        103.826904296875,
        30.744873046875
      ],
      [
        103.82623291015625,
        30.744873046875
      ],
      [
        103.8255615234375,
        30.744873046875
      ],
      [
        103.82470703125,
        30.744873046875
      ],
      [
        103.82379150390625,
        30.744873046875
      ],
      [
        103.82269287109375,
        30.74462890625
      ],
      [
        103.822021484375,
        30.74462890625
      ],
      [
        103.8209228515625,
        30.74462890625
      ],
      [
        103.8203125,
        30.74462890625
      ],
      [
        103.81964111328125,
        30.74462890625
      ],
      [
        103.8189697265625,
        30.74462890625
      ],
      [
        103.81829833984375,
        30.74462890625
      ],
      [
        103.817626953125,
        30.74462890625
      ],
      [
        103.8167724609375,
        30.74462890625
      ],
      [
        103.81591796875,
        30.744873046875
      ],
      [
        103.81475830078125,
        30.744873046875
      ],
      [
        103.81390380859375,
        30.744873046875
      ],
      [
        103.813232421875,
        30.74462890625
      ],
      [
        103.81256103515625,
        30.74420166015625
      ],
      [
        103.81170654296875,
        30.74420166015625
      ],
      [
        103.81103515625,
        30.7440185546875
      ],
      [
        103.8099365234375,
        30.7437744140625
      ],
      [
        103.80908203125,
        30.74334716796875
      ],
      [
        103.80816650390625,
        30.74334716796875
      ],
      [
        103.80731201171875,
        30.74285888671875
      ],
      [
        103.806640625,
        30.74285888671875
      ],
      [
        103.80621337890625,
        30.742431640625
      ],
      [
        103.8057861328125,
        30.74334716796875
      ],
      [
        103.8055419921875,
        30.74420166015625
      ],
      [
        103.80535888671875,
        30.744873046875
      ],
      [
        103.8046875,
        30.74554443359375
      ],
      [
        103.8035888671875,
        30.7462158203125
      ],
      [
        103.80267333984375,
        30.74664306640625
      ],
      [
        103.80181884765625,
        30.746826171875
      ],
      [
        103.8011474609375,
        30.747314453125
      ],
      [
        103.80029296875,
        30.74749755859375
      ],
      [
        103.79962158203125,
        30.7479248046875
      ],
      [
        103.7991943359375,
        30.7484130859375
      ],
      [
        103.79827880859375,
        30.74859619140625
      ],
      [
        103.79742431640625,
        30.7479248046875
      ],
      [
        103.79632568359375,
        30.74774169921875
      ],
      [
        103.795654296875,
        30.747314453125
      ],
      [
        103.79473876953125,
        30.746826171875
      ],
      [
        103.79388427734375,
        30.74639892578125
      ],
      [
        103.793212890625,
        30.7462158203125
      ],
      [
        103.7923583984375,
        30.7459716796875
      ],
      [
        103.79144287109375,
        30.74530029296875
      ],
      [
        103.79034423828125,
        30.7451171875
      ],
      [
        103.78948974609375,
        30.74444580078125
      ],
      [
        103.788818359375,
        30.7440185546875
      ],
      [
        103.78753662109375,
        30.74310302734375
      ],
      [
        103.78619384765625,
        30.74224853515625
      ],
      [
        103.7855224609375,
        30.74176025390625
      ],
      [
        103.78594970703125,
        30.74114990234375
      ],
      [
        103.78662109375,
        30.740478515625
      ],
      [
        103.7877197265625,
        30.7391357421875
      ],
      [
        103.78839111328125,
        30.73870849609375
      ],
      [
        103.78924560546875,
        30.73760986328125
      ],
      [
        103.78973388671875,
        30.7371826171875
      ],
      [
        103.79058837890625,
        30.73626708984375
      ],
      [
        103.79144287109375,
        30.73541259765625
      ],
      [
        103.7921142578125,
        30.7347412109375
      ],
      [
        103.79278564453125,
        30.73406982421875
      ],
      [
        103.7921142578125,
        30.73321533203125
      ],
      [
        103.79168701171875,
        30.7327880859375
      ],
      [
        103.791015625,
        30.7325439453125
      ],
      [
        103.79058837890625,
        30.73211669921875
      ],
      [
        103.7899169921875,
        30.731689453125
      ],
      [
        103.78924560546875,
        30.73101806640625
      ],
      [
        103.78863525390625,
        30.7303466796875
      ],
      [
        103.7877197265625,
        30.72967529296875
      ],
      [
        103.78729248046875,
        30.729248046875
      ],
      [
        103.78662109375,
        30.72882080078125
      ],
      [
        103.7855224609375,
        30.7281494140625
      ],
      [
        103.78509521484375,
        30.72772216796875
      ],
      [
        103.78466796875,
        30.727294921875
      ],
      [
        103.7835693359375,
        30.72637939453125
      ],
      [
        103.78265380859375,
        30.7257080078125
      ],
      [
        103.7822265625,
        30.72528076171875
      ],
      [
        103.78155517578125,
        30.72509765625
      ],
      [
        103.7811279296875,
        30.724609375
      ],
      [
        103.7802734375,
        30.72393798828125
      ],
      [
        103.78070068359375,
        30.72265625
      ],
      [
        103.7811279296875,
        30.72198486328125
      ],
      [
        103.78155517578125,
        30.7213134765625
      ],
      [
        103.78179931640625,
        30.72064208984375
      ],
      [
        103.7822265625,
        30.72003173828125
      ],
      [
        103.78265380859375,
        30.7193603515625
      ],
      [
        103.7830810546875,
        30.71868896484375
      ],
      [
        103.7835693359375,
        30.71783447265625
      ],
      [
        103.78399658203125,
        30.7169189453125
      ],
      [
        103.784423828125,
        30.71649169921875
      ],
      [
        103.78509521484375,
        30.71539306640625
      ],
      [
        103.78594970703125,
        30.71405029296875
      ],
      [
        103.78594970703125,
        30.71337890625
      ],
      [
        103.786865234375,
        30.71209716796875
      ],
      [
        103.78729248046875,
        30.71142578125
      ],
      [
        103.7877197265625,
        30.71075439453125
      ],
      [
        103.7877197265625,
        30.7100830078125
      ],
      [
        103.78814697265625,
        30.70965576171875
      ],
      [
        103.78863525390625,
        30.709228515625
      ],
      [
        103.788818359375,
        30.70855712890625
      ],
      [
        103.78924560546875,
        30.7081298828125
      ],
      [
        103.78973388671875,
        30.70703125
      ],
      [
        103.7901611328125,
        30.7061767578125
      ],
      [
        103.79034423828125,
        30.70526123046875
      ],
      [
        103.791259765625,
        30.704833984375
      ],
      [
        103.79034423828125,
        30.70416259765625
      ],
      [
        103.78948974609375,
        30.7034912109375
      ],
      [
        103.78814697265625,
        30.70263671875
      ],
      [
        103.78704833984375,
        30.70196533203125
      ],
      [
        103.78753662109375,
        30.7012939453125
      ],
      [
        103.7877197265625,
        30.70062255859375
      ],
      [
        103.78839111328125,
        30.69952392578125
      ],
      [
        103.788818359375,
        30.69866943359375
      ],
      [
        103.78973388671875,
        30.6971435546875
      ],
      [
        103.7901611328125,
        30.69622802734375
      ],
      [
        103.79058837890625,
        30.69537353515625
      ],
      [
        103.79083251953125,
        30.6947021484375
      ],
      [
        103.791015625,
        30.69403076171875
      ],
      [
        103.79144287109375,
        30.69317626953125
      ],
      [
        103.79168701171875,
        30.6925048828125
      ],
      [
        103.791015625,
        30.69183349609375
      ],
      [
        103.79034423828125,
        30.69140625
      ],
      [
        103.7901611328125,
        30.691162109375
      ],
      [
        103.78973388671875,
        30.69073486328125
      ],
      [
        103.78863525390625,
        30.68988037109375
      ],
      [
        103.7879638671875,
        30.689453125
      ],
      [
        103.79034423828125,
        30.68505859375
      ],
      [
        103.79718017578125,
        30.67822265625
      ],
      [
        103.79827880859375,
        30.67669677734375
      ]
    ]
  },
  {
    coords: [
      [
        [
          104.1162109375,
          30.375732421875
        ],
        [
          104.1162109375,
          30.3765869140625
        ],
        [
          104.11639404296875,
          30.3779296875
        ],
        [
          104.11663818359375,
          30.37945556640625
        ],
        [
          104.1170654296875,
          30.3809814453125
        ],
        [
          104.1173095703125,
          30.38165283203125
        ],
        [
          104.1173095703125,
          30.38299560546875
        ],
        [
          104.11749267578125,
          30.38385009765625
        ],
        [
          104.11773681640625,
          30.38470458984375
        ],
        [
          104.117919921875,
          30.3856201171875
        ],
        [
          104.118408203125,
          30.38671875
        ],
        [
          104.11859130859375,
          30.38739013671875
        ],
        [
          104.1192626953125,
          30.38848876953125
        ],
        [
          104.11968994140625,
          30.3897705078125
        ],
        [
          104.12017822265625,
          30.3902587890625
        ],
        [
          104.12078857421875,
          30.39154052734375
        ],
        [
          104.12127685546875,
          30.3922119140625
        ],
        [
          104.12188720703125,
          30.39306640625
        ],
        [
          104.12298583984375,
          30.3955078125
        ],
        [
          104.1239013671875,
          30.3974609375
        ],
        [
          104.12432861328125,
          30.3985595703125
        ],
        [
          104.12518310546875,
          30.39990234375
        ],
        [
          104.1260986328125,
          30.40142822265625
        ],
        [
          104.12652587890625,
          30.40252685546875
        ],
        [
          104.12677001953125,
          30.4034423828125
        ],
        [
          104.126953125,
          30.40411376953125
        ],
        [
          104.126953125,
          30.40472412109375
        ],
        [
          104.126953125,
          30.4056396484375
        ],
        [
          104.127197265625,
          30.40673828125
        ],
        [
          104.127197265625,
          30.40802001953125
        ],
        [
          104.127197265625,
          30.41046142578125
        ],
        [
          104.127197265625,
          30.41156005859375
        ],
        [
          104.12738037109375,
          30.41357421875
        ],
        [
          104.12738037109375,
          30.4144287109375
        ],
        [
          104.12738037109375,
          30.415771484375
        ],
        [
          104.127197265625,
          30.4166259765625
        ],
        [
          104.126953125,
          30.41796875
        ],
        [
          104.12677001953125,
          30.4185791015625
        ],
        [
          104.12652587890625,
          30.420166015625
        ],
        [
          104.1260986328125,
          30.4212646484375
        ],
        [
          104.12567138671875,
          30.42193603515625
        ],
        [
          104.12542724609375,
          30.4234619140625
        ],
        [
          104.124755859375,
          30.42413330078125
        ],
        [
          104.12432861328125,
          30.4254150390625
        ],
        [
          104.1239013671875,
          30.4267578125
        ],
        [
          104.1236572265625,
          30.4276123046875
        ],
        [
          104.12347412109375,
          30.428955078125
        ],
        [
          104.12347412109375,
          30.43048095703125
        ],
        [
          104.12322998046875,
          30.43157958984375
        ],
        [
          104.12347412109375,
          30.4322509765625
        ],
        [
          104.12298583984375,
          30.43359375
        ],
        [
          104.122802734375,
          30.4342041015625
        ],
        [
          104.12255859375,
          30.43511962890625
        ],
        [
          104.12237548828125,
          30.43597412109375
        ],
        [
          104.12188720703125,
          30.4364013671875
        ],
        [
          104.1217041015625,
          30.43707275390625
        ],
        [
          104.12127685546875,
          30.43817138671875
        ],
        [
          104.12078857421875,
          30.4388427734375
        ],
        [
          104.120361328125,
          30.43951416015625
        ],
        [
          104.12017822265625,
          30.440185546875
        ],
        [
          104.11968994140625,
          30.4410400390625
        ],
        [
          104.1195068359375,
          30.442138671875
        ],
        [
          104.1192626953125,
          30.44305419921875
        ],
        [
          104.1190185546875,
          30.44366455078125
        ],
        [
          104.1190185546875,
          30.4443359375
        ],
        [
          104.1190185546875,
          30.44500732421875
        ],
        [
          104.1181640625,
          30.44500732421875
        ],
        [
          104.11639404296875,
          30.44525146484375
        ],
        [
          104.11419677734375,
          30.44525146484375
        ],
        [
          104.1102294921875,
          30.4456787109375
        ],
        [
          104.109375,
          30.4456787109375
        ],
        [
          104.1058349609375,
          30.44586181640625
        ],
        [
          104.10498046875,
          30.44586181640625
        ],
        [
          104.1036376953125,
          30.44586181640625
        ],
        [
          104.102783203125,
          30.44610595703125
        ],
        [
          104.1014404296875,
          30.44610595703125
        ],
        [
          104.0992431640625,
          30.44635009765625
        ],
        [
          104.09857177734375,
          30.44635009765625
        ],
        [
          104.0968017578125,
          30.44635009765625
        ],
        [
          104.0948486328125,
          30.446533203125
        ],
        [
          104.09417724609375,
          30.446533203125
        ],
        [
          104.09332275390625,
          30.446533203125
        ],
        [
          104.0924072265625,
          30.44677734375
        ],
        [
          104.09130859375,
          30.44677734375
        ],
        [
          104.09002685546875,
          30.44696044921875
        ],
        [
          104.08758544921875,
          30.44720458984375
        ],
        [
          104.08563232421875,
          30.44744873046875
        ],
        [
          104.0836181640625,
          30.4476318359375
        ],
        [
          104.08233642578125,
          30.4476318359375
        ],
        [
          104.08099365234375,
          30.44744873046875
        ],
        [
          104.07989501953125,
          30.44720458984375
        ],
        [
          104.0789794921875,
          30.44696044921875
        ],
        [
          104.078125,
          30.44696044921875
        ],
        [
          104.07635498046875,
          30.44720458984375
        ],
        [
          104.07568359375,
          30.44744873046875
        ],
        [
          104.07440185546875,
          30.44744873046875
        ],
        [
          104.07373046875,
          30.44744873046875
        ],
        [
          104.07305908203125,
          30.4476318359375
        ],
        [
          104.0723876953125,
          30.4476318359375
        ],
        [
          104.07110595703125,
          30.44744873046875
        ],
        [
          104.07000732421875,
          30.44720458984375
        ],
        [
          104.0693359375,
          30.44720458984375
        ],
        [
          104.06842041015625,
          30.44696044921875
        ],
        [
          104.06732177734375,
          30.44696044921875
        ],
        [
          104.06646728515625,
          30.44677734375
        ],
        [
          104.06536865234375,
          30.446533203125
        ],
        [
          104.064697265625,
          30.446533203125
        ],
        [
          104.06341552734375,
          30.44635009765625
        ],
        [
          104.0625,
          30.44610595703125
        ],
        [
          104.06182861328125,
          30.4456787109375
        ],
        [
          104.06121826171875,
          30.4454345703125
        ],
        [
          104.060546875,
          30.44500732421875
        ],
        [
          104.0594482421875,
          30.44476318359375
        ],
        [
          104.058349609375,
          30.44476318359375
        ],
        [
          104.05767822265625,
          30.44476318359375
        ],
        [
          104.0570068359375,
          30.44476318359375
        ],
        [
          104.05633544921875,
          30.44476318359375
        ],
        [
          104.05548095703125,
          30.44476318359375
        ],
        [
          104.05413818359375,
          30.44500732421875
        ],
        [
          104.053466796875,
          30.44525146484375
        ],
        [
          104.0526123046875,
          30.4456787109375
        ],
        [
          104.05194091796875,
          30.44610595703125
        ],
        [
          104.05126953125,
          30.446533203125
        ],
        [
          104.05084228515625,
          30.44696044921875
        ],
        [
          104.0501708984375,
          30.4476318359375
        ],
        [
          104.04974365234375,
          30.44830322265625
        ],
        [
          104.049072265625,
          30.448974609375
        ],
        [
          104.04840087890625,
          30.4498291015625
        ],
        [
          104.04754638671875,
          30.45074462890625
        ],
        [
          104.04644775390625,
          30.451416015625
        ],
        [
          104.0457763671875,
          30.45159912109375
        ],
        [
          104.04510498046875,
          30.45159912109375
        ],
        [
          104.04449462890625,
          30.4520263671875
        ],
        [
          104.044677734375,
          30.453125
        ]
      ],
      [
        [
          104.04449462890625,
          30.4520263671875
        ],
        [
          104.04449462890625,
          30.451416015625
        ],
        [
          104.04425048828125,
          30.4503173828125
        ],
        [
          104.0438232421875,
          30.448974609375
        ],
        [
          104.04315185546875,
          30.44854736328125
        ],
        [
          104.04229736328125,
          30.4476318359375
        ],
        [
          104.04180908203125,
          30.44677734375
        ],
        [
          104.0416259765625,
          30.4454345703125
        ],
        [
          104.04095458984375,
          30.44476318359375
        ],
        [
          104.04052734375,
          30.44415283203125
        ],
        [
          104.03961181640625,
          30.44390869140625
        ],
        [
          104.03851318359375,
          30.44366455078125
        ],
        [
          104.037841796875,
          30.4434814453125
        ],
        [
          104.0372314453125,
          30.4434814453125
        ],
        [
          104.03656005859375,
          30.4434814453125
        ],
        [
          104.03546142578125,
          30.4434814453125
        ],
        [
          104.0345458984375,
          30.4432373046875
        ],
        [
          104.033935546875,
          30.44281005859375
        ],
        [
          104.03302001953125,
          30.442138671875
        ],
        [
          104.0328369140625,
          30.44146728515625
        ],
        [
          104.0323486328125,
          30.4410400390625
        ],
        [
          104.03192138671875,
          30.44061279296875
        ],
        [
          104.03125,
          30.44036865234375
        ],
        [
          104.03057861328125,
          30.44061279296875
        ],
        [
          104.02972412109375,
          30.44036865234375
        ],
        [
          104.02886962890625,
          30.43951416015625
        ],
        [
          104.0281982421875,
          30.4385986328125
        ],
        [
          104.02752685546875,
          30.43817138671875
        ],
        [
          104.02667236328125,
          30.4375
        ],
        [
          104.0260009765625,
          30.43707275390625
        ],
        [
          104.02532958984375,
          30.4368896484375
        ],
        [
          104.024658203125,
          30.4366455078125
        ],
        [
          104.02423095703125,
          30.43621826171875
        ],
        [
          104.0238037109375,
          30.435302734375
        ],
        [
          104.0235595703125,
          30.4342041015625
        ],
        [
          104.02337646484375,
          30.43359375
        ],
        [
          104.02313232421875,
          30.43267822265625
        ],
        [
          104.022705078125,
          30.4320068359375
        ],
        [
          104.02227783203125,
          30.431396484375
        ],
        [
          104.0216064453125,
          30.43072509765625
        ],
        [
          104.0213623046875,
          30.4300537109375
        ],
        [
          104.0211181640625,
          30.42938232421875
        ],
        [
          104.02069091796875,
          30.4287109375
        ],
        [
          104.02001953125,
          30.42803955078125
        ],
        [
          104.0194091796875,
          30.42718505859375
        ],
        [
          104.01873779296875,
          30.42694091796875
        ],
        [
          104.01806640625,
          30.426513671875
        ],
        [
          104.0169677734375,
          30.42633056640625
        ],
        [
          104.01629638671875,
          30.42584228515625
        ],
        [
          104.01519775390625,
          30.4256591796875
        ],
        [
          104.0145263671875,
          30.4254150390625
        ],
        [
          104.01409912109375,
          30.42474365234375
        ],
        [
          104.01324462890625,
          30.424560546875
        ],
        [
          104.0125732421875,
          30.42413330078125
        ],
        [
          104.01190185546875,
          30.42388916015625
        ],
        [
          104.01123046875,
          30.4234619140625
        ],
        [
          104.01055908203125,
          30.42254638671875
        ],
        [
          104.0101318359375,
          30.42169189453125
        ],
        [
          104.00970458984375,
          30.42059326171875
        ],
        [
          104.00970458984375,
          30.41925048828125
        ],
        [
          104.00994873046875,
          30.4185791015625
        ],
        [
          104.0101318359375,
          30.41796875
        ],
        [
          104.01055908203125,
          30.41748046875
        ],
        [
          104.01104736328125,
          30.4166259765625
        ],
        [
          104.01123046875,
          30.41595458984375
        ],
        [
          104.01171875,
          30.415283203125
        ],
        [
          104.0123291015625,
          30.4144287109375
        ],
        [
          104.0128173828125,
          30.41375732421875
        ],
        [
          104.0128173828125,
          30.41290283203125
        ],
        [
          104.0123291015625,
          30.4124755859375
        ],
        [
          104.0123291015625,
          30.41156005859375
        ],
        [
          104.01214599609375,
          30.410888671875
        ],
        [
          104.01190185546875,
          30.4102783203125
        ],
        [
          104.011474609375,
          30.40960693359375
        ],
        [
          104.0123291015625,
          30.40936279296875
        ],
        [
          104.013427734375,
          30.40869140625
        ],
        [
          104.01611328125,
          30.40802001953125
        ],
        [
          104.0172119140625,
          30.4075927734375
        ],
        [
          104.017822265625,
          30.40740966796875
        ],
        [
          104.01983642578125,
          30.406494140625
        ],
        [
          104.0205078125,
          30.40631103515625
        ],
        [
          104.02313232421875,
          30.40472412109375
        ],
        [
          104.02398681640625,
          30.404296875
        ],
        [
          104.0257568359375,
          30.4031982421875
        ],
        [
          104.027099609375,
          30.40277099609375
        ],
        [
          104.02752685546875,
          30.40234375
        ],
        [
          104.02947998046875,
          30.4012451171875
        ],
        [
          104.0303955078125,
          30.4010009765625
        ],
        [
          104.03106689453125,
          30.40032958984375
        ],
        [
          104.0325927734375,
          30.399658203125
        ],
        [
          104.033935546875,
          30.3988037109375
        ],
        [
          104.0350341796875,
          30.39837646484375
        ],
        [
          104.03564453125,
          30.39794921875
        ],
        [
          104.0367431640625,
          30.39727783203125
        ],
        [
          104.03851318359375,
          30.3963623046875
        ],
        [
          104.04071044921875,
          30.395263671875
        ],
        [
          104.04205322265625,
          30.3944091796875
        ],
        [
          104.042724609375,
          30.39398193359375
        ],
        [
          104.0438232421875,
          30.3935546875
        ],
        [
          104.044677734375,
          30.39288330078125
        ],
        [
          104.04559326171875,
          30.3924560546875
        ],
        [
          104.04620361328125,
          30.3922119140625
        ],
        [
          104.04754638671875,
          30.39154052734375
        ],
        [
          104.0479736328125,
          30.39111328125
        ],
        [
          104.049072265625,
          30.390869140625
        ],
        [
          104.04974365234375,
          30.39068603515625
        ],
        [
          104.0504150390625,
          30.39044189453125
        ],
        [
          104.05194091796875,
          30.3900146484375
        ],
        [
          104.0526123046875,
          30.3900146484375
        ],
        [
          104.053955078125,
          30.3897705078125
        ],
        [
          104.0545654296875,
          30.3897705078125
        ],
        [
          104.055908203125,
          30.3897705078125
        ],
        [
          104.0572509765625,
          30.3897705078125
        ],
        [
          104.0567626953125,
          30.38934326171875
        ],
        [
          104.05615234375,
          30.38848876953125
        ],
        [
          104.0556640625,
          30.38800048828125
        ],
        [
          104.05523681640625,
          30.3875732421875
        ],
        [
          104.05438232421875,
          30.38690185546875
        ],
        [
          104.053466796875,
          30.38629150390625
        ],
        [
          104.05303955078125,
          30.38580322265625
        ],
        [
          104.0523681640625,
          30.3853759765625
        ],
        [
          104.051513671875,
          30.384521484375
        ],
        [
          104.05108642578125,
          30.38409423828125
        ],
        [
          104.0504150390625,
          30.38360595703125
        ],
        [
          104.04949951171875,
          30.38275146484375
        ],
        [
          104.04864501953125,
          30.38232421875
        ],
        [
          104.0479736328125,
          30.38165283203125
        ],
        [
          104.04730224609375,
          30.38079833984375
        ],
        [
          104.047119140625,
          30.3798828125
        ],
        [
          104.04669189453125,
          30.37945556640625
        ],
        [
          104.04644775390625,
          30.3787841796875
        ],
        [
          104.0457763671875,
          30.37744140625
        ],
        [
          104.04534912109375,
          30.3765869140625
        ],
        [
          104.044921875,
          30.375732421875
        ],
        [
          104.04449462890625,
          30.37506103515625
        ],
        [
          104.0438232421875,
          30.3741455078125
        ],
        [
          104.042724609375,
          30.373291015625
        ],
        [
          104.04205322265625,
          30.37261962890625
        ],
        [
          104.0413818359375,
          30.3719482421875
        ],
        [
          104.04095458984375,
          30.37152099609375
        ],
        [
          104.04052734375,
          30.37109375
        ],
        [
          104.0400390625,
          30.37042236328125
        ],
        [
          104.03961181640625,
          30.36956787109375
        ],
        [
          104.03875732421875,
          30.36798095703125
        ],
        [
          104.038330078125,
          30.36737060546875
        ],
        [
          104.038330078125,
          30.366455078125
        ],
        [
          104.0380859375,
          30.36578369140625
        ],
        [
          104.03875732421875,
          30.3656005859375
        ],
        [
          104.04119873046875,
          30.3642578125
        ],
        [
          104.04248046875,
          30.36358642578125
        ],
        [
          104.0435791015625,
          30.3631591796875
        ],
        [
          104.044677734375,
          30.36248779296875
        ],
        [
          104.046875,
          30.36138916015625
        ],
        [
          104.04840087890625,
          30.36077880859375
        ],
        [
          104.0504150390625,
          30.359619140625
        ],
        [
          104.05126953125,
          30.35919189453125
        ],
        [
          104.05194091796875,
          30.3587646484375
        ],
        [
          104.0526123046875,
          30.3585205078125
        ],
        [
          104.05328369140625,
          30.35833740234375
        ],
        [
          104.05413818359375,
          30.35791015625
        ],
        [
          104.0548095703125,
          30.357666015625
        ],
        [
          104.05633544921875,
          30.35699462890625
        ],
        [
          104.05767822265625,
          30.3568115234375
        ],
        [
          104.0592041015625,
          30.3565673828125
        ],
        [
          104.060546875,
          30.35589599609375
        ],
        [
          104.0625,
          30.355712890625
        ],
        [
          104.06402587890625,
          30.355224609375
        ],
        [
          104.06890869140625,
          30.35369873046875
        ],
        [
          104.0726318359375,
          30.35150146484375
        ],
        [
          104.078125,
          30.3477783203125
        ],
        [
          104.08074951171875,
          30.34600830078125
        ],
        [
          104.08343505859375,
          30.344482421875
        ],
        [
          104.08624267578125,
          30.34356689453125
        ],
        [
          104.0906982421875,
          30.3431396484375
        ],
        [
          104.0950927734375,
          30.3431396484375
        ],
        [
          104.09967041015625,
          30.3431396484375
        ],
        [
          104.10321044921875,
          30.3431396484375
        ],
        [
          104.10455322265625,
          30.3431396484375
        ],
        [
          104.10540771484375,
          30.3431396484375
        ],
        [
          104.1060791015625,
          30.3431396484375
        ],
        [
          104.1082763671875,
          30.3431396484375
        ],
        [
          104.11004638671875,
          30.3431396484375
        ],
        [
          104.11199951171875,
          30.3431396484375
        ],
        [
          104.113525390625,
          30.3431396484375
        ],
        [
          104.1146240234375,
          30.3431396484375
        ],
        [
          104.115966796875,
          30.3433837890625
        ],
        [
          104.11663818359375,
          30.3433837890625
        ],
        [
          104.115966796875,
          30.34423828125
        ],
        [
          104.1151123046875,
          30.34600830078125
        ],
        [
          104.1146240234375,
          30.34686279296875
        ],
        [
          104.11419677734375,
          30.34820556640625
        ],
        [
          104.11376953125,
          30.348876953125
        ],
        [
          104.11334228515625,
          30.3499755859375
        ],
        [
          104.11309814453125,
          30.350830078125
        ],
        [
          104.1129150390625,
          30.35174560546875
        ],
        [
          104.1126708984375,
          30.35260009765625
        ],
        [
          104.1126708984375,
          30.353515625
        ],
        [
          104.1126708984375,
          30.3541259765625
        ],
        [
          104.1126708984375,
          30.35504150390625
        ],
        [
          104.1129150390625,
          30.35589599609375
        ],
        [
          104.1129150390625,
          30.3568115234375
        ],
        [
          104.11309814453125,
          30.357421875
        ],
        [
          104.113525390625,
          30.3585205078125
        ],
        [
          104.11376953125,
          30.35943603515625
        ],
        [
          104.11444091796875,
          30.36077880859375
        ],
        [
          104.1148681640625,
          30.36187744140625
        ],
        [
          104.11553955078125,
          30.36358642578125
        ],
        [
          104.11572265625,
          30.364501953125
        ],
        [
          104.115966796875,
          30.3653564453125
        ],
        [
          104.1162109375,
          30.36602783203125
        ],
        [
          104.1162109375,
          30.36688232421875
        ],
        [
          104.11639404296875,
          30.3677978515625
        ],
        [
          104.11639404296875,
          30.36846923828125
        ],
        [
          104.1162109375,
          30.36956787109375
        ],
        [
          104.1162109375,
          30.37066650390625
        ],
        [
          104.1162109375,
          30.3719482421875
        ],
        [
          104.115966796875,
          30.37353515625
        ],
        [
          104.11572265625,
          30.3741455078125
        ],
        [
          104.115966796875,
          30.37481689453125
        ],
        [
          104.1162109375,
          30.375732421875
        ],
        [
          104.11572265625,
          30.37548828125
        ]
      ]
    ]
  },
  {
    coords: [
      [
        104.0211181640625,
        30.73101806640625
      ],
      [
        104.02203369140625,
        30.7303466796875
      ],
      [
        104.0224609375,
        30.72967529296875
      ],
      [
        104.02313232421875,
        30.72882080078125
      ],
      [
        104.02398681640625,
        30.72772216796875
      ],
      [
        104.024658203125,
        30.726806640625
      ],
      [
        104.02508544921875,
        30.72637939453125
      ],
      [
        104.02557373046875,
        30.7259521484375
      ],
      [
        104.0260009765625,
        30.72528076171875
      ],
      [
        104.02618408203125,
        30.724609375
      ],
      [
        104.02667236328125,
        30.72418212890625
      ],
      [
        104.02752685546875,
        30.72332763671875
      ],
      [
        104.02862548828125,
        30.72174072265625
      ],
      [
        104.029052734375,
        30.72113037109375
      ],
      [
        104.02947998046875,
        30.72064208984375
      ],
      [
        104.0301513671875,
        30.72003173828125
      ],
      [
        104.03082275390625,
        30.71893310546875
      ],
      [
        104.03173828125,
        30.718017578125
      ],
      [
        104.03216552734375,
        30.7171630859375
      ],
      [
        104.0325927734375,
        30.71673583984375
      ],
      [
        104.03302001953125,
        30.716064453125
      ],
      [
        104.033935546875,
        30.7147216796875
      ],
      [
        104.0345458984375,
        30.71405029296875
      ],
      [
        104.0350341796875,
        30.71337890625
      ],
      [
        104.03564453125,
        30.71295166015625
      ],
      [
        104.0361328125,
        30.7122802734375
      ],
      [
        104.03741455078125,
        30.7103271484375
      ],
      [
        104.0380859375,
        30.70947265625
      ],
      [
        104.03875732421875,
        30.70880126953125
      ],
      [
        104.0391845703125,
        30.7083740234375
      ],
      [
        104.03961181640625,
        30.70745849609375
      ],
      [
        104.040283203125,
        30.70703125
      ],
      [
        104.04071044921875,
        30.7061767578125
      ],
      [
        104.0413818359375,
        30.70550537109375
      ],
      [
        104.04180908203125,
        30.704833984375
      ],
      [
        104.042724609375,
        30.7037353515625
      ],
      [
        104.04339599609375,
        30.70306396484375
      ],
      [
        104.0435791015625,
        30.702392578125
      ],
      [
        104.04400634765625,
        30.70172119140625
      ],
      [
        104.044677734375,
        30.70086669921875
      ],
      [
        104.04559326171875,
        30.70001220703125
      ],
      [
        104.0460205078125,
        30.6990966796875
      ],
      [
        104.04669189453125,
        30.6982421875
      ],
      [
        104.04730224609375,
        30.69732666015625
      ],
      [
        104.0479736328125,
        30.69622802734375
      ],
      [
        104.04840087890625,
        30.6949462890625
      ],
      [
        104.04840087890625,
        30.69403076171875
      ],
      [
        104.04840087890625,
        30.69317626953125
      ],
      [
        104.04864501953125,
        30.6922607421875
      ],
      [
        104.04888916015625,
        30.69140625
      ],
      [
        104.04888916015625,
        30.69073486328125
      ],
      [
        104.049072265625,
        30.68988037109375
      ],
      [
        104.04949951171875,
        30.68878173828125
      ],
      [
        104.04974365234375,
        30.68768310546875
      ],
      [
        104.04974365234375,
        30.68701171875
      ],
      [
        104.0501708984375,
        30.6859130859375
      ],
      [
        104.0504150390625,
        30.68505859375
      ],
      [
        104.05059814453125,
        30.68414306640625
      ],
      [
        104.05084228515625,
        30.6834716796875
      ],
      [
        104.0504150390625,
        30.6856689453125
      ],
      [
        104.0504150390625,
        30.68505859375
      ],
      [
        104.05059814453125,
        30.68414306640625
      ],
      [
        104.05084228515625,
        30.6834716796875
      ],
      [
        104.051513671875,
        30.6826171875
      ],
      [
        104.0523681640625,
        30.68194580078125
      ],
      [
        104.053466796875,
        30.6815185546875
      ],
      [
        104.05413818359375,
        30.6812744140625
      ],
      [
        104.0550537109375,
        30.680419921875
      ],
      [
        104.0556640625,
        30.67999267578125
      ],
      [
        104.0567626953125,
        30.6793212890625
      ],
      [
        104.05767822265625,
        30.67889404296875
      ],
      [
        104.05853271484375,
        30.67840576171875
      ],
      [
        104.0594482421875,
        30.677978515625
      ],
      [
        104.06097412109375,
        30.67730712890625
      ],
      [
        104.06182861328125,
        30.6768798828125
      ],
      [
        104.0625,
        30.67669677734375
      ],
      [
        104.06341552734375,
        30.67620849609375
      ],
      [
        104.06402587890625,
        30.67578125
      ],
      [
        104.064697265625,
        30.67535400390625
      ],
      [
        104.06536865234375,
        30.67510986328125
      ],
      [
        104.06512451171875,
        30.6744384765625
      ],
      [
        104.064697265625,
        30.67401123046875
      ],
      [
        104.06402587890625,
        30.67291259765625
      ],
      [
        104.0635986328125,
        30.6722412109375
      ],
      [
        104.06292724609375,
        30.671630859375
      ],
      [
        104.06317138671875,
        30.6705322265625
      ],
      [
        104.06317138671875,
        30.66961669921875
      ],
      [
        104.06317138671875,
        30.6689453125
      ],
      [
        104.06317138671875,
        30.6680908203125
      ],
      [
        104.06317138671875,
        30.666748046875
      ],
      [
        104.06341552734375,
        30.66546630859375
      ],
      [
        104.06341552734375,
        30.65863037109375
      ],
      [
        104.06341552734375,
        30.65777587890625
      ],
      [
        104.06341552734375,
        30.6571044921875
      ],
      [
        104.0635986328125,
        30.65643310546875
      ],
      [
        104.0635986328125,
        30.65533447265625
      ],
      [
        104.0635986328125,
        30.65399169921875
      ],
      [
        104.0635986328125,
        30.6533203125
      ],
      [
        104.0635986328125,
        30.6524658203125
      ],
      [
        104.0635986328125,
        30.65179443359375
      ],
      [
        104.0635986328125,
        30.65093994140625
      ],
      [
        104.0635986328125,
        30.6500244140625
      ],
      [
        104.0635986328125,
        30.6494140625
      ],
      [
        104.0638427734375,
        30.64874267578125
      ],
      [
        104.0638427734375,
        30.6480712890625
      ],
      [
        104.0638427734375,
        30.64739990234375
      ],
      [
        104.0638427734375,
        30.646728515625
      ],
      [
        104.0638427734375,
        30.6461181640625
      ],
      [
        104.0638427734375,
        30.64495849609375
      ],
      [
        104.0638427734375,
        30.6434326171875
      ],
      [
        104.06402587890625,
        30.64166259765625
      ],
      [
        104.06402587890625,
        30.63995361328125
      ],
      [
        104.06402587890625,
        30.63861083984375
      ],
      [
        104.06402587890625,
        30.63751220703125
      ],
      [
        104.06402587890625,
        30.63641357421875
      ],
      [
        104.06341552734375,
        30.635986328125
      ],
      [
        104.062744140625,
        30.635986328125
      ],
      [
        104.06182861328125,
        30.635986328125
      ],
      [
        104.06072998046875,
        30.635986328125
      ],
      [
        104.06005859375,
        30.635986328125
      ],
      [
        104.05877685546875,
        30.6357421875
      ],
      [
        104.05767822265625,
        30.6357421875
      ],
      [
        104.0570068359375,
        30.6357421875
      ],
      [
        104.05633544921875,
        30.6357421875
      ],
      [
        104.0550537109375,
        30.6357421875
      ],
      [
        104.053466796875,
        30.6357421875
      ],
      [
        104.0526123046875,
        30.6357421875
      ],
      [
        104.0517578125,
        30.63616943359375
      ],
      [
        104.05059814453125,
        30.63665771484375
      ],
      [
        104.04998779296875,
        30.6370849609375
      ],
      [
        104.0479736328125,
        30.637939453125
      ],
      [
        104.04730224609375,
        30.63818359375
      ],
      [
        104.04620361328125,
        30.63885498046875
      ],
      [
        104.044921875,
        30.63946533203125
      ],
      [
        104.04400634765625,
        30.63970947265625
      ],
      [
        104.04315185546875,
        30.640380859375
      ],
      [
        104.04229736328125,
        30.64056396484375
      ],
      [
        104.04180908203125,
        30.64105224609375
      ],
      [
        104.04119873046875,
        30.6414794921875
      ],
      [
        104.04052734375,
        30.642333984375
      ],
      [
        104.0400390625,
        30.64276123046875
      ],
      [
        104.03961181640625,
        30.6434326171875
      ],
      [
        104.0389404296875,
        30.64434814453125
      ],
      [
        104.03741455078125,
        30.64385986328125
      ],
      [
        104.03631591796875,
        30.6434326171875
      ],
      [
        104.03546142578125,
        30.64300537109375
      ],
      [
        104.03436279296875,
        30.64215087890625
      ],
      [
        104.033447265625,
        30.64166259765625
      ],
      [
        104.03302001953125,
        30.6412353515625
      ],
      [
        104.0323486328125,
        30.64080810546875
      ],
      [
        104.03192138671875,
        30.640380859375
      ],
      [
        104.03125,
        30.63995361328125
      ],
      [
        104.03082275390625,
        30.63946533203125
      ],
      [
        104.0301513671875,
        30.6390380859375
      ],
      [
        104.029296875,
        30.63836669921875
      ],
      [
        104.02862548828125,
        30.637939453125
      ],
      [
        104.0279541015625,
        30.63775634765625
      ],
      [
        104.02728271484375,
        30.63726806640625
      ],
      [
        104.02642822265625,
        30.6368408203125
      ],
      [
        104.02557373046875,
        30.63641357421875
      ],
      [
        104.02490234375,
        30.63531494140625
      ],
      [
        104.02447509765625,
        30.63421630859375
      ],
      [
        104.02398681640625,
        30.63330078125
      ],
      [
        104.0238037109375,
        30.6324462890625
      ],
      [
        104.02337646484375,
        30.63177490234375
      ],
      [
        104.0224609375,
        30.631103515625
      ],
      [
        104.02203369140625,
        30.63067626953125
      ],
      [
        104.0216064453125,
        30.6300048828125
      ],
      [
        104.0205078125,
        30.629150390625
      ],
      [
        104.01983642578125,
        30.62847900390625
      ],
      [
        104.0191650390625,
        30.6280517578125
      ],
      [
        104.01849365234375,
        30.62738037109375
      ],
      [
        104.018310546875,
        30.626953125
      ],
      [
        104.0172119140625,
        30.6256103515625
      ],
      [
        104.01654052734375,
        30.624755859375
      ],
      [
        104.015869140625,
        30.62408447265625
      ],
      [
        104.01519775390625,
        30.6234130859375
      ],
      [
        104.0145263671875,
        30.62255859375
      ],
      [
        104.01409912109375,
        30.62213134765625
      ],
      [
        104.013427734375,
        30.6214599609375
      ],
      [
        104.0123291015625,
        30.62054443359375
      ],
      [
        104.01190185546875,
        30.6201171875
      ],
      [
        104.01123046875,
        30.62054443359375
      ],
      [
        104.01055908203125,
        30.6212158203125
      ],
      [
        104.00994873046875,
        30.6214599609375
      ],
      [
        104.009033203125,
        30.622314453125
      ],
      [
        104.00836181640625,
        30.62255859375
      ],
      [
        104.00775146484375,
        30.62322998046875
      ],
      [
        104.0068359375,
        30.6236572265625
      ],
      [
        104.0057373046875,
        30.62451171875
      ],
      [
        104.00445556640625,
        30.62542724609375
      ],
      [
        104.0035400390625,
        30.6260986328125
      ],
      [
        104.00286865234375,
        30.62628173828125
      ],
      [
        104.00201416015625,
        30.627197265625
      ],
      [
        104.000244140625,
        30.6282958984375
      ],
      [
        103.9993896484375,
        30.62890625
      ],
      [
        103.99871826171875,
        30.62957763671875
      ],
      [
        103.998046875,
        30.6302490234375
      ],
      [
        103.99761962890625,
        30.63067626953125
      ],
      [
        103.9971923828125,
        30.631591796875
      ],
      [
        103.99652099609375,
        30.6322021484375
      ],
      [
        103.995849609375,
        30.63311767578125
      ],
      [
        103.99542236328125,
        30.6337890625
      ],
      [
        103.9947509765625,
        30.6346435546875
      ],
      [
        103.99432373046875,
        30.63507080078125
      ],
      [
        103.993896484375,
        30.635986328125
      ],
      [
        103.99322509765625,
        30.63726806640625
      ],
      [
        103.9912109375,
        30.640380859375
      ],
      [
        103.989013671875,
        30.6434326171875
      ],
      [
        103.98681640625,
        30.64630126953125
      ],
      [
        103.98486328125,
        30.649169921875
      ],
      [
        103.98419189453125,
        30.6500244140625
      ],
      [
        103.9837646484375,
        30.65069580078125
      ],
      [
        103.98333740234375,
        30.6513671875
      ],
      [
        103.98284912109375,
        30.65203857421875
      ],
      [
        103.982421875,
        30.65289306640625
      ],
      [
        103.98199462890625,
        30.653564453125
      ],
      [
        103.98175048828125,
        30.6546630859375
      ],
      [
        103.9815673828125,
        30.65576171875
      ],
      [
        103.9815673828125,
        30.65643310546875
      ],
      [
        103.9815673828125,
        30.6571044921875
      ],
      [
        103.98175048828125,
        30.657958984375
      ],
      [
        103.98175048828125,
        30.65887451171875
      ],
      [
        103.98199462890625,
        30.65948486328125
      ],
      [
        103.98199462890625,
        30.660400390625
      ],
      [
        103.98223876953125,
        30.66107177734375
      ],
      [
        103.98223876953125,
        30.66192626953125
      ],
      [
        103.982421875,
        30.66259765625
      ],
      [
        103.98309326171875,
        30.66302490234375
      ],
      [
        103.98309326171875,
        30.6634521484375
      ],
      [
        103.98333740234375,
        30.66412353515625
      ],
      [
        103.9837646484375,
        30.66546630859375
      ],
      [
        103.98419189453125,
        30.66656494140625
      ],
      [
        103.98443603515625,
        30.6669921875
      ],
      [
        103.984619140625,
        30.66766357421875
      ],
      [
        103.98504638671875,
        30.66851806640625
      ],
      [
        103.98529052734375,
        30.66943359375
      ],
      [
        103.98553466796875,
        30.6700439453125
      ],
      [
        103.9857177734375,
        30.67071533203125
      ],
      [
        103.984619140625,
        30.67095947265625
      ],
      [
        103.9837646484375,
        30.67095947265625
      ],
      [
        103.98284912109375,
        30.67095947265625
      ],
      [
        103.98223876953125,
        30.671142578125
      ],
      [
        103.9810791015625,
        30.67138671875
      ],
      [
        103.9791259765625,
        30.671630859375
      ],
      [
        103.978271484375,
        30.67181396484375
      ],
      [
        103.97760009765625,
        30.67181396484375
      ],
      [
        103.975830078125,
        30.671630859375
      ],
      [
        103.97406005859375,
        30.67138671875
      ],
      [
        103.973876953125,
        30.6722412109375
      ],
      [
        103.973876953125,
        30.67291259765625
      ],
      [
        103.973876953125,
        30.673828125
      ],
      [
        103.9736328125,
        30.6744384765625
      ],
      [
        103.973388671875,
        30.67559814453125
      ],
      [
        103.97320556640625,
        30.67669677734375
      ],
      [
        103.9725341796875,
        30.6768798828125
      ],
      [
        103.97119140625,
        30.67730712890625
      ],
      [
        103.9703369140625,
        30.67755126953125
      ],
      [
        103.96942138671875,
        30.67779541015625
      ],
      [
        103.96856689453125,
        30.677978515625
      ],
      [
        103.9678955078125,
        30.67822265625
      ],
      [
        103.967041015625,
        30.67840576171875
      ],
      [
        103.9656982421875,
        30.67889404296875
      ],
      [
        103.96441650390625,
        30.6793212890625
      ],
      [
        103.9635009765625,
        30.67950439453125
      ],
      [
        103.96282958984375,
        30.67974853515625
      ],
      [
        103.9615478515625,
        30.68017578125
      ],
      [
        103.9593505859375,
        30.68084716796875
      ],
      [
        103.95867919921875,
        30.68109130859375
      ],
      [
        103.957763671875,
        30.68109130859375
      ],
      [
        103.95733642578125,
        30.6815185546875
      ],
      [
        103.95623779296875,
        30.68170166015625
      ],
      [
        103.95556640625,
        30.68194580078125
      ],
      [
        103.9547119140625,
        30.68218994140625
      ],
      [
        103.95404052734375,
        30.682373046875
      ],
      [
        103.953369140625,
        30.6826171875
      ],
      [
        103.9522705078125,
        30.68328857421875
      ],
      [
        103.951416015625,
        30.6834716796875
      ],
      [
        103.95074462890625,
        30.6837158203125
      ],
      [
        103.9500732421875,
        30.68414306640625
      ],
      [
        103.94940185546875,
        30.6845703125
      ],
      [
        103.94879150390625,
        30.684814453125
      ],
      [
        103.9478759765625,
        30.68548583984375
      ],
      [
        103.94677734375,
        30.68634033203125
      ],
      [
        103.9459228515625,
        30.686767578125
      ],
      [
        103.94500732421875,
        30.68743896484375
      ],
      [
        103.94415283203125,
        30.68768310546875
      ],
      [
        103.9434814453125,
        30.6878662109375
      ],
      [
        103.94305419921875,
        30.6883544921875
      ],
      [
        103.94085693359375,
        30.689453125
      ],
      [
        103.93994140625,
        30.68963623046875
      ],
      [
        103.9393310546875,
        30.68988037109375
      ],
      [
        103.93865966796875,
        30.6900634765625
      ],
      [
        103.937744140625,
        30.6903076171875
      ],
      [
        103.9368896484375,
        30.69073486328125
      ],
      [
        103.935791015625,
        30.691162109375
      ],
      [
        103.93511962890625,
        30.691650390625
      ],
      [
        103.9300537109375,
        30.6944580078125
      ],
      [
        103.92828369140625,
        30.69512939453125
      ],
      [
        103.92657470703125,
        30.696044921875
      ],
      [
        103.92547607421875,
        30.69622802734375
      ],
      [
        103.92437744140625,
        30.69671630859375
      ],
      [
        103.92327880859375,
        30.69671630859375
      ],
      [
        103.922607421875,
        30.6968994140625
      ],
      [
        103.9212646484375,
        30.6968994140625
      ],
      [
        103.92041015625,
        30.6971435546875
      ],
      [
        103.91864013671875,
        30.69732666015625
      ],
      [
        103.91796875,
        30.69732666015625
      ],
      [
        103.9171142578125,
        30.69757080078125
      ],
      [
        103.91644287109375,
        30.69781494140625
      ],
      [
        103.91619873046875,
        30.69842529296875
      ],
      [
        103.916015625,
        30.6990966796875
      ],
      [
        103.916015625,
        30.69952392578125
      ],
      [
        103.91552734375,
        30.7001953125
      ],
      [
        103.91534423828125,
        30.70086669921875
      ],
      [
        103.91510009765625,
        30.7015380859375
      ],
      [
        103.91510009765625,
        30.70220947265625
      ],
      [
        103.9149169921875,
        30.70281982421875
      ],
      [
        103.9144287109375,
        30.70416259765625
      ],
      [
        103.91400146484375,
        30.70550537109375
      ],
      [
        103.91290283203125,
        30.70855712890625
      ],
      [
        103.91265869140625,
        30.70965576171875
      ],
      [
        103.91204833984375,
        30.71099853515625
      ],
      [
        103.91180419921875,
        30.711669921875
      ],
      [
        103.91156005859375,
        30.7125244140625
      ],
      [
        103.911376953125,
        30.71337890625
      ],
      [
        103.9111328125,
        30.71405029296875
      ],
      [
        103.91070556640625,
        30.71514892578125
      ],
      [
        103.9102783203125,
        30.71624755859375
      ],
      [
        103.9102783203125,
        30.7169189453125
      ],
      [
        103.90985107421875,
        30.71783447265625
      ],
      [
        103.90936279296875,
        30.71893310546875
      ],
      [
        103.908935546875,
        30.71978759765625
      ],
      [
        103.90850830078125,
        30.72021484375
      ],
      [
        103.90826416015625,
        30.72113037109375
      ],
      [
        103.9078369140625,
        30.72174072265625
      ],
      [
        103.90716552734375,
        30.72332763671875
      ],
      [
        103.90655517578125,
        30.724853515625
      ],
      [
        103.9058837890625,
        30.7261962890625
      ],
      [
        103.90496826171875,
        30.72772216796875
      ],
      [
        103.90478515625,
        30.72857666015625
      ],
      [
        103.90521240234375,
        30.72900390625
      ],
      [
        103.90606689453125,
        30.7294921875
      ],
      [
        103.90716552734375,
        30.72967529296875
      ],
      [
        103.9080810546875,
        30.7303466796875
      ],
      [
        103.908935546875,
        30.731201171875
      ],
      [
        103.90936279296875,
        30.731689453125
      ],
      [
        103.90985107421875,
        30.7322998046875
      ],
      [
        103.91046142578125,
        30.73297119140625
      ],
      [
        103.911376953125,
        30.73388671875
      ],
      [
        103.91180419921875,
        30.73455810546875
      ],
      [
        103.91265869140625,
        30.73541259765625
      ],
      [
        103.913330078125,
        30.736083984375
      ],
      [
        103.913818359375,
        30.73651123046875
      ],
      [
        103.91424560546875,
        30.7369384765625
      ],
      [
        103.9146728515625,
        30.73760986328125
      ],
      [
        103.91510009765625,
        30.738037109375
      ],
      [
        103.91552734375,
        30.73846435546875
      ],
      [
        103.91619873046875,
        30.7393798828125
      ],
      [
        103.9171142578125,
        30.74005126953125
      ],
      [
        103.91796875,
        30.74114990234375
      ],
      [
        103.91729736328125,
        30.74176025390625
      ],
      [
        103.9168701171875,
        30.742431640625
      ],
      [
        103.91619873046875,
        30.74285888671875
      ],
      [
        103.91552734375,
        30.7435302734375
      ],
      [
        103.9149169921875,
        30.74444580078125
      ],
      [
        103.91400146484375,
        30.744873046875
      ],
      [
        103.913818359375,
        30.7459716796875
      ],
      [
        103.91357421875,
        30.7470703125
      ],
      [
        103.91357421875,
        30.7479248046875
      ],
      [
        103.9144287109375,
        30.74884033203125
      ],
      [
        103.91510009765625,
        30.749267578125
      ],
      [
        103.915771484375,
        30.74969482421875
      ],
      [
        103.9166259765625,
        30.7501220703125
      ],
      [
        103.91729736328125,
        30.7503662109375
      ],
      [
        103.917724609375,
        30.75079345703125
      ],
      [
        103.919921875,
        30.75323486328125
      ],
      [
        103.9210205078125,
        30.75457763671875
      ],
      [
        103.92169189453125,
        30.75518798828125
      ],
      [
        103.92303466796875,
        30.75677490234375
      ],
      [
        103.9237060546875,
        30.75738525390625
      ],
      [
        103.92413330078125,
        30.758056640625
      ],
      [
        103.9248046875,
        30.75872802734375
      ],
      [
        103.92523193359375,
        30.7591552734375
      ],
      [
        103.9267578125,
        30.76092529296875
      ],
      [
        103.92742919921875,
        30.76177978515625
      ],
      [
        103.92852783203125,
        30.76287841796875
      ],
      [
        103.928955078125,
        30.7635498046875
      ],
      [
        103.92962646484375,
        30.76422119140625
      ],
      [
        103.93096923828125,
        30.76556396484375
      ],
      [
        103.9324951171875,
        30.767333984375
      ],
      [
        103.93621826171875,
        30.771484375
      ],
      [
        103.9371337890625,
        30.7725830078125
      ],
      [
        103.937744140625,
        30.77301025390625
      ],
      [
        103.938232421875,
        30.77349853515625
      ],
      [
        103.93841552734375,
        30.77410888671875
      ],
      [
        103.9393310546875,
        30.7750244140625
      ],
      [
        103.93994140625,
        30.77587890625
      ],
      [
        103.9410400390625,
        30.77520751953125
      ],
      [
        103.94195556640625,
        30.77459716796875
      ],
      [
        103.94281005859375,
        30.77410888671875
      ],
      [
        103.94390869140625,
        30.77349853515625
      ],
      [
        103.944580078125,
        30.77301025390625
      ],
      [
        103.94549560546875,
        30.7725830078125
      ],
      [
        103.94659423828125,
        30.77215576171875
      ],
      [
        103.94744873046875,
        30.77191162109375
      ],
      [
        103.94830322265625,
        30.771728515625
      ],
      [
        103.94989013671875,
        30.771728515625
      ],
      [
        103.95098876953125,
        30.771728515625
      ],
      [
        103.95159912109375,
        30.771484375
      ],
      [
        103.9527587890625,
        30.771240234375
      ],
      [
        103.95361328125,
        30.77081298828125
      ],
      [
        103.9544677734375,
        30.7703857421875
      ],
      [
        103.95538330078125,
        30.7701416015625
      ],
      [
        103.95623779296875,
        30.77081298828125
      ],
      [
        103.9569091796875,
        30.771484375
      ],
      [
        103.957763671875,
        30.7723388671875
      ],
      [
        103.9588623046875,
        30.77301025390625
      ],
      [
        103.9593505859375,
        30.773681640625
      ],
      [
        103.96044921875,
        30.7747802734375
      ],
      [
        103.9610595703125,
        30.77520751953125
      ],
      [
        103.96173095703125,
        30.77587890625
      ],
      [
        103.962646484375,
        30.77679443359375
      ],
      [
        103.9637451171875,
        30.77740478515625
      ],
      [
        103.964599609375,
        30.77789306640625
      ],
      [
        103.96527099609375,
        30.7783203125
      ],
      [
        103.9659423828125,
        30.77874755859375
      ],
      [
        103.96661376953125,
        30.77899169921875
      ],
      [
        103.96746826171875,
        30.7794189453125
      ],
      [
        103.9681396484375,
        30.77960205078125
      ],
      [
        103.96942138671875,
        30.78009033203125
      ],
      [
        103.97052001953125,
        30.7802734375
      ],
      [
        103.971435546875,
        30.780517578125
      ],
      [
        103.9722900390625,
        30.78070068359375
      ],
      [
        103.97320556640625,
        30.78070068359375
      ],
      [
        103.97406005859375,
        30.78094482421875
      ],
      [
        103.97515869140625,
        30.78118896484375
      ],
      [
        103.975830078125,
        30.78118896484375
      ],
      [
        103.9766845703125,
        30.7813720703125
      ],
      [
        103.97760009765625,
        30.7813720703125
      ],
      [
        103.97869873046875,
        30.7816162109375
      ],
      [
        103.97955322265625,
        30.7816162109375
      ],
      [
        103.980224609375,
        30.78179931640625
      ],
      [
        103.9813232421875,
        30.78179931640625
      ],
      [
        103.982421875,
        30.78204345703125
      ],
      [
        103.98394775390625,
        30.78228759765625
      ],
      [
        103.98504638671875,
        30.78228759765625
      ],
      [
        103.9857177734375,
        30.78179931640625
      ],
      [
        103.98614501953125,
        30.7813720703125
      ],
      [
        103.98663330078125,
        30.78070068359375
      ],
      [
        103.98724365234375,
        30.7802734375
      ],
      [
        103.98748779296875,
        30.77960205078125
      ],
      [
        103.98773193359375,
        30.77899169921875
      ],
      [
        103.9879150390625,
        30.7783203125
      ],
      [
        103.98834228515625,
        30.77740478515625
      ],
      [
        103.98858642578125,
        30.77679443359375
      ],
      [
        103.989013671875,
        30.77630615234375
      ],
      [
        103.98944091796875,
        30.77587890625
      ],
      [
        103.9903564453125,
        30.7750244140625
      ],
      [
        103.9912109375,
        30.77410888671875
      ],
      [
        103.99163818359375,
        30.77349853515625
      ],
      [
        103.99188232421875,
        30.7725830078125
      ],
      [
        103.9923095703125,
        30.771728515625
      ],
      [
        103.9925537109375,
        30.77105712890625
      ],
      [
        103.99298095703125,
        30.76995849609375
      ],
      [
        103.99322509765625,
        30.76904296875
      ],
      [
        103.99365234375,
        30.7679443359375
      ],
      [
        103.993896484375,
        30.76708984375
      ],
      [
        103.99432373046875,
        30.7662353515625
      ],
      [
        103.9945068359375,
        30.76531982421875
      ],
      [
        103.9949951171875,
        30.76446533203125
      ],
      [
        103.99517822265625,
        30.7635498046875
      ],
      [
        103.99542236328125,
        30.7626953125
      ],
      [
        103.99560546875,
        30.76202392578125
      ],
      [
        103.99609375,
        30.7613525390625
      ],
      [
        103.99609375,
        30.76068115234375
      ],
      [
        103.99627685546875,
        30.76007080078125
      ],
      [
        103.99627685546875,
        30.75897216796875
      ],
      [
        103.99627685546875,
        30.75830078125
      ],
      [
        103.99652099609375,
        30.75738525390625
      ],
      [
        103.9967041015625,
        30.75677490234375
      ],
      [
        103.9969482421875,
        30.755859375
      ],
      [
        103.9971923828125,
        30.75518798828125
      ],
      [
        103.997802734375,
        30.75457763671875
      ],
      [
        103.998046875,
        30.75390625
      ],
      [
        103.99847412109375,
        30.75341796875
      ],
      [
        103.9991455078125,
        30.75323486328125
      ],
      [
        103.99957275390625,
        30.7523193359375
      ],
      [
        104.00067138671875,
        30.75146484375
      ],
      [
        104.0013427734375,
        30.75079345703125
      ],
      [
        104.00177001953125,
        30.7503662109375
      ],
      [
        104.00225830078125,
        30.74993896484375
      ],
      [
        104.00286865234375,
        30.74951171875
      ],
      [
        104.00335693359375,
        30.7490234375
      ],
      [
        104.0037841796875,
        30.74859619140625
      ],
      [
        104.0048828125,
        30.7479248046875
      ],
      [
        104.00555419921875,
        30.747314453125
      ],
      [
        104.00616455078125,
        30.746826171875
      ],
      [
        104.0068359375,
        30.7462158203125
      ],
      [
        104.00750732421875,
        30.7459716796875
      ],
      [
        104.0081787109375,
        30.74530029296875
      ],
      [
        104.00885009765625,
        30.74462890625
      ],
      [
        104.00946044921875,
        30.74420166015625
      ],
      [
        104.0101318359375,
        30.7437744140625
      ],
      [
        104.01080322265625,
        30.74334716796875
      ],
      [
        104.01123046875,
        30.74285888671875
      ],
      [
        104.01171875,
        30.742431640625
      ],
      [
        104.0123291015625,
        30.74176025390625
      ],
      [
        104.0128173828125,
        30.7413330078125
      ],
      [
        104.01324462890625,
        30.74066162109375
      ],
      [
        104.013427734375,
        30.74005126953125
      ],
      [
        104.013916015625,
        30.73956298828125
      ],
      [
        104.0145263671875,
        30.73895263671875
      ],
      [
        104.0150146484375,
        30.738037109375
      ],
      [
        104.01519775390625,
        30.73736572265625
      ],
      [
        104.015625,
        30.7369384765625
      ],
      [
        104.01611328125,
        30.73651123046875
      ],
      [
        104.01654052734375,
        30.73583984375
      ],
      [
        104.0172119140625,
        30.73541259765625
      ],
      [
        104.017822265625,
        30.7347412109375
      ],
      [
        104.01849365234375,
        30.73388671875
      ],
      [
        104.0191650390625,
        30.733642578125
      ],
      [
        104.020263671875,
        30.7325439453125
      ],
      [
        104.02069091796875,
        30.73211669921875
      ],
      [
        104.0211181640625,
        30.73101806640625
      ],
      [
        104.02178955078125,
        30.7303466796875
      ],
      [
        104.02203369140625,
        30.7303466796875
      ]
    ]
  }
]
