<template>
  <component :is="getAreaDatav"></component>
</template>

<script>
import datav from './bigscreen/components/datav/index.vue'
import datav_chengdu from './bigscreen/components/datav/index_chengdu.vue'
import { loadData, stg } from '@/util/index'

export default {
  name: 'dashboard',
  components: {
    'datav': datav,
    'datav-5101': datav_chengdu
  },
  data() {
    return {
      areaDatav: '',
      areaCode: ''
    }
  },
  computed: {
    getAreaDatav() {
      // 行政区划前两位为省，3、4位为市，因此判断前4位即可
      const code = this.areaCode.substring(0, 4)
      switch (code) {
        case '5101':
          return 'datav-' + code
        default:
          return 'datav'
      }
    }
  },
  created() {
    // 根据areaCode渲染不同的组件
    this.areaCode = stg().getItem('areaCode')
    loadData(this)
  }
}
</script>
<style lang="scss">
#data-view {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0
}
</style>
