<template>
  <div class="online_rate">
    <dec-block-title> 在线率TOP5(%)</dec-block-title>
    <dv-charts @click.native="handleClick" style="cursor: pointer" :option="option"/>
  </div>
</template>

<script>
import decBlockTitle from '../../decorations/blockTitle2'
import {isEmptyObj} from "@/util";
// import { queryToMD5 } from "@/utils/md5_query"

export default {
  name: 'onlineRate_chengdu',
  components: {
    decBlockTitle
  },
  props: {
    showMore: {
      type: Boolean,
    },
    topList: {
      type: Object,
      default: () => {
        return {}
      }
    },
    onlineDevice: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      option: {},
      onlineRateBar: [], // 昨日在线率(所有)
      allOnlineRate: [],
      allOnlineRateIntime: [],
      loading: true
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      if (isEmptyObj(this.onlineDevice) || isEmptyObj(this.topList))
        return
      this.onlineRateBar = []
      // 昨日在线率
      this.onlineRateBar = []
      this.topList.online.map((item) => {
        let it = {}
        this.onlineDevice.device.map((vo) => {
          if (item.K.split('/').join('') === vo.K.split('/').join('')) {
            it.V = vo.V === 0 ? 0 : ((item.V * 100) / vo.V).toFixed(2) * 1
            it.N = vo.N
          }
        })
        this.onlineRateBar.push(it)
      })
      this.onlineRateBar.sort((o1, o2) => {
        if (o1.V < o2.V) {
          return 1
        } else if (o1.V > o2.V) {
          return -1
        } else {
          return 0
        }
      })

      this.allOnlineRate = []
      this.topList.online.map((item) => {
        let it = {}
        this.onlineDevice.device.map((vo) => {
          if (item.K.split('/').join('') === vo.K.split('/').join('')) {
            it.V = vo.V === 0 ? 0 : ((item.V * 100) / vo.V).toFixed(2) * 1
            it.N = vo.N
          }
        })
        this.allOnlineRate.push(it)
      })
      this.allOnlineRate.sort((o1, o2) => {
        if (o1.V < o2.V) {
          return 1
        } else if (o1.V > o2.V) {
          return -1
        } else {
          return 0
        }
      })

      this.allOnlineRateIntime = []
      this.topList.online_intime.map((item) => {
        let it = {}
        this.onlineDevice.device.map((vo) => {
          if (item.K.split('/').join('') === vo.K.split('/').join('')) {
            it.V = vo.V === 0 ? 0 : ((item.V * 100) / vo.V).toFixed(2) * 1
            it.N = vo.N
          }
        })
        this.allOnlineRateIntime.push(it)
      })
      this.allOnlineRateIntime.sort((o1, o2) => {
        if (o1.V < o2.V) {
          return 1
        } else if (o1.V > o2.V) {
          return -1
        } else {
          return 0
        }
      })

      this.setData()

    },
    setData() {
      if (this.onlineRateBar.length === 0 && this.allOnlineRateIntime.length === 0) {
        this.option = {}
        return
      }
      if (!this.showMore) {
        this.option = {
          // color: '#fff',
          legend: {
            data: [{
              name: '当日',
              color: '#24FFFC'
            }, {
              name: '实时',
              color: '#55a6ff'
            }],
            top: 20,
            right: 5,
            textStyle: {
              fontSize: 11,
              fill: '#D0D0D0'
            },
            selectAble: false
          },
          xAxis: {
            min: 0,
            axisLine: {
              style: {
                stroke: '#324D4D',
                lineDash: [5, 2]
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              style: {
                fontSize: 11,
                fill: '#D0D0D0'
              }
            },
            splitLine: {
              stroke: 'red',
              lineWidth: 1
            },
            data: this.allOnlineRate.filter((item, index) => index < 5).map(item => {
              return item.N || ""
            })
          },
          yAxis: {
            min: 0,
            max: 100,
            data: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              style: {
                fontSize: 11,
                fill: '#D0D0D0'
              }
            },
            splitLine: {
              style: {
                stroke: '#324D4D',
                lineWidth: 1,
                lineDash: [5, 2]
              }
            }
          },
          series: [
            {
              stack: 'a',
              name: '当日',
              xAxisIndex: 0,
              yAxisIndex: 0,
              data: this.allOnlineRate.filter((item, index) => index < 5).map(item => {
                return item.V
              }),
              type: 'bar',
              barWidth: 20,
              barStyle: {
                fill: '#24FFFC'
              }
            },
            {
              type: 'bar',
              stack: 'b',
              name: '实时',
              xAxisIndex: 0,
              yAxisIndex: 0,
              data: this.allOnlineRateIntime.filter((item, index) => index < 5).map(item => {
                return item.V
              }),
              barWidth: 20,
              barStyle: {
                fill: '#55a6ff'
              }
            }
          ],
          grid: {
            left: 30,
            top: 20,
            right: 0,
            bottom: 20
          }
        }
      } else {
        this.option = {
          legend: {
            data: [{
              name: '当日',
              color: '#24FFFC'
            }, {
              name: '实时',
              color: '#55a6ff'
            }],
            top: 20,
            right: 5,
            textStyle: {
              fontSize: 11,
              fill: '#D0D0D0'
            },
            selectAble: false
          },
          yAxis: {
            axisLine: {
              style: {
                stroke: '#324D4D',
                lineDash: [5, 2]
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              style: {
                fontSize: 11,
                fill: '#D0D0D0',
                rotate: 45
              }
            },
            splitLine: {
              show: false
            },
            data: this.allOnlineRate.map(item => {
              return item.N || ""
            }).reverse()
          },
          xAxis: {
            min: 0,
            max: 100,
            data: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              style: {
                fontSize: 11,
                fill: '#D0D0D0'
              }
            },
            splitLine: {
              show: true,
              style: {
                stroke: '#324D4D',
                lineWidth: 1,
                lineDash: [5, 2]
              }
            }
          },
          series: [
            {
              type: 'bar',
              stack: 'a',
              name: '当日',
              data: this.allOnlineRate.map(item => {
                return item.V
              }).reverse(),
              barWidth: 20,
              barStyle: {
                fill: '#24FFFC'
              }
            },
            {
              type: 'bar',
              stack: 'b',
              name: '实时',
              data: this.allOnlineRateIntime.map(item => {
                return item.V
              }).reverse(),
              barWidth: 20,
              barStyle: {
                fill: '#55a6ff'
              }
            }
          ],
          grid: {
            right: 5,
            left: 45,
            bottom: 20,
            top: 30
          }
        }
      }
    },
    handleClick() {
      this.$emit('clicked')
    }
  },
  watch: {
    showMore: {
      handler: function (val) {
        this.init()
      },
    },
    topList: {
      handler: function () {
        this.init()
      },
      deep: true
    },
    onlineDevice: {
      handler: function () {
        this.init()
      },
      deep: true
    },
  },
}
</script>

<style lang="scss">
.online_rate {
  width: 100%;
  box-sizing: border-box;

  .dv-charts-container {
    height: calc(100% - 45px);
  }
}
</style>
