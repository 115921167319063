<template>
  <div class="exceed_content">
    <dec-block-title>
      {{ getDevBlockTitle(level) }}
    </dec-block-title>
    <ul class="ul_head" v-if="!showMore">
      <li class="li_item">
        <span class="item1">{{ getTableColName(level) }}</span>
        <span class="item1">监测点数</span>
        <span class="item3">在线数</span>
        <span class="item3">达标数</span>
        <span class="item3">超标数</span>
      </li>
    </ul>
    <ul class="ul_head" v-else>
      <li class="li_item">
        <span class="item1">{{ getTableColName(level) }}</span>
        <span class="item1">监测点数</span>
        <span class="item3">在线数</span>
        <span class="item3">在线率</span>
        <span class="item3">达标数</span>
        <span class="item3">达标率</span>
        <span class="item3">超标数</span>
        <span class="item3">超标率</span>
      </li>
    </ul>
    <ul class="ul_cont" v-if="!showMore">
      <li class="li_item" v-for="(item, index) in streetList" :key="index" v-show="!showMore">
        <span class="item1">{{ item.Name }}</span>
        <span class="item1">{{ item.Number }}</span>
        <span class="item3">{{ item.OnlineDeviceCount }}</span>
        <span class="item3">{{ item.NomalDeviceCount }}</span>
        <span class="item3">{{ item.ExcessDeviceCount }}</span>
      </li>
    </ul>
    <ul class="ul_cont" v-else>
      <li class="li_item" v-for="(item, index) in streetList" :key="index">
        <span class="item1">{{ item.Name }}</span>
        <span class="item1">{{ item.Number }}</span>
        <span class="item3">{{ item.OnlineDeviceCount }}</span>
        <span class="item3">{{ item.OnlinePercent }}</span>
        <span class="item3">{{ item.NomalDeviceCount }}</span>
        <span class="item3">{{ item.NomalPercent }}</span>
        <span class="item3">{{ item.ExcessDeviceCount }}</span>
        <span class="item3">{{ item.ExcessPercent }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import decBlockTitle from '../decorations/blockTitle2'

export default {
  name: 'SubDistrictCharts',
  props: ['showMore', 'streetData'],
  components: {
    decBlockTitle
  },
  data() {
    return {
      level: '',
      streetList: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.streetList = this.streetData || []
    },
    handleClick(e) {
      this.showMore = !this.showMore
      this.$emit('showRightContent', {e: e, showMore: this.showMore})
    },
    getDevBlockTitle(level) {
      switch (level) {
        case 'country':
          return '省级信息展示'
        case 'province':
          return '市级信息展示'
        case 'city':
          return '区级信息展示'
        default:
          return ''
      }
    },
    getTableColName(level) {
      switch (level) {
        case 'country':
          return '省/直辖市名'
        case 'province':
          return '市名'
        case 'city':
          return '区名'
        case 'district':
          return '街道名'
        default:
          return ''
      }
    }
  },
  watch: {
    streetData: {
      handler: function () {
        this.init()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
$height: 46vh;
.exceed_content {
  font-size: 12px;
  color: #67e0e3;
  background-color: #011d24;
  height: inherit;

  .ul_head {
    margin: 0;
    padding: 0;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #67e0e3;
    }

    .li_item {
      display: flex;
      justify-content: space-around;
      height: 4vh;
      line-height: 4vh;

      span {
        text-align: center;
        display: inline-block;
      }

      .item1 {
        width: 40%;
      }

      .item2,
      .item3 {
        width: 30%;
      }
    }
  }

  .ul_cont {
    margin: 0;
    padding: 0;
    height: $height - 8vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #67e0e3;
    }

    .li_item {
      display: flex;
      justify-content: space-around;
      height: 4vh;
      line-height: 4vh;

      span {
        text-align: center;
        display: inline-block;
      }

      .item1 {
        width: 40%;
      }

      .item2,
      .item3 {
        width: 30%;
      }
    }
  }
}
</style>
