<!--
index_chengdu 成都市专用index

注意：部份功能（如两侧图表）与index相同的原因是：这些功能是成都市先提出需求再同步更新到index的。
需要考虑如何将两者合并以减少代码冗余。
-->
<template>
  <div id="data-view">
    <top-header/>
    <dv-loading v-if="bigScreenLoading">Loading...</dv-loading>
    <div class="main-content" v-else>
      <!--      左侧图表-->
      <!--        省级/市级-->
      <div class="block-left-content" v-if="dataObj.level !== 'district'">
        <div class="content-box">
          <!--          日期选择功能-->
          <span class="data-range-picker clickable" style="padding-left:0" v-if="dateRange4Online.length !== 0"
                @click="resetDataRange(0)">重置</span>
          <div class="data-range-picker clickable" @click="openDataPickerDialog4Online">
            <span v-if="dateRange4Online.length === 0">选择日期范围</span>
            <span v-if="dateRange4Online.length !== 0">{{ new Date(dateRange4Online[0]).toLocaleDateString('zh-CN') }} - {{
                new Date(dateRange4Online[1]).toLocaleDateString('zh-CN')
              }}</span>
          </div>
          <!--          在线率-->
          <online-rate
            class="content-box-item"
            :show-more="buttonShowMore"
            :top-list="allTopList"
            :online-device="onlineDeviceData"
            :key="currentKey"
            @clicked="handleLeftContentClick(1)"
          ></online-rate>
          <el-button
            type="text"
            icon="el-icon-caret-bottom"
            class="foldStatusButton button"
            v-show="!buttonShowMore"
            @click="unfoldContentItem($event)"
          ></el-button>
          <el-button
            type="text"
            icon="el-icon-caret-top"
            class="foldStatusButton button"
            v-show="buttonShowMore"
            @click="foldContentItem($event)"
          ></el-button>
        </div>
        <div class="content-box">
          <span class="data-range-picker clickable" style="padding-left:0" v-if="dateRange4Reach.length !== 0"
                @click="resetDataRange(1)">重置</span>
          <div class="data-range-picker" @click="openDataPickerDialog4Reach">
            <span v-if="dateRange4Reach.length === 0">选择日期范围</span>
            <span v-else>{{
                new Date(dateRange4Reach[0]).toLocaleDateString('zh-CN')
              }} - {{ new Date(dateRange4Reach[1]).toLocaleDateString('zh-CN') }}</span>
          </div>
          <!--          达标率-->
          <reach-rate
            class="content-box-item"
            :show-more="buttonShowMore"
            :top-list="allTopList"
            :online-device="onlineDeviceData"
            :key="currentKeyDistrict"
          ></reach-rate>
          <el-button
            type="text"
            icon="el-icon-caret-bottom"
            class="foldStatusButton button"
            v-show="!buttonShowMore"
            @click="unfoldContentItem($event)"
          ></el-button>
          <el-button
            type="text"
            icon="el-icon-caret-top"
            class="foldStatusButton button"
            v-show="buttonShowMore"
            @click="foldContentItem($event)"
          ></el-button>
        </div>
      </div>
      <!--        区级-->
      <div class="block-left-content" v-else>
        <div class="small-content-box">
          <!--          在线率-->
          <online-rate
            class="content-box-item"
            :show-more="buttonShowMore"
            :top-list="allTopList"
            :online-device="onlineDeviceData"
            :key="currentKeyDistrict"
          ></online-rate>
          <el-button
            type="text"
            icon="el-icon-caret-bottom"
            class="foldStatusButton button"
            v-show="!buttonShowMore"
            @click="unfoldContentItem($event)"
          ></el-button>
          <el-button
            type="text"
            icon="el-icon-caret-top"
            class="foldStatusButton button"
            v-show="buttonShowMore"
            @click="foldContentItem($event)"
          ></el-button>
        </div>
        <div class="small-content-box">
          <!--          达标率-->
          <reach-rate
            class="content-box-item"
            :show-more="buttonShowMore"
            :top-list="allTopList"
            :online-device="onlineDeviceData"
            :key="currentKeyDistrict"
          ></reach-rate>
          <el-button
            type="text"
            icon="el-icon-caret-bottom"
            class="foldStatusButton button"
            v-show="!buttonShowMore"
            @click="unfoldContentItem($event)"
          ></el-button>
          <el-button
            type="text"
            icon="el-icon-caret-top"
            class="foldStatusButton button"
            v-show="buttonShowMore"
            @click="foldContentItem($event)"
          ></el-button>
        </div>
        <!--        报警信息-->
        <div class="content-box">
          <alarm-data
            class="large"
            :county-level-data="countyLevelData"/>
        </div>
      </div>
      <!--      中央组件-->
      <div class="block-center-content">
        <center
          :sum-data="sumData"
          :online-device="onlineDeviceData"
          :top-list="allTopList"
          :content-data="dataObj" @cityChange="getAreaCode"></center>
      </div>
      <!--      右侧图表-->
      <div class="block-right-content">
        <!--        区级/街道信息展示-->
        <div
          id="right-component-0"
          class="content-box fold-0"
        >
          <sub-district-charts
            class="content-box-item"
            :show-more="buttonShowMoreRight0"
            :street-data="sumData.streetData"
            :level="dataObj.level"
          ></sub-district-charts>
          <el-button
            type="text"
            icon="el-icon-caret-left"
            class="foldStatusButton button-right"
            v-show="!buttonShowMoreRight0"
            @click="unfoldRightContentItem($event)"
          ></el-button>
          <el-button
            type="text"
            icon="el-icon-caret-right"
            class="foldStatusButton button-right"
            v-show="buttonShowMoreRight0"
            @click="foldRightContentItem($event)"
          ></el-button>
        </div>
        <!--        区域企业占比-->
        <div
          id="right-component-1"
          class="content-box fold-1"
        >
          <enterprise-proportion-compare
            class="content-box-item"
            :key="currentKeyRight1"
            :top-list="allTopList"
            :online-device="onlineDeviceData"
            :show-more="buttonShowMoreRight1"
          ></enterprise-proportion-compare>
          <el-button
            type="text"
            icon="el-icon-caret-left"
            class="foldStatusButton button-right"
            v-show="!buttonShowMoreRight1"
            @click="unfoldRightContentItem($event)"
          ></el-button>
          <el-button
            type="text"
            icon="el-icon-caret-right"
            class="foldStatusButton button-right"
            v-show="buttonShowMoreRight1"
            @click="foldRightContentItem($event)"
          ></el-button>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="showDataPickerDialog4Online"
      width="25%"
      title="选择日期范围"
      :modal="false"
      @closed="getLeftContentDataOfTime(0)"
    >
      <el-date-picker
        v-model="dateRange4Online"
        type="daterange"
        format="yyyy 年 MM 月 dd 日"
        value-format="timestamp"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
      <el-button type="primary" style="margin-left:8px;" @click="showDataPickerDialog4Online = false">确认</el-button>
    </el-dialog>
    <el-dialog
      :visible.sync="showDataPickerDialog4Reach"
      width="25%"
      :modal="false"
      title="选择日期范围"
      @closed="getLeftContentDataOfTime(1)"
    >
      <el-date-picker
        v-model="dateRange4Reach"
        type="daterange"
        format="yyyy 年 MM 月 dd 日"
        value-format="timestamp"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
      <el-button type="primary" style="margin-left:8px;" @click="showDataPickerDialog4Reach = false">确认</el-button>
    </el-dialog>
  </div>
</template>

<script>
import topHeader from './Chengdu/topHeader'
import onlineRate from './Chengdu/onlineRate'
import reachRate from './Chengdu/reachRate'
import alarmRate from './alarmRate'
import emissionReduceSix from './emissionReduceSix'
import alarmData from './alarmData'
import SubDistrictCharts from './SubDistrictCharts'
import regionCompareDis from './regionCompare_dis'
import EnterpriseProportionCompare from './Chengdu/EnterpriseProportionCompare'

import center from './Chengdu/center'
import {stg} from '@/util/index'
import {mapState} from 'vuex'
import conf from '@/config'

export default {
  name: 'DataView_Chengdu',
  components: {
    topHeader,
    onlineRate,
    reachRate,
    alarmRate,
    alarmData,
    emissionReduceSix,
    SubDistrictCharts,
    EnterpriseProportionCompare,
    regionCompareDis,
    center
  },
  data() {
    return {
      timer: null, // 时间对象
      postdata: {
        WithSumData: true, // 是否返回汇总数据,
        WithOnlineDevice: true, // 返回在线设备,
        WithTopList: true // 返回top5
      },
      dataObj: {
        online: [], // 昨日在线率
        compliance: [], // 昨日达标率
        onlineRateBar: [],
        complianceRateBar: [],
        device: [], // 安装数
        redEmissions: [], // 减排累计
        exceedStandardsByMonth: [],
        alarmData: [],
        redEmissionsByMonth: [],
        allOnlineRate: [],
        allOnlineRateIntime: [],
        allCompliance: []
      },
      buttonShowMore: false, // 控制左侧图表展开/收起按钮的显示
      buttonShowMoreRight0: false, // 控制右侧图表展开/收起显示
      buttonShowMoreRight1: false,
      currentKey: 0, // 图表的key，用于改变容器大小后更新内部canvas
      currentKeyDistrict: 0,
      currentKeyRight0: 0,
      currentKeyRight1: 0,
      leftShowMore: true,
      showDataPickerDialog4Online: false,
      showDataPickerDialog4Reach: false,
      dateRange4Online: [],
      dateRange4Reach: [],
      defaultLeftContentData: {
        online: {},
        reach: {}
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > new Date(new Date().toLocaleDateString()).getTime() - 1
        }
      },
      bigScreenLoading: false,
      boardList: {},
      sumData: {},
      allTopList: {},
      onlineDeviceData: {},
      countyLevelData: {}
    }
  },
  created() {
    this.postdata.AreaCode = stg().getItem("areaCode");
    this.postdata.level = stg().getItem("level");
    this.netGetData();
  },
  watch: {
    newBoardData(val) {
      this.dataObj = Object.assign({}, val)
      this.$nextTick(function () {
        const newkey = new Date().getTime()
        this.currentKey = newkey
        this.currentKeyDistrict = newkey + 3
        this.currentKeyRight0 = newkey + 1
        this.currentKeyRight1 = newkey + 2
      })
    }
  },
  computed: {
    ...mapState(['newBoardData'])
  },
  methods: {
    /**
     * @description 获取数据
     */
    netGetData() {
      this.bigScreenLoading = true
      const param = Object.assign({}, this.postdata)
      param.areaId = parseInt(stg().getItem('areaId'), 10)
      this.boardList = {
        compliance: [],
        emissions: [],
        exceedStandards: [],
        online: [],
        redEmissions: [],
        redPm10: [],
        redPm25: [],
        redVocs: []
      }
      this.onlineDeviceData = {}
      this.countyLevelData = {}
      this.sumData = {
        complianceDeviceCount: 0,
        notComplianceDeviceCount: 0,
        onlineDeviceCount: 0,
        redEmissionAmount: 0,
        redPm10Amount: 0,
        redPm25Amount: 0,
        redVocsAmount: 0,
        totalDeviceInstalledCount: 0,
        totalLocaleCount: 0
      }
      this.allTopList = {}

      //boardTopList
      this.$post("admin/listBoardTopList", param).then(res => {
        this.boardList = res
      })
      //大屏OnlineDevice数据统计
      this.$post("admin/listBoardOnlineDevice", param).then(res => {
        this.onlineDeviceData = res
      })
      //大屏CountyLevel数据统计
      this.$post("admin/countyLevelData", this.postdata).then(res => {
        this.countyLevelData = res
      })
      // sumData中 onlineDeviceCountByDate数据
      this.$post("admin/listonlineDeviceCountByDate", this.postdata).then(res => {
        this.$set(this.sumData, 'complianceDeviceRatioByDate', res.sumData.complianceDeviceRatioByDate)
        this.$set(this.sumData, 'onlineDeviceCountByDate', res.sumData.onlineDeviceCountByDate)
        this.$set(this.sumData, 'monthGroupByDates', res.sumData.monthGroupByDates.reverse())
      })
      // sumData中 liststreetData数据
      this.$post("admin/liststreetData", this.postdata).then(res => {
        this.$set(this.sumData, 'streetData', res.sumData.streetData)
      })
      // sumData中 listsumDataTotal数据
      this.$post("admin/listsumDataTotal", this.postdata).then(res => {
        Object.assign(this.sumData, res.sumData)
      })
      //allTopList中compliance数据
      this.$post("admin/listComplianceTopList", this.postdata).then(res => {
        this.$set(this.allTopList, 'compliance', res.compliance)
      })
      //allTopList中online数据
      this.$post("admin/listOnlineTopList", this.postdata).then(res => {
        this.$set(this.allTopList, 'online', res.online)
      })
      // allTopList中online_intime数据
      this.$post("admin/listOnlineIntimeTopList", this.postdata).then(res => {
        this.$set(this.allTopList, 'online_intime', res.online_intime)
      })
      //allTopList中redEmissions数据
      this.$post("admin/listDeviceTopList", this.postdata).then(res => {
        this.$set(this.allTopList, 'device', res.device)
      })
      //allTopList中redEmissions数据
      this.$post("admin/listRedEmissionsTopList", this.postdata).then(res => {
        this.$set(this.allTopList, 'redEmissions', res.redEmissions)
      })
      //allTopList中listDeviceCountByHourTopList数据
      this.$post("admin/listDeviceCountByHourTopList", this.postdata).then(res => {
        this.$set(this.allTopList, 'onlineAndComplianceDeviceCountByHour', res.onlineAndComplianceDeviceCountByHour)
      })

      Promise.all([this.$post('admin/listBoardDataV2', param), this.$post('admin/listAllTopList', param)])
        .then(([previousRes, res]) => {
          this.dataObj = {
            code: this.postdata.AreaCode,
            level: this.postdata.level,
          }
          this.defaultLeftContentData = {
            online: {
              onlineRateBar: this.dataObj.onlineRateBar,
              allOnlineRate: this.dataObj.allOnlineRate,
              allOnlineRateIntime: this.dataObj.allOnlineRateIntime
            },
            reach: {
              complianceRateBar: this.dataObj.complianceRateBar,
              allCompliance: this.dataObj.allCompliance
            }
          }
          this.bigScreenLoading = false
          const newkey = new Date().getTime()
          this.$nextTick(function () {
            this.currentKey = newkey
            this.currentKeyDistrict = newkey + 1
          })
        })
    },
    resetDataRange(type) {
      switch (type) {
        case 0:
          this.dataObj.onlineRateBar = this.defaultLeftContentData.online.onlineRateBar || []
          this.dataObj.allOnlineRateIntime = this.defaultLeftContentData.online.allOnlineRateIntime || []
          this.dataObj.allOnlineRate = this.defaultLeftContentData.online.allOnlineRate || []
          this.dateRange4Online = []
          break
        case 1:
          this.dataObj.complianceRateBar = this.defaultLeftContentData.reach.complianceRateBar || []
          this.dataObj.allCompliance = this.defaultLeftContentData.reach.allCompliance || []
          this.dateRange4Reach = []
      }
      this.$nextTick(function () {
        this.currentKey = new Date().getTime()
        this.currentKeyDistrict = new Date().getTime()
      })
    },
    /**
     * @description 获取左侧图表按时间范围的数据
     * @param type 图表数据类型
     */
    getLeftContentDataOfTime(type) {
      const param = {
        AreaCode: this.dataObj.code,
        Type: null,
        StartTime: null,
        EndTime: null
      }
      switch (type) {
        case 0:
          if (this.dateRange4Online.length < 2) return
          param.Type = 'online'
          param.StartTime = this.dateRange4Online[0] / 1000
          param.EndTime = this.dateRange4Online[1] / 1000
          break
        case 1:
          if (this.dateRange4Reach.length < 2) return
          param.Type = 'compliance'
          param.StartTime = this.dateRange4Reach[0] / 1000
          param.EndTime = this.dateRange4Reach[1] / 1000
          break
      }
      this.$post('admin/listOnlineDataOfTime', param)
        .then(res => {
          switch (type) {
            case 0:
              this.dataObj.onlineRateBar =
                res.map((item) => {
                  this.dataObj.onlineDevice.device.map((vo) => {
                    if (item.K.split('/').join('') === vo.K.split('/').join('')) {
                      item.V =
                        vo.V == 0 ? 0 : ((item.V * 100) / vo.V).toFixed(2) * 1
                      item.N = vo.N
                    }
                  })
                  return item
                }).sort((o1, o2) => {
                  if (o1.V < o2.V) {
                    return 1
                  } else if (o1.V > o2.V) {
                    return -1
                  } else {
                    return 0
                  }
                })
              this.dataObj.allOnlineRate =
                res.map((item) => {
                  this.dataObj.onlineDevice.device.map((vo) => {
                    if (item.K.split('/').join('') === vo.K.split('/').join('')) {
                      item.V =
                        vo.V == 0 ? 0 : ((item.V * 100) / vo.V).toFixed(2) * 1
                      item.N = vo.N
                    }
                  })
                  return item
                }).sort((o1, o2) => {
                  if (o1.V < o2.V) {
                    return 1
                  } else if (o1.V > o2.V) {
                    return -1
                  } else {
                    return 0
                  }
                })
              this.dataObj.allOnlineRateIntime = []
              break
            case 1:
              this.dataObj.allCompliance =
                res.map((item) => {
                  this.dataObj.onlineDevice.device.map((vo) => {
                    if (item.K.split('/').join('') === vo.K.split('/').join('')) {
                      item.V =
                        vo.V == 0 ? 0 : ((item.V * 100) / vo.V).toFixed(2) * 1
                      item.N = vo.N
                    }
                  })
                  return item
                }).sort((o1, o2) => {
                  if (o1.V < o2.V) {
                    return 1
                  } else if (o1.V > o2.V) {
                    return -1
                  } else {
                    return 0
                  }
                })
              this.dataObj.complianceRateBar =
                res.map((item) => {
                  this.dataObj.onlineDevice.device.map((vo) => {
                    if (item.K.split('/').join('') === vo.K.split('/').join('')) {
                      item.V =
                        vo.V == 0 ? 0 : ((item.V * 100) / vo.V).toFixed(2) * 1
                      item.N = vo.N
                    }
                  })
                  return item
                }).sort((o1, o2) => {
                  if (o1.V < o2.V) {
                    return 1
                  } else if (o1.V > o2.V) {
                    return -1
                  } else {
                    return 0
                  }
                })
          }
          this.$nextTick(function () {
            this.currentKey = new Date().getTime()
            this.currentKeyDistrict = new Date().getTime()
          })
        })
    },
    openDataPickerDialog4Online() {
      this.showDataPickerDialog4Online = true
    },
    openDataPickerDialog4Reach() {
      this.showDataPickerDialog4Reach = true
    },
    /**
     * 折叠/展开
     */
    unfoldRightContentItem(e) {
      const currentNode = e.currentTarget.parentNode
      const siblingNodeList = Array.from(currentNode.parentNode.children)
      const currentNodeIndex = siblingNodeList.indexOf(currentNode)

      currentNode.classList.remove('fold-' + currentNodeIndex)
      currentNode.classList.add('unfold-' + currentNodeIndex)
      this.$nextTick(function () {
        this['currentKeyRight' + currentNodeIndex] = new Date().getTime()
        this['buttonShowMoreRight' + currentNodeIndex] = true
      })
    },
    foldRightContentItem(e) {
      const currentNode = e.currentTarget.parentNode
      const siblingNodeList = Array.from(currentNode.parentNode.children)
      const currentNodeIndex = siblingNodeList.indexOf(currentNode)

      currentNode.classList.remove('unfold-' + currentNodeIndex)
      currentNode.classList.add('fold-' + currentNodeIndex)
      this.$nextTick(function () {
        this['currentKeyRight' + currentNodeIndex] = new Date().getTime()
        this['buttonShowMoreRight' + currentNodeIndex] = false
      })
    },
    unfoldContentItem(e) {
      this.$nextTick(function () {
        this.currentKey = new Date().getTime()
        this.currentKeyDistrict = new Date().getTime()
      })
      this.buttonShowMore = !this.buttonShowMore
      const currentNode = e.currentTarget.parentNode
      const parentNode = currentNode.parentNode
      let node = parentNode.firstChild
      currentNode.classList.add('large')
      while (node !== null) {
        if (node !== currentNode) {
          node.classList.add('fold')
        }
        node = node.nextSibling
      }
    },
    foldContentItem(e) {
      this.$nextTick(function () {
        this.currentKey = new Date().getTime()
        this.currentKeyDistrict = new Date().getTime()
      })
      this.buttonShowMore = !this.buttonShowMore
      const currentNode = e.currentTarget.parentNode
      const parentNode = currentNode.parentNode
      let node = parentNode.firstChild
      currentNode.classList.remove('large')
      while (node !== null) {
        if (node !== currentNode) {
          node.classList.remove('fold')
        }
        node = node.nextSibling
      }
    },

    /**
     * @description 获取区域代码
     * @param data 数据传入
     */
    getAreaCode(data) {
      if (data && data.code) {
        this.postdata.AreaCode = data.code + ''
        this.postdata.level = data.level + ''
      } else {
        this.postdata.AreaCode = stg().getItem('areaCode')
        this.postdata.level = stg().getItem('level')
      }
      // this.netGetData()
    },
    /**
     * 处理左侧图表点击事件
     * @param type 图表类型
     */
    handleLeftContentClick(type) {
      if (this.dataObj.level === 'country') return
      if (this.$store.state.districtInfoType !== type) {
        this.leftShowMore = true
      } else {
        this.leftShowMore = !this.leftShowMore
      }
      this.$store.commit('setShowMoreDistrictInfo', {isShow: this.leftShowMore, type: this.leftShowMore ? type : 0})
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/common.scss";

.foldStatusButton {
  color: #B2F2F6;
  padding: 0;
}

#data-view {
  width: 100%;
  height: 100%;
  background-color: #011d24;
  color: #fff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;

  // #dv-full-screen-container {
  //   background-image: url('./img/bg.png');
  //   background-size: 100% 100%;
  //   box-shadow: 0 0 3px blue;
  //   background-color: #011D24;
  //   display: flex;
  //   flex-direction: column;
  //   box-sizing: border-box;
  //   overflow-y: auto;
  // }

  .main-content {
    flex: 1;
    display: flex;
    padding: 0 5px 5px;
    height: 90vh;
  }

  .block-left-content,
  .block-center-content,
  .block-right-content {
    display: flex;
    // padding: 0 10px 0 0;
  }

  .block-left-content,
  .block-right-content {
    width: 18vw;
    box-sizing: border-box;
    flex-direction: column;
  }

  .block-right-content,
  .block-left-content {
    .content-box {
      height: 46vh;
    }

    .small-content-box {
      height: 23vh;
    }

    .clickable {
      cursor: pointer;
    }
  }

  .block-left-content {
    .button {
      width: 100%;
    }

    .content-box-item {
      flex: 1;
      height: calc(100% - 24px);
    }

    .large {
      height: 100%;
    }

    .fold {
      display: none;
    }

    .data-range-picker {
      cursor: pointer;
      float: right;
      color: #B2F2F6;
      font-size: 13px;
      line-height: 1;
      font-weight: bold;
      padding: 12px;
    }
  }

  .block-right-content {
    .content-box-item {
      float: right;
      width: calc(100% - 16px);
    }

    .button-right {
      margin: 0;
      padding: 0;
      float: left;
      width: 16px;
      height: 100%;
      background-color: #011d24;
    }

    .unfold-0 {
      position: absolute;
      top: 8vh + 46vh * 0;
      right: 5px;
      width: 70vh;
    }

    .unfold-1 {
      position: absolute;
      top: 8vh + 46vh * 1;
      right: 5px;
      width: 70vh;
    }

    .fold-0 {
      position: absolute;
      top: 8vh + 46vh * 0;
      right: 5px;
      width: inherit;
    }

    .fold-1 {
      position: absolute;
      top: 8 + 46vh * 1;
      right: 5px;
      width: inherit;
    }
  }

  .block-center-content {
    width: 64vw;
    // flex: 1;
  }
}

.section_title_span {
  font-size: 11px;
  margin-left: 3px;
}
</style>
