<!-- sctterMap.vue 地图下钻并且带有散点图 -->
<template>
  <div class="wrapper">
    <div id="olmap" class="echarts" ref="chartMap"></div>
    <!--    <p class="back" @click.stop="backTop" v-show="contentData_map.level !== 'country'">返回</p>-->
  </div>
</template>

<script>
import echarts from 'echarts'
import { stg, bdToGdLatLng } from '@/util/index'
import gcj02Mecator from '@/util/gcj02Mecator'
import { mapState } from 'vuex'
import { getLabelPosition, cityData } from '@/view/bigscreen/assets/util/index'
import { iconListSvgPath } from '../../../assets/util'
import 'ol/ol.css'
import Map from 'ol/Map'
import View from 'ol/View'
import { ImageArcGISRest, OSM, XYZ } from 'ol/source'
import { Image as ImageLayer, Tile as TileLayer } from 'ol/layer'
import EChartsLayer from 'ol-echarts'
import { GKX } from '@/asset/geojson/Chengdu/GKX.js'
import gcoord from 'gcoord'

const serviceUrl = 'http://221.237.108.49:6080/arcgis/rest/services/CXN/XZQH_ANXI_1016/MapServer'

const defaultEchartsOpt = () => ({
  backgroundColor: 'transparent',
  tooltip: {
    trigger: 'item',
    enterable: true,
    confine: true
  },
  series: []
})

// http://221.237.108.49:6080/arcgis/rest/services/CXN/XZQH_ANXI_1016/MapServer
export default {
  name: 'dotMap',
  props: {
    contentData: {
      type: Object,
      default: () => {
        return {
          areaId: null,
          code: ''
        }
      }
    },
    onlineDevice: {
      type: Object,
      default: () => {
        return {}
      }
    },
    sumData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    topList: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isShowEmphasisDot: {
      type: Object
    },
    selectedArea: {
      type: Object,
      default: () => {
        return {
          name: '成都市',
          level: 2,
          value: '成都市',
          coordinate: [103.9, 30.77],
          zoom: 9.3
        }
      }
    }
  },
  data() {
    return {
      contentData_map: {
        code: null,
        level: null
      },
      myChart: null,
      Polyline: '',
      map: {},
      parentJson: [],
      geoJsonData: {},
      mapData: [],
      dotData: [],
      parentCode: [],
      list: [],
      tooltipExist: false,
      dotColor: ['#FF001A', '#78ff79', '#FEFF65', '#AEAEAE', '#000000'],
      seriesData: [{
        name: '监测点',
        type: 'scatter',
        coordinateSystem: 'geo',
        data: null,
        symbol: (value) => {
          let r
          switch (value[10]) {
            case '大':
              r = iconListSvgPath.big
              break
            case '中':
              r = iconListSvgPath.medium
              break
            case '小':
              r = iconListSvgPath.small
              break
          }
          return r
        },
        // 这里可以设置点的大小
        symbolSize: 14,
        tooltip: {
          borderColor: '#03608C',
          formatter: function(params) {
            const healthStatusColor = params.value[2] === 1 ? '#78ff79' : '#FF001A'
            return `
            <p>
            <span style="color:#B2F2F6">店铺名称：</span>
            <span style="color:#67e0e3">${params.value[4]}</span>
            </p>
            <p>
              <div style="display:inline-block;color:#B2F2F6">实时状态：</div>
              <div style="width:10px;height:10px;background-color:${params.value[11]};display:inline-block;border-radius:50%;margin-right:5px"></div>
              <span style="color:#67e0e3">(${params.value[14]})</span>
              <div style="display:inline-block;color:#B2F2F6">排放状态码：</div>
              <div style="width:10px;height:10px;background-color:${healthStatusColor};display:inline-block;border-radius:50%;margin-right:5px"></div>
            </p>
            <p>
              <span style="color:#B2F2F6">店铺规模：</span>
              <span style="color:#67e0e3">${params.value[10]}</span>
              <span style="margin-left:4px;color:#B2F2F6">灶头数：</span>
              <span style="color:#67e0e3">${params.value[13]}</span>
            </p>
            <p>
              <span style="color:#B2F2F6;">联系人：</span>
              <span style="color:#67e0e3;">${params.value[15]}</span>
              <span style="margin-left:4px;color:#B2F2F6">联系电话：</span>
              <span style="color:#67e0e3">${params.value[5]}</span>
            </p>
            <h5 style="color:#B2F2F6;font-size:14px;margin:5px 0 0;">监测点信息</h5>
            <p>
              <span style="color:#B2F2F6">监测点名称：</span>
              <span style="color:#67e0e3">${params.value[6]}</span>
            </p>
            <p>
              <span style="color:#B2F2F6">所属单位：</span>
              <span style="color:#67e0e3">${params.value[12]}</span>
            </p>
            <p>
              <span style="color:#B2F2F6">菜系：</span>
              <span style="color:#67e0e3">${params.value[7]}</span>
            </p>
            <p>
              <span style="color:#B2F2F6">地址：</span>
              <span style="color:#67e0e3">${params.value[8]}</span>
            </p>
            <p>
              <span style="color:#B2F2F6">设备：</span>
              <span style="color:#67e0e3">${params.value[9]}</span>
            </p>
            <div>
              <a style="color:#67e0e3;margin-right: 4px;" href="${document.URL.split('board')[0] + 'Notification?MN=' + params.value[9] + '&type=0'}" target="_blank" rel="opener">一点一档</a>
              <a style="color:#67e0e3;" href="${document.URL.split('board')[0] + 'Notification?LocaleId=' + params.value[16] + '&Owner=' + params.value[17] + '&type=1'}" target="_blank" rel="opener">报警信息</a>
            </div>
            `
          },
          extraCssText: `
            padding: 8px 16px;
            border-radius: 0;
          `
        },
        itemStyle: {
          color: function(params) {
            return params.value[11]
          }
        }
      }, {
        name: '国控线',
        type: 'lines',
        geoIndex: 0,
        data: GKX,
        polyline: true,
        silent: true,
        lineStyle: {
          color: 'rgb(255,255,66)',
          width: 2
        }
      }],
      echartsOptions: {
        tooltip: {
          trigger: 'item',
          enterable: true,
          confine: true
        },
        series: []
      },
      postdata: {
        WithSumData: true, // 是否返回汇总数据,
        WithOnlineDevice: true, // 返回在线设备,
        WithTopList: true // 返回top5
      },
      showMore: false,
      mapScale: null,
      olMap: null,
      echartslayer: null
    }
  },
  mounted() {
    // this.initMap()
    this.initOlMap()
  },
  computed: {
    ...mapState(['showMoreDistrictInfo', 'districtInfoType', 'topBoardData', 'isXihu'])
  },
  methods: {
    initOlMap() {
      const layers = [
        new TileLayer({
          title: '午夜蓝图',
          source: new XYZ({
            projection: gcj02Mecator,
            url: 'http://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}'
          })
        }),
        new ImageLayer({
          source: new ImageArcGISRest({
            ratio: 1,
            params: {},
            url: serviceUrl
          })
        })
      ]
      this.olMap = new Map({
        target: 'olmap',
        layers: layers,
        view: new View({
          projection: 'EPSG:4326',
          center: this.selectedArea.coordinate,
          zoom: this.selectedArea.zoom
        })
      })
      this.olMap.on('click', function(e) {
        console.log(e.coordinate)
      })
    },
    stoveNumFormatter(StoveNum) {
      let r
      if (StoveNum > 6) {
        r = '大'
      } else if (StoveNum > 3) {
        r = '中'
      } else {
        r = '小'
      }
      return r
    },
    dotColorAndStatusFormatter(Status) {
      let r = []
      switch (Status) {
        case 'NORMAL':
          r = [1, '达标']
          break
        case 'EXCESS':
          r = [0, '超标']
          break
        case 'DOWN':
          r = [3, '离线']
          break
        case 'OFF':
          r = [4, '异常离线']
          break
        case 'ALARM':
          r = [1, '预警']
          break
        default:
          r = [4, '未知状态']
      }
      return r
    },
    dotStatusFormatter(Status) {
      switch (Status) {
        case 'NORMAL':
          return 2
        case 'EXCESS':
          return 1
        default:
          return 3
      }
    },
    boardDataChange(data) {
      this.$store.commit('pushBoardDataStack', { dataObj: this.contentData })
      this.$store.commit('setNewBoardDataChengdu', { res: data, dataObj: this.contentData })
    },
    // 获取地址详情
    getAreaDetail() {
      const areaData = {
        Param: { Id: this.contentData_map.areaId }
      }
      this.$post('admin/listArea', areaData)
        .then((res) => {
          this.Polyline = res.content[0].Polyline
          stg().setItem('quPolyline', this.Polyline)
        })
        .catch(() => { })
    },
    // 获取geoJson数据
    getGeoJson() {
      const adcode = '510100'
      const that = this
      if (this.echartslayer) { // 先清空ol-echarts实例
        this.echartslayer.remove()
      }
      if (this.contentData_map.areaId) {
        this.getAreaDetail()
      } else {
        stg().setItem('quPolyline', '')
      }
      stg().setItem('quName', '')
      window.AMapUI.loadUI(['geo/DistrictExplorer'], (DistrictExplorer) => {
        // 高德地图的行政区划查询
        const districtExplorer = (window.districtExplorer = new DistrictExplorer())
        districtExplorer.loadAreaNode(adcode, function(error, areaNode) {
          if (error) {
            console.error(error)
            return
          }
          that.mapScale = areaNode._data.scale
          const level = areaNode.getParentFeature()
          let Json = []
          if (level.properties.level !== 'district') {
            Json = areaNode.getSubFeatures()
          } else {
            Json = [level]
          }
          that.geoJsonData = {
            features: Json
          }
          that.getMapData(adcode)
        })
      })
    },
    // 获取数据
    getMapData(adcode) {
      const deviceDetail = this.onlineDevice.listDevice || []
      const exceedList = this.topList.exceedStandards || []
      const areaList = this.onlineDevice.device
      const areaDetailList = this.geoJsonData.features
      const list = []
      for (let i = 0; i < areaList.length; i++) {
        let obj = {
          N: areaList[i].N,
          name: areaList[i].N,
          center: [],
          level: 'country',
          code: 100000,
          value: Math.floor(Math.random() * (10000))
        }
        const m = exceedList.find(e => e.K.split('/').join('') === areaList[i].K.split('/').join(''))
        let n = areaDetailList.find(e => e.properties.name.substring(0, 2) === areaList[i].N.substring(0, 2))
        if (!n) {
          const t = cityData.find(e => e.label === areaList[i].N)
          if (!t) continue
          n = {
            properties: {
              name: t.label,
              centroid: t.coordinate,
              level: t.level,
              adcode: t.code
            }
          }
        }
        if (m !== -1) {
          obj = {
            ...obj,
            ...m
          }
        }
        obj.center = n.properties.centroid.slice()
        obj.level = n.properties.level
        obj.code = n.properties.adcode
        list.push(obj)
      }
      this.list = list
      this.dotData = []
      for (let i = 0; i < deviceDetail.length; i++) {
        const r = this.dotColorAndStatusFormatter(deviceDetail[i].Status)
        let pos = []
        try {
          pos = gcoord.transform(
            [deviceDetail[i].Lng, deviceDetail[i].Lat], // 经纬度坐标
            gcoord.BD09, // 当前坐标系
            gcoord.WGS1984 // 目标坐标系
          )
        } catch (e) {
          console.log(deviceDetail[i])
        }
        this.dotData.push({
          name: deviceDetail[i].MN,
          value: [
            pos[0],
            pos[1],
            deviceDetail[i].HealthCodeColor === 'Green' ? 1 : 0,
            this.dotStatusFormatter(deviceDetail[i].Status),
            deviceDetail[i].CustomerName, // 4
            deviceDetail[i].CustomerMobile,
            deviceDetail[i].LocaleName,
            deviceDetail[i].CuisineName,
            deviceDetail[i].Addr,
            deviceDetail[i].MN, // 9
            this.stoveNumFormatter(deviceDetail[i].StoveNum),
            this.dotColor[r[0]],
            deviceDetail[i].OwnerName,
            deviceDetail[i].StoveNum,
            r[1], // 14
            deviceDetail[i].CustomerContact,
            deviceDetail[i].Id,
            deviceDetail[i].Owner
          ]
        })
      }
      // 渲染echarts
      this.initEcharts()
    },
    // 初始化地图
    initMap() {
      this.myChart = echarts.init(this.$refs.chartMap)
      window.addEventListener('resize', () => {
        this.myChart.resize()
      })
      // echarts事件监听
      // 地图鼠标悬浮tooltips
      this.myChart.on('mouseover', (params) => {
        if (params.componentSubType === 'map') {
          if (!this.tooltipExist) {
            this.myChart.dispatchAction({
              type: 'hideTip'
            })
            this.myChart.dispatchAction({
              type: 'showTip',
              seriesIndex: params.seriesIndex,
              dataIndex: params.dataIndex
            })
          }
        }
      })
      this.myChart.on('click', (params) => {
        // 点位点击出现tooltips
        if (params.componentSubType === 'scatter' || params.componentSubType === 'effectScatter') {
          this.myChart.dispatchAction({
            type: 'hideTip'
          })
          this.myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: params.seriesIndex,
            dataIndex: params.dataIndex
          })
          this.tooltipExist = true
        } else {
          // 点击地图跳转
          if (this.tooltipExist) {
            // 若存在点位tooltip，先隐藏
            this.tooltipExist = !this.tooltipExist
            this.myChart.dispatchAction({
              type: 'hideTip'
            })
            return
          }
          if (params.componentSubType === 'map') {
            const param = Object.assign({}, this.postdata)
            param.AreaCode = params.data.code.toString()
            param.level = params.data.level
            this.$post('admin/listBoardDataV2', param)
              .then(res => {
                this.boardDataChange({
                  ...res,
                  code: param.AreaCode,
                  level: param.level
                })
              })
          }
        }
      })
    },
    initEcharts() {
      if (!this.olMap) {
        this.initOlMap()
      }
      this.seriesData[0].data = this.dotData
      this.echartsOptions.series = this.seriesData
      this.echartslayer = new EChartsLayer(this.echartsOptions, {
        hideOnMoving: true,
        hideOnZooming: true
      })
      this.echartslayer.appendTo(this.olMap)
      if (this.showMoreDistrictInfo) {
        this.showLabelLine(3)
      }
      this.$store.commit('setShowMoreDistrictInfo', { isShow: true, type: 3 })
    },
    // 返回上一级
    backTop() {
      this.$store.dispatch('popNewBoardData')
    },
    showLabelLine(type) {
      let t
      let label
      const mapCenter = this.olMap.getView().getCenter()
      switch (type) {
        case 1:
          t = this.contentData_map.onlineRateBar
          label = '昨日在线率'
          break
        case 2:
          t = this.contentData_map.complianceRateBar
          label = '昨日达标率'
          break
        case 3:
          t = this.onlineDevice.device
          label = '安装数'
          break
        case 4:
          t = this.contentData_map.redEmissionsBar
          label = '减排累计'
          break
        default: return
      }
      const data = []
      let j
      let m = 0
      for (let i = 0; i < t.length; i++) {
        j = this.list.find(e => e.name === t[i].N)
        if ((!j.center) || j.center.length === 0) {
          m++
          continue
        }
        data.push({
          name: t[i].N,
          value: t[i].V,
          coords: [j.center, getLabelPosition(mapCenter, this.mapScale, i - m)]
        })
      }
      // 去掉额外的labelLine
      this.seriesData = this.seriesData.filter(e => {
        return e.type !== 'lines' || e.name === '国控线'
      })
      this.seriesData.push({
        name: label,
        type: 'lines',
        coordinateSystem: 'geo',
        geoIndex: 0,
        data: data,
        lineStyle: {
          type: 'solid',
          color: '#218890',
          width: 1
        },
        label: {
          show: true,
          color: '#67e0e3',
          align: 'left',
          fontSize: 13,
          lineHeight: 16,
          formatter: function(params) {
            return params.name + '\n' + label + '：' + params.value
          }
        }
      })
      this.echartsOptions.series = this.seriesData
      this.echartslayer.setChartOptions(this.echartsOptions)
    }
  },
  watch: {
    selectedArea(val) {
      this.olMap.getView().setCenter(val.coordinate)
      this.olMap.getView().setZoom(val.zoom || 9)
    },
    showMoreDistrictInfo(val) {
      if (!val) {
        // this.seriesData.splice(2)
        // this.echartsOptions.series.splice(2)
        this.seriesData = this.seriesData.filter(e => {
          return e.type !== 'lines' || e.name === '国控线'
        })
        this.echartsOptions.series = this.seriesData
        this.echartslayer.$chart.setOption(this.echartsOptions, true)
      }
    },
    districtInfoType(val) {
      this.showLabelLine(val)
    },
    contentData: {
      handler: function(val, oldVal) {
        // 使用nextTick()，以免遇到map container div not exist的问题
        this.$nextTick(() => {
          if (!val) return
          if ((val && oldVal) && (val.code.toString() === oldVal.code.toString())) return
          this.contentData_map = val
          if (val.level === 'district') {
            this.$store.commit('setShowMoreDistrictInfo', { isShow: false, type: 0 })
          }
          this.getGeoJson()
        })
      },
      immediate: true
    },
    isShowEmphasisDot: {
      handler: function(val, oldVal) {
        if (val.show || oldVal.type.localeCompare(val.type) !== 0) {
          // 为突出显示超标点，将地图其他标点，如dotData，设置为null
          let newDotData = []
          switch (val.type) {
            case 'online':
              newDotData = this.dotData.filter(e => e.value[3] < 3)
              break
            case 'normal':
              newDotData = this.dotData.filter(e => e.value[3] === 2)
              break
            case 'exceed':
              newDotData = this.dotData.filter(e => e.value[3] === 1)
              break
            default:
              newDotData = this.dotData
          }
          this.seriesData[0].data = newDotData
        } else {
          // 清除地图上的突出显示的标点
          this.seriesData[0].data = this.dotData
        }
        this.echartsOptions.series = this.seriesData
        this.echartslayer.$chart.setOption(this.echartsOptions, true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.echarts {
  width: 100%;
  height: 100%;
  position: relative;
}

.back {
  position: absolute;
  left: 2%;
  top: 2%;
  color: #eee;
  z-index: 99999;
  cursor: pointer;
}

.large {
  width: 400px !important;

  .hide:hover {
    cursor: pointer;
  }
}
</style>
