<template>
  <div class="center_block">
    <select-city v-show="showSelectCity" @on-change="selectCityChange"></select-city>
    <div class="data_box">
      <ul id="center-top">
        <li class="clickable switch-label-line" :class="{ 'clicked': this.districtInfoType === 3 }"
            @click="handleLeftContentClick(3, $event)">
          <h3>监测点总数[个]</h3>
          <digital-flop
            :config="{ number: [sumData.totalDeviceInstalledCount], style: { fontSize: 20, fill: '#CDA52B' } }"
            :styles="{ width: '100%', height: '34px', textAlign: 'center'}"></digital-flop>
        </li>
        <li class="clickable switch-emphasis-dot" @click="switchEmphasisDot('online', $event)">
          <h3>在线数[台]</h3>
          <digital-flop :config="{ number: [sumData.onlineDeviceCount], style: { fontSize: 20, fill: '#CDA52B' } }"
                        :styles="{ width: '100%', height: '34px', textAlign: 'center'}"></digital-flop>
        </li>
        <li class="clickable switch-emphasis-dot" @click="switchEmphasisDot('normal', $event)">
          <h3>达标数[台]</h3>
          <digital-flop :config="{ number: [sumData.complianceDeviceCount], style: { fontSize: 20, fill: '#CDA52B' } }"
                        :styles="{ width: '100%', height: '34px', textAlign: 'center'}"></digital-flop>
        </li>
        <li class="clickable switch-emphasis-dot" @click="switchEmphasisDot('exceed', $event)">
          <h3>超标数[台]</h3>
          <digital-flop
            :config="{ number: [sumData.notComplianceDeviceCount], style: { fontSize: 20, fill: '#CDA52B' } }"
            :styles="{ width: '100%', height: '34px', textAlign: 'center'}"></digital-flop>
        </li>
      </ul>
    </div>
    <div class="map_block">
      <dot-map
        :selectedArea="selectedArea"
        :sum-data="sumData"
        :online-device="onlineDevice"
        :top-list="topList"
        :content-data="contentData"
        :isShowEmphasisDot="emphasisSomeDot"
        @on-change="selectCityChange"></dot-map>
      <!--      <dot-map-district :content-data="contentData" :isShowEmphasisDot="emphasisSomeDot" v-show="contentData.level === 'district'"></dot-map-district>-->
    </div>
    <div class="bottom_block clickable">
      <online-platform
        @click.native="switchHoursAndDate(0)"
        :content-data="{
          dateData: sumData.onlineDeviceCountByDate,
          hoursData: topList.onlineAndComplianceDeviceCountByHour
        }"
        :showHours="showHoursData0"
      ></online-platform>
      <center-reach-rate
        @click.native="switchHoursAndDate(1)"
        :content-data="{
          dateData: sumData.complianceDeviceRatioByDate,
          hoursData: topList.onlineAndComplianceDeviceCountByHour
        }"
        :showHours="showHoursData1"
      ></center-reach-rate>
    </div>
  </div>
</template>

<script>
// import { queryToMD5 } from "@/utils/md5_query"
import selectCity from './city'
import onlinePlatform from '../onlinePlatform'
import centerReachRate from '../centerReachRate'
import dotMap from './sctterMap'
import dotMapDistrict from './sctterMapQu'
import digitalFlop from '../../decorations/digitalFlop'
import {mapState} from 'vuex'
import {cityData} from '@/view/bigscreen/assets/util/index'

export default {
  name: 'center_chengdu',
  components: {
    selectCity,
    digitalFlop,
    onlinePlatform,
    centerReachRate,
    dotMap,
    dotMapDistrict
  },
  props: ['contentData', 'sumData', 'onlineDevice', 'topList'],
  data() {
    return {
      timer: null, // 时间对象
      curArea: null, // 当前区域
      centerData: {
        totalLocaleCount: 0, // 安装点总数
        totalDeviceCount: 0, // 监测点总数
        onlineDeviceCount: 0, // 在线数
        redPm25Amount: 0, // PM2.5实时减排量
        redPm10Amount: 0, // PM10实时减排量
        complianceDeviceCount: 0, // 达标数
        notComplianceDeviceCount: 0, // 超标数
        redVocsAmount: 0, // 非甲烷总烃减排量昨日
        redEmissionAmount: 0, // 油烟实时减排总量
        onlineDeviceCountByDate: [], // 台数
        complianceDeviceRatioByDate: [] // 达标率
      },
      emphasisSomeDot: {
        show: false,
        type: 0
      },
      showMore: true,
      countForShowLabelLine: 1, // 保证事件只触发一次,
      showHoursData0: 0,
      showHoursData1: 0,
      selectedArea: {
        name: '成都市',
        level: 2,
        value: '成都市',
        coordinate: [103.9, 30.77],
        zoom: 9
      },
      showSelectCity: true
    }
  },
  mounted() {
    if (this.contentData.level === 'district') {
      this.showSelectCity = false
      this.selectedArea = cityData.find(e => e.code === this.contentData.code)
    }
  },
  computed: {
    ...mapState(['districtInfoType'])
  },
  methods: {
    switchHoursAndDate(type) {
      switch (type) {
        case 0:
          this.showHoursData0 = (this.showHoursData0 + 1) % 2
          break
        case 1:
          this.showHoursData1 = (this.showHoursData1 + 1) % 2
          break
      }
    },
    /**
     * @description 选择区域
     * @param {Object} area 区域
     */
    selectCityChange(area, n) {
      this.selectedArea = Object.assign({}, area)
      // this.curArea = Object.assign({}, area, this.contentData)
      this.$emit('cityChange', area)
    },
    switchEmphasisDot(type, e) {
      const centerTop = document.getElementById('center-top')
      const clickedList = centerTop.getElementsByClassName('switch-emphasis-dot clicked')
      const clickableList = centerTop.getElementsByClassName('clickable')
      const index = Array.from(clickableList).indexOf(e.currentTarget)
      for (let i = 1; i < clickableList.length; i++) {
        if (i !== index) {
          clickableList[i].classList.remove('clicked')
        }
      }
      e.currentTarget.classList.toggle('clicked')
      this.emphasisSomeDot = {
        show: clickedList.length ? true : false,
        type: type
      }
    },
    handleLeftContentClick(type, e) {
      e.currentTarget.classList.toggle('clicked')
      if (this.contentData.level === 'country') return
      if (this.$store.state.districtInfoType !== type) {
        this.showMore = true
      } else {
        this.showMore = !this.showMore
      }
      this.$store.commit('setShowMoreDistrictInfo', {isShow: this.showMore, type: this.showMore ? type : 0})
    }
  }
}
</script>

<style lang="scss">
.center_block {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 16px;
  width: 100%;
  height: 90vh;

  .clickable:hover {
    cursor: pointer;
  }

  .map_block {
    flex: 1;
    height: 60vh;
    // margin-top: 14px;
  }

  .data_box {
    display: flex;
    height: 7vh;
    align-items: center;

    ul {
      flex: 1;
      display: flex;
      justify-content: space-between;
      // flex-wrap: nowrap;
      // padding-left: 94px;
      margin: 0;

      li {
        // width: 12.5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 10px;

        h3 {
          font-size: 16px;
          color: #B2F2F6;
          margin: 0 0 10px;
          font-weight: normal;
          text-align: center;
        }
      }
    }

    ul .clicked {
      background-color: #0e265d;
      opacity: 0.7;
    }
  }

  .bottom_block {
    display: flex;
    height: 13vh;

    > div {
      flex: 1;
    }
  }

}
</style>
