<template>
  <div class="reach_rate_page">
    <dec-block-title>达标率TOP5(%)</dec-block-title>
    <dv-charts @click.native="handleClick" style="cursor: pointer" :option="option"/>
  </div>
</template>

<script>
import decBlockTitle from '../../decorations/blockTitle2'
import {isEmptyObj} from "@/util";
// import { queryToMD5 } from "@/utils/md5_query"

export default {
  name: 'reachRate_chengdu',
  components: {
    decBlockTitle
  },
  props: {
    showMore: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    topList: {
      type: Object,
      default: () => {
        return {}
      }
    },
    onlineDevice: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  mounted() {
    this.init()
  },
  data() {
    return {
      option: {},
      allCompliance: [],
      complianceRateBar: [],
      loading: true
    }
  },
  methods: {
    init() {
      this.loading = true
      if (isEmptyObj(this.onlineDevice)
        || isEmptyObj(this.topList))
        return
      this.complianceRateBar = []
      this.topList.compliance.map((item) => {
        let it = {}
        this.onlineDevice.device.map((vo) => {
          if (item.K.split('/').join('') === vo.K.split('/').join('')) {
            it.V = vo.V === 0 ? 0 : ((item.V * 100) / vo.V).toFixed(2) * 1
            it.N = vo.N
            this.complianceRateBar.push(it)
          }

        })
        return item
      })
      this.complianceRateBar.sort((o1, o2) => {
        if (o1.V < o2.V) {
          return 1
        } else if (o1.V > o2.V) {
          return -1
        } else {
          return 0
        }
      })
      this.allCompliance = []
      this.topList.compliance.map((item) => {
        this.onlineDevice.device.map((vo) => {
          let it = {}
          if (item.K.split('/').join('') === vo.K.split('/').join('')) {
            it.V = vo.V === 0 ? 0 : ((item.V * 100) / vo.V).toFixed(2) * 1
            it.N = vo.N
            this.allCompliance.push(it)
          }
        })
        return item
      })
      this.allCompliance.sort((o1, o2) => {
        if (o1.V < o2.V) {
          return 1
        } else if (o1.V > o2.V) {
          return -1
        } else {
          return 0
        }
      })
      this.setData()
    },
    setData() {
      if (!this.complianceRateBar) {
        return false
      }
      if (this.complianceRateBar.length === 0 && this.allCompliance.length === 0) {
        this.option = {}
        return
      }
      if (!this.showMore) {
        this.option = {
          // color: '#fff',
          xAxis: {
            min: 0,
            axisLine: {
              style: {
                stroke: '#324D4D',
                lineDash: [5, 2]
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              style: {
                fontSize: 11,
                fill: '#D0D0D0'
              }
            },
            splitLine: {
              stroke: 'red',
              lineWidth: 1
            },
            data: this.allCompliance.filter((item, index) => index < 5).map(item => {
              return item.N || ""
            })
          },
          yAxis: {
            min: 0,
            max: 100,
            data: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              style: {
                fontSize: 11,
                fill: '#D0D0D0'
              }
            },
            splitLine: {
              style: {
                stroke: '#324D4D',
                lineWidth: 1,
                lineDash: [5, 2]
              }
            }
          },
          series: [
            {
              xAxisIndex: 0,
              yAxisIndex: 0,
              data: this.allCompliance.filter((item, index) => index < 5).map(item => {
                return item.V
              }),
              type: 'bar',
              barWidth: 20,
              barStyle: {
                fill: '#24FFFC'
              }
            }
          ],
          grid: {
            left: 30,
            top: 20,
            right: 0,
            bottom: 20
          }
        }
      } else {
        this.option = {
          yAxis: {
            min: 0,
            axisLine: {
              style: {
                stroke: '#324D4D',
                lineDash: [5, 2]
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              style: {
                fontSize: 11,
                fill: '#D0D0D0',
                rotate: 45
              }
            },
            splitLine: {
              show: false
            },
            data: this.allCompliance.map(item => {
              return item.N || ""
            }).reverse()
          },
          xAxis: {
            min: 0,
            max: 100,
            data: 'value',
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              style: {
                fontSize: 11,
                fill: '#D0D0D0'
              }
            },
            splitLine: {
              show: true,
              style: {
                stroke: '#324D4D',
                lineWidth: 1,
                lineDash: [5, 2]
              }
            }
          },
          series: [
            {
              name: '昨日',
              type: 'bar',
              xAxisIndex: 0,
              yAxisIndex: 0,
              data: this.allCompliance.map(item => {
                return item.V
              }).reverse(),
              barWidth: 20,
              barStyle: {
                fill: '#24FFFC'
              }
            }
          ],
          grid: {
            right: 5,
            left: 45,
            bottom: 20,
            top: 30
          }
        }
      }
    },
    handleClick() {
      this.$emit('clicked')
    }
  },
  watch: {
    showMore: {
      handler: function () {
        this.init()
      },
    },
    topList: {
      handler: function () {
        this.init()
      },
      deep: true
    },
    onlineDevice: {
      handler: function () {
        this.init()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.reach_rate_page {
  width: 100%;
  // height: 33%;
  box-sizing: border-box;

  .dv-charts-container {
    height: calc(100% - 45px);
  }
}
</style>
